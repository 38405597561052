import { ContactGroup } from '@frontend/contact-group';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../common/BootstrapValues';
import AddressBadge from '../../components/badges/AddressBadge';
import ContactBadge from '../../components/badges/contact-badge/contact-badge.component';
import ContactGroupBadge from '../../components/badges/contact-group-badge/contact-group-badge.component';
import Spinner from '../../components/loading/Spinner';
import ModalSectionTitle from '../../components/modals/ModalSectionTitle';
import ObjectField from '../../components/modals/ObjectField';
import useContactGroupDetail from './contact-group-detail-modal.controller';

interface ContactGroupDetailProps {
    contactGroup?: ContactGroup;
}

export default function ContactGroupDetail(props: ContactGroupDetailProps) {
    const viewProps = useContactGroupDetail(props);

    if (props.contactGroup === undefined || viewProps.contactGroupsContacts === null) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }
    return (
        <div className='d-flex flex-column'>
            <ModalSectionTitle
                title={
                    <FormattedMessage
                        id='ContactGroupDetail.infofield.title'
                        description='The infofield title on the contactgroup detail component.'
                        defaultMessage='Info'
                    />
                }
                hideDivider={true}
            />

            <ObjectField
                fieldName={
                    <FormattedMessage
                        id='ContactGroupDetail.contactGroup.id'
                        description={'The contact group id on the contact group detail component'}
                        defaultMessage='Address'
                    />
                }
                value={
                    <AddressBadge
                        address={props.contactGroup.address}
                        city={props.contactGroup.city}
                        zip={props.contactGroup.zip}
                    />
                }
            />
            {viewProps.contactGroupsChildrenList!.length > 0 && !!viewProps.contactGroupsChildrenList && (
                <>
                    <hr className='horizontal dark mt-0' />
                    <h5 className='d-flex justify-content-center'>
                        <FormattedMessage
                            id='ContactGroupDetail.contactGroup.Children'
                            description={'The children of this contact group on the contact group detail component'}
                            defaultMessage='Children'
                        />
                    </h5>
                    <div className='row'>
                        {viewProps.contactGroupsChildrenList.map((c) => (
                            <div
                                className='col-6 d-flex flex-row'
                                key={c.id}>
                                <ContactGroupBadge
                                    contactGroupId={c.id.toString()}
                                    contactGroup={c}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
            {viewProps.contactGroupsParent !== undefined && (
                <>
                    <hr className='horizontal dark mt-0' />
                    <h5 className='d-flex justify-content-center'>
                        <FormattedMessage
                            id='ContactGroupDetail.contactGroup.Parent'
                            description={'The parent of this contact group on the contact groups detail component'}
                            defaultMessage='Parent'
                        />
                    </h5>
                    <div className='d-flex justify-content-center'>
                        <ContactGroupBadge
                            contactGroupId={viewProps.contactGroupsParent.id.toString()}
                            contactGroup={viewProps.contactGroupsParent}
                        />
                    </div>
                </>
            )}
            {viewProps.contactGroupsContacts.results.length > 0 ? (
                <>
                    <hr className='horizontal dark mt-0' />
                    <h5 className='d-flex justify-content-center'>
                        <FormattedMessage
                            id='ContactGroupsDetail.contactGroup.Contacts'
                            description={'The contacts of this contact group on the contact group detai component'}
                            defaultMessage='Contacts'
                        />
                    </h5>
                    <div className='row'>
                        {viewProps.contactGroupsContacts.results.map((c) => (
                            <div
                                key={c.id}
                                className='col-6 d-flex flex-row'>
                                <ContactBadge
                                    contactId={c.id.toString()}
                                    contact={c}
                                />
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
}
