import { HiExclamationCircle, HiQuestionMarkCircle } from 'react-icons/hi';
import { IoIosAddCircle } from 'react-icons/io';
import { IoArrowDownCircle, IoArrowUpCircle, IoCheckmarkCircle } from 'react-icons/io5';
import { MdAccessTimeFilled, MdCancel, MdRemoveCircle } from 'react-icons/md';

import { TransactionEvent, TransactionEventState, isStatusUpdateEvent } from '../../../common/TransactionEvents';
import TimeLineItemTextField from './TimeLineItemTextField';

export default function StatusUpdateEvent(props: { event: TransactionEvent; from?: boolean }) {
    if (!isStatusUpdateEvent(props.event.event_data)) return <></>;

    const field = props.from ? props.event.event_data.state_from : props.event.event_data.state_to;
    switch (field) {
        //New
        case TransactionEventState.NEW:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoIosAddCircle className='text-success display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.created_date}
                    />
                </div>
            );
        //Scheduling
        case TransactionEventState.SCHEDULE_INIT:
        case TransactionEventState.SCHEDULE_WAITING:
        case TransactionEventState.SCHEDULE_IN_PROGRESS:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdAccessTimeFilled className='text-light display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );
        case TransactionEventState.SCHEDULE_CHANGED:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdAccessTimeFilled className='text-light display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );
        case TransactionEventState.SCHEDULE_DONE:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdAccessTimeFilled className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );

        //Dropoff
        case TransactionEventState.DROPOFF_INIT:
        case TransactionEventState.DROPOFF_WAITING:
        case TransactionEventState.DROPOFF_IN_PROGRESS:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoArrowDownCircle className='text-light display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );

        case TransactionEventState.DROPOFF_DONE:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoArrowDownCircle className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );
        //Pickup
        case TransactionEventState.PICKUP_INIT:
        case TransactionEventState.PICKUP_WAITING:
        case TransactionEventState.PICKUP_IN_PROGRESS:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoArrowUpCircle className='text-light display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );

        case TransactionEventState.PICKUP_DONE:
        case TransactionEventState.SCHEDULED_PICKUP_DONE:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoArrowUpCircle className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );
        //Remove Parcel
        case TransactionEventState.REMOVE_PARCEL_INIT:
        case TransactionEventState.REMOVE_PARCEL_WAITING:
        case TransactionEventState.REMOVE_PARCEL_IN_PROGRESS:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdRemoveCircle className='text-warning display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );

        case TransactionEventState.REMOVE_PARCEL_DONE:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdRemoveCircle className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );

        //Canceled
        case TransactionEventState.DROPOFF_CANCELLED:
        case TransactionEventState.PICKUP_CANCELLED:
        case TransactionEventState.CANCELLED:
        case TransactionEventState.SCHEDULE_CANCELLED:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdCancel className='text-warning display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );

        //Cleaned
        case TransactionEventState.DROPOFF_CLEANED:
        case TransactionEventState.PICKUP_CLEANED:
        case TransactionEventState.REMOVE_PARCEL_CLEANED:
        case TransactionEventState.PICKUP_CANCELLED_CLEANED:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoCheckmarkCircle className='text-success display-1' />
                    </span>
                    <TimeLineItemTextField title={field} />
                </div>
            );

        //Timeout
        case TransactionEventState.DROPOFF_TIMEOUT:
        case TransactionEventState.PICKUP_TIMEOUT:
        case TransactionEventState.REMOVE_PARCEL_TIMEOUT:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdAccessTimeFilled className='text-warning display-1' />
                    </span>

                    <TimeLineItemTextField
                        title={`${props.event.times !== undefined && props.event.times > 1 ? props.event.times + 'x ' : ''} ${field}`}
                        date={props.event.event_date}
                    />
                </div>
            );

        //Done
        case TransactionEventState.DONE:
        case TransactionEventState.ERROR_DONE:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoCheckmarkCircle className='text-success display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );

        //Error
        case TransactionEventState.ERROR:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <HiExclamationCircle className='text-danger display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );

        //Unknown
        default:
            return (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <HiQuestionMarkCircle className='text-warning display-1' />
                    </span>
                    <TimeLineItemTextField
                        title={field}
                        date={props.event.event_date}
                    />
                </div>
            );
    }
}
