import { Category } from '@frontend/category';
import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../common/BootstrapValues';
import Filter from '../../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import CreateModal from '../../../components/modals/CreateModal';
import Table from '../../../components/tables/Table';
import CategoryDetailModal from '../category-detail/category-detail-modal/category-detail-modal.component';
import CreateCategory from '../forms/CreateCategory';
import CategoryListElement from './CategoryListElement';
import useCategoryList from './category-list.controller';

const CATEGORY_LIST_ID = 'CategoryList';
export default function CategoryList() {
    const viewProps = useCategoryList();

    if (viewProps.filteredCategories === null) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }

    return (
        <>
            <div
                className='card'
                id={CATEGORY_LIST_ID}>
                <div className='card-header d-flex'>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <div className='d-flex flex-row justify-content-between align-items-end w-100'>
                            <HorizontalButtonGroup
                                buttons={[
                                    {
                                        type: ClassType.PRIMARY,
                                        hide: false,
                                        text: (
                                            <FormattedMessage
                                                id='CategoryList.CreateCategory'
                                                description='Button for creating category'
                                                defaultMessage='Create Category'
                                            />
                                        ),
                                        id: 'CategoryList.CreateCategoryButton',
                                        onClick: () => viewProps.changeCreateCategoryOpen(true),
                                        icon: FaPlus
                                    }
                                ]}
                                direction='left'
                            />
                            <div className='ms-md-auto pe-md-3 d-flex align-items-center'>
                                <Filter
                                    objects={viewProps.filteredCategories}
                                    filterKeys={['name']}
                                    filterValue={(value) => viewProps.setFilterValue(value.toLowerCase())}
                                    filterCallback={viewProps.changeTextFilter}
                                    useSearch
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    columns={viewProps.columns}
                    data={viewProps.data}
                    onSelectElement={viewProps.changeSelectedCategory}
                    RowElement={CategoryListElement}
                    expandEnabled={true}
                    id='CategoryList-Table'
                    sortKeys={viewProps.sortKeys}
                    customSorting={(page, sortBy, sortOrder) => {
                        if (viewProps.sortKeys.includes(sortBy)) {
                            if (sortOrder === undefined) {
                                viewProps.changeCustomSorting(undefined);
                            } else {
                                viewProps.changeCustomSorting({ column: sortBy, orderBy: sortOrder });
                            }
                        }
                    }}
                />
            </div>
            {viewProps.selectedCategory && (
                <CategoryDetailModal
                    category={viewProps.selectedCategory}
                    show={!!viewProps.selectedCategory}
                    handleClose={() => viewProps.changeSelectedCategory(undefined)}
                    customWidth={30}
                />
            )}
            {viewProps.createCategoryOpen && (
                <CreateModal
                    handleClose={() => viewProps.changeCreateCategoryOpen(false)}
                    header='Create Category'
                    show={viewProps.createCategoryOpen}>
                    <CreateCategory handleClose={() => viewProps.changeCreateCategoryOpen(false)} />
                </CreateModal>
            )}
        </>
    );
}

export interface TableCategory extends Category {
    subRows: Category[];
}
