const development = process.env['NX_BUILD_ENV'] === 'development';
const test = process.env['NX_BUILD_ENV'] === 'test';
const production = process.env['NX_BUILD_ENV'] === 'production';

export const FEATURES = {
    dashboardResponsiveWidgets: development || test || production,
    extendedCreateTransactionForm: development || test,
    lastWarehouseActivity: development || test,
    pubSub: development || test || production
};
