import { ClassType } from '@frontend/common';
import { FaInfo, FaTrash } from 'react-icons/fa';
import { NotificationCenterItem, UseNotificationCenter } from 'react-toastify/addons/use-notification-center/useNotificationCenter';

const NotificationItem = (props: NotificationItemProps) => {
    return (
        <article className='notification-item mb-2'>
            <div style={{ width: '32px' }}>{props.notification.icon ? (props.notification.icon as React.ReactNode) : <FaInfo size={32} />}</div>
            <div>
                <div>{props.notification.content as React.ReactNode}</div>
            </div>
            <NotificationItemActions
                markAsRead={props.markAsRead}
                remove={props.remove}
                notification={props.notification}
                delete={props.delete}
            />
        </article>
    );
};

export default NotificationItem;

export const NotificationItemActions = (props: NotificationItemProps) => {
    return (
        <div className='notification-item-actions-container'>
            {/* {props.notification.read ? (
                <FaCheck color='green' />
            ) : (
                <button
                    className='item-action-button'
                    title='Mark as read'
                    onClick={() => props.remove(props.notification.id)}>
                    <PulsatingDot />
                </button>
            )} */}
            <button
                className='item-action-button'
                onClick={() => {
                    props.remove(props.notification.id);
                    props.notification.data && props.delete((props.notification.data as any).id);
                }}>
                <FaTrash color={ClassType.PRIMARY} />
            </button>
        </div>
    );
};

interface NotificationItemProps extends Pick<UseNotificationCenter<object>, 'markAsRead' | 'remove'> {
    notification: NotificationCenterItem;
    delete: (id: number) => void;
}
