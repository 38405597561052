import { Shop, fetchShopById } from '@frontend/shop';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SpotLayout } from '../../api/SpotLayouts';
import { Spot } from '../../api/Spots';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchSpotLayoutById } from '../../store/spotLayoutSlice';
import { fetchSpotById } from '../../store/spotSlice';

interface ViewProps {
    breadcrumbs: string[];
}

export const useUrlBreadcrumbs = (): ViewProps => {
    const location = useLocation();
    const [breadcrumbs, changeBreadcrumbs] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const store = useAppSelector((state) => state);

    useEffect(() => {
        const locations = location.pathname.split('/');
        locations.shift();
        const resolveBreadcrumbs = async () => {
            const res = await getBreadcrumbsData(locations, dispatch, store);
            changeBreadcrumbs(
                res.map((b: any) => {
                    return b.path;
                })
            );
        };
        resolveBreadcrumbs();
    }, [location]);

    return {
        breadcrumbs
    };
};

async function getBreadcrumbsData(locations: string[], dispatch: any, store: any) {
    const regex = /%20/;
    const arr = await Promise.all(
        locations.map(async (pathName, index) => {
            if (regex.exec(pathName)) {
                return { path: locations[index - 1], parent: null };
            }
            if (index === 0) {
                return { path: pathName, parenpath: null };
            } else {
                const previousPath = locations[index - 1];
                let path = pathName;
                const parent = previousPath;

                if (previousPath === 'catalogues') {
                    const found = store.shops.shopList?.find((s: Shop) => s.id.toString() === pathName);
                    if (!found) {
                        const result = await dispatch(fetchShopById(pathName));
                        if (result.type.includes('fulfilled')) {
                            path = (result.payload as Shop).name;
                        }
                    } else {
                        path = found.name;
                    }
                } else if (previousPath === 'spots' && pathName !== 'layout') {
                    const found = store.spots.spotList?.find((s: Spot) => s.id == pathName);
                    if (!found) {
                        const result = await dispatch(fetchSpotById(pathName));
                        if (result.type.includes('fulfilled')) {
                            path = (result.payload as Spot).name;
                        }
                    } else {
                        path = found.name;
                    }
                } else if (previousPath === 'layout') {
                    const layout = store.spotLayouts.spotLayoutList?.find((s: SpotLayout) => s.id.toString() == pathName);
                    if (!layout) {
                        const result = await dispatch(fetchSpotLayoutById(pathName));
                        if (result.type.includes('fulfilled')) {
                            const spot = result.payload.spot;
                            const found = store.spots.spotList?.find((s: Spot) => s.id == spot);
                            if (!found) {
                                const result = await dispatch(fetchSpotById(spot));
                                if (result.type.includes('fulfilled')) {
                                    path = (result.payload as Spot).name;
                                }
                            }
                        }
                    } else {
                        const spot = layout.spot;
                        const found = store.spots.spotList?.find((s: Spot) => s.id == spot);
                        if (!found) {
                            const result = await dispatch(fetchSpotById(spot));
                            if (result.type.includes('fulfilled')) {
                                path = (result.payload as Spot).name;
                            }
                        } else path = found.name;
                    }
                }
                return { path, parent };
            }
        })
    );
    return arr;
}
