import { Transaction, TransactionResponse, fetchTransactions } from '@frontend/transaction';
import { useEffect, useState } from 'react';

import { BulkTransaction } from '../../../api/BulkTransactions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchBulkTransactionById } from '../../../store/bulkTransactionSlice';
import { BulkTransactionDetailModalWrapperProps } from './bulk-transaction-detail-modal-wrapper.component';

interface ViewProps {
    transactions?: Transaction[];
    bulkTransaction?: BulkTransaction;
}

const useBulkTransactionDetailModalWrapper = (props: BulkTransactionDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const bulkTransactionState = useAppSelector((state) => state.bulkTransactions);
    const [transactions, changeTransactions] = useState<Transaction[]>([]);
    const [bulkTransaction, changeBulkTransaction] = useState<BulkTransaction | undefined>(undefined);

    useEffect(() => {
        const found = bulkTransactionState.bulkTransactionResponseList.results.find((b) => b.id == props.id);
        if (found) {
            changeBulkTransaction(found);
        } else {
            dispatch(fetchBulkTransactionById(props.id));
        }
    }, [props.id]);

    useEffect(() => {
        if (bulkTransaction) {
            dispatch(fetchTransactions({ account: account?.toString(), page: '1', page_size: '1000', bulk_transaction: bulkTransaction.id.toString() })).then(
                (res) => {
                    changeTransactions((res.payload as TransactionResponse).results);
                }
            );
        }
    }, [bulkTransaction]);

    return { transactions, bulkTransaction };
};

export default useBulkTransactionDetailModalWrapper;
