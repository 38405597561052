import { FormattedMessage } from 'react-intl';

import AccessCode from '../../../components/access-code/access-code.component';
import CheckBox from '../../../components/forms/check/CheckBox';
import EmailInput from '../../../components/forms/emailInput/EmailInput';
import PasswordInput from '../../../components/forms/passwordInput/PasswordInput';
import PhoneInput from '../../../components/forms/phoneInput/PhoneInput';
import SelectInput from '../../../components/forms/select/Select';
import TextInput from '../../../components/forms/textInput/TextInput';
import CreateModal from '../../../components/modals/CreateModal';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { Languages } from '../../../utils/FormUtils';
import AccountSelect from '../../accounts/account-select/AccountSelect';
import RoleSelectModal from '../roleselect/RoleSelectModal';
import useCreateUser from './create-user.controller';

const USER_CREATE_MODAL_ID = 'UserCreateModal';

export interface CreateUserFormProps {
    handleClose: () => void;
}

const CreateUserForm = (props: CreateUserFormProps) => {
    const viewProps = useCreateUser(props);

    return (
        <>
            <form
                id={USER_CREATE_MODAL_ID}
                method='post'
                autoComplete='off'
                onSubmit={(e) => {
                    e.preventDefault();
                    viewProps.changeSubmitted(true);
                }}>
                <div
                    className='modal-body'
                    id='create-user-modal'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <EmailInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Email'
                                        description={'Form label for email'}
                                        defaultMessage='Email'></FormattedMessage>
                                }
                                value={viewProps.formResult.email}
                                onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, email: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.Email'
                                        description={'Error message for email'}
                                        defaultMessage='Please enter a valid email'
                                    />
                                }
                                isValidCallback={(value) => {
                                    viewProps.changeEmailValid(value);
                                }}
                                id='CreateUserForm-EmailInput'
                                required
                                submitted={viewProps.submitted}
                                noAutoComplete
                            />
                        </div>
                        <div className='col-md-6'>
                            <PasswordInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Password'
                                        description={'Form label for password'}
                                        defaultMessage='Password'></FormattedMessage>
                                }
                                value={viewProps.formResult.password}
                                onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, password: value })}
                                required
                                strength
                                compare={viewProps.formResult.email}
                                isValidCallback={(value) => {
                                    viewProps.changeFormValid({ ...viewProps.formValid, password: value });
                                }}
                                id='CreateUserForm-PasswordInput'
                                noAutoComplete
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <TextInput
                                required={viewProps.shouldCreateContact}
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.firstName'
                                        description={'Form label for first name'}
                                        defaultMessage='First name'></FormattedMessage>
                                }
                                value={viewProps.formResult.first_name}
                                onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, first_name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.firstName'
                                        description={'Error message for first name'}
                                        defaultMessage='Please enter a valid first name'
                                    />
                                }
                                isValidCallback={(value) => {
                                    viewProps.changeFormValid({ ...viewProps.formValid, first_name: value });
                                }}
                                submitted={viewProps.submitted}
                                id='CreateUserForm-FirstNameInput'
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.lastName'
                                        description={'Form label for last name'}
                                        defaultMessage='Last name'></FormattedMessage>
                                }
                                value={viewProps.formResult.last_name}
                                onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, last_name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.lastName'
                                        description={'Error message for last name'}
                                        defaultMessage='Please enter a valid last name'
                                    />
                                }
                                isValidCallback={(value) => {
                                    viewProps.changeFormValid({ ...viewProps.formValid, last_name: value });
                                }}
                                submitted={viewProps.submitted}
                                id='CreateUserForm-LastNameInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <PhoneInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Phone'
                                        description={'Form label for phone number'}
                                        defaultMessage='Phone number'
                                    />
                                }
                                submitted={viewProps.submitted}
                                value={viewProps.formResult.phone}
                                onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, phone: value.toString() })}
                                isValidCallback={(value) => {
                                    viewProps.changeFormValid({ ...viewProps.formValid, phone: value });
                                }}
                                id='CreateUserForm-PhoneNumberInput'
                            />
                        </div>
                        <div className='col-md-6'>
                            <PhoneInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Mobile'
                                        description={'Form label for mobile number'}
                                        defaultMessage='Mobile number'
                                    />
                                }
                                submitted={viewProps.submitted}
                                value={viewProps.formResult.mobile}
                                onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, mobile: value.toString() })}
                                isValidCallback={(value) => {
                                    viewProps.changeFormValid({ ...viewProps.formValid, mobile: value });
                                }}
                                id='CreateUserForm-MobileNumberInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Language'
                                        description={'Label for selecting language'}
                                        defaultMessage='Language'></FormattedMessage>
                                }
                                submitted={viewProps.submitted}
                                options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                                value={viewProps.formResult.language}
                                onChange={(res: any) => {
                                    viewProps.changeFormResult({ ...viewProps.formResult, language: res });
                                }}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.Language'
                                        description={'Error message for language'}
                                        defaultMessage='Please select a valid language'
                                    />
                                }
                                required
                                isValidCallback={(value) => {
                                    viewProps.changeFormValid({ ...viewProps.formValid, language: value });
                                }}
                                id='CreateUserForm-LanguageSelect'
                            />
                        </div>
                        <div className='col-md-6'>
                            {viewProps.isSuperAdmin && (
                                <>
                                    <AccountSelect
                                        label={
                                            <FormattedMessage
                                                id='CreateUserForm.Form.Label.Accounts'
                                                description={'Label for adding accounts in the CreateUseForm'}
                                                defaultMessage={'Accounts'}
                                            />
                                        }
                                        submitted={viewProps.submitted}
                                        required
                                        errorMessage={
                                            <FormattedMessage
                                                id='CreateUserForm.Error.Accounts'
                                                description={'Error message for accounts'}
                                                defaultMessage='Please select a valid account'
                                            />
                                        }
                                        isMulti
                                        id='CreateUserForm-AccountsSelect'
                                        isValidCallback={(value) => viewProps.changeFormValid({ ...viewProps.formValid, accounts: value })}
                                        value={viewProps.formResult.account_members}
                                        placeholder='Select account...'
                                        isClearable={true}
                                        onChange={(value: any) =>
                                            viewProps.changeFormResult({ ...viewProps.formResult, account_members: viewProps.generateAccountMembers(value) })
                                        }
                                        options={viewProps.accountMemberList.map((acc) => ({ value: acc.account.id, label: acc.account.name }))}
                                    />
                                    {viewProps.formResult.account_members.length > 0 && (
                                        <button
                                            type='button'
                                            className='btn bg-gradient-primary'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                viewProps.changeRoleSelectOpen(true);
                                            }}>
                                            Change roles
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4 mt-4'>
                            <CheckBox
                                checked={viewProps.shouldCreateContact}
                                submitted={viewProps.submitted}
                                label='Create contact?'
                                onChange={(v) => {
                                    viewProps.changeShouldCreateContact(v);
                                    if (v === false) {
                                        viewProps.changeShouldCreateAccessCode(false);
                                        viewProps.changeAccessCode({});
                                    }
                                }}
                            />
                        </div>
                        {viewProps.shouldCreateContact && (
                            <AccessCode
                                submitted={viewProps.submitted}
                                createdContact={viewProps.createdContact}
                                callback={viewProps.callback}
                            />
                        )}
                    </div>
                </div>

                <CreateModalFooter
                    handleClose={() => viewProps.handleCancel()}
                    onSubmit={() => undefined}
                    disabled={!Object.values(viewProps.formValid).every((v) => v == true) || !viewProps.emailValid}
                />
            </form>
            {viewProps.roleSelectOpen && (
                <CreateModal
                    handleClose={() => viewProps.changeRoleSelectOpen(false)}
                    show={viewProps.roleSelectOpen}
                    header='Change roles'
                    customWidth={100}>
                    <RoleSelectModal
                        handleClose={() => viewProps.changeRoleSelectOpen(false)}
                        accounts={viewProps.formResult.account_members}
                        onChange={(value) => viewProps.changeFormResult({ ...viewProps.formResult, account_members: value })}
                    />
                </CreateModal>
            )}
        </>
    );
};

export default CreateUserForm;

export const userCreatedSuccess = (
    <FormattedMessage
        id='CreateUserForm.Created.User.Success'
        description={'Message when successfully creating a user'}
        defaultMessage='Your user was successfully created'></FormattedMessage>
);

export const userFoundMessage = (
    <FormattedMessage
        id='CreateUserForm.Found.User.Success'
        description={'Message when finding a user that already exists'}
        defaultMessage='A user with this username already exists, please provide a different username'></FormattedMessage>
);

export const userCreatedError = (
    <FormattedMessage
        id='CreateUserForm.Error.User.Create'
        description={'Error message when creating a user'}
        defaultMessage='An error occurred while creating this user'></FormattedMessage>
);
