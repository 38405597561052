import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import useBadgeTemplate from './badge-template.controller';

export interface Props {
    type: DetailModalType;
    objectId?: string;
    children: React.ReactNode;
}

const BadgeTemplate = (props: Props) => {
    const viewProps = useBadgeTemplate(props);

    return (
        <span
            id={'badge-' + props.objectId}
            className={`badge badge-secondary ${props.objectId && 'cursor-pointer'} m-1`}
            onClick={() => props.objectId && viewProps.onClick()}>
            {props.children}
        </span>
    );
};

export default BadgeTemplate;
