import { FormattedMessage } from 'react-intl';

const Unauthorized = () => {
    return (
        <div className='card d-flex flex-column text-center m-5'>
            <h4 className='m-5'>
                <FormattedMessage
                    id='Unauthorized.description'
                    description='The description on the unauthorized page.'
                    defaultMessage='We regret to inform you that your current level of access does not permit you to view or modify the information on this platform. Nonetheless, your organization can grant you access to specific functionalities that are relevant to your role. Kindly get in touch with your organization for further details or simply wait until you receive notification of any changes to your access privileges.'
                />
            </h4>
        </div>
    );
};

export default Unauthorized;
