import { ModalProps } from '../../../../components/modals/Modal';
import SpinnerModal from '../../../../components/modals/SpinnerModal';
import CategoryDetailModal from '../category-detail-modal/category-detail-modal.component';
import useCategoryDetailModalWrapper from './category-detail-modal-wrapper.controller';

export const ID = 'category-detail-modal-wrapper';

export interface CategoryDetailModalWrapperProps extends ModalProps {
    id: string;
}

const CategoryDetailModalWrapper = (props: CategoryDetailModalWrapperProps): JSX.Element => {
    const viewProps = useCategoryDetailModalWrapper(props);

    if (!viewProps.category) {
        return (
            <SpinnerModal
                {...props}
                show={!viewProps.category}
            />
        );
    }

    return (
        <CategoryDetailModal
            category={viewProps.category}
            {...props}
            customWidth={30}
        />
    );
};

export default CategoryDetailModalWrapper;
