import Spinner from '../loading/Spinner';
import Modal, { ModalProps, ModalType } from './Modal';

const SpinnerModal = (props: ModalProps) => {
    return (
        <Modal
            {...props}
            customWidth={60}
            type={ModalType.PANE}>
            <div className='d-flex align-items-center justify-content-center h-75'>
                <Spinner />
            </div>
        </Modal>
    );
};

export default SpinnerModal;
