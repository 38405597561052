import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _, { toNumber } from 'lodash';
import { CategoryAPIClient } from './api/client';
import { CategoriesQueryParams, CategoryResponse } from './api/models';
import { Category } from './category';


interface CategoriesState {
    categoryList: Category[] | null;
    searchCategoryList: { [searchQuery: string]: Category[] };
    allCategories: CategoryResponse | null;
    status: SliceStatus;
    searchStatus: SliceStatus;
    lastSearchUpdate: { [searchQuery: string]: number };
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: CategoriesState = {
    categoryList: null,
    searchCategoryList: {},
    allCategories: null,
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    lastSearchUpdate: {},
    lastUpdate: Date.now(),
    error: null
};

export const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categoryList = action.payload;
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAllCategories.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAllCategories.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.allCategories === null) {
                    state.allCategories = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.allCategories.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.allCategories.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchAllCategories.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(addCategory.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                const category = action.payload;
                if (state.categoryList !== null) {
                    const parent = action.meta.arg.get('parent') as string;
                    if (parent !== null && typeof parent === 'string') {
                        const match = parent.match(/\/(\d+)\/$/);
                        if (match) {
                            const parentId = match[1];
                            state.categoryList = addChildToParent(state.categoryList, toNumber(parentId), category);
                        }
                    } else state.categoryList.push(category);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(addCategory.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateCategory.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                const category = action.payload;
                if (state.categoryList) {
                    const foundCategory = state.categoryList.find((c) => c.id === category.id);
                    if (foundCategory && state.categoryList) {
                        state.categoryList = state.categoryList.filter((c) => c.id !== category.id);
                    }
                    state.categoryList = updateObjectInArray(state.categoryList, category);
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(updateCategory.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(deleteCategory.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                if (action.payload === true && state.categoryList) {
                    const categoryId = action.meta.arg;
                    state.categoryList = removeChildFromParent(state.categoryList, categoryId);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchCategoryById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchCategoryById.fulfilled, (state, action) => {
                const category = action.payload;
                if (state.categoryList) {
                    const foundCategory = state.categoryList.find((c) => c.id === category.id);
                    if (foundCategory && state.categoryList) {
                        state.categoryList = state.categoryList.filter((c) => c.id !== category.id);
                    }
                    state.categoryList = updateObjectInArray(state.categoryList, category);
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchCategoryById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchCategories.pending, (state) => {
                state.searchStatus = SliceStatus.LOADING;
            })
            .addCase(searchCategories.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                if (searchQuery && !Array.isArray(searchQuery)) {
                    if (state.searchCategoryList && state.searchCategoryList[searchQuery]) {
                        state.searchCategoryList[searchQuery] = action.payload;
                    } else {
                        state.searchCategoryList = {[searchQuery]: action.payload}
                    }
                }
            })
            ;
    }
});

export const fetchCategories = createAsyncThunk<Category[], ApiQueryParams<CategoriesQueryParams>>(
    'fetchCategories',
    async (queryParams: ApiQueryParams<CategoriesQueryParams>, { rejectWithValue }) => {
        try {
            return await CategoryAPIClient.fetchCategoryTreeApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAllCategories = createAsyncThunk<CategoryResponse, ApiQueryParams<CategoriesQueryParams>>(
    'fetchAllCategories',
    async (queryParams: ApiQueryParams<CategoriesQueryParams>, { rejectWithValue }) => {
        try {
            return await CategoryAPIClient.fetchAllCategoriesApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const addCategory = createAsyncThunk<Category, FormData>('addCategory', async (category, { rejectWithValue }) => {
    try {
        return await CategoryAPIClient.createCategoryApi(category);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateCategory = createAsyncThunk<Category, { category: FormData; id: number }>(
    'updateCategory',
    async (options: { category: FormData; id: number }, { rejectWithValue }) => {
        try {
            return await CategoryAPIClient.updateCategoryApi(options.category, options.id);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteCategory = createAsyncThunk<boolean, number>('deleteCategory', async (categoryId, { rejectWithValue }) => {
    try {
        return await CategoryAPIClient.deleteCategoryApi(categoryId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchCategoryById = createAsyncThunk<Category, number>('fetchCategoryById', async (categoryId, { rejectWithValue }) => {
    try {
        return await CategoryAPIClient.fetchCategoryApi({ id: categoryId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchCategories = createAsyncThunk<Category[], ApiQueryParams<CategoriesQueryParams>>('searchCategories', async (queryParams, { rejectWithValue }) => {
    try {
        return await CategoryAPIClient.searchCategoriesApi(queryParams);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
})

export const categoryReducer =  categorySlice.reducer;

function addChildToParent(array: any[], parentId: number, newChild: any): any[] {
    const newState = _.cloneDeep(array);

    function recursiveAdd(children: any[]): any[] {
        return children.map((node) => {
            if (node.id === parentId) {
                return {
                    ...node,
                    children: [...(node.children || []), newChild]
                };
            } else if (node.children !== null && node.children.length > 0) {
                return {
                    ...node,
                    children: recursiveAdd(node.children)
                };
            }
            return node;
        });
    }

    return recursiveAdd(newState);
}

function removeChildFromParent(array: any[], idToRemove: number): any[] {
    const newState = _.cloneDeep(array);

    function recursiveRemove(children: any[]): any[] {
        return children.reduce((result, node) => {
            if (node.id === idToRemove) {
                return result;
            } else if (node.children && node.children.length > 0) {
                return [...result, { ...node, children: recursiveRemove(node.children) }];
            } else {
                return [...result, node];
            }
        }, []);
    }

    return recursiveRemove(newState);
}

function updateObjectInArray(array: any[], newObject: any): any[] {
    const newState = _.cloneDeep(array);

    function recursiveUpdate(children: any[], parentId: number | null): any[] {
        return children.flatMap((node) => {
            if (node.id !== parentId) {
                if (node.children) {
                    const foundNode = node.children.find((c: any) => c.id === newObject.id);
                    if (foundNode) {
                        const childIndex = node.children.indexOf(foundNode);
                        node.children.splice(childIndex, 1);
                    } else {
                        return [{ ...node, children: recursiveUpdate(node.children, parentId) }];
                    }
                }
            } else if (node.id === parentId) {
                if (!node.children) {
                    node.children = [];
                }
                node.children.push(newObject);
            } else if (node.children !== null && node.children.length > 0) {
                return [
                    {
                        ...node,
                        children: recursiveUpdate(node.children, parentId)
                    }
                ];
            }
            return [node];
        });
    }

    if (newObject.parent === null) {
        const objectsToRemove: any[] = [];

        const findAndRemove = (nodeArray: any[]) => {
            for (let i = 0; i < nodeArray.length; i++) {
                const node = nodeArray[i];
                if (node.id === newObject.id) {
                    objectsToRemove.push(node);
                    nodeArray.splice(i, 1);
                    i--;
                } else if (node.children && node.children.length > 0) {
                    findAndRemove(node.children);
                }
            }
        };

        findAndRemove(newState);

        // Remove any existing node with the same id
        const existingNodeIndex = newState.findIndex((node) => node.id === newObject.id);
        if (existingNodeIndex !== -1) {
            newState.splice(existingNodeIndex, 1);
        }

        // Add only the newObject to the top level
        newState.push(newObject);

        for (const objToRemove of objectsToRemove) {
            // Only push objects that were not removed from their previous location
            if (!newState.some((node) => node.id === objToRemove.id)) {
                newState.push(objToRemove);
            }
        }
    } else {
        const match = newObject.parent.match(/\/(\d+)\/$/);
        const parentID = match ? parseInt(match[1]) : NaN;
        const updatedState = recursiveUpdate(newState, parentID);
        return updatedState;
    }

    return newState;
}
