/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthenticationManager } from '@frontend/authentication';
import { FeatureFlagsProvider } from '@frontend/feature-flags';
import { fetchUserInfo } from '@frontend/user';
import React, { Suspense, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import Auth from './app/authorization/Auth';
import ShopOverview from './app/catalogue/ShopOverview';
import Workflow from './app/common/Workflow';
import Spinner from './app/components/loading/Spinner';
import DetailModalContainer from './app/components/modals/detail-modal/detail-modal-container/detail-modal-container.component';
import MainContextProvider from './app/contexts/MainContext';
import useAuthorizationConfig, { DefaultRole } from './app/hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from './app/hooks/redux';
import useAccount from './app/hooks/useAccount';
import useGetWorkflow from './app/hooks/useGetWorkflow';
import useLocale from './app/hooks/useLocale';
import useScripts from './app/hooks/useScripts';
import useSendLogsBeforeUnload from './app/hooks/useSendLogsBeforeUnload';
import CloseCurrentPage from './app/navigation/CloseCurrentPage';
import Unauthorized from './app/navigation/Unauthorized';
import Footer from './app/navigation/footer/Footer';
import Header from './app/navigation/header/Header';
import Navbar from './app/navigation/navbar/Navbar';
import ResetPassword from './app/navigation/password/reset-password/resetPassword';
import usePubSubEventListener from './app/pub-sub/usePubSubEventListener';
import UserList from './app/users/user-list/user-list.component';
import { BackendStatusUpdate } from './app/utils/BackendStatusUpdate';

const LoginModule = React.lazy(() => import('./app/navigation/authentication/Login'));
const Logout = React.lazy(() => import('./app/navigation/authentication/Logout'));
const RegisterUserInvitation = React.lazy(() => import('./app/users/invitations/RegisterUserInvitation'));
const SelectAccountMembership = React.lazy(() => import('./app/navigation/authentication/SelectAccountMembership'));
const TransactionModule = React.lazy(() => import('./app/transactions/transaction-list/transaction-list.component'));
const ScheduleTransactionPickupForm = React.lazy(() => import('./app/transactions/forms/schedule-transaction-pickup-form/ScheduleTransactionPickupForm'));
const ContactList = React.lazy(() => import('./app/contacts/contact-list/contact-list.component'));
const ContactGroupList = React.lazy(() => import('./app/contact-groups/contact-group-list/contact-group-detail.component'));
const PrinterOverview = React.lazy(() => import('./app/iot/printing/printer-list/PrinterList'));
const SpotLayoutView = React.lazy(() => import('./app/spotLayout/layout/SpotLayoutView'));
const PageNotFound = React.lazy(() => import('./app/navigation/PageNotFound'));
const UserInfoPage = React.lazy(() => import('./app/settings/user-information/UserInfoPage'));
const ChangePassword = React.lazy(() => import('./app/navigation/password/change-password/changePassword'));
const RequestPasswordReset = React.lazy(() => import('./app/navigation/password/reset-password/RequestPasswordReset'));
const SlotList = React.lazy(() => import('./app/slots/slot-list/slot-list.component'));
const SpotNavigation = React.lazy(() => import('./app/spots/spot-navigation/SpotNavigation'));
const Shops = React.lazy(() => import('./app/catalogue/Shops'));
const ReportsOverview = React.lazy(() => import('./app/reports/ReportsOverview'));
const ReportDetail = React.lazy(() => import('./app/reports/report-detail/ReportDetail'));
const Changelog = React.lazy(() => import('./app/changelog/Changelog'));
const Dashboard = React.lazy(() => import('./app/dashboard/Dashboard'));
const QRView = React.lazy(() => import('./app/qrview/QRView'));
const Support = React.lazy(() => import('./app/navigation/support/Support'));
const Logs = React.lazy(() => import('./app/logs/logs-list/LogList'));
const Iot = React.lazy(() => import('./app/iot/IotView'));
const AccessControllerView = React.lazy(() => import('./app/iot/accessControllers/accessControllers-list/AccessControllersList'));
const LostItemsView = React.lazy(() => import('./app/dashboard/Views/LostItemsView'));
const BulkTransactionModule = React.lazy(() => import('./app/transactions/bulk-transaction-list/bulk-transaction-list.component'));
const Warehouse = React.lazy(() => import('./app/dashboard/Views/warehouse/warehouse.component'));
const StockList = React.lazy(() => import('./app/stock-list/stock-list-component'));
const ProductList = React.lazy(() => import('./app/catalogue/products/product-list/product-list.component'));
const AttributeList = React.lazy(() => import('./app/catalogue/attributes/attribute-list/attribute-list.component'));
const CategoryList = React.lazy(() => import('./app/catalogue/categories/category-list/CategoryList'));

interface AuthContextInterface {
    auth: any;
    authCheck: (requiredPermissions: any) => boolean;
}

export const AuthContext = React.createContext<AuthContextInterface>({
    auth: {},
    authCheck: (requiredPermissions: any) => false
});

function App() {
    const [navbarPinned, changeNavbarPinned] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const { messages, locale, changeLocale } = useLocale();
    const { accountId } = useAccount();
    const [realAccountId, setRealAccountId] = useState<number>();
    const { workflow } = useGetWorkflow();

    const authConfig = useAuthorizationConfig();
    const isSuperAdmin = user.selectedMembership?.role == 'super_admin';

    useSendLogsBeforeUnload(realAccountId);
    usePubSubEventListener();

    useEffect(() => {
        if (AuthenticationManager.getInstance().hasValidToken()) dispatch(fetchUserInfo());
    }, [AuthenticationManager.getInstance().loggedIn]);

    useEffect(() => {
        if (accountId !== undefined) {
            setRealAccountId(accountId);
        }
    }, [accountId]);

    useEffect(() => {
        if (accountId) {
            const intervalId = setInterval(() => {
                BackendStatusUpdate(accountId);
            }, 5 * 60 * 1000);

            return () => {
                clearInterval(intervalId);
            };
        } else if (accountId === undefined) {
            if (realAccountId) {
                BackendStatusUpdate(realAccountId);
            }
        }
    }, [accountId, realAccountId]);

    return (
        <IntlProvider
            messages={messages}
            locale='en'
            defaultLocale={locale}>
            <BrowserRouter>
                <MainContextProvider>
                    <AuthContext.Provider value={authConfig}>
                        <FeatureFlagsProvider>
                            <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned ? 'pinned' : 'hidden'}`}>
                                <div className='min-height-300 bg-primary position-absolute w-100'></div>
                                {AuthenticationManager.getInstance().loggedIn && user.selectedMembership !== null ? <Navbar /> : <></>}

                                <main className='main-content position-relative border-radius-lg'>
                                    <Header
                                        changeLocale={changeLocale}
                                        navbarPinned={() => changeNavbarPinned(!navbarPinned)}
                                    />
                                    <div className='container-fluid ps-4'>
                                        <Suspense fallback={<Spinner show={true} />}>
                                            {AuthenticationManager.getInstance().loggedIn ? (
                                                <>
                                                    {user.selectedMembership === null ? (
                                                        <SelectAccountMembership />
                                                    ) : (
                                                        <Routes>
                                                            <Route path='/contacts'>
                                                                <Route
                                                                    index
                                                                    element={<ContactList />}
                                                                />
                                                            </Route>
                                                            <Route path='/groups'>
                                                                <Route
                                                                    index
                                                                    element={<ContactGroupList />}
                                                                />
                                                            </Route>
                                                            <Route path='/spots'>
                                                                <Route
                                                                    index
                                                                    element={<SpotNavigation />}
                                                                />
                                                                <Route
                                                                    path='/spots/:spotId/slots/*'
                                                                    element={<SlotList />}
                                                                />
                                                                <Route
                                                                    path='/spots/layout/:id'
                                                                    element={<SpotLayoutView />}
                                                                />
                                                                <Route
                                                                    path='/spots/:spotId'
                                                                    element={<Navigate to='/spots' />}
                                                                />
                                                                <Route
                                                                    path='/spots/layout'
                                                                    element={<Navigate to='/spots' />}
                                                                />
                                                            </Route>
                                                            <Route path='catalogues'>
                                                                <Route
                                                                    index
                                                                    element={<Shops />}
                                                                />
                                                                <Route
                                                                    path=':shopId'
                                                                    element={<Navigate to='/catalogues' />}
                                                                />
                                                                <Route
                                                                    path=':shopId/*'
                                                                    element={<ShopOverview />}
                                                                />
                                                            </Route>
                                                            <Route path='/reports'>
                                                                <Route
                                                                    index
                                                                    element={<ReportsOverview />}
                                                                />
                                                                <Route
                                                                    path=':reportId'
                                                                    element={<ReportDetail />}
                                                                />
                                                            </Route>
                                                            {(workflow !== Workflow.WAREHOUSE.value || isSuperAdmin) && (
                                                                <>
                                                                    <Route path='/transactions'>
                                                                        <Route
                                                                            index
                                                                            element={
                                                                                <Auth
                                                                                    role={DefaultRole.ADMIN}
                                                                                    show>
                                                                                    {workflow === undefined ? (
                                                                                        <Spinner />
                                                                                    ) : workflow === Workflow.WAREHOUSE.value ? (
                                                                                        <BulkTransactionModule />
                                                                                    ) : (
                                                                                        <TransactionModule />
                                                                                    )}
                                                                                </Auth>
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path='/transactions/:type'
                                                                            element={<TransactionModule />}
                                                                        />
                                                                        <Route path='forms'>
                                                                            <Route
                                                                                path='schedule-pickup'
                                                                                element={<ScheduleTransactionPickupForm />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route path='iot'>
                                                                        <Route
                                                                            index
                                                                            element={<Iot />}
                                                                        />
                                                                        <Route
                                                                            path='printing'
                                                                            element={<PrinterOverview />}
                                                                        />
                                                                        <Route
                                                                            path='access-controllers'
                                                                            element={<AccessControllerView />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        path='/logs'
                                                                        element={
                                                                            <Auth
                                                                                role={DefaultRole.ADMIN}
                                                                                show>
                                                                                <Logs />
                                                                            </Auth>
                                                                        }
                                                                    />
                                                                </>
                                                            )}

                                                            <Route path='/me'>
                                                                <Route
                                                                    index
                                                                    element={<UserInfoPage />}
                                                                />
                                                                <Route
                                                                    path='change-password'
                                                                    element={<ChangePassword />}
                                                                />
                                                            </Route>
                                                            <Route path='/users'>
                                                                <Route
                                                                    index
                                                                    element={<UserList />}
                                                                />
                                                            </Route>
                                                            <Route path='/changelog'>
                                                                <Route
                                                                    index
                                                                    element={<Changelog />}
                                                                />
                                                            </Route>
                                                            <Route
                                                                path='/qr/*'
                                                                element={<QRView />}
                                                            />
                                                            <Route
                                                                path='/support'
                                                                element={<Support />}
                                                            />

                                                            <Route
                                                                path='/success'
                                                                element={<CloseCurrentPage />}
                                                            />
                                                            <Route
                                                                path='/unauthorized'
                                                                element={<Unauthorized />}
                                                            />
                                                            <Route
                                                                path='/dashboard'
                                                                element={<Dashboard />}
                                                            />
                                                            <Route
                                                                path='/lost-items'
                                                                element={<LostItemsView />}
                                                            />
                                                            <Route
                                                                path='/warehouse'
                                                                element={<Warehouse />}
                                                            />
                                                            <Route
                                                                path='/stock'
                                                                element={<StockList />}
                                                            />
                                                            <Route
                                                                path='/'
                                                                element={
                                                                    <Navigate
                                                                        replace
                                                                        to='/dashboard'
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path='/logout'
                                                                element={<Logout />}
                                                            />
                                                            <Route
                                                                path='/login'
                                                                element={
                                                                    <Navigate
                                                                        replace
                                                                        to='/'
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path='/*'
                                                                element={<PageNotFound />}
                                                            />
                                                        </Routes>
                                                    )}
                                                    <DetailModalContainer />
                                                </>
                                            ) : (
                                                <Routes>
                                                    <Route
                                                        path='/login'
                                                        element={<LoginModule />}
                                                    />
                                                    <Route
                                                        path='/signup-invitation/*'
                                                        element={<RegisterUserInvitation />}
                                                    />
                                                    <Route
                                                        path='request-password-change'
                                                        element={<RequestPasswordReset />}
                                                    />
                                                    <Route
                                                        path='reset-password'
                                                        element={<ResetPassword />}
                                                    />
                                                    <Route
                                                        path='/'
                                                        element={
                                                            <Navigate
                                                                replace
                                                                to='/login'
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path='/*'
                                                        element={
                                                            <Navigate
                                                                replace
                                                                to={'/login'}
                                                            />
                                                        }
                                                    />
                                                </Routes>
                                            )}
                                        </Suspense>
                                    </div>
                                    <Footer />
                                </main>
                            </div>
                        </FeatureFlagsProvider>
                    </AuthContext.Provider>
                </MainContextProvider>
            </BrowserRouter>
            <ToastContainer />
        </IntlProvider>
    );
}

export default App;
