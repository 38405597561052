import { Logger } from '@frontend/Logger';
import { shouldFetch } from '@frontend/common';
import { ToastUtil } from '@frontend/toast-utils';
import { fetchUsers } from '@frontend/user';
import { useEffect, useMemo, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';
import { toast } from 'react-toastify';

import { Invite, sendInviteApi } from '../../api/UserInvites';
import { Locale } from '../../common/BackendEnums';
import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import DropdownButton from '../../components/dropdown/DropdownButton';
import Filter from '../../components/filters/AsyncFilter';
import EmailInput from '../../components/forms/emailInput/EmailInput';
import DefaultLanguageSelect from '../../components/forms/language/DefaultLocaleSelect';
import Spinner from '../../components/loading/Spinner';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import Table from '../../components/tables/Table';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import { deleteUserInvites, fetchUserInvites } from '../../store/userInvitesSlice';
import InviteListElement from './InviteListElement';
import InvitesListColumns from './InvitesListColumns';

interface Props {
    onClose: () => void;
}

const ManageInvitesModal = (props: Props) => {
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [sending, changeSending] = useState<boolean>(false);
    const [email, changeEmail] = useState<string>('');
    const [language, changeLanguage] = useState<Locale>(Locale.ENGLISH);
    const [emailValid, changeEmailValid] = useState<boolean>(false);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const users = useAppSelector((state) => state.user);
    const invites = useAppSelector((state) => state.userInvites);
    const [pageSize, setPageSize] = useState<number>(25);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [filteredInvites, changeFilteredInvites] = useState<Invite[] | null>();
    const [selectedInvites, changeSelectedInvites] = useState<Invite[]>([]);
    const dispatch = useAppDispatch();
    const { selectedUser } = useAccount();

    useEffect(() => {
        dispatch(fetchUserInvites({ account: accountId?.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
        if (shouldFetch(users.userListStatus, users.lastUpdate))
            dispatch(fetchUsers({ account: accountId?.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
    }, []);

    const data = useMemo(() => {
        if (filteredInvites) {
            return filteredInvites;
        } else return [];
    }, [filteredInvites]);
    const columns: Column<Invite>[] = useMemo(() => InvitesListColumns, []);

    useEffect(() => {
        changeFilteredInvites(invites.invites?.results);
    }, [invites.invites?.results]);

    if (invites.invites === null) {
        return (
            <div className='mt-5'>
                <Spinner
                    show={true}
                    type={ClassType.PRIMARY}
                />
            </div>
        );
    }

    const sendInvite = async () => {
        await sendInviteApi({
            accounts: [accountId!],
            email: email,
            language: Locale.ENGLISH
        })
            .then((res) => {
                changeSending(false);
                toast.success(
                    ...ToastUtil.generateToastConfig(
                        <FormattedMessage
                            id='inviteSuccessfullToast'
                            description='The message displayed when a invite was successfully sent to the new user.'
                            defaultMessage='Invite sent'
                        />,
                        undefined,
                        ClassType.SUCCESS
                    )
                );
                Logger.log(`${selectedUser?.email} invited ${email}`, { user: selectedUser?.id });
                dispatch(fetchUserInvites({ account: accountId?.toString(), page: (pageIndex + 1).toString(), page_size: pageSize.toString() }));
            })
            .catch((err) => {
                toast.error(
                    ...ToastUtil.generateToastConfig(
                        CommonMessage.STATUS.ERROR,
                        <FormattedMessage
                            id='inviteErrorToast'
                            description='The message displayed when an invite could not be sent.'
                            defaultMessage='Something went wrong, please make sure this user is not already invited or the email is not used by another user.'
                        />,
                        ClassType.DANGER
                    )
                );
                Logger.error(`${selectedUser?.email} failed to invite ${email}`, { user: selectedUser?.email });
                changeSending(false);
            });
    };

    const onDeleteInvites = () => {
        dispatch(deleteUserInvites(selectedInvites.map((i) => i.uuid)));
        selectedInvites.map((i) => Logger.log(`${selectedUser?.email} deleted invitiation: ${i.email}`, { user: selectedUser?.email }));
        changeShowDeleteModal(false);
    };

    return (
        <div>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-primary text-gradient'>
                    <FormattedMessage
                        id='ManageInvites.modal.header.title'
                        description='The title on the manage invites modal.'
                        defaultMessage='Manage invites'
                    />
                </h3>
                <button
                    onClick={props.onClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>

            <div className='modal-body px-0'>
                <div className='d-flex flex-row m-2 p-1'>
                    <span className='w-50 m-1'>
                        <EmailInput
                            value={email}
                            onChange={changeEmail}
                            isValidCallback={changeEmailValid}
                            submitted={false}
                        />
                    </span>
                    <span className='d-flex flex-column justify-content-end w-25 m-1'>
                        <DefaultLanguageSelect
                            submitted={false}
                            onChange={changeLanguage}
                            value={language}
                        />
                    </span>
                    <span className='d-flex flex-column justify-content-end w-25 m-1'>
                        <button
                            className='btn btn-primary'
                            disabled={!emailValid}
                            onClick={() => {
                                changeSending(true);
                                sendInvite();
                            }}>
                            <div className='d-flex flex-row align-items-center'>
                                {sending ? (
                                    <div
                                        className='spinner-border text-light spinner-border-sm'
                                        role='status'>
                                        <span className='visually-hidden'>Loading...</span>
                                    </div>
                                ) : (
                                    <FiSend />
                                )}

                                <span className='ms-2'>
                                    {
                                        <FormattedMessage
                                            id='ManageInvites.SendInvite.Button'
                                            description='Send invite button in the manage invites modal'
                                            defaultMessage='Send invite'
                                        />
                                    }
                                </span>
                            </div>
                        </button>
                    </span>
                </div>

                <hr className='horizontal dark mt-0' />

                {invites.invites !== null && (
                    <div className='p-3'>
                        <Filter
                            objects={invites.invites.results}
                            filterCallback={changeFilteredInvites}
                            filterKeys={['email']}
                        />
                    </div>
                )}
                <Table
                    data={data}
                    columns={columns}
                    pageCount={invites.invites ? Math.ceil(invites.invites.count / pageSize) : undefined}
                    next={invites.invites.next?.toString()}
                    page={(value) => setPageIndex(value)}
                    pageSize={(value) => setPageSize(value)}
                    RowElement={InviteListElement}
                    onSelectElement={() => undefined}
                    selectEnabled
                    selectedItemsCallback={changeSelectedInvites}
                />
            </div>

            <div className='modal-footer d-flex flex-row justify-content align-items-center'>
                <DropdownButton
                    buttonType='outline-secondary'
                    title={
                        <FormattedMessage
                            id='DropdownButton.Options'
                            description='Default options button.'
                            defaultMessage='Options'
                        />
                    }
                    options={[
                        {
                            disabled: selectedInvites.length === 0,
                            onClick: () => changeShowDeleteModal(true),
                            icon: IoMdTrash,
                            type: ClassType.DANGER,
                            title: (
                                <FormattedMessage
                                    id='ManageUserInvites.Options.deleteInviteOption'
                                    description='Delete invite button label'
                                    defaultMessage='Delete'
                                />
                            )
                        }
                    ]}
                />
            </div>

            {selectedInvites.length > 0 && (
                <ConfirmationModal
                    show={showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => changeShowDeleteModal(false)}
                    message={
                        <FormattedMessage
                            id='deleteInvites.confirmMessage'
                            description='The confirmation message checking if the user is sure with deleting the selected invites.'
                            defaultMessage='Are you sure you want to delete the selected invites? This action cannot be undone.'
                        />
                    }
                    onConfirm={onDeleteInvites}
                />
            )}
        </div>
    );
};

export default ManageInvitesModal;
