import React from 'react';
import { IconType } from 'react-icons/lib';

import { ClassType } from '../../common/BootstrapValues';
import Spinner from '../loading/Spinner';

interface Button {
    text: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    icon?: IconType;
    type?: ClassType;
    isLoading?: boolean;
    hide: boolean;
    id?: string;
}

interface Props {
    buttons: Button[];
    direction: 'left' | 'right';
}

const HorizontalButtonGroup: React.FC<Props> = ({ buttons, direction }) => {
    const groupedButtons = buttons.filter((b) => !b.hide);
    const flexDirection = direction === 'right' ? 'row-reverse' : 'row'; // Adjust flex direction
    const justifyContent = direction === 'right' ? 'flex-end' : 'flex-start'; // Adjust justify content

    return (
        <div style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: flexDirection, justifyContent: justifyContent }}>
            {groupedButtons.map((button, i) => {
                const marginStyle = {
                    marginRight: direction === 'right' ? '10px' : '0',
                    marginLeft: direction === 'left' ? '10px' : '0'
                };

                return (
                    <button
                        id={button.id}
                        onClick={button.onClick}
                        className={'my-0 btn ' + (button.type ? 'btn-' + button.type : 'btn-secondary')}
                        key={i}
                        style={{ ...marginStyle }}
                        disabled={button.disabled}>
                        <div className='d-flex flex-row align-items-center'>
                            {button.isLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {button.icon && <button.icon />}
                                    <span className={button.icon ? 'ps-2' : ''}>{button.text}</span>
                                </>
                            )}
                        </div>
                    </button>
                );
            })}
        </div>
    );
};

export default HorizontalButtonGroup;
