import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { FormattedMessage } from 'react-intl';

import { Slot } from '../../api/Slots';
import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import ProductBadge from '../../components/badges/product-badge/product-badge.component';
import TransactionBadge from '../../components/badges/transaction-badge/transaction-badge.component';
import Spinner from '../../components/loading/Spinner';
import CreateModal from '../../components/modals/CreateModal';
import ModalSectionTitle from '../../components/modals/ModalSectionTitle';
import ObjectField from '../../components/modals/ObjectField';
import { fetchSlotById } from '../../store/slotSlice';
import SlotSettings from '../forms/settings/SlotSettings';
import SlotLogsTimeline from '../slot-logs-timeline/SlotLogsTimeline';
import useSlotDetail from './slot-detail-controller';

interface SlotDetailProps {
    slot: Slot | undefined;
}

const SlotDetail = (props: SlotDetailProps) => {
    const viewProps = useSlotDetail(props);

    if (!props.slot) {
        <Spinner
            show={true}
            type={ClassType.LIGHT}
        />;
    }
    return (
        <>
            <div className='d-flex flex-row w-100'>
                <div className='d-flex flex-column w-100 h-100'>
                    <ObjectField
                        fieldName={
                            <FormattedMessage
                                id='SlotDetail.Detail.Title.Type'
                                description={'Detail title for type'}
                                defaultMessage='Type'
                            />
                        }
                        value={props.slot?.type}
                    />
                    <ModalSectionTitle title='Dimensions' />
                    <ObjectField
                        fieldName={
                            <FormattedMessage
                                id='SlotDetail.Detail.Title.Width'
                                description={'Detail title for width'}
                                defaultMessage='Width'
                            />
                        }
                        value={props.slot?.specification?.width}
                    />
                    <ObjectField
                        fieldName={
                            <FormattedMessage
                                id='SlotDetail.Detail.Title.Height'
                                description={'Detail title for height'}
                                defaultMessage='Height'
                            />
                        }
                        value={props.slot?.specification?.height}
                    />
                    <ObjectField
                        fieldName={
                            <FormattedMessage
                                id='SlotDetail.Detail.Title.Depth'
                                description={'Detail title for depth'}
                                defaultMessage='Depth'
                            />
                        }
                        value={props.slot?.specification?.depth}
                    />
                    <ModalSectionTitle title='Assigned Products' />
                    <div className='d-flex flex-row justify-content-around flex-wrap pb-2'>
                        {viewProps.assignedProducts == undefined ? (
                            <Spinner size='sm' />
                        ) : viewProps.assignedProducts.length == 0 ? (
                            <p>{CommonMessage.STATUS.NONE}</p>
                        ) : (
                            viewProps.assignedProducts.map((p) => (
                                <ProductBadge
                                    product={p}
                                    productId={p.id.toString()}
                                    key={p.id.toString()}
                                />
                            ))
                        )}
                    </div>
                    {viewProps.slotOccupation!.productContentListPerSlot!.length > 0 ? (
                        <>
                            <ModalSectionTitle title='Content' />
                            <div className='pb-2'>
                                {viewProps.slotOccupation!.productContentListPerSlot?.map((p: any) => (
                                    <ProductBadge
                                        product={p}
                                        productId={p.id.toString()}
                                        key={p.id}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {viewProps.slotOccupation?.activeTransactionsPerSlot && viewProps.slotOccupation.activeTransactionsPerSlot.length > 0 && (
                        <>
                            <ModalSectionTitle title='Transactions' />
                            <div className='d-flex flex-row justify-content-around flex-wrap'>
                                {viewProps.slotOccupation!.activeTransactionsPerSlot?.map((at: any) => (
                                    <span
                                        key={at.id}
                                        className='pe-3'>
                                        <TransactionBadge transactionId={at.id} />
                                    </span>
                                ))}
                            </div>
                        </>
                    )}
                    {viewProps.authorisedSuperAdmin && !!viewProps.hardwareDriverOptions && (
                        <>
                            <ModalSectionTitle title='Hardware driver options' />
                            <ObjectField
                                fieldName='IP'
                                value={
                                    viewProps.hardwareDriverOptions.data
                                        ? viewProps.hardwareDriverOptions.data[0].config.ip
                                        : viewProps.hardwareDriverOptions.ip
                                }
                            />
                            <ObjectField
                                fieldName='Board'
                                value={
                                    viewProps.hardwareDriverOptions.data
                                        ? viewProps.hardwareDriverOptions.data[0].config.board
                                        : viewProps.hardwareDriverOptions.board
                                }
                            />
                            <ObjectField
                                fieldName='Slot'
                                value={
                                    viewProps.hardwareDriverOptions.data
                                        ? viewProps.hardwareDriverOptions.data[0].config.slot
                                        : viewProps.hardwareDriverOptions.slot
                                }
                            />
                            <ObjectField
                                fieldName='Driver'
                                value={
                                    viewProps.hardwareDriverOptions.data
                                        ? viewProps.hardwareDriverOptions.data[0].driver
                                        : viewProps.spotLayoutItem?.hardware_driver
                                }
                            />
                            {!!viewProps.hardwareDriverOptions.column && (
                                <ObjectField
                                    fieldName='Column'
                                    value={viewProps.hardwareDriverOptions.column}
                                />
                            )}
                            {!!viewProps.hardwareDriverOptions.row && (
                                <ObjectField
                                    fieldName='Row'
                                    value={viewProps.hardwareDriverOptions.row}
                                />
                            )}
                        </>
                    )}
                    {viewProps.authorisedSuperAdmin && (
                        <>
                            <hr className='horizontal dark mt-0' />
                            <h5 className='d-flex justify-content-center'>
                                <FormattedMessage
                                    id='SlotDetails.Settings.Slot.Title'
                                    description={'Title of Slot settings on the SlotDetail component'}
                                    defaultMessage='Slot settings'
                                />
                            </h5>
                            {
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                    <button
                                        className='btn bg-gradient-primary'
                                        onClick={() => viewProps.changeSlotSettingsOpen(true)}>
                                        {
                                            <FormattedMessage
                                                id='SlotDetail.Settings.Button'
                                                description={'Title of the Slot settings button on the SlotDetail component'}
                                                defaultMessage='Open slot settings'
                                            />
                                        }
                                    </button>
                                </div>
                            }
                            <ObjectField
                                fieldName='Slot Active'
                                value={props.slot?.settings_is_active ? <FaCheck></FaCheck> : <ImCross></ImCross>}
                            />

                            <ObjectField
                                fieldName='Pudo transaction limit'
                                value={props.slot?.settings_transactions_limit_for_pudo}
                            />

                            <ObjectField
                                fieldName='Pudo allow multiple transactions'
                                value={props.slot?.settings_allow_multiple_pudo_pickup_transactions ? <FaCheck></FaCheck> : <ImCross></ImCross>}
                            />

                            <ObjectField
                                fieldName='Vending transaction limit'
                                value={props.slot?.settings_transactions_limit_for_vending}
                            />

                            <ObjectField
                                fieldName='Vending allow multiple transactions'
                                value={props.slot?.settings_allow_same_vending_products ? <FaCheck></FaCheck> : <ImCross></ImCross>}
                            />

                            <ObjectField
                                fieldName='Lending transaction limit'
                                value={props.slot?.settings_transactions_limit_for_lending}
                            />

                            <ObjectField
                                fieldName='Lending allow multiple transactions'
                                value={props.slot?.settings_allow_same_lending_products ? <FaCheck></FaCheck> : <ImCross></ImCross>}
                            />

                            <ObjectField
                                fieldName='Return transaction limit'
                                value={props.slot?.settings_transactions_limit_for_return}
                            />

                            <ObjectField
                                fieldName='Return allow multiple transactions'
                                value={props.slot?.settings_allow_same_return_products ? <FaCheck></FaCheck> : <ImCross></ImCross>}
                            />
                        </>
                    )}
                </div>

                {props.slot && (
                    <div className='d-flex w-50 h-100 ms-5'>
                        <SlotLogsTimeline slot={props.slot} />
                    </div>
                )}
            </div>

            {
                <CreateModal
                    handleClose={() => viewProps.changeSlotSettingsOpen(false)}
                    header={'Slot settings'}
                    show={viewProps.slotSettingsOpen}>
                    <SlotSettings
                        slot={props.slot}
                        handleClose={() => viewProps.changeSlotSettingsOpen(false)}
                        onSuccess={() => fetchSlotById(props.slot!.id)}
                    />
                </CreateModal>
            }
        </>
    );
};

export default SlotDetail;
