import { ModalProps } from '../../../components/modals/Modal';
import SpinnerModal from '../../../components/modals/SpinnerModal';
import ContactGroupDetailModal from '../contact-group-detail-modal/contact-group-detail-modal.component';
import useContactGroupDetailModalWrapper from './contact-group-detail-modal-wrapper.controller';

export const ID = 'contact-group-detail-modal-wrapper';

export interface ContactGroupDetailModalWrapperProps extends ModalProps {
    id: string;
}

const ContactGroupDetailModalWrapper = (props: ContactGroupDetailModalWrapperProps): JSX.Element => {
    const viewProps = useContactGroupDetailModalWrapper(props);

    if (viewProps.contactGroup == undefined) {
        return <SpinnerModal {...props} />;
    }
    return (
        <ContactGroupDetailModal
            contactGroup={viewProps.contactGroup}
            {...props}
            customWidth={40}
        />
    );
};

export default ContactGroupDetailModalWrapper;
