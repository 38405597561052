import { ApiError, ApiQueryParams, DetailOptions } from '@frontend/api-utils';
import { ReduxError, SliceStatus, TELLOPORT_SELECTED_ACCOUNT_COOKIE, removeCookie, setCookie } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { UserApiClient } from './api/client';
import { AccountMembership, CreateUserModel, UserData, UserQueryParams, UsersOptions } from './api/models';
import { UserInfo } from './user';

export interface UserState {
    user: UserInfo | null;
    usersList: UserData | null;
    searchUsersList: { [searchQuery: string]: UserData } | null;
    userListStatus: SliceStatus;
    selectedMembership: AccountMembership | null;
    status: SliceStatus;
    searchStatus: SliceStatus;
    lastUpdate: number;
    lastSearchUpdate: { [searchQuery: string]: number };
    message: string;
    error: ReduxError | null;
    lastSearchQuery: string | null;
}

const initialState: UserState = {
    user: null,
    usersList: null,
    searchUsersList: null,
    userListStatus: SliceStatus.INIT,
    selectedMembership: null,
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    lastUpdate: Date.now(),
    lastSearchUpdate: {},
    message: '',
    error: null,
    lastSearchQuery: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        selectAccountMembership(state, action: PayloadAction<AccountMembership>) {
            const existingMembership = state.user?.account_members.find((am) => am.account.id === action.payload.account.id);
            if (existingMembership === undefined) throw new Error('User is not subscribed to this account or account does not exist.');
            setCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE, existingMembership.account.id.toString(), 30);
            state.selectedMembership = existingMembership;
            state.lastUpdate = Date.now();
        },
        removeSelectedAccountMembership(state) {
            removeCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE);
            state.selectedMembership = null;
            state.lastUpdate = Date.now();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.user = action.payload;
                if (action.payload.account_members !== undefined && action.payload.account_members.length === 1) {
                    state.selectedMembership = action.payload.account_members[0];
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchUsers.pending, (state) => {
                state.userListStatus = SliceStatus.LOADING;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.usersList == null) {
                    state.usersList = {
                        count: action.payload.count,
                        next: action.payload.next,
                        previous: action.payload.previous,
                        results: new Array(action.payload.count)
                    };
                    state.usersList?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.usersList?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.userListStatus = SliceStatus.IDLE;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                if (action.error.message?.includes('Duplicate request')) return;
                if (action.payload) state.error = action.payload as ReduxError;
                state.userListStatus = SliceStatus.ERROR;
            })
            .addCase(addUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                const user = action.payload;
                if (state.usersList == null) state.usersList = { count: 0, next: null, previous: null, results: [] };
                state.usersList.results.push(user);
                state.status = SliceStatus.IDLE;
            })
            .addCase(addUser.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(updateUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const user = action.payload;
                if (state.usersList == null) state.usersList = { count: 0, next: null, previous: null, results: [] };
                const existingUser = state.usersList.results.find((u) => u.id === user.id);
                if (existingUser) {
                    const userIndex = state.usersList.results.indexOf(existingUser);
                    state.usersList.results.splice(userIndex, 1, user);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateUser.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                if (action.payload === true && state.usersList) {
                    const userId = action.meta.arg;
                    state.usersList.results = state.usersList.results.filter((u) => u.id !== userId);
                }

                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchUsers.pending, (state, action) => {
                state.searchStatus = SliceStatus.LOADING;
                if (action.meta.arg.search && !Array.isArray(action.meta.arg.search)) {
                    state.lastSearchQuery = action.meta.arg.search;
                }
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (searchQuery !== state.lastSearchQuery) return;
                if (searchQuery === undefined || searchQuery === null || Array.isArray(searchQuery)) {
                    return undefined;
                } else {
                    if (state.searchUsersList !== null && state.searchUsersList[searchQuery.toLowerCase()]) {
                        state.searchUsersList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    } else {
                        state.searchUsersList = { [searchQuery.toLowerCase()]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.searchUsersList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    }
                }
                state.searchStatus = SliceStatus.IDLE;
            })
            .addCase(fetchUser.pending, (state) => {
                state.userListStatus = SliceStatus.LOADING;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                if (!state.usersList) {
                    state.usersList = { count: 1, next: null, previous: null, results: [action.payload] };
                } else {
                    const found = state.usersList.results.find((u) => u.id == action.meta.arg.id);
                    if (!found) return;
                    else state.usersList.results.splice(state.usersList.results.indexOf(found), 1, action.payload);
                }
            });
    }
});

export const fetchUserInfo = createAsyncThunk<UserInfo>('fetchUserInfo', async (_, { rejectWithValue }) => {
    try {
        return await UserApiClient.fetchUserInfoApi();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchUsers = createAsyncThunk<UserData, ApiQueryParams<UserQueryParams>>(
    'fetchUsers',
    async (queryParams: ApiQueryParams<UserQueryParams>, { rejectWithValue }) => {
        try {
            return await UserApiClient.fetchUsersApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchUser = createAsyncThunk<UserInfo, DetailOptions>('fetchUser', async (options: DetailOptions, { rejectWithValue }) => {
    try {
        return await UserApiClient.fetchUserApi(options);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addUser = createAsyncThunk<UserInfo, CreateUserModel>('addUser', async (user, { rejectWithValue }) => {
    try {
        return await UserApiClient.addUserApi(user);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateUser = createAsyncThunk<UserInfo, UsersOptions>('updateUser', async (userID, { rejectWithValue }) => {
    try {
        return await UserApiClient.updateUserApi(userID);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteUser = createAsyncThunk<boolean, number>('deleteUser', async (userId, { rejectWithValue }) => {
    try {
        const res = await UserApiClient.deleteUserApi(userId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete user with id: ${userId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchUsers = createAsyncThunk<UserData, ApiQueryParams<UserQueryParams>>(
    'searchUsers',
    async (options: ApiQueryParams<UserQueryParams>, { rejectWithValue }) => {
        try {
            return await UserApiClient.searchUsersApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const { selectAccountMembership, removeSelectedAccountMembership } = userSlice.actions;
export const userReducer = userSlice.reducer;
