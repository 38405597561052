import { Logger } from '@frontend/Logger';
import { SliceOperation, SliceStatus } from '@frontend/common';
import { Contact, CreateContactModel, addContact } from '@frontend/contact';
import { ContactGroup } from '@frontend/contact-group';
import { ToastUtil } from '@frontend/toast-utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { Locale, NotificationChannel } from '../../../common/BackendEnums';
import { ClassType } from '../../../common/BootstrapValues';
import AccessCode from '../../../components/access-code/access-code.component';
import DateInput from '../../../components/forms/dateInput/DateInput';
import EmailInput from '../../../components/forms/emailInput/EmailInput';
import PhoneInput from '../../../components/forms/phoneInput/PhoneInput';
import SelectInput from '../../../components/forms/select/Select';
import ContactRoleSelect from '../../../components/forms/select/contacts/contact-role/contact-role-select.component';
import TextInput from '../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';
import { GetError } from '../../../store/utils/GetError';
import { Languages } from '../../../utils/FormUtils';

interface CreateContactFormProps {
    groups: ContactGroup[];
    successCallback?: () => void;
    isModal?: boolean;
    onCancel: () => void;
}

const CreateContactForm = (props: CreateContactFormProps) => {
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const contacts = useAppSelector((state) => state.contacts);
    const isLoading = contacts.status == 'loading';
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const { selectedUser } = useAccount();
    const [createdContact, changeCreatedContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        if (!isLoading && contacts.lastOperation === SliceOperation.POST && submitted) {
            if (contacts.status === SliceStatus.ERROR) {
                Logger.error(`${selectedUser?.email} failed to create a contact`, { user: selectedUser?.id }, contacts.error?.json);
                toast.error(...ToastUtil.generateToastConfig(contacts.message, GetError(contacts.error), ClassType.DANGER));
                changeSubmitted(false);
            } else if (contacts.status === SliceStatus.IDLE) {
                Logger.log(
                    `${selectedUser?.email} created ${formResult.email}`,
                    { user: selectedUser?.id },
                    createContactModalFromCreateContactFormResult(formResult)
                );
                changeSubmitted(false);
            }
        }
    }, [isLoading]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        dispatch(addContact(createContactModalFromCreateContactFormResult(formResult))).then((res) => {
            const contact = res.payload as Contact;
            changeCreatedContact(contact);
        });
    };

    const [formResult, changeFormResult] = useState<CreateContactFormResult>({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        birthday: undefined,
        title: { value: '', label: '' },
        language: { value: '', label: '' },
        notificationChannels: [],
        group: { value: '', label: '' },
        user: null,
        additional_data: {},
        accountId: accountId
    });

    const [fnValid, changeFNValid] = useState<boolean>(false);
    const [lnValid, changeLNValid] = useState<boolean>(true);
    const [emValid, changeEMValid] = useState<boolean>(true);
    const [moValid, changeMOValid] = useState<boolean>(true);
    const [biValid, changeBIValid] = useState<boolean>(true);
    const [tiValid, changeTIValid] = useState<boolean>(true);
    const [laValid, changeLAValid] = useState<boolean>(true);
    const [ncValid, changeNCValid] = useState<boolean>(true);
    const [grValid, changeGRValid] = useState<boolean>(true);
    const [adValid, changeAdValid] = useState<boolean>(true);
    const formValid = fnValid && lnValid && emValid && moValid && biValid && tiValid && laValid && ncValid && grValid && adValid;

    return (
        <form
            id='CreateContactForm'
            onSubmit={handleSubmit}>
            <div className={props.isModal === true ? 'modal-body' : ''}>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextInput
                            id='CreateContactForm-firstnameInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.firstnameInput.label'
                                    description='The label for the firstname input on the create contact form.'
                                    defaultMessage='Firstname'
                                />
                            }
                            value={formResult.firstName}
                            onChange={(value) => changeFormResult({ ...formResult, firstName: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.firstnameInput.errorMessage'
                                    description='The error message for the firstname input on the create contact form.'
                                    defaultMessage='Please enter a valid firstname'
                                />
                            }
                            required
                            isValidCallback={(valid) => {
                                changeFNValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextInput
                            id='CreateContactForm-lastnameInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.lastnameInput.label'
                                    description='The label for the lastname input on the create contact form.'
                                    defaultMessage='Lastname'
                                />
                            }
                            value={formResult.lastName}
                            onChange={(value) => changeFormResult({ ...formResult, lastName: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.lastnameInput.errorMessage'
                                    description='The error message for the lastname input on the create contact form.'
                                    defaultMessage='Please enter a valid lastname'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeLNValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <EmailInput
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.emailInput.label'
                                    description='The label for the email input on the create contact form.'
                                    defaultMessage='Email'
                                />
                            }
                            value={formResult.email}
                            onChange={(value) => changeFormResult({ ...formResult, email: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.emailInput.errorMessage'
                                    description='The error message for the email input on the create contact form.'
                                    defaultMessage='Please enter a valid email.'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeEMValid(valid);
                            }}
                            submitted={submitted}
                            required={formResult.notificationChannels.includes('email')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <PhoneInput
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.mobileInput.label'
                                    description='The label for the mobile input on the create contact form.'
                                    defaultMessage='Mobile number'
                                />
                            }
                            value={formResult.mobile}
                            onChange={(value) => changeFormResult({ ...formResult, mobile: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.mobileInput.errorMessage'
                                    description='The error message for the mobile input on the create contact form.'
                                    defaultMessage='Please enter a valid (international) mobile number.'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeMOValid(valid);
                            }}
                            submitted={submitted}
                            required={formResult.notificationChannels.includes('sms')}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <DateInput
                            id='CreateContactForm-birthdayInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.birthdayInput.label'
                                    description='The label for the birthday input on the create contact form.'
                                    defaultMessage='Birthday'
                                />
                            }
                            value={formResult.birthday}
                            onChange={(value) => changeFormResult({ ...formResult, birthday: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.birthdayInput.errorMessage'
                                    description='The error message for the birthday input on the create contact form.'
                                    defaultMessage='Please enter a valid birthday'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeBIValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SelectInput
                            id='CreateContactForm-titleSelect'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.titleInput.label'
                                    description='The label for the title input on the create contact form.'
                                    defaultMessage='Title'
                                />
                            }
                            value={formResult.title}
                            onChange={(value: any) => changeFormResult({ ...formResult, title: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.titleInput.errorMessage'
                                    description='The error message for the title input on the create contact form.'
                                    defaultMessage='Please select a title.'
                                />
                            }
                            options={TitleValues.map((t) => ({ value: t.value, label: t.displayName }))}
                            isValidCallback={(valid) => {
                                changeTIValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <SelectInput
                            id='CreateContactForm-languageSelect'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.languageInput.label'
                                    description='The label for the language input on the create contact form.'
                                    defaultMessage='Language'
                                />
                            }
                            value={formResult.language}
                            onChange={(value: any) => changeFormResult({ ...formResult, language: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.languageInput.errorMessage'
                                    description='The error message for the language input on the create contact form.'
                                    defaultMessage='Please select a language.'
                                />
                            }
                            options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                            isValidCallback={(valid) => {
                                changeLAValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SelectInput
                            id='CreateContactForm-notificationSelect'
                            isMulti
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.notificationChannelInput.label'
                                    description='The label for the notification channel input on the create contact form.'
                                    defaultMessage='Notification channel'
                                />
                            }
                            value={formResult.notificationChannels}
                            onChange={(value: any) => changeFormResult({ ...formResult, notificationChannels: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.notificationChannelInput.errorMessage'
                                    description='The error message for the notification channel input on the create contact form.'
                                    defaultMessage='Please select a channel.'
                                />
                            }
                            options={[
                                { value: NotificationChannel.EMAIL, label: NotificationChannel.EMAIL },
                                { value: NotificationChannel.SMS, label: NotificationChannel.SMS }
                            ]}
                            isValidCallback={(valid) => {
                                changeNCValid(valid);
                            }}
                            submitted={submitted}
                            required
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <SelectInput
                            id='CreateContactForm-groupSelect'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.groupInput.label'
                                    description='The label for the group input on the create contact form.'
                                    defaultMessage='Group'
                                />
                            }
                            value={formResult.group}
                            onChange={(value: any) => changeFormResult({ ...formResult, group: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.createcontact.groupInput.errorMessage'
                                    description='The error message for the group input on the create contact form.'
                                    defaultMessage='Please select a valid group.'
                                />
                            }
                            options={props.groups.map((g) => ({ value: g.id, label: g.name }))}
                            isValidCallback={(valid) => {
                                changeGRValid(valid);
                            }}
                            submitted={submitted}
                            isClearable
                        />
                    </div>
                    <div className='col-6'>
                        <ContactRoleSelect
                            submitted
                            onChange={(v: any) => changeFormResult({ ...formResult, additional_data: { role: `"${v.value}"` } })}
                            value={formResult.additional_data?.role}
                            isValidCallback={(v) => {
                                changeAdValid(v);
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <AccessCode
                        submitted={submitted}
                        createdContact={createdContact}
                        callback={props.successCallback}
                    />
                </div>
            </div>
            <CreateModalFooter
                onSubmit={() => undefined}
                handleClose={props.onCancel}
                disabled={!formValid || submitted}></CreateModalFooter>
        </form>
    );
};

export const TitleValues: { value: string; displayName: React.ReactNode }[] = [
    {
        value: 'madam',
        displayName: (
            <FormattedMessage
                id='contacts.forms.createcontact.titleInput.madamDisplayName'
                description='The displayname for madam for the title input on the create contact form.'
                defaultMessage='Madam'
            />
        )
    },
    {
        value: 'mister',
        displayName: (
            <FormattedMessage
                id='contacts.forms.createcontact.titleInput.misterDisplayName'
                description='The displayname for mister for the title input on the create contact form.'
                defaultMessage='Mister'
            />
        )
    },
    {
        value: 'individual',
        displayName: (
            <FormattedMessage
                id='contacts.forms.createcontact.titleInput.individualDisplayName'
                description='The displayname for individual for the title input on the create contact form.'
                defaultMessage='Individual'
            />
        )
    }
];

interface CreateContactFormResult {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    birthday: Date | undefined;
    title: { value: string; label: string };
    language: { value: string; label: string };
    notificationChannels: string[];
    group: { value: string; label: string };
    user: number | null;
    accountId: number;
    additional_data?: { [key: string]: any };
}

export const createContactModalFromCreateContactFormResult = (result: CreateContactFormResult): CreateContactModel => {
    return {
        first_name: result.firstName,
        last_name: result.lastName,
        title: result.title.value,
        mobile: result.mobile,
        email: result.email,
        birthday: result.birthday ? result.birthday.toISOString().split('T')[0] : undefined,
        notification_channels: result.notificationChannels as NotificationChannel[],
        language: result.language ? (result.language.value as Locale) : undefined,
        account: result.accountId,
        user: result.user,
        contact_groups: result.group !== null && result.group.value !== '' ? [+result.group.value] : [],
        associate_contact: true
    };
};

export const helpTextAdditionalData = (
    <FormattedMessage
        id='CreateContactForm.HelpText.AdditionalData'
        description='helptext for the additional data field'
        defaultMessage={`The format for additional data is '{"key": "value"}', each key value pair has to be inside the brackets and seperated by a comma. You can make as many key value pairs as you want to display any additional info related to the contact.`}
    />
);

export default CreateContactForm;
