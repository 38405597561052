import { Product } from '@frontend/product';
import { Row } from 'react-table';

import ProductBadge from '../../../components/badges/product-badge/product-badge.component';
import { RowProps } from '../../../components/tables/rows/TRow';

interface ProductListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<Product>;
    onClick?: (element: Product) => void;
}

const ProductListElement = (props: ProductListElementProps) => {
    return (
        <tr
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td>
                <span className='text-secondary text-xs font-weight-bold'>{props.row.original.id}</span>
            </td>
            <td className='w-20'>
                <span className='text-secondary text-xs font-weight-bold'>
                    {props.row.original.image ? (
                        <img
                            src={props.row.original.image}
                            height='150'
                        />
                    ) : (
                        <>-</>
                    )}
                </span>
            </td>
            <td className='w-20'>
                <ProductBadge
                    productId={props.row.original.id.toString()}
                    product={props.row.original}
                />
            </td>
            <td>
                <span className='text-secondary text-xs font-weight-bold'>
                    {`weight: ${props.row.original.weight !== null ? props.row.original.weight : ''}`}
                    <br />
                    {`height: ${props.row.original.height !== null ? props.row.original.height : ''}`}
                    <br />
                    {`width: ${props.row.original.width !== null ? props.row.original.width : ''}`}
                    <br />
                    {`diameter: ${props.row.original.diameter !== null ? props.row.original.diameter : ''}`}
                    <br />
                    {`length: ${props.row.original.length !== null ? props.row.original.length : ''}`}
                </span>
            </td>
            <td>
                <span className='text-secondary text-xs font-weight-bold'>{props.row.original.computed_stock_count}</span>
            </td>
        </tr>
    );
};

export default ProductListElement;
