import { addAttribute, fetchAttributes } from '@frontend/attribute';
import { Category, fetchCategories } from '@frontend/category';
import { ToastUtil } from '@frontend/toast-utils';
import { KeyboardEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AttributeType, AttributeTypeName } from '../../../common/AttributeType';
import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import MultiSelect from '../../../components/forms/select/MultiSelect';
import SelectInput from '../../../components/forms/select/Select';
import TextInput from '../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getAllChildren } from '../../../utils/GetAllChildren';
import { parseObject } from '../../../utils/ParseObject';

interface CreateAttributeProps {
    handleClose: () => void;
}
const CreateAttributeForm = (props: CreateAttributeProps) => {
    const dispatch = useAppDispatch();
    const categoryState = useAppSelector((state) => state.categories);
    const attributeState = useAppSelector((state) => state.attributes);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<any>([]);
    const { shopId } = useParams();
    const [allCategories, changeAllCategories] = useState<Category[]>([]);

    const initialState: CreateAttributeModel = {
        name: '',
        type: undefined,
        values: [],
        account: accountId!,
        category: null
    };
    const [formResult, changeFormResult] = useState(initialState);

    const createOption = (label: string) => ({
        label,
        value: label
    });

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev: any) => {
                    const newArray = Array.isArray(prev) ? [...prev] : [];

                    newArray.push(createOption(inputValue));
                    return newArray;
                });
                setInputValue('');
                event.preventDefault();
        }
    };

    useEffect(() => {
        if (categoryState.categoryList === undefined) {
            dispatch(fetchCategories({ account: accountId?.toString(), shop: shopId }));
        }
    }, []);

    useEffect(() => {
        if (categoryState.categoryList) {
            const allCategories = getAllChildren(categoryState.categoryList);
            changeAllCategories(allCategories);
        }
    }, [categoryState.categoryList]);

    useEffect(() => {
        changeFormResult({ ...formResult, values: value.map((v: any) => v.value) });
    }, [value]);

    const [nameValid, changeNameValid] = useState(false);
    const [typeValid, changeTypeValid] = useState(false);
    const [categoryValid, changeCategoryValid] = useState(false);
    const formValid = nameValid && typeValid && categoryValid;

    const handleSubmit = () => {
        const parsedForm = parseObject(formResult);
        if (attributeState.attributeList) {
            const createdAttribute = attributeState.attributeList.find((a) => a.name.toLocaleLowerCase() === parsedForm.name.toLowerCase());
            if (submitted === false && !createdAttribute && formValid) {
                dispatch(
                    addAttribute({
                        account: parsedForm.account,
                        category: parsedForm.category,
                        name: parsedForm.name,
                        type: parsedForm.type,
                        values: parsedForm.values
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (submitted && attributeState.attributeList) {
            const parsedForm = parseObject(formResult);
            const createdAttribute = attributeState.attributeList.find((a) => a.name.toLocaleLowerCase() === parsedForm.name.toLowerCase());
            if (createdAttribute) {
                toast.success(...ToastUtil.generateToastConfig(formResult.name, attributeCreatedSuccess, ClassType.SUCCESS));
                setTimeout(() => {
                    dispatch(fetchAttributes({ account: accountId?.toString() }));
                }, 2000);
                props.handleClose();
                changeFormResult(initialState);
            } else {
                changeSubmitted(false);
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, attributeCreatedError, ClassType.DANGER));
            }
        }
    }, [attributeState.attributeList]);

    return (
        <>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                    changeSubmitted(true);
                    handleSubmit();
                }}>
                <div
                    className='modal-body'
                    style={{ overflowY: 'unset' }}>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='createAttribute.label.name'
                                        description='Label for name'
                                        defaultMessage='name'
                                    />
                                }
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                                value={formResult.name}
                                required
                                id='CreateAttributeForm-NameInput'
                                isValidCallback={(value) => changeNameValid(value)}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateAttribute.label.type'
                                        description='Label for type'
                                        defaultMessage='type'
                                    />
                                }
                                submitted={submitted}
                                options={AttributeType.ALL.map((a) => ({ value: a.name, label: a.displayedValue }))}
                                onChange={(value: any) => changeFormResult({ ...formResult, type: value })}
                                value={formResult.type}
                                required
                                id='CreateAttributeForm-TypeSelect'
                                isValidCallback={(value) => changeTypeValid(value)}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <MultiSelect
                                label={
                                    <FormattedMessage
                                        id='CreateAttributeForm.label.values'
                                        description='Label for values'
                                        defaultMessage='values'
                                    />
                                }
                                isMulti
                                submitted={submitted}
                                menuIsOpen={false}
                                id='CreateAttributeForm-ValuesSelect'
                                inputValue={inputValue}
                                onChange={(newValue: any) => setValue(newValue)}
                                onInputChange={(newValue) => setInputValue(newValue)}
                                onKeyDown={handleKeyDown}
                                placeholder='Enter value and press Enter or Tab'
                                value={value}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateAttributeForm.label.category'
                                        description='Label for category'
                                        defaultMessage='category'
                                    />
                                }
                                submitted={submitted}
                                options={allCategories.map((c) => ({ label: c.name, value: c.id }))}
                                onChange={(value: any) => changeFormResult({ ...formResult, category: value })}
                                value={formResult.category}
                                isClearable
                                required
                                id='CreateAttributeForm-CategorySelect'
                                isValidCallback={(value) => changeCategoryValid(value)}
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    onSubmit={() => handleSubmit()}
                    handleClose={() => props.handleClose()}
                    disabled={!formValid}
                />
            </form>
        </>
    );
};
export default CreateAttributeForm;

export interface CreateAttributeModel {
    name: string;
    type: { label: string | React.ReactNode; value: AttributeTypeName } | undefined;
    values: string[];
    account: number;
    category: { value: number; label: string } | null;
}

const attributeCreatedSuccess = (
    <FormattedMessage
        id='CreateAttributeForm.Created.Attribute.Success'
        description='Message when attribute was created successfully'
        defaultMessage='Attribute was created successfully'
    />
);

const attributeCreatedError = (
    <FormattedMessage
        id='CreateAttributeForm.Create.Attribute.Error'
        description='Message when failing to create attribute'
        defaultMessage='There was an error creating your attribute'
    />
);
