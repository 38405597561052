import { Attribute, fetchAttributeById } from '@frontend/attribute';
import { useEffect, useState } from 'react';

import { AttributeType } from '../../../common/AttributeType';
import CategoryBadge from '../../../components/badges/category-badge/category-badge.component';
import Spinner from '../../../components/loading/Spinner';
import ObjectField from '../../../components/modals/ObjectField';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

export default function AttributeDetail(props: { attribute?: Attribute }) {
    const dispatch = useAppDispatch();
    const attributeState = useAppSelector((state) => state.attributes);
    const [attribute, changeAttribute] = useState<Attribute | undefined>();

    useEffect(() => {
        changeAttribute(props.attribute);
        if (props.attribute && props.attribute.id !== attribute?.id) dispatch(fetchAttributeById(props.attribute.id.toString()));
    }, [props.attribute]);

    useEffect(() => {
        changeAttribute(attributeState.attributeList?.find((a) => a.id === props.attribute?.id));
    }, [attribute, attributeState.attributeList]);

    if (attribute === undefined) return <Spinner />;

    return (
        <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column w-100 h-100'>
                {attribute.category_obj && (
                    <ObjectField
                        value={
                            <CategoryBadge
                                categoryId={attribute.category_obj.id.toString()}
                                name={attribute.category_obj.name}
                            />
                        }
                        fieldName='Category'
                    />
                )}
                <ObjectField
                    value={attribute.values.join(',')}
                    fieldName='Values'
                />
                <ObjectField
                    value={AttributeType.getByName(attribute.type)?.displayedValue}
                    fieldName='Type'
                />
            </div>
        </div>
    );
}
