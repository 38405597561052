import { UserInfo } from '@frontend/user';
import { FaUser } from 'react-icons/fa';

import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';

export interface Props {
    userId?: string;
    user?: UserInfo;
    username?: string;
}

const UserBadge = (props: Props) => {
    return (
        <BadgeTemplate
            type={DetailModalType.USER}
            objectId={props.userId}>
            <FaUser className='me-2' />
            {props.username ? props.username : props.user ? props.user.username : 'Unknown'}
        </BadgeTemplate>
    );
};

export default UserBadge;
