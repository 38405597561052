import { HiQuestionMarkCircle } from 'react-icons/hi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoArrowUpCircle } from 'react-icons/io5';

import { SlotDoorLog, SlotDoorLogState } from '../../api/slots/SlotLogs';
import DateBadge from '../../components/badges/DateBadge';

const SlotDoorLogElement = (props: { slotDoorLog: SlotDoorLog }) => {
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                {props.slotDoorLog.state === SlotDoorLogState.CLOSED ? (
                    <IoIosCheckmarkCircle className='text-success display-1' />
                ) : props.slotDoorLog.state === SlotDoorLogState.OPENED ? (
                    <IoArrowUpCircle className='text-info display-1' />
                ) : (
                    <HiQuestionMarkCircle className='text-warning display-1' />
                )}
            </span>
            <div className='timeline-content'>
                <h6 className='text-dark text-sm font-weight-bold mb-0'>{props.slotDoorLog.state}</h6>
                <DateBadge
                    date={props.slotDoorLog.created_at}
                    displayTime={true}
                />
            </div>
        </div>
    );
};

export default SlotDoorLogElement;
