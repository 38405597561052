import { APIClient, ApiError, ApiQueryParams, ApiViewSet } from "@frontend/api-utils";
import { ProductAttribute } from "@frontend/product";
import { ProductAttributeBody, ProductAttributeQueryParams, ProductAttributeResponse } from "./models";

const productAttributesViewset: ApiViewSet = {
    baseName: 'product-attributes'
};

export class ProductAttributeAPIClient extends APIClient {
    public static async fetchProductAttributesApi(queryParams?: ApiQueryParams<ProductAttributeQueryParams>): Promise<ProductAttributeResponse> {
        return await this.apiPagination<ProductAttributeResponse, ProductAttributeQueryParams>(productAttributesViewset, queryParams);
    }
    
    public static async createProductAttributesApi(body: ProductAttributeBody): Promise<ProductAttribute> {
        const url = '/product-attributes/';
        const response = await this.post(url, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating product attribute');
            }
            throw new ApiError('Error creating product attribute', json);
        }
        return await response.json();
    }
    
    public static async updateProductAttributesApi(id: number, body: ProductAttributeBody): Promise<ProductAttribute> {
        const url = `/product-attributes/${id}/`;
        const response = await this.put(url, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating product attribute');
            }
            throw new ApiError('Error updating product attribute', json);
        }
        return await response.json();
    }
    
    public static async deleteProductAttributesApi(id: number): Promise<boolean> {
        const url = `/product-attributes/${id}/`;
        const response = await this.delete(url);
        return response.ok;
    }
}

