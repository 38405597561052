import { ContactGroupBadgeProps } from './contact-group-badge.component';

interface ViewProps {
    name: string;
}

const useContactGroupBadge = (props: ContactGroupBadgeProps): ViewProps => {
    const name = props.contactGroup ? props.contactGroup.name : props.name ? props.name : 'Unknown';

    return {
        name
    };
};

export default useContactGroupBadge;
