import { FormattedMessage } from 'react-intl';

const PRODUCTS = {
    PRODUCT: (
        <FormattedMessage
            id='UpdateProductForm.Label.Name'
            description={'Label for name in form'}
            defaultMessage='Product name'
        />
    )
};

export default PRODUCTS;
