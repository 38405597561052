import { CategoryBadgeProps } from './category-badge.component';

interface ViewProps {
    name: string | undefined;
}

const useCategoryBadge = (props: CategoryBadgeProps): ViewProps => {
    const name = props.category ? props.category.name : props.name ? props.name : 'Unknown';
    return {
        name
    };
};
export default useCategoryBadge;
