import { FormattedMessage } from 'react-intl';

const CONTACTS = {
    CONTACT: (
        <FormattedMessage
            id='CONTACTS.CONTACT'
            description='Label for contact'
            defaultMessage='Contact'
        />
    ),
    CONTACT_GROUP: (
        <FormattedMessage
            id='CONTACT.CONTACT_GROUP'
            description='Label for contact group'
            defaultMessage='Contact Group'
        />
    ),
    SENDER: (
        <FormattedMessage
            id='CONTACT.SENDER'
            description='Label for sender'
            defaultMessage='Sender'
        />
    ),
    RECEIVER: (
        <FormattedMessage
            id='CONTACT.RECEIVER'
            description='Label for receiver'
            defaultMessage='Receiver'
        />
    ),
    SENDER_GROUP: (
        <FormattedMessage
            id='CONTACT.SENDER_GROUP'
            description='Label for sender group'
            defaultMessage='Sender group'
        />
    ),
    RECEIVER_GROUP: (
        <FormattedMessage
            id='CONTACT.RECEIVER_GROUP'
            description='Label for receiver group'
            defaultMessage='Receiver group'
        />
    )
};

export default CONTACTS;
