import { ApiError, ApiQueryParams, getAllValuesFromStore } from '@frontend/api-utils';
import { ReduxError, SliceStatus, addOrUpdateList, removeListElement } from '@frontend/common';
import { FEATURES } from '@frontend/feature-flags';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import _, { toNumber } from 'lodash';

import { TransactionAPIClient } from './api/client';
import {
    BulkTransactionStatusUpdateModel,
    CancelTransactionQueryParams,
    CreateTransactionModel,
    MoveTransactionOptions,
    TransactionResponse,
    TransactionsQueryParams,
    UpdateBulkTransactionReceiverOptions
} from './api/models';
import { TransactionStatus, TransactionStatusName } from './common/transaction-status';
import TransactionStatusChangeEvent from './common/transaction-status-change-event';
import { Transaction } from './transaction';

export interface TransactionState {
    all: Transaction[];
    allTransactionList: TransactionResponse | null;
    filterObject: { [key: string]: any } | null;
    transactionList: { [transactionStatus: string]: TransactionResponse };
    userTransactions: { [transactionContact: string]: TransactionResponse };
    searchTransactionList: { [tt_number: string]: TransactionResponse } | null;
    lastUpdate: number;
    pendingTransactions: { transaction: Transaction; status: TransactionStatusName }[];
    status: SliceStatus;
    message: string;
    error: ReduxError | null;
}

const initialState: TransactionState = {
    all: [],
    allTransactionList: null,
    filterObject: null,
    transactionList: {},
    userTransactions: {},
    searchTransactionList: null,
    pendingTransactions: [],
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: '',
    error: null
};

export const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        removeTransactionById(state, action: PayloadAction<string>) {
            state.transactionList = removeTransactionIfFound(action.payload, state.transactionList);
            state.userTransactions = removeTransactionIfFound(action.payload, state.userTransactions);
            if (state.searchTransactionList) state.searchTransactionList = removeTransactionIfFound(action.payload, state.searchTransactionList);
            if (FEATURES.pubSub === false) state.pendingTransactions = state.pendingTransactions.filter((t) => t.transaction.id != action.payload);
            removeListElement<Transaction>(action.payload, state.all);
            if (state.allTransactionList) {
                removeListElement<Transaction>(action.payload, state.allTransactionList.results);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                const sender = action.meta.arg.sender;
                const receiver = action.meta.arg.receiver;
                const search = action.meta.arg.search;
                const fetchedTransactions = action.payload.results;

                if (action.payload.results == undefined || Array.isArray(action.payload))
                    (action.payload as unknown as Transaction[]).forEach((el) => addOrUpdateList<Transaction>(el, state.all));
                else fetchedTransactions.forEach((el) => addOrUpdateList<Transaction>(el, state.all));

                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);

                if (action.payload.results === undefined) return;

                if (search) {
                    if (search === undefined || search === null || Array.isArray(search)) {
                        return undefined;
                    } else {
                        if (state.searchTransactionList === null) {
                            state.searchTransactionList = {};
                            state.searchTransactionList[search] = { ...action.payload, results: new Array(action.payload.count) };
                            state.searchTransactionList[search].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                        if (state.searchTransactionList[search]) {
                            state.searchTransactionList[search] = {
                                ...action.payload,
                                results: new Array(action.payload.count)
                            };
                            state.searchTransactionList[search].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        } else if (state.searchTransactionList[search] === undefined) {
                            state.searchTransactionList[search] = {
                                ...action.payload,
                                results: new Array(action.payload.count)
                            };
                            state.searchTransactionList[search].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    }
                    state.lastUpdate = Date.now();
                    state.status = SliceStatus.IDLE;
                    return;
                }
                const { page, page_size, ...args } = action.meta.arg;
                const argsEqual = _.isEqual(state.filterObject, args);
                if (state.filterObject && argsEqual && state.allTransactionList !== null) {
                    state.allTransactionList.count = action.payload.count;
                    state.allTransactionList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.filterObject = args;
                    state.allTransactionList = { ...action.payload, results: new Array(action.payload.count) };
                    state.allTransactionList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                if (sender || receiver) {
                    const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                    if (receiver) {
                        if (Array.isArray(receiver)) {
                            return undefined;
                        } else {
                            if (!state.userTransactions[receiver])
                                state.userTransactions[receiver] = { ...action.payload, results: new Array(action.payload.count) };
                            state.userTransactions[receiver].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    }
                    if (sender) {
                        if (Array.isArray(sender)) {
                            return undefined;
                        } else {
                            if (!state.userTransactions[sender])
                                state.userTransactions[sender] = { ...action.payload, results: new Array(action.payload.count) };
                            state.userTransactions[sender].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    }
                    state.status = SliceStatus.IDLE;
                    state.lastUpdate = Date.now();
                    return;
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })

            .addCase(fetchTransactionById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionById.fulfilled, (state, action) => {
                if (FEATURES.pubSub === false) {
                    const pending = state.pendingTransactions.find((tr) => tr.transaction.id === action.payload.id);
                    if (
                        pending &&
                        pending.status !== TransactionStatusName.DROPOFF_IN_PROGRESS &&
                        pending.status !== TransactionStatusName.PICKUP_IN_PROGRESS
                    ) {
                        state.pendingTransactions.splice(state.pendingTransactions.indexOf(pending), 1);
                    }
                }
                if (action.payload !== undefined) {
                    const newState = propagateTransactionUpdate(action.payload, state);
                    state.transactionList = newState.trList;
                    state.userTransactions = newState.userTrList;
                    state.searchTransactionList = newState.searchTrList;
                } else console.log('Oops, something went wrong');
                addOrUpdateList<Transaction>(action.payload, state.all);
                if (state.allTransactionList) {
                    addOrUpdateList<Transaction>(action.payload, state.allTransactionList.results);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTransactionById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(fetchTransactionByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionByUrl.fulfilled, (state, action) => {
                addOrUpdateList<Transaction>(action.payload, state.all);
                if (state.allTransactionList) {
                    addOrUpdateList<Transaction>(action.payload, state.allTransactionList.results);
                }
                const newState = propagateTransactionUpdate(action.payload, state);
                state.transactionList = newState.trList;
                state.userTransactions = newState.userTrList;
                state.searchTransactionList = newState.searchTrList;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTransactionByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })

            .addCase(addTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addTransaction.fulfilled, (state, action) => {
                const transaction = action.payload;
                addOrUpdateList<Transaction>(transaction, state.all);
                if (state.allTransactionList) {
                    addOrUpdateList<Transaction>(action.payload, state.allTransactionList.results);
                } else state.allTransactionList = { count: 1, next: null, previous: null, results: [action.payload] };
                if (FEATURES.pubSub === false)
                    state.pendingTransactions = [{ transaction: transaction, status: TransactionStatus.getByString(transaction.status)!.name }];
                state.status = SliceStatus.IDLE;
                state.message = '';
            })
            .addCase(addTransaction.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })

            .addCase(cancelTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(cancelTransaction.fulfilled, (state, action) => {
                const newState = propagateTransactionUpdate(action.payload, state);
                addOrUpdateList<Transaction>(action.payload, state.all);
                if (state.allTransactionList) {
                    addOrUpdateList<Transaction>(action.payload, state.allTransactionList.results);
                }
                state.transactionList = newState.trList;
                state.userTransactions = newState.userTrList;
                state.searchTransactionList = newState.searchTrList;
                state.status = SliceStatus.IDLE;
            })
            .addCase(cancelTransaction.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(bulkUpdateTransactionStatus.fulfilled, (state, action) => {
                const body = action.meta.arg;
                const event = TransactionStatusChangeEvent.getByValue(body.event);
                const initState = TransactionStatus.getByName(body.initial_state);
                if (event === undefined || initState === undefined) {
                    console.error('Event or initial status was unknow therefore automatic updates of the transactions will not be done.');
                    return;
                }
                const expectedEndStatus = TransactionStatusChangeEvent.getExpectedEndStatusForEvent(event, initState);
                if (expectedEndStatus === undefined) {
                    console.error('Unable to calculate expected status therefore automatic updates of the transactions will not be done.');
                    return;
                }
                if (FEATURES.pubSub === false) {
                    const transactions = getAllValuesFromStore(state.transactionList).filter((t) => body.transaction_ids.includes(t.real_id));
                    const newPeningTransactions = _.clone(state.pendingTransactions);
                    transactions.forEach((t) => newPeningTransactions.push({ transaction: t, status: expectedEndStatus.name }));
                    state.pendingTransactions = newPeningTransactions;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(bulkUpdateTransactionStatus.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(moveTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(moveTransaction.fulfilled, (state, action) => {
                addOrUpdateList<Transaction>(action.payload, state.all);
                if (state.allTransactionList) {
                    addOrUpdateList<Transaction>(action.payload, state.allTransactionList.results);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(moveTransaction.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(bulkUpdateTransactionReceiver.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(bulkUpdateTransactionReceiver.fulfilled, (state, action) => {
                if (FEATURES.pubSub === false) {
                    const body = action.meta.arg;
                    const transactions = getAllValuesFromStore(state.transactionList).filter((t) => body.transaction_ids.includes(t.real_id));
                    const newPendingTransactions = _.clone(state.pendingTransactions);
                    transactions.forEach((t) => {
                        const status = TransactionStatus.getByString(t.status);
                        if (status) {
                            newPendingTransactions.push({ transaction: t, status: status.name });
                        }
                    });
                    if (state.userTransactions) {
                        Object.keys(state.userTransactions).forEach((key) => {
                            if (state.userTransactions[key]) {
                                action.meta.arg.transaction_ids.forEach((id) => {
                                    const found = state.userTransactions[key].results.find((tr) => tr.real_id === id);
                                    if (found) {
                                        const index = state.userTransactions[key].results.indexOf(found);
                                        if (index !== -1) {
                                            state.userTransactions[key].results.splice(index, 1);
                                        }
                                    }
                                });
                            }
                        });
                    }
                    state.pendingTransactions = newPendingTransactions;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(bulkUpdateTransactionReceiver.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

/**
 * Always try to provide a transaction status param.
 * If no status is provided the entire list will be overwritten with the backend response.
 * This of course is not ideal.
 *
 * You can provide one status (recomended)
 * or multiple (this may take a bit longer)
 */
export const fetchTransactions = createAsyncThunk<TransactionResponse, ApiQueryParams<TransactionsQueryParams>>(
    'fetchTransactions',
    async (queryParams: ApiQueryParams<TransactionsQueryParams>, { rejectWithValue }) => {
        try {
            return await TransactionAPIClient.fetchTransactionsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchTransactionById = createAsyncThunk<Transaction, string>('fetchTransactionById', async (transactionId, { rejectWithValue }) => {
    try {
        return await TransactionAPIClient.fetchTransactionApi({ id: transactionId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchTransactionByUrl = createAsyncThunk<Transaction, string>('fetchTransactionByUrl', async (transactionUrl, { rejectWithValue }) => {
    try {
        return await TransactionAPIClient.fetchTransactionApi({ url: transactionUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addTransaction = createAsyncThunk<Transaction, CreateTransactionModel>('addTransaction', async (transaction, { rejectWithValue }) => {
    try {
        return await TransactionAPIClient.addTransactionApi(transaction);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const cancelTransaction = createAsyncThunk<Transaction, CancelTransactionQueryParams>(
    'cancelTransaction',
    async (transactionId, { rejectWithValue }) => {
        try {
            return await TransactionAPIClient.cancelTransactionApi(transactionId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const moveTransaction = createAsyncThunk<Transaction, MoveTransactionOptions>(
    'moveTransaction',
    async (options: MoveTransactionOptions, { rejectWithValue }) => {
        try {
            return await TransactionAPIClient.moveTransactionApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const bulkUpdateTransactionStatus = createAsyncThunk<void, BulkTransactionStatusUpdateModel>(
    'bulkUpdateTransactionStatus',
    async (body: BulkTransactionStatusUpdateModel, { rejectWithValue }) => {
        try {
            return await TransactionAPIClient.bulkUpdateTransactionStatusApi(body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const bulkUpdateTransactionReceiver = createAsyncThunk<void, UpdateBulkTransactionReceiverOptions>(
    'bulkUpdateTransactionReceiver',
    async (body: UpdateBulkTransactionReceiverOptions, { rejectWithValue }) => {
        try {
            return await TransactionAPIClient.bulkUpdateTransactionReceiverApi(body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const getTransactionById = (state: TransactionState, id: string): Transaction | null => {
    for (const status in state.transactionList) {
        const transactionResponse = state.transactionList[status];
        const transaction = findTransactionInResponse(id, transactionResponse);
        if (transaction) {
            return transaction;
        }
    }

    // Search through userTransactions
    for (const contact in state.userTransactions) {
        const transactionResponse = state.userTransactions[contact];
        const transaction = findTransactionInResponse(id, transactionResponse);
        if (transaction) {
            return transaction;
        }
    }

    // Search through searchTransactionList if it exists
    for (const tt_number in state.searchTransactionList) {
        const transactionResponse = state.searchTransactionList[tt_number];
        const transaction = findTransactionInResponse(id, transactionResponse);
        if (transaction) {
            return transaction;
        }
    }

    return null; // Transaction with the given ID not found
};

export const { removeTransactionById } = transactionSlice.actions;
export const transactionReducer = transactionSlice.reducer;

function findTransactionInResponse(id: string, response: TransactionResponse): Transaction | null {
    for (const transaction of response.results) {
        if (transaction && transaction.id === id) {
            return transaction;
        }
    }
    return null; // Transaction with the given ID not found in this response
}

function propagateTransactionUpdate(newTransaction: Transaction, state: WritableDraft<TransactionState>) {
    const trList = updateTransactionInList(newTransaction, state.transactionList);
    const userTrList = updateTransactionInList(newTransaction, state.userTransactions);
    let searchTrList = null;
    if (state.searchTransactionList) searchTrList = updateTransactionInList(newTransaction, state.searchTransactionList);

    return {
        trList,
        userTrList,
        searchTrList
    };
}
function updateTransactionInList(newTransaction: Transaction, list: { [key: string]: TransactionResponse }) {
    const newState: { [key: string]: TransactionResponse } = _.cloneDeep(list);

    Object.keys(newState).forEach((key) => {
        newState[key].results = new Array(list[key].count);
        list[key].results.forEach((v, i) => {
            if (v != undefined) newState[key].results[i] = v;
        });
    });

    Object.entries(newState).forEach(([key, value]) => {
        const foundtransaction = value.results.find((tr) => tr?.id == newTransaction.id);
        if (foundtransaction && foundtransaction.status !== newTransaction.status) {
            if (!key.includes(TransactionStatus.getByString(newTransaction.status)!.name)) {
                newState[key].results.splice(value.results.indexOf(foundtransaction), 1);
            }
            if (newState[TransactionStatus.getByString(newTransaction.status)!.name]) {
                newState[TransactionStatus.getByString(newTransaction.status)!.name].results.push(newTransaction);
            } else {
                newState[TransactionStatus.getByString(newTransaction.status)!.name] = { count: 1, next: null, previous: null, results: [newTransaction] };
            }
        }
        if (foundtransaction) newState[key].results[value.results.indexOf(foundtransaction)] = newTransaction;
        if (!foundtransaction && key.includes(TransactionStatus.getByString(newTransaction.status)!.name)) {
            newState[key].results.push(newTransaction);
        }
    });
    return newState;
}

function removeTransactionIfFound(transactionId: string, list: { [key: string]: TransactionResponse }): { [key: string]: TransactionResponse } {
    const newState: { [key: string]: TransactionResponse } = _.cloneDeep(list);

    Object.keys(newState).forEach((key) => {
        newState[key].results = new Array(list[key].count);
        list[key].results.forEach((v, i) => {
            if (v != undefined) newState[key].results[i] = v;
        });
    });

    Object.entries(newState).forEach(([key, value]) => {
        const foundtransaction = value.results.find((tr) => tr?.id == transactionId);
        if (foundtransaction) {
            newState[key].results.splice(value.results.indexOf(foundtransaction), 1);
        }
    });

    return newState;
}
