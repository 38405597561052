interface DateBadgeProps {
    date?: Date | string;
    displayTime?: boolean;
}

const DateBadge = (props: DateBadgeProps) => {
    if (props.date === undefined) return <></>;
    return (
        <span>
            {props.date !== null && (
                <>
                    {new Date(props.date).toLocaleDateString()}
                    {props.displayTime && (
                        <>
                            <br />
                            {new Date(props.date).toLocaleTimeString()}
                        </>
                    )}
                </>
            )}
        </span>
    );
};

export default DateBadge;
