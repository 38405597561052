import { useEffect, useState } from 'react';

import useLocale, { Locale, getCorrectLocale, getLocaleStringRepresentation } from '../../hooks/useLocale';
import DropdownButton from '../dropdown/DropdownButton';

interface LanguageDropdownButtonProps {
    changeLocale: (locale: Locale) => void;
    buttonType?: string;
}

const LanguageDropdownButton = (props: LanguageDropdownButtonProps) => {
    const { locale } = useLocale();
    const [selected, changeSelected] = useState<string | undefined>(locale?.toString());
    useEffect(() => {
        changeSelected(locale?.toString());
    }, [locale]);

    return (
        <DropdownButton
            placement='bottom-end'
            title={getLocaleStringRepresentation(selected ? getCorrectLocale(selected) : Locale.ENGLISH)}
            options={[
                {
                    onClick: () => {
                        props.changeLocale(Locale.DUTCH);
                        changeSelected(Locale.DUTCH.toString());
                    },
                    title: getLocaleStringRepresentation(Locale.DUTCH)
                },
                {
                    onClick: () => {
                        props.changeLocale(Locale.ENGLISH);
                        changeSelected(Locale.ENGLISH.toString());
                    },
                    title: getLocaleStringRepresentation(Locale.ENGLISH)
                },
                {
                    onClick: () => {
                        props.changeLocale(Locale.FRENCH);
                        changeSelected(Locale.FRENCH.toString());
                    },
                    title: getLocaleStringRepresentation(Locale.FRENCH)
                }
            ]}
        />
    );
};

export default LanguageDropdownButton;
