import { Logger } from '@frontend/Logger';
import { FEATURES } from '@frontend/feature-flags';
import { useEffect } from 'react';

import { useAppDispatch } from '../hooks/redux';
import { PubSubTopic } from './PubSubSubscription';
import PubSubEventHandler from './handlers/PubSubEventHandler';

declare global {
    interface WindowEventMap {
        'PubSub:subscribe': CustomEvent<PubSubMessage>;
        'PubSub:unsubscribe': CustomEvent<PubSubMessage>;
    }
}

export default function usePubSubEventListener() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const eventHandler = new PubSubEventHandler(dispatch);
        const listener = (event: CustomEvent<MessageEvent<string>>) => {
            const result = JSON.parse(event.detail.data) as unknown;
            if (isPubSubEventObject(result)) {
                if (isPubSubEvent(result)) {
                    eventHandler.handle(result);
                    return;
                }
                if (isPubSubMessage(result)) {
                    if (result.content.action) dispatchEvent(new CustomEvent<PubSubMessage>(`PubSub:${result.content.action}`, { detail: result }));
                    else Logger.log(result.content.message);
                    return;
                }
                if (isPubSubError(result)) {
                    Logger.warn(result.content.error, {}, { cause: result.content.cause });
                    return;
                }
                Logger.error(`Unknown event type received via PubSub type:{${result.type}}.`, {}, result);
                return;
            }
            Logger.error('PubSub error, check data for more info.', {}, result);
        };
        if (FEATURES.pubSub) addEventListener('PubSub:onMessage', listener);
        return () => {
            removeEventListener('PubSub:onMessage', listener);
        };
    }, []);
}

function isPubSubEventObject(object: unknown): object is PubSubEventObject {
    return 'type' in (object as any) && 'content' in (object as any) && ['error', 'message', 'event'].includes((object as any).type);
}
function isPubSubEvent(object: PubSubEventObject): object is PubSubEvent {
    return object.type === 'event';
}
function isPubSubError(object: PubSubEventObject): object is PubSubError {
    return object.type === 'error';
}
function isPubSubMessage(object: PubSubEventObject): object is PubSubMessage {
    return object.type === 'message';
}

interface PubSubEventObject {
    type: 'error' | 'message' | 'event';
    content: ErrorContent | MessageContent | EventContent;
}
interface PubSubError extends PubSubEventObject {
    type: 'error';
    content: ErrorContent;
}
export interface PubSubMessage extends PubSubEventObject {
    type: 'message';
    content: MessageContent;
}
export interface PubSubEvent extends PubSubEventObject {
    type: 'event';
    content: EventContent;
}

interface ErrorContent {
    error: string;
    cause: string;
}
interface MessageContent {
    message: string;
    action: 'subscribe' | 'unsubscribe';
    name: string;
}
interface EventContent {
    new: any;
    old: any;
    topic: PubSubTopic;
}
