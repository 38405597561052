import { HiQuestionMarkCircle } from 'react-icons/hi';

import { SlotLog, isSlotDoorLog, isSlotSensorLog } from '../../api/slots/SlotLogs';
import DateBadge from '../../components/badges/DateBadge';
import SlotDoorLogElement from './SlotDoorLog';
import SlotSensorLogElement from './SlotSensorLog';

const SlotLogTimelineElement = (slotLog: SlotLog) => {
    if (isSlotDoorLog(slotLog)) {
        return <SlotDoorLogElement slotDoorLog={slotLog} />;
    } else if (isSlotSensorLog(slotLog)) {
        return <SlotSensorLogElement slotSensorLog={slotLog} />;
    }

    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <HiQuestionMarkCircle className='text-warning display-1' />
            </span>
            <div className='timeline-content'>
                <h6 className='text-dark text-sm font-weight-bold mb-0'>{slotLog.type}</h6>
                <DateBadge
                    date={slotLog.created_at}
                    displayTime={true}
                />
            </div>
        </div>
    );
};

export default SlotLogTimelineElement;
