import { ApiError } from '@frontend/api-utils';
import { ClassType, SliceStatus } from '@frontend/common';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Slot, changeSlotSettings } from '../../../api/Slots';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import SlotType, { SlotTypeName } from '../../../common/SlotType';
import CheckSwitch from '../../../components/forms/check/CheckSwitch';
import NumberInput from '../../../components/forms/numberInput/NumberInput';
import SelectInput from '../../../components/forms/select/Select';
import SpotSelect from '../../../components/forms/select/spots/SpotSelect';
import TextInput from '../../../components/forms/textInput/TextInput';
import Spinner from '../../../components/loading/Spinner';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { postSlots } from '../../../store/slotSlice';
import { GetError } from '../../../store/utils/GetError';

interface SlotSettingsProps {
    slot?: Slot;
    selectedSlots?: Slot[];
    handleClose: () => void;
    onSuccess?: () => void;
}

/**
 * DO NOT USE
 * This component cannot be used yet since there is no API to change the Slot settings at the moment of writing.
 * @todo finish this component once changing settings of a Slot is available in the API.
 * @param props
 * @returns
 */
const SlotSettings = (props: SlotSettingsProps) => {
    const dispatch = useAppDispatch();
    useAuthorization(DefaultRole.SUPER_ADMIN);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const slots = useAppSelector((state) => state.slots);
    const [submitted, changeSubmitted] = useState(false);
    const [formResult, changeFormResult] = useState<changeSlotSettings>();
    const isInit = slots.status === SliceStatus.INIT;
    const params = useParams();
    const selectedSlotsLength =
        props.selectedSlots && params.spotId
            ? props.selectedSlots.length === slots.slotList[params.spotId].results.length
                ? 'Changing all slots'
                : props.selectedSlots?.map((s) => s.slot_nr).join('-')
            : '';

    useEffect(() => {
        if (props.slot) {
            const initialSlotState: changeSlotSettings = {
                account: accountId!,
                type: SlotType.getByString(props.slot.type)?.value,
                spot: props.slot.spot,
                slot_nr: props.slot.slot_nr,
                settings_is_active: props.slot?.settings_is_active,
                settings_allow_notifications: props.slot?.settings_allow_notifications,
                settings_transactions_limit_for_pudo: props.slot?.settings_transactions_limit_for_pudo,
                settings_allow_multiple_pudo_pickup_transactions: props.slot?.settings_allow_multiple_pudo_pickup_transactions,
                settings_transactions_limit_for_vending: props.slot?.settings_transactions_limit_for_vending,
                settings_allow_same_vending_products: props.slot?.settings_allow_same_vending_products,
                settings_transactions_limit_for_lending: props.slot?.settings_transactions_limit_for_lending,
                settings_allow_same_lending_products: props.slot?.settings_allow_same_lending_products,
                settings_transactions_limit_for_return: props.slot?.settings_transactions_limit_for_return,
                settings_allow_same_return_products: props.slot?.settings_allow_same_return_products,
                settings_dropoff_in_progress_timeout_in_sec: props.slot?.settings_dropoff_in_progress_timeout_in_sec,
                settings_pickup_in_progress_timeout_in_sec: props.slot?.settings_pickup_in_progress_timeout_in_sec,
                settings_remove_parcel_in_progress_timeout_in_sec: props.slot?.settings_remove_parcel_in_progress_timeout_in_sec
            };
            changeFormResult(initialSlotState);
        } else {
            const defaultSlotState: changeSlotSettings = {
                account: accountId!,
                type: SlotTypeName.STANDARD,
                spot: '',
                slot_nr: undefined,
                settings_is_active: true,
                settings_allow_notifications: true,
                settings_transactions_limit_for_pudo: 1,
                settings_allow_multiple_pudo_pickup_transactions: false,
                settings_transactions_limit_for_vending: 1,
                settings_allow_same_vending_products: false,
                settings_transactions_limit_for_lending: 1,
                settings_allow_same_lending_products: false,
                settings_transactions_limit_for_return: 1,
                settings_allow_same_return_products: false,
                settings_dropoff_in_progress_timeout_in_sec: 0,
                settings_pickup_in_progress_timeout_in_sec: 0,
                settings_remove_parcel_in_progress_timeout_in_sec: 0
            };
            changeFormResult(defaultSlotState);
        }
    }, [props.slot, props.selectedSlots]);

    const handleSubmit = async () => {
        if (props.selectedSlots && props.selectedSlots.length > 0 && submitted && formResult) {
            const requests: { id: string; error: unknown }[] = [];
            let requestCount = 0;
            for (let i = 0; i < props.selectedSlots.length; i++) {
                dispatch(postSlots({ id: props.selectedSlots[i].id, body: { ...formResult, slot_nr: props.selectedSlots[i].slot_nr } })).catch((err) => {
                    requests.push({ id: props.selectedSlots![i].slot_nr, error: (err as ApiError).json });
                });
                requestCount++;
            }
            if (requestCount === props.selectedSlots.length) {
                if (requests.length > 0) {
                    toast.error(
                        ...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, requests.map((s) => `${s.id}: ${s.error}`).join('\n'), ClassType.DANGER)
                    );
                } else {
                    props.handleClose();
                }
            }
        } else {
            if (formResult && props.slot) {
                dispatch(postSlots({ id: props.slot?.id, body: formResult }));
            }
        }
    };

    useEffect(() => {
        if (props.slot && submitted) {
            if (slots.status === SliceStatus.IDLE) {
                toast.success(
                    ...ToastUtil.generateToastConfig(
                        CommonMessage.STATUS.SUCCESS,
                        <FormattedMessage
                            id='SlotSettings.Success'
                            description='Success message after changing slot settings'
                            defaultMessage='SLOT settings have been successfully updated'
                        />,
                        ClassType.SUCCESS
                    )
                );
                props.handleClose();
                props.onSuccess && props.onSuccess();
            } else if (slots.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, GetError(slots.error), ClassType.DANGER));
            }
        }
    }, [submitted, slots]);

    const [slotNrValid, setSlotNrValid] = useState<boolean>(true);

    if (isInit || !formResult) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }
    return (
        <>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <span className='text-secondary text-m font-weight-bolder opacity-7 align-content-center'>
                    {props.slot ? `Slot nr. ${props.slot.slot_nr}` : selectedSlotsLength}
                </span>
            </div>
            <form
                id={'general-settings'}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                    changeSubmitted(true);
                }}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Slot.Type'
                                        description={'Title for slot type'}
                                        defaultMessage='Type'
                                    />
                                }
                                value={formResult.type}
                                onChange={(value: any) => changeFormResult({ ...formResult, type: value })}
                                options={SlotType.ALL.map((s) => ({ value: s.value, label: s.displayedValue }))}
                                submitted={submitted}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <SpotSelect
                                submitted={submitted}
                                idAsString
                                value={formResult.spot}
                                onChange={(v: any) => changeFormResult({ ...formResult, spot: v })}
                            />
                        </div>
                        {props.slot && (
                            <div className='col-6'>
                                <TextInput
                                    label={
                                        <FormattedMessage
                                            id='SlotSettings.SlotNr'
                                            description='Label for slot nr'
                                            defaultMessage='SLOT nr'
                                        />
                                    }
                                    submitted={submitted}
                                    value={formResult.slot_nr}
                                    onChange={(v: any) => changeFormResult({ ...formResult, slot_nr: v })}
                                    required
                                    isValidCallback={(v) => {
                                        setSlotNrValid(v);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <CheckSwitch
                                onChange={(value) => changeFormResult({ ...formResult, settings_is_active: value })}
                                checked={formResult.settings_is_active}
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Slot.isActive'
                                        description={'Title for is active setting'}
                                        defaultMessage='Slot is active'></FormattedMessage>
                                }
                                submitted={submitted}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.PUDO'
                                        description={'Slot settings for pudo'}
                                        defaultMessage='PuDo'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.settings_transactions_limit_for_pudo}
                                onChange={(value) => changeFormResult({ ...formResult, settings_transactions_limit_for_pudo: value })}
                            />
                            <CheckSwitch
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.PUDO.Multiple.Transactions'
                                        description={'Slot settings for pudo mulitple transactions'}
                                        defaultMessage='Multiple pick-up transactions'
                                    />
                                }
                                checked={formResult.settings_allow_multiple_pudo_pickup_transactions}
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, settings_allow_multiple_pudo_pickup_transactions: value })}
                            />
                        </div>

                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Vending'
                                        description={'Slot settings for vending'}
                                        defaultMessage='Vending'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.settings_transactions_limit_for_vending}
                                onChange={(value) => changeFormResult({ ...formResult, settings_transactions_limit_for_vending: value })}
                            />
                            <CheckSwitch
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Vending.Allow.Same.Product'
                                        description={'Slot settings for vending same product'}
                                        defaultMessage='Allow vending same product'
                                    />
                                }
                                checked={formResult.settings_allow_same_vending_products}
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, settings_allow_same_vending_products: value })}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Lending'
                                        description={'Slot settings for lending'}
                                        defaultMessage='Lending'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.settings_transactions_limit_for_lending}
                                onChange={(value) => changeFormResult({ ...formResult, settings_transactions_limit_for_lending: value })}
                            />
                            <CheckSwitch
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Lending.Allow.Same.Product'
                                        description={'Slot settings for lending same product'}
                                        defaultMessage='Allow lending same product'
                                    />
                                }
                                checked={formResult.settings_allow_same_lending_products}
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, settings_allow_same_lending_products: value })}
                            />
                        </div>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Return'
                                        description={'Slot settings for return'}
                                        defaultMessage='Return'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.settings_transactions_limit_for_return}
                                onChange={(value) => changeFormResult({ ...formResult, settings_transactions_limit_for_return: value })}
                            />
                            <CheckSwitch
                                label={
                                    <FormattedMessage
                                        id='SlotSettings.Return.Allow.Same.Product'
                                        description={'Slot settings for pudo same product'}
                                        defaultMessage='Allow return same product'
                                    />
                                }
                                checked={formResult.settings_allow_same_return_products}
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, settings_allow_same_return_products: value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <NumberInput
                                submitted={submitted}
                                label={CommonMessage.FORM.SPOT.DROPOFF_TIMEOUT}
                                value={
                                    formResult.settings_dropoff_in_progress_timeout_in_sec ? formResult.settings_dropoff_in_progress_timeout_in_sec : undefined
                                }
                                onChange={(v: any) => changeFormResult({ ...formResult, settings_dropoff_in_progress_timeout_in_sec: v })}
                            />
                        </div>
                        <div className='col-4'>
                            <NumberInput
                                submitted={submitted}
                                label={CommonMessage.FORM.SPOT.PICKUP_TIMEOUT}
                                value={
                                    formResult.settings_pickup_in_progress_timeout_in_sec ? formResult.settings_pickup_in_progress_timeout_in_sec : undefined
                                }
                                onChange={(v: any) => changeFormResult({ ...formResult, settings_pickup_in_progress_timeout_in_sec: v })}
                            />
                        </div>
                        <div className='col-4'>
                            <NumberInput
                                submitted={submitted}
                                label={CommonMessage.FORM.SPOT.REMOVE_PARCEL_TIMEOUT}
                                value={
                                    formResult.settings_remove_parcel_in_progress_timeout_in_sec
                                        ? formResult.settings_remove_parcel_in_progress_timeout_in_sec
                                        : undefined
                                }
                                onChange={(v: any) => changeFormResult({ ...formResult, settings_remove_parcel_in_progress_timeout_in_sec: v })}
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    onSubmit={() => undefined}
                    handleClose={() => props.handleClose()}
                    disabled={!slotNrValid}
                />
            </form>
        </>
    );
};

export default SlotSettings;
