import { FormattedMessage } from 'react-intl';

import { Spot } from '../../api/Spots';
import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import AddressBadge from '../../components/badges/AddressBadge';
import CheckSwitch from '../../components/forms/check/CheckSwitch';
import Spinner from '../../components/loading/Spinner';
import CreateModal from '../../components/modals/CreateModal';
import ModalSectionTitle from '../../components/modals/ModalSectionTitle';
import ObjectField from '../../components/modals/ObjectField';
import SpotSettings from '../forms/settings/SpotSettings';
import TerminalSettings from '../forms/settings/TerminalSettings';
import useSpotDetail from './spot-detail-controller';

interface SpotDetailProps {
    spot?: Spot;
}

export default function SpotDetail(props: SpotDetailProps) {
    const viewProps = useSpotDetail(props);

    if (!props.spot) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }
    return (
        <>
            <ObjectField
                fieldName={
                    <FormattedMessage
                        id='SpotDetail.Detail.Title.Address'
                        description='Detail title for address'
                        defaultMessage='Address'
                    />
                }
                value={
                    <AddressBadge
                        address={props.spot.address_line_1}
                        zip={props.spot.zip_code}
                        city={props.spot.city}
                    />
                }
            />
            <ObjectField
                fieldName={CommonMessage.SLOTS.SLOT}
                value={props.spot.slots_num}
            />
            {viewProps.authorized ? (
                <ObjectField
                    fieldName={
                        <FormattedMessage
                            id='SpotDetail.Detail.Title.Account'
                            description={'Detail title for account'}
                            defaultMessage='Account'
                        />
                    }
                    value={props.spot.account}
                />
            ) : (
                <></>
            )}
            <ModalSectionTitle title='Settings' />
            {viewProps.lightSettings !== undefined && (
                <ObjectField
                    fieldName='Switch lights'
                    value={
                        <CheckSwitch
                            onChange={(value) => viewProps.handleLightsSwitch(value)}
                            checked={viewProps.lightSettings}
                            label={
                                <FormattedMessage
                                    id='SpotSettings.Switch.Lights'
                                    description={'Title for switch lights'}
                                    defaultMessage='Switch lights on/off'></FormattedMessage>
                            }
                            submitted={false}
                        />
                    }
                />
            )}
            <hr className='horizontal dark mt-0' />
            {viewProps.authorized ? (
                <>
                    {viewProps.filteredTerminalsList ? (
                        <>
                            <h5 className='d-flex justify-content-center'>
                                <FormattedMessage
                                    id='SpotDetails.Settings.Terminals.List'
                                    description={'Title of list to display terminals in spots'}
                                    defaultMessage='Terminal settings'
                                />
                            </h5>
                            <div className='d-flex flex-row justify-content-around'>
                                {viewProps.filteredTerminalsList.map((t) => (
                                    <div
                                        className='col-mb-4'
                                        key={t.id}>
                                        <button
                                            className='btn bg-gradient-primary me-1'
                                            onClick={() => {
                                                viewProps.changeSelectedTerminal(t);
                                            }}>
                                            {t.name.split('-', 3)}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <> </>
                    )}
                    <h5 className='d-flex justify-content-center'>
                        <FormattedMessage
                            id='SpotDetails.Settings.Spot.List'
                            description={'Title of list to display terminals in spots'}
                            defaultMessage='Spot settings'
                        />
                    </h5>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                        <button
                            className='btn bg-gradient-primary'
                            onClick={() => {
                                viewProps.changeSpotIsOpen(true);
                            }}>
                            {
                                <FormattedMessage
                                    id='SpotDetail.SPOT.Settings'
                                    description='Open settings message on spot detail component'
                                    defaultMessage='Open distriSPOT settings'
                                />
                            }
                        </button>
                    </div>
                </>
            ) : (
                <></>
            )}
            {!!viewProps.selectedTerminal && (
                <CreateModal
                    header='Terminal settings'
                    handleClose={() => viewProps.changeSelectedTerminal(undefined)}
                    show={!!viewProps.selectedTerminal}>
                    <TerminalSettings
                        handleClose={() => viewProps.changeSelectedTerminal(undefined)}
                        terminal={viewProps.selectedTerminal}></TerminalSettings>
                </CreateModal>
            )}
            {!!viewProps.spotIsOpen && (
                <CreateModal
                    header='Spot settings'
                    handleClose={() => viewProps.changeSpotIsOpen(false)}
                    show={viewProps.spotIsOpen}>
                    <SpotSettings
                        handleClose={() => viewProps.changeSpotIsOpen(false)}
                        show={viewProps.spotIsOpen}
                        spot={props.spot}></SpotSettings>
                </CreateModal>
            )}
        </>
    );
}
