import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus, addOrUpdateList, removeListElement } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { getAllValuesFromStore } from '@frontend/api-utils';
import { ProductAPIClient } from './api/client';
import { CreateProductParams, ProductResponse, ProductSlotsModel, ProductsQueryParams, UpdateProductParams } from './api/models';
import { Product } from './product';

export interface ProductsState {
    all: Product[];
    shopProductList: { [shopId: string]: ProductResponse };
    spotProducts: {[spotId: string]: ProductResponse };
    allProducts: ProductResponse | null;
    searchProductsList: { [searchQuery: string]: ProductResponse } | null;
    status: SliceStatus;
    searchStatus: SliceStatus;
    lastUpdate: number;
    lastSearchUpdate: { [searchQuery: string]: number };
    uploadStatus: SliceStatus;
    uploadMessage: string;
    message: string;
    error: ReduxError | null;
}

const initialState: ProductsState = {
    all: [],
    shopProductList: {},
    spotProducts: {},
    allProducts: null,
    searchProductsList: null,
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    lastUpdate: Date.now(),
    lastSearchUpdate: {},
    uploadStatus: SliceStatus.INIT,
    uploadMessage: '',
    message: '',
    error: null
};

export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                const id = action.meta.arg.shop;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (action.meta.arg.spot) {
                    const spotId = (action.meta.arg.spot as string);
                    if (state.spotProducts && state.spotProducts[spotId]) {
                        if (action.payload.count != state.spotProducts[spotId].results.length) {
                            state.spotProducts[spotId] = {
                                ...action.payload,
                                results: new Array(action.payload.count)
                            }
                        }
                        state.spotProducts[spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results)
                    } else {
                        state.spotProducts[spotId] = {
                            ...action.payload,
                            results: new Array(action.payload.count)
                        }
                        state.spotProducts[spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results)
                    }
                }
                if (!action.meta.arg.shop && action.meta.arg.show_all) {
                    if (state.allProducts == null) {
                        state.allProducts = {
                            count: action.payload.count,
                            next: action.payload.next,
                            previous: action.payload.previous,
                            results: new Array(action.payload.count)
                        };
                        state.allProducts.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        state.allProducts = { ...state.allProducts, count: action.payload.count, next: action.payload.next, previous: action.payload.previous };
                        state.allProducts.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                } else {
                    if (id && !Array.isArray(id)) {
                        if (state.shopProductList == null) {
                            state.shopProductList = {};
                            state.shopProductList[id] = { ...action.payload, results: new Array(action.payload.count) };
                        } else if (state.shopProductList[id] == undefined) {
                            state.shopProductList[id] = { ...action.payload, results: new Array(action.payload.count) };
                            state.shopProductList[id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        } else {
                            state.shopProductList[id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    } else if (!id) {
                        const productList: { [shopId: string]: ProductResponse } = {};
                        if (state.shopProductList == null) {
                            state.shopProductList = {};
                        }
                        action.payload.results.forEach((p) =>
                            p.product_shops.forEach((shop) => {
                                if (productList[shop] == undefined) {
                                    productList[shop] = { ...action.payload, count: 1, results: [p] };
                                }
                                productList[shop].results.push(p);
                                productList[shop] = { ...action.payload, count: productList[shop].results.length };
                            })
                        );
                    }
                }

                action.payload.results.forEach((prod) => addOrUpdateList<Product>(prod, state.all));
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchProductById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductById.fulfilled, (state, action) => {
                const product = action.payload;
                addOrUpdateList<Product>(product, state.all);
                if (state.shopProductList !== null) {
                    const existingSearchProduct = state.searchProductsList && getAllValuesFromStore(state.searchProductsList).find((s) => s.id === product.id);
                    Object.keys(state.shopProductList).forEach((key) => {
                        const existingProduct = state.shopProductList[key].results.find((s) => s !== null && s !== undefined && product.id == s.id);
                        if (existingProduct) {
                            const productIndex = state.shopProductList[key].results.indexOf(existingProduct);
                            if (productIndex !== -1) {
                                state.shopProductList[key].results.splice(productIndex, 1, product);
                            }
                        }
                    });
                    if (existingSearchProduct && state.searchProductsList) {
                        Object.keys(state.searchProductsList).map((k) => {
                            if (
                                state.searchProductsList &&
                                state.searchProductsList[k].results.find((s) => s !== undefined && s !== null && s.id === existingSearchProduct.id)
                            ) {
                                const index = state.searchProductsList[k].results.indexOf(existingSearchProduct);
                                state.searchProductsList[k].results.splice(index, 1, product);
                            }
                        });
                    }
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProductById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchProductByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductByUrl.fulfilled, (state, action) => {
                const product = action.payload;
                addOrUpdateList<Product>(product, state.all);
                if (state.shopProductList !== null) {
                    Object.keys(state.shopProductList).forEach((key) => {
                        const existingProduct = state.shopProductList[key].results.find((s) => product.id == s.id);
                        if (existingProduct) {
                            const productIndex = state.shopProductList[key].results.indexOf(existingProduct);
                            if (productIndex !== -1) {
                                state.shopProductList[key].results.splice(productIndex, 1, product);
                            }
                        }
                    });
                }
                if (state.searchProductsList) {
                    Object.keys(state.searchProductsList).forEach((key) => {
                        if (state.searchProductsList) {
                            const existingProduct = state.searchProductsList[key].results.find((s) => product.id == s.id);
                            if (existingProduct) {
                                const productIndex = state.searchProductsList[key].results.indexOf(existingProduct);
                                if (productIndex !== -1) {
                                    state.searchProductsList[key].results.splice(productIndex, 1, product);
                                }
                            }
                        }
                    });
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProductByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateSlotsOfProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateSlotsOfProduct.fulfilled, (state, action) => {
                const productId = action.meta.arg.id;
                const newProduct = action.payload;
                addOrUpdateList<Product>(newProduct, state.all);
                if (state.shopProductList !== null) {
                    Object.keys(state.shopProductList).forEach((key) => {
                        const product = state.shopProductList[key].results.find((p) => p.id === parseInt(productId));
                        if (product) {
                            const productIndex = state.shopProductList[key].results.indexOf(product);
                            state.shopProductList[key].results.splice(productIndex, 1, newProduct);
                        }
                    });
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateSlotsOfProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(createProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                const product = action.payload;
                addOrUpdateList<Product>(product, state.all);
                const productShops = action.meta.arg.body.getAll('product_shops');
                productShops.forEach((shop) => {
                    if (state.shopProductList == null) {
                        state.shopProductList = { [shop as unknown as number]: { count: 1, next: null, previous: null, results: [product] } };
                    } else {
                        const shopList = state.shopProductList[shop as unknown as number];
                        if (shopList == null) {
                            state.shopProductList[shop as unknown as number] = { count: 1, next: null, previous: null, results: [product] };
                        } else {
                            state.shopProductList[shop as unknown as number] = { ...shopList, count: shopList.count + 1 };
                            state.shopProductList[shop as unknown as number].results.push(action.payload);
                        }
                    }
                });
                state.status = SliceStatus.IDLE;
            })
            .addCase(createProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(updateProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                const product = action.payload;
                addOrUpdateList<Product>(product, state.all);
                const existingSearchProduct = state.searchProductsList && getAllValuesFromStore(state.searchProductsList).find((s) => s.id === product.id);
                Object.keys(state.shopProductList).forEach((key) => {
                    const existingProduct = state.shopProductList[key].results.find((p) => p !== undefined && p.id === product.id);
                    if (existingProduct) {
                        const productIndex = state.shopProductList[key].results.indexOf(existingProduct);
                        if (productIndex !== -1) {
                            state.shopProductList[key].results.splice(productIndex, 1, product);
                        }
                    }
                });
                if (existingSearchProduct && state.searchProductsList) {
                    Object.keys(state.searchProductsList).map((k) => {
                        if (state.searchProductsList && state.searchProductsList[k].results.find((s) => s.id === existingSearchProduct.id)) {
                            const index = state.searchProductsList[k].results.indexOf(existingSearchProduct);
                            state.searchProductsList[k].results.splice(index, 1, product);
                        }
                    });
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(uploadProductImages.pending, (state) => {
                state.uploadStatus = SliceStatus.LOADING;
            })
            .addCase(uploadProductImages.fulfilled, (state) => {
                state.uploadStatus = SliceStatus.IDLE;
                state.uploadMessage = 'Successfully uploaded images';
            })
            .addCase(uploadProductImages.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.uploadStatus = SliceStatus.ERROR;
                if (action.error.message) state.uploadMessage = action.error.message;
            })
            .addCase(uploadProductCSV.pending, (state) => {
                state.uploadStatus = SliceStatus.LOADING;
            })
            .addCase(uploadProductCSV.fulfilled, (state) => {
                state.uploadStatus = SliceStatus.IDLE;
                state.uploadMessage = 'Successfully uploaded CSV';
            })
            .addCase(uploadProductCSV.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.uploadStatus = SliceStatus.ERROR;
                if (action.error.message) state.uploadMessage = action.error.message;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                if (action.payload === true) {
                    const productId = action.meta.arg;
                    removeListElement<Product>(productId, state.all);
                    if (state.shopProductList) {
                        Object.keys(state.shopProductList).forEach((key) => {
                            if (state.shopProductList && state.shopProductList[key] !== null) {
                                const foundProduct = state.shopProductList[key].results.find((p) => p !== undefined && p !== null && p.id === productId);
                                if (foundProduct) {
                                    const index = state.shopProductList[key].results.indexOf(foundProduct);
                                    state.shopProductList[key].results.splice(index, 1);
                                }
                            }
                        });
                    }
                    if (state.searchProductsList) {
                        Object.keys(state.searchProductsList).forEach((key) => {
                            if (state.searchProductsList && state.searchProductsList[key] !== null) {
                                const foundProduct = state.searchProductsList[key].results.find((p) => p !== undefined && p !== null && p.id === productId);
                                if (foundProduct) {
                                    const index = state.searchProductsList[key].results.indexOf(foundProduct);
                                    state.searchProductsList[key].results.splice(index, 1);
                                }
                            }
                        });
                    }
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchProducts.pending, (state) => {
                state.searchStatus = SliceStatus.LOADING;
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (searchQuery === undefined || searchQuery === null || Array.isArray(searchQuery)) {
                    const searchProducts: { [searchQuery: string]: Product[] } = {};
                    const lastSearchUpdate: { [searchQuery: string]: number } = {};
                    action.payload.results.forEach((product) => {
                        if (searchProducts[product.name] === undefined) searchProducts[product.name] = [];
                        searchProducts[product.name].push(product);
                        lastSearchUpdate[product.name] = Date.now();
                    });
                    if (Array.isArray(searchQuery)) {
                        searchQuery.forEach((s) => {
                            if (state.searchProductsList !== null) {
                                state.searchProductsList[s] =
                                    searchProducts[s] !== undefined
                                        ? { ...action.payload, results: searchProducts[s] }
                                        : { count: 0, next: null, previous: null, results: [] };
                                state.lastSearchUpdate[s] = Date.now();
                            }
                        });
                    } else {
                        state.searchProductsList = { ['unknown']: action.payload };
                        state.lastSearchUpdate = lastSearchUpdate;
                    }
                } else {
                    if (state.searchProductsList && state.searchProductsList[searchQuery.toLowerCase()]) {
                        state.searchProductsList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    } else {
                        state.searchProductsList = {
                            ...state.searchProductsList,
                            [searchQuery.toLowerCase()]: { ...action.payload, results: new Array(action.payload.count) }
                        };
                        state.searchProductsList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    }
                }
                state.searchStatus = SliceStatus.IDLE;
            })
            .addCase(searchProducts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.searchStatus = SliceStatus.ERROR;
            });
    }
});

export const fetchProducts = createAsyncThunk<ProductResponse, ApiQueryParams<ProductsQueryParams>>(
    'fetchProducts',
    async (queryParams: ApiQueryParams<ProductsQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductAPIClient.fetchProductsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchProductById = createAsyncThunk<Product, string>('fetchProductById', async (productId, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.fetchProductApi({ id: productId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchProductByUrl = createAsyncThunk<Product, string>('fetchProductByUrl', async (productUrl, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.fetchProductApi({ url: productUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateSlotsOfProduct = createAsyncThunk<Product, ProductSlotsModel>('updateSlotsOfProduct', async (slots, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.updateSlotsOfProductApi(slots);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const createProduct = createAsyncThunk<Product, CreateProductParams>('CreateProduct', async (body, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.createProductApi(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateProduct = createAsyncThunk<Product, UpdateProductParams>('updateProduct', async (productId, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.updateProductApi(productId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteProduct = createAsyncThunk<boolean, number>('deleteProduct', async (productId, { rejectWithValue }) => {
    try {
        const res = await ProductAPIClient.deleteProductApi(productId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete product with id: ${productId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const uploadProductImages = createAsyncThunk<{ data: string }, FormData>('uploadProductImage', async (body, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.uploadProductImagesApi(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const uploadProductCSV = createAsyncThunk<{ data: string }, FormData>('uploadProductCSV', async (body, { rejectWithValue }) => {
    try {
        return await ProductAPIClient.uploadProductCSVApi(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchProducts = createAsyncThunk<ProductResponse, ApiQueryParams<ProductsQueryParams>>(
    'searchProducts',
    async (options: ApiQueryParams<ProductsQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductAPIClient.searchProductsApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const productReducer =  productSlice.reducer;
