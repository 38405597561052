import { FaBox } from 'react-icons/fa';

import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';

export const ID = 'transaction-badge';

export interface Props {
    transactionId: string;
}

const TransactionBadge = (props: Props): JSX.Element => {
    return (
        <BadgeTemplate
            type={DetailModalType.TRANSACTION}
            objectId={props.transactionId}>
            <FaBox className='me-2' />
            {props.transactionId}
        </BadgeTemplate>
    );
};

export default TransactionBadge;
