import _ from 'lodash';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TransactionStatus } from '../../common/TransactionStatus';
import TransactionStatusChangeEvent from '../../common/TransactionStatusChangeEvent';
import SelectInput from '../../components/forms/select/Select';

export interface NotificationSetting {
    event: TransactionStatusChangeEvent;
    send?: { profile: string };
}

interface Props {
    previeusSettings?: NotificationSetting[];
    initialStatus: TransactionStatus;
    currentEndStatus: TransactionStatus;
    onChange: (settings: NotificationSetting[]) => void;
}

export default function NotificationOverwrite(props: Props) {
    const [notificationSettings, changeNotificationSettings] = useState<NotificationSetting[]>(props.previeusSettings ? props.previeusSettings : []);
    if (props.currentEndStatus === undefined) return <></>;
    const possibleNotifications = TransactionStatusChangeEvent.getIntermediateEventsForStatusUpdate(props.initialStatus, props.currentEndStatus);

    const updateNotificationsSettings = (setting: NotificationSetting) => {
        const settings = _.clone(notificationSettings.filter((s) => s.event.value !== setting.event.value));
        if (setting.send !== undefined) settings.push(setting);

        props.onChange(settings);
        changeNotificationSettings(settings);
    };

    return (
        <div className='me-3'>
            <h6>
                <FormattedMessage
                    id='NotificationOptions.notificationConfig.title'
                    description='The title of the notification config modal.'
                    defaultMessage='Notification configuration'
                />
            </h6>
            <div>
                {possibleNotifications !== undefined &&
                    possibleNotifications.map((event) => {
                        return (
                            <NotificationOptions
                                key={event.value}
                                event={event}
                                value={notificationSettings.find((s) => s.event.value === event.value)}
                                onChange={updateNotificationsSettings}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

interface NotificationOptionsProps {
    value?: NotificationSetting;
    event: TransactionStatusChangeEvent;
    onChange: (setting: NotificationSetting) => void;
}
function NotificationOptions(props: NotificationOptionsProps) {
    const onChange = (value: any) => {
        const send = getNotificationProfile(value.value);
        props.onChange({
            event: props.event,
            send: send
        });
    };
    const options = [
        {
            value: 'default',
            label: (
                <FormattedMessage
                    id='NotificationOptions.useDefault'
                    description='the use default configuration option for NotificationOptions'
                    defaultMessage='Use default'
                />
            )
        },
        {
            value: 'send',
            label: (
                <FormattedMessage
                    id='NotificationOptions.Send'
                    description='the send option for NotificationOptions'
                    defaultMessage='Send'
                />
            )
        },
        {
            value: 'dontSend',
            label: (
                <FormattedMessage
                    id='NotificationOptions.DontSend'
                    description='the dont send option for NotificationOptions'
                    defaultMessage="Don't send"
                />
            )
        }
    ];

    if (props.event.value === 'pickup_in_progress') {
        options.push({
            value: 'noPickup',
            label: (
                <FormattedMessage
                    id='NotificationOptions.noPickup'
                    description='the no pickup notifications option for NotificationOptions'
                    defaultMessage='No pickup notifications'
                />
            )
        });
    }
    return (
        <SelectInput
            value={props.value === undefined ? { value: 'default' } : props.value.send}
            label={props.event.displayedValue}
            options={options}
            onChange={(value: any) => onChange(value)}
            submitted={false}
        />
    );
}

export function getNotificationProfile(value: string) {
    switch (value) {
        case 'send':
            return { profile: 'notify_sender' };
        case 'dontSend':
            return { profile: 'no_notifications' };
        case 'noPickup':
            return { profile: 'no_pickup_notifications' };
    }
}
