import { UserInfo } from '@frontend/user';
import { FormattedMessage } from 'react-intl';

import EmailBadge from '../../components/badges/EmailBadge';
import convertLanguage from '../../utils/LanguageConvert';
import AccountsList from '../accounts/accounts-list/AccountsList';

interface UserDetailProps {
    user?: UserInfo;
}

export default function UserDetail(props: UserDetailProps) {
    if (!props.user) {
        return <></>;
    }
    const language = convertLanguage(props.user.language);
    return (
        <>
            <div className='d-flex flex-column'>
                <h5 className='d-flex justify-content-center'>
                    <FormattedMessage
                        id='UserDetail.Info.Title'
                        description='UserDetail info title'
                        defaultMessage={'Info'}
                    />
                </h5>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.Username.Title'
                            description='UserDetail username title'
                            defaultMessage={'Username'}
                        />
                    </h6>
                    {!!props.user.username && <p>{props.user.username}</p>}
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.Email.Title'
                            description='UserDetail email title'
                            defaultMessage={'Email'}
                        />
                    </h6>
                    {!!props.user.email && <EmailBadge email={props.user.email}></EmailBadge>}
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.firstName.Title'
                            description='UserDetail first name title'
                            defaultMessage={'First Name'}
                        />
                    </h6>
                    {!!props.user.first_name && <p>{props.user.first_name}</p>}
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.lastName.Title'
                            description='UserDetail last name title'
                            defaultMessage={'Last Name'}
                        />
                    </h6>
                    {!!props.user.last_name && <p>{props.user.last_name}</p>}
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.Language.Title'
                            description='UserDetail language title'
                            defaultMessage={'Language'}
                        />
                    </h6>
                    {language}
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.phoneNumber.Title'
                            description='UserDetail phone number title'
                            defaultMessage={'Phone number'}
                        />
                    </h6>
                    {!!props.user.phone && <p>{props.user.phone}</p>}
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='UserDetail.mobileNumber.Title'
                            description='UserDetail mobile number title'
                            defaultMessage={'Mobile number'}
                        />
                    </h6>
                    {!!props.user.mobile && <p>{props.user.mobile}</p>}
                </div>
            </div>
            <h5 className='d-flex justify-content-center'>
                <FormattedMessage
                    id='UserDetail.Accounts.Title'
                    description='UserDetail info title'
                    defaultMessage={'Accounts'}
                />
            </h5>
            <AccountsList accounts={props.user.account_members}></AccountsList>
        </>
    );
}
