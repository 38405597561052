import { IoMdContact } from 'react-icons/io';

import { TransactionEvent, isContactUpdateEvent } from '../../../common/TransactionEvents';
import TimeLineItemTextField from './TimeLineItemTextField';

export default function ContactUpdateEvent(props: { event: TransactionEvent }) {
    if (!isContactUpdateEvent(props.event.event_data)) return <></>;
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <IoMdContact className='text-info display-1' />
            </span>
            <TimeLineItemTextField
                title={props.event.event_data.message}
                date={props.event.event_date}
            />
        </div>
    );
}
