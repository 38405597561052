import { Dispatch, SetStateAction, useState } from 'react';

import { TransactionStatus } from '../../../common/TransactionStatus';
import { Props } from './transaction-detail-modal.component';

interface ViewProps {
    canMove?: boolean;
    canUpdateStatus?: boolean;
    updateModal: {
        value: boolean;
        change: Dispatch<SetStateAction<boolean>>;
    };
    moveModal: {
        value: boolean;
        change: Dispatch<SetStateAction<boolean>>;
    };
}

const useTransactionDetailModal = (props: Props): ViewProps => {
    const updateModal = useState<boolean>(false);
    const moveModal = useState<boolean>(false);

    const canMove =
        props.transaction !== undefined &&
        (props.transaction.status === TransactionStatus.READY_FOR_DROPOFF.value ||
            props.transaction.status === TransactionStatus.READY_FOR_PICKUP.value ||
            props.transaction.status === TransactionStatus.CANCELLED.value);
    const canUpdateStatus =
        props.transaction !== undefined &&
        (props.transaction.status === TransactionStatus.READY_FOR_DROPOFF.value ||
            props.transaction.status === TransactionStatus.READY_FOR_PICKUP.value ||
            props.transaction.status === TransactionStatus.CANCELLED.value);

    return {
        canMove,
        canUpdateStatus,
        updateModal: {
            value: updateModal[0],
            change: updateModal[1]
        },
        moveModal: {
            value: moveModal[0],
            change: moveModal[1]
        }
    };
};

export default useTransactionDetailModal;
