import CategoryDetailModalWrapper from '../../../catalogue/categories/category-detail/category-detail-modal-wrapper/category-detail-modal-wrapper.component';
import ProductDetailModalWrapper from '../../../catalogue/products/products-detail/ProductDetailModalWrapper';
import ContactGroupDetailModalWrapper from '../../../contact-groups/contact-group-detail/contact-group-detail-modal-wrapper/contact-group-detail-modal-wrapper.component';
import ContactDetailModalWrapper from '../../../contacts/contact-detail/ContactDetailModalWrapper';
import SlotDetailModalWrapper from '../../../slots/slot-detail/slot-detail-modal-wrapper/slot-detail-modal-wrapper.component';
import SpotDetailModalWrapper from '../../../spots/spot-detail/spot-detail-modal-wrapper/spot-detail-modal-wrapper.component';
import BulkTransactionDetailModalWrapper from '../../../transactions/bulk-transaction-detail/bulk-transaction-detail-modal-wrapper/bulk-transaction-detail-modal-wrapper.component';
import TransactionDetailModalWrapper from '../../../transactions/transaction-detail/transaction-detail-modal-wrapper/transaction-detail-modal-wrapper.component';
import UserDetailModalWrapper from '../../../users/user-detail/user-detail-modal/user-detail-modal-wrapper.component';
import { ModalProps } from '../Modal';
import { DetailModalType } from './detail-modal-container/detail-modal-container.controller';

export const ID = 'detail-modal';

export interface Props extends ModalProps {
    detailModalType: DetailModalType;
    id: string;
}

const ObjectDetailModal = (props: Props): JSX.Element => {
    return (
        <div id={ID}>
            {props.detailModalType == DetailModalType.PRODUCT && <ProductDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.CONTACT && <ContactDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.TRANSACTION && <TransactionDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.BULK_TRANSACTION && <BulkTransactionDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.SPOT && <SpotDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.SLOT && <SlotDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.CONTACT_GROUP && <ContactGroupDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.CATEGORY && <CategoryDetailModalWrapper {...props} />}
            {props.detailModalType == DetailModalType.USER && <UserDetailModalWrapper {...props} />}
        </div>
    );
};

export default ObjectDetailModal;
