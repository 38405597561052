import { FaBell } from 'react-icons/fa';

interface Props {
    onClick: () => void;
    count: number;
    setRef?: any;
}

const BellButton = (props: Props) => {
    return (
        <button
            ref={props.setRef}
            className='btn btn-light position-relative'
            onClick={props.onClick}>
            <FaBell size={20} />
            {props.count > 0 && <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>{props.count}</span>}
        </button>
    );
};

export default BellButton;
