import { Category } from '@frontend/category';
import { useEffect, useState } from 'react';

interface ProductBreadcrumbProps {
    selectedCategory: Category | undefined;
    onChange?: (value: Category | undefined) => void;
}
const ProductBreadcrumb = (props: ProductBreadcrumbProps) => {
    const [breadcrumbs, changeBreadcrumbs] = useState<string[]>([]);
    const [categoryList, changeCategoryList] = useState<Category[]>([]);

    useEffect(() => {
        if (props.selectedCategory && props.selectedCategory !== undefined) {
            const foundCategory = categoryList.find((c) => c.id === props.selectedCategory!.id);
            if (!foundCategory) {
                changeCategoryList((prevState) => [...prevState, props.selectedCategory!]);
            }
        }
    }, [props.selectedCategory]);

    useEffect(() => {
        const temp = categoryList.map((c) => c.name);
        changeBreadcrumbs(temp);
    }, [categoryList]);

    return (
        <>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5'>
                    <li className='breadcrumb-item text-sm'>
                        <a
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                changeCategoryList([]);
                                props.onChange && props.onChange(undefined);
                            }}
                            className='opacity-10 text-primary'>
                            Home
                        </a>
                    </li>
                    {breadcrumbs.map((value, index) => {
                        if (index === breadcrumbs.length - 1) {
                            return (
                                <li
                                    key={value}
                                    className='breadcrumb-item text-sm text-primary font-weight-bold active'
                                    aria-current='page'>
                                    <a href='#'>{value}</a>
                                </li>
                            );
                        }

                        return (
                            <li
                                key={value}
                                className='breadcrumb-item text-sm'
                                aria-current='page'>
                                <a
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        changeCategoryList(categoryList.slice(0, index + 1));
                                        props.onChange && props.onChange(categoryList[index]);
                                    }}>
                                    {value}
                                </a>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </>
    );
};

export default ProductBreadcrumb;
