import { ApiError, ApiViewSet, DetailOptions, apiDetail, fetchApi, postApi } from '@frontend/api-utils';
import { LocaleValue } from '@frontend/language';

import { ReportFormats } from '../../common/BackendEnums';

export interface ReportInfo {
    id: number;
    url: string;
    name: string;
    identifier_tag: ReportInfoIdentifierTag;
    icon: string;
    order_num: number;
    created_at: Date;
    udpated_at: Date | null;
    created_by: number | null;
    category: number;

    parameters: {
        data: ReportParameter[];
    };
    formats: {
        data: ReportFormats[];
    };
    actions: {
        data: ReportActionModel[];
    };
}

export interface ReportParameter {
    name: string;
    type: ReportParamType;
    value?: string;
    options?: any[];
}

export interface Report {
    columns: { display_name: string; column_name: string; type: string }[];
    data: any[];
}

export interface ReportActionModel {
    name: string;
    fields: ReportActionModelFields[];
    display_name: string;
}

export interface ReportActionModelFields {
    data: ReportActionModelData;
    name: string;
    type: string;
    required: boolean;
    resource: string;
}

export interface ReportActionModelData {
    url: string;
    params: ReportActionModelDataParams[];
}

export interface ReportActionModelDataParams {
    name: string;
    type: string;
    default_value: string;
}

export enum ReportParamType {
    DATETIME = 'datetime',
    DATE = 'date',
    SPOT = 'url',
    DROPDOWN = 'dropdown'
}
const reportsViewSet: ApiViewSet = {
    baseName: 'reports'
};

export async function fetchReportApi(options: DetailOptions, language: LocaleValue): Promise<Report> {
    return apiDetail<Report>(reportsViewSet, options, { headers: { 'Accept-Language': language } });
}

export function createReportUrl(reportId: number, accountId: number, queryParams: Map<string, string | undefined>, format?: ReportFormats): string {
    let url = `/${reportsViewSet.baseName}/${reportId}/?account=${accountId}`;
    url += format ? `&frmt=${format}` : '';
    queryParams.forEach((value, key) => {
        if (value !== undefined) url += `&${key}=${value}`;
    });
    return url;
}

export function createQueryParamsUrl(url: string, queryParams: Map<string, string | undefined>, format?: ReportFormats): string {
    let newUrl = url;
    newUrl += format ? `?frmt=${format}` : '';
    queryParams.forEach((value, key) => {
        if (value !== undefined) newUrl += `&${key}=${value}`;
    });
    return newUrl;
}

export async function fetchReportBlobApi(
    report: ReportInfo,
    reportId: number,
    accountId: number,
    queryParams: Map<string, string | undefined>,
    format?: ReportFormats
): Promise<void> {
    const url = createReportUrl(reportId, accountId, queryParams, format);
    fetchApi(url, undefined).then(async (response) => {
        const blob = await response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        const fileName = report ? report.name : Date.now().toString();
        alink.download = `${fileName}.${format?.toString()}`;
        alink.click();
    });
}

export async function validateReportActionApi(id: number, body: { name: string; fields: { [key: string]: unknown } }): Promise<any> {
    const url = `/reports/${id}/validate_action/`;
    const response = await postApi(url, { action: body });
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error validating this report');
        }
        throw new ApiError('Error validating this report', json);
    }
    return await response.json();
}

export async function executeReportActionApi(id: number, body: { name: string; fields: { [key: string]: unknown } }): Promise<any> {
    const url = `/reports/${id}/execute_action/`;
    const response = await postApi(url, { action: body });
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error executing this action');
        }
        throw new ApiError('Error executing this action', json);
    }
    return await response.json();
}

export enum ReportInfoIdentifierTag {
    transactions_report = 'transactions_report',
    transactions_report_dexis_belgium = 'transactions_report_dexis_belgium',
    scheduled_transactions_report = 'scheduled_transactions_report',
    daily_transactions = 'daily_transactions',
    daily_dropoff_report = 'daily_dropoff_report',
    daily_returns_dexis_belgium = 'daily_returns_dexis_belgium',
    stock_report = 'stock_report',
    consumption_volume_by_product_report = 'consumption_volume_by_product_report',
    consumption_volume_by_group_report = 'consumption_volume_by_group_report',
    daily_orders_dexis_belgium = 'daily_orders_dexis_belgium',
    daily_returns_processed_dexis_belgium = 'daily_returns_processed_dexis_belgium',
    stock_report_dexis_belgium = 'stock_report_dexis_belgium',
    daily_stock_update_dexis_belgium = 'daily_stock_update_dexis_belgium',
    slot_logs_report = 'slot_logs_report',
    slot_config_report = 'slot_config_report',
    terminal_health_check = 'terminal_health_check',
    sensor_warning_logs_report = 'sensor_warning_logs_report',
    consumption_volume_by_contact_report = 'consumption_volume_by_contact_report',
    stock_fill_report = 'stock_fill_report'
}
