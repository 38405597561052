import { Placement, autoUpdate, offset, useFloating } from '@floating-ui/react-dom';
import { ClassType } from '@frontend/common';
import { useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import CheckSwitch from '../forms/check/CheckSwitch';
import ConfirmationModal from '../modals/ConfirmationModal';
import { onClickOutside } from '../modals/onClickOutside';
import useNotification from './notification-button.controller';
import BellButton from './notification-center-components/bell-button.component';
import NotificationContainer from './notification-center-components/notification-container.component';
import NotificationItem from './notification-center-components/notification-item.component';

const NotificationButton = (props: { placement?: Placement; class?: string }) => {
    const viewProps = useNotification();
    const containerRef = useRef(null);
    onClickOutside(containerRef, () => viewProps.setIsOpen(false));
    const { refs, floatingStyles } = useFloating({
        placement: props.placement || 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware: [offset(10)]
    });

    return (
        <div
            ref={containerRef}
            className={props.class ? props.class : ''}>
            <BellButton
                setRef={refs.setReference}
                count={viewProps.opened ? 0 : viewProps.totalCount}
                onClick={() => {
                    viewProps.setIsOpen(!viewProps.isOpen);
                    viewProps.setOpened(true);
                }}
            />
            {viewProps.isOpen && (
                <NotificationContainer
                    setRef={refs.setFloating}
                    floatingStyles={floatingStyles}
                    placement='bottom'
                    isOpen={viewProps.isOpen}>
                    <div className='card'>
                        <div className='card-header bg-info'>
                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                <h6 className='me-5 text-white'>
                                    {
                                        <FormattedMessage
                                            id='notification-button.component.notification'
                                            description='notification title'
                                            defaultMessage='Notifications'
                                        />
                                    }
                                </h6>
                                {
                                    <CheckSwitch
                                        color='white'
                                        label='Show all messages'
                                        checked={viewProps.showAll}
                                        onChange={() => viewProps.changeShowAll(!viewProps.showAll)}
                                    />
                                }
                                {
                                    <FaTrash
                                        size={20}
                                        color='black'
                                        cursor={'pointer'}
                                        onClick={() => viewProps.changeShowDeleteModal(true)}
                                    />
                                }
                            </div>
                        </div>
                        <div className='card-body notification-body'>
                            {viewProps.notificationsList.length === 0 || (viewProps.unreadCount === 0 && viewProps.showUnreadOnly && <h6>No notifications</h6>)}
                            {viewProps.notificationsList.map((n) => (
                                <NotificationItem
                                    key={n.id}
                                    notification={n}
                                    remove={viewProps.remove}
                                    markAsRead={viewProps.markAsRead}
                                    delete={viewProps.removeNotification}
                                />
                            ))}
                            {!viewProps.showAll && viewProps.totalCount - viewProps.notificationsList.length !== 0 && (
                                <div className='d-flex flex-row justify-content-center'>
                                    <a
                                        href='#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            viewProps.changeShowAll(!viewProps.showAll);
                                        }}>{`+${viewProps.totalCount - viewProps.notificationsList.length} notifications`}</a>
                                </div>
                            )}
                        </div>
                        {/* <div className='card-footer d-flex flex-row justify-content-between bg-primary'>
                            <button
                                onClick={viewProps.clear}
                                className='btn bg-gradient-secondary'>
                                clear
                            </button>
                            <button
                                onClick={viewProps.markAllAsRead}
                                className='btn bg-gradient-secondary'>
                                mark as read
                            </button>
                            <button
                                onClick={gen}
                                className='btn bg-gradient-secondary'>
                                gen
                            </button>
                        </div> */}
                    </div>
                </NotificationContainer>
            )}
            <ConfirmationModal
                show={viewProps.showDeleteModal}
                handleClose={() => viewProps.changeShowDeleteModal(false)}
                message={
                    <FormattedMessage
                        id='notification-delete-all'
                        description='Delete all notifications'
                        defaultMessage='You are about to delete <s>ALL</s> notifications'
                        values={{
                            s: (chunks) => <strong>{chunks}</strong>
                        }}
                    />
                }
                onConfirm={viewProps.removeAllNotifications}
                severity={ClassType.DANGER}
            />
        </div>
    );
};

export default NotificationButton;
