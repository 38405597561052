import React, { useEffect, useState } from 'react';

import { Locale } from '../../../common/BackendEnums';
import { getInputClass } from '../../../utils/FormUtils';

interface Props {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: Locale) => void;
    onFocusChange?: () => void;
    value?: Locale;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    submitted: boolean;
}

export default function DefaultLanguageSelect(props: Props) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && props.value === undefined) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <select
                    className={getInputClass(touched, valid)}
                    required={props.required}
                    id={props.label?.toString() + '.list'}
                    value={props.value || Locale.ENGLISH}
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value as Locale);
                        isValid();
                    }}>
                    <option
                        key={'Nederlands'}
                        value={Locale.DUTCH}>
                        Nederlands
                    </option>
                    <option
                        key={'English'}
                        value={Locale.ENGLISH}>
                        English
                    </option>
                    <option
                        key={'Français'}
                        value={Locale.FRENCH}>
                        Français
                    </option>
                </select>
                {valid === false && touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
