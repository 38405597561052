import { IoArrowRedoCircle } from 'react-icons/io5';

import { TransactionEvent, TransactionMovedDetail, isMovedEvent } from '../../../common/TransactionEvents';
import DateBadge from '../../../components/badges/DateBadge';
import SpotBadge from '../../../components/badges/spot-badge/spot-badge.component';

export default function MovedEvent(props: { event: TransactionEvent }) {
    if (!isMovedEvent(props.event.event_data)) return <></>;
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <IoArrowRedoCircle className='text-warning display-1' />
            </span>
            <div className='timeline-content'>
                <h6 className='text-dark text-sm font-weight-bold mb-0'>Transaction moved</h6>
                <p className='text-nowrap text-secondary font-weight-bold text-xs mt-1 mb-0'>
                    <SpotBadge
                        spot_id={(props.event.detail as TransactionMovedDetail).old_spot.id.toString()}
                        name={(props.event.detail as TransactionMovedDetail).old_spot.name}
                    />
                </p>
                <DateBadge
                    date={props.event.event_date}
                    displayTime={true}
                />
            </div>
        </div>
    );
}
