import { useEffect, useState } from 'react';

import { Spot } from '../../../api/Spots';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchSpotById } from '../../../store/spotSlice';
import { SpotDetailModalWrapperProps } from './spot-detail-modal-wrapper.component';

interface ViewProps {
    spot?: Spot;
}

const useSpotDetailModalWrapper = (props: SpotDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const spots = useAppSelector((state) => state.spots.spotList);
    const [spot, changeSpot] = useState<Spot | undefined>(undefined);

    useEffect(() => {
        const found = spots?.find((s) => +s.id.replace('SPT', '') === parseInt(props.id));
        if (found) changeSpot(found);
        else dispatch(fetchSpotById(props.id));
    }, [props.id, spots]);

    return { spot };
};

export default useSpotDetailModalWrapper;
