import { SliceStatus } from '@frontend/common';
import { fetchUsers } from '@frontend/user';
import { useEffect, useMemo } from 'react';

import useAuthorization from '../../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { SelectInputProps } from '../Select';

interface ViewProps {
    options: { value: number; label: string }[];
    isLoading: boolean;
}

const useUserSelect = (props: SelectInputProps): ViewProps => {
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state) => state.user);
    const membership = useAppSelector((state) => state.user.selectedMembership);
    const isSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const isAdmin = useAuthorization(DefaultRole.ADMIN);

    useEffect(() => {
        if (!membership) return;
        dispatch(fetchUsers({ account: membership.account.id.toString(), page: '1', page_size: '1000' }));
    }, []);

    const options = useMemo(() => {
        if (userState.usersList !== null) {
            if (isSuperAdmin) {
                return userState.usersList.results.map((u) => ({
                    value: u.id,
                    label: u.first_name && u.last_name ? u.first_name + ' ' + u.last_name : u.username.split('@')[0]
                }));
            } else if (isAdmin) {
                return userState.usersList.results
                    .filter((user) => (userState.user && user.email.includes(userState.user.email)) || !user.email.includes('telloport'))
                    .map((u) => ({
                        value: u.id,
                        label: u.first_name && u.last_name ? u.first_name + ' ' + u.last_name : u.username.split('@')[0]
                    }));
            } else return [];
        } else return [];
    }, [userState.usersList]);

    return {
        options,
        isLoading: userState.userListStatus === SliceStatus.LOADING && options.length === 0
    };
};

export default useUserSelect;
