import { ChangeEvent, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getInputClass } from '../../../utils/FormUtils';

interface ImageInputProps {
    onChange?: (value: File) => void;
    label: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    value?: string | File | null;
    errorMessage?: React.ReactNode;
    id?: string;
}

const ImageInput = (props: ImageInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState('');
    const [error, changeError] = useState<FileInputError>(FileInputError.NONE);
    const [isValid, changeIsValid] = useState<boolean>(false);
    const [touched, changeTouched] = useState<boolean>(false);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFile = e.target.files[0];

            if (selectedFile && selectedFile.type.startsWith('image/') && /\.(png|jpg|jpeg)$/i.test(selectedFile.name)) {
                if (props.onChange) {
                    props.onChange(selectedFile);
                }
                setValue(selectedFile.name);
                changeError(FileInputError.NONE);
                changeIsValid(true);
            } else {
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
                changeError(FileInputError.INVALID_IMAGE);
                changeIsValid(false);
            }
        }
    };

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <input
                    id={props.id}
                    ref={inputRef}
                    type='file'
                    className={getInputClass(touched, isValid, value)}
                    accept='image/*'
                    onChange={handleFileChange}
                    onFocus={() => {
                        changeTouched(true);
                    }}></input>
                {isValid === false && touched === true && (
                    <>
                        {props.errorMessage === undefined ? (
                            <>{error === FileInputError.INVALID_IMAGE && invalidFileError}</>
                        ) : (
                            <span className='badge bg-gradient-danger mt-2'>
                                <small>{props.errorMessage}</small>
                            </span>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default ImageInput;

export enum FileInputError {
    NONE,
    INVALID_IMAGE,
    INVALID_CSV,
    INVALID_FILE
}

const invalidFileError = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='imageInput.invalidFile.errorMessage'
                description='The error message for the image input.'
                defaultMessage='Please select a valid image.'
            />
        </small>
    </span>
);
