import { FaPhone } from 'react-icons/fa';

interface PhoneNumberBadgeProps {
    phoneNumber?: string;
}

export default function PhoneNumberBadge(props: PhoneNumberBadgeProps) {
    if (props.phoneNumber === undefined || props.phoneNumber === '') return <></>;
    return (
        <a href={'tel:' + props.phoneNumber}>
            <span className='d-flex flex-row align-items-center'>
                <span className='text-success me-2'>
                    <FaPhone />
                </span>
                {props.phoneNumber}
            </span>
        </a>
    );
}
