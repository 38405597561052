import { FormattedMessage } from 'react-intl';

export enum WorkflowName {
    PUDO = 'pudo',
    PUDO_PRIVATE = 'pudo_private',
    IMES_DEXIS_NIKE = 'imes_dexis_nike',
    LENDING = 'lending',
    VENDING = 'vending',
    WAREHOUSE = 'warehouse'
}

export default class Workflow {
    static readonly PUDO = new Workflow(
        WorkflowName.PUDO,
        (
            <FormattedMessage
                id='Workflow.PUDO'
                description='displayed value for workflow PUDO'
                defaultMessage='PuDo'
            />
        )
    );
    static readonly PUDO_PRIVATE = new Workflow(
        WorkflowName.PUDO_PRIVATE,
        (
            <FormattedMessage
                id='Workflow.PUDO_PRIVATE'
                description='displayed value for workflow PUDO_PRIVATE'
                defaultMessage='PuDo private'
            />
        )
    );
    static readonly IMES_DEXIS_NIKE = new Workflow(
        WorkflowName.IMES_DEXIS_NIKE,
        (
            <FormattedMessage
                id='Workflow.IMES_DEXIS_NIKE'
                description='displayed value for workflow IMES_DEXIS_NIKE'
                defaultMessage='Imes Dexis Nike'
            />
        )
    );
    static readonly LENDING = new Workflow(
        WorkflowName.LENDING,
        (
            <FormattedMessage
                id='Workflow.LENDING'
                description='displayed value for workflow LENDING'
                defaultMessage='Lending'
            />
        )
    );
    static readonly VENDING = new Workflow(
        WorkflowName.VENDING,
        (
            <FormattedMessage
                id='Workflow.VENDING'
                description='displayed value for workflow VENDING'
                defaultMessage='Vending'
            />
        )
    );
    static readonly WAREHOUSE = new Workflow(
        WorkflowName.WAREHOUSE,
        (
            <FormattedMessage
                id='Workflow.WAREHOUSE'
                description='displayed value for workflow WAREHOUSE'
                defaultMessage='Warehouse'
            />
        )
    );

    static readonly ALL = [Workflow.PUDO, Workflow.PUDO_PRIVATE, Workflow.IMES_DEXIS_NIKE, Workflow.LENDING, Workflow.VENDING, Workflow.WAREHOUSE];
    private constructor(public readonly value: WorkflowName, public readonly displayedValue: React.ReactNode) {}

    static getByName(value: WorkflowName): Workflow | undefined {
        return this.ALL.find((s) => s.value === value);
    }

    static getByString(value: string): Workflow | undefined {
        return this.ALL.find((s) => s.value.toString() === value);
    }
}
