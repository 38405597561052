import { ApiError, ApiQueryParams, ApiViewSet, PaginationResponse, apiPagination, putApi } from '@frontend/api-utils';

export type AccessControllerResponse = PaginationResponse<AccessController>;

export type AccessControllerLogsResponse = PaginationResponse<AccessControllerLog>;

export enum AccessControllerType {
    TLP_ACSY = 'tlp_acsy',
    TLP_PRNT = 'tlp_prnt'
}

export enum AccessControllerStatus {
    SUCCESS = 'Success',
    ERROR = 'Error'
}

export enum AccessControllerActionType {
    DOOR_EVENT = 'DoorEvent',
    OPEN_DOOR = 'OpenDoor',
    HEARTBEAT = 'DoorHeart'
}

export interface AccessController {
    uuid: string;
    name: string;
    type: AccessControllerType;
    model: string;
    request_data: { data: { [key: string]: unknown }[] };
    serial_number: string;
    data: { [key: string]: unknown };
    additional_data: { [key: string]: unknown };
    last_active_state: AccessControllerLastState;
    last_active_time: string;
    last_action: unknown;
    last_action_time: Date | string | null;
}

export interface AccessControllerLastState {
    data: {
        s_code: string;
        device_id: string;
    };
    status: AccessControllerStatus;
    action_type: AccessControllerActionType;
}

export interface AccessControllerLog {
    message: {
        message: string;
    };
    created_at: string;
}

export enum AccessControllerQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGE_SIZE = 'page_size'
}

const accessControllerViewSet: ApiViewSet = {
    baseName: 'services/iot/tlp_acsy'
};

export async function fetchAccessControllersApi(queryParams?: ApiQueryParams<AccessControllerQueryParams>): Promise<AccessControllerResponse> {
    return await apiPagination<AccessControllerResponse, AccessControllerQueryParams>(accessControllerViewSet, queryParams);
}

export async function fetchAccessControllerLogsApi(
    params: { uuid: string; model: string },
    queryParams?: ApiQueryParams<AccessControllerQueryParams>
): Promise<AccessControllerLogsResponse> {
    const URL = `/${accessControllerViewSet.baseName}/${params.model}/${params.uuid}/logs/`;
    return await apiPagination<AccessControllerLogsResponse, AccessControllerQueryParams>(accessControllerViewSet, queryParams, URL);
}

//commands

export async function accessControllerCommand(params: { uuid: string; model: string; path: string; body?: object }) {
    const accessControllerCommandURL = `/${accessControllerViewSet.baseName}/${params.model}/${params.uuid}/${params.path}/`;

    if (params.body) {
        const response = await putApi(accessControllerCommandURL, params.body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error executing command');
            }
            throw new ApiError('Error executing command', json);
        }
        return await response.json();
    } else {
        const response = await putApi(accessControllerCommandURL, undefined);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error executing command');
            }
            throw new ApiError('Error executing command', json);
        }
        return await response.json();
    }
}
