import { Attribute, fetchAttributes, updateAttribute } from '@frontend/attribute';
import { Category } from '@frontend/category';
import { ToastUtil } from '@frontend/toast-utils';
import { KeyboardEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { AttributeType } from '../../../common/AttributeType';
import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import MultiSelect from '../../../components/forms/select/MultiSelect';
import SelectInput from '../../../components/forms/select/Select';
import TextInput from '../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getAllChildren } from '../../../utils/GetAllChildren';
import { parseObject } from '../../../utils/ParseObject';
import { CreateAttributeModel } from './CreateAttributeForm';

interface UpdateAttributeProps {
    attribute: Attribute;
    handleClose: () => void;
}

const UpdateAttribute = (props: UpdateAttributeProps) => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const categoryState = useAppSelector((state) => state.categories);
    const attributeState = useAppSelector((state) => state.attributes);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<any>([]);
    const [selectedCategory, changeSelectedCategory] = useState<Category>();
    const [allCategories, changeAllCategories] = useState<Category[]>([]);
    const [foundAttributeType, changeFoundAttributeType] = useState<AttributeType>();

    const initialState: CreateAttributeModel = {
        account: props.attribute.account,
        category: null,
        name: props.attribute.name,
        type: undefined,
        values: props.attribute.values
    };

    const [formResult, changeFormResult] = useState(initialState);

    const createOption = (label: string) => ({
        label,
        value: label
    });

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev: any) => {
                    const newArray = Array.isArray(prev) ? [...prev] : [];

                    newArray.push(createOption(inputValue));
                    return newArray;
                });
                setInputValue('');
                event.preventDefault();
        }
    };

    useEffect(() => {
        if (categoryState.categoryList) {
            const allCategories = getAllChildren(categoryState.categoryList);
            changeAllCategories(allCategories);
        }
    }, [categoryState.categoryList]);

    useEffect(() => {
        changeFormResult({ ...formResult, values: value.map((v: any) => v.value) });
    }, [value]);

    useEffect(() => {
        if (categoryState.categoryList && props.attribute) {
            const foundCategory = getAllChildren(categoryState.categoryList).find((c) => c.id === props.attribute.category_obj?.id);
            if (foundCategory) {
                changeFormResult({ ...formResult, category: { label: foundCategory.name, value: foundCategory.id } });
            }
        }

        if (props.attribute) {
            const foundAttribute = AttributeType.getByString(props.attribute.type);
            if (foundAttribute) {
                changeFoundAttributeType(foundAttribute);
            }
        }
    }, [props.attribute, categoryState.categoryList]);

    useEffect(() => {
        if (foundAttributeType) {
            changeFormResult({ ...formResult, type: { label: foundAttributeType.displayedValue, value: foundAttributeType.name } });
        }

        if (props.attribute.values) {
            setValue(props.attribute.values.map((v) => ({ label: v, value: v })));
        }
    }, [selectedCategory, foundAttributeType, props.attribute.values]);

    const [nameValid, changeNameValid] = useState(false);
    const [typeValid, changeTypeValid] = useState(false);
    const [categoryValid, changeCategoryValid] = useState(false);
    const formValid = nameValid && typeValid && categoryValid;
    useEffect(() => {
        if (!submitted || !attributeState.attributeList) {
            return;
        }

        const parsedForm = parseObject(formResult);
        const updatedAttribute = {
            account: parsedForm.account,
            category: parsedForm.category,
            name: parsedForm.name,
            type: parsedForm.type,
            values: parsedForm.values
        };

        const isNameMatch = parsedForm.name === props.attribute.name;
        const isNameAvailable = !attributeState.attributeList.some((a) => a.name.toLowerCase() === parsedForm.name.toLowerCase());

        if ((isNameMatch && formValid) || (!isNameMatch && isNameAvailable && formValid)) {
            dispatch(updateAttribute({ attribute: updatedAttribute, id: props.attribute.id }));
        } else if (!isNameMatch && !isNameAvailable) {
            toast.warning(...ToastUtil.generateToastConfig('Duplicate attribute', attributeUpdateDuplicate, ClassType.WARNING));
            changeSubmitted(false);
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted && attributeState.attributeList) {
            const createdAttribute = attributeState.attributeList.find((a) => a.name.toLocaleLowerCase() === formResult.name.toLocaleLowerCase());
            if (createdAttribute) {
                toast.success(...ToastUtil.generateToastConfig(formResult.name, attributeUpdateSuccess, ClassType.SUCCESS));
                setTimeout(() => {
                    dispatch(fetchAttributes({ account: accountId?.toString() }));
                }, 2000);
                props.handleClose();
            } else {
                changeSubmitted(false);
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, attributeUpdateError, ClassType.DANGER));
            }
        }
    }, [attributeState.attributeList]);

    return (
        <>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                    changeSubmitted(true);
                }}>
                <div
                    className='modal-body'
                    style={{ overflowY: 'unset' }}>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='createAttribute.label.name'
                                        description='Label for name'
                                        defaultMessage='name'
                                    />
                                }
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                                value={formResult.name}
                                required
                                id='UpdateAttributeForm-NameInput'
                                isValidCallback={(value) => changeNameValid(value)}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateAttribute.label.type'
                                        description='Label for type'
                                        defaultMessage='type'
                                    />
                                }
                                submitted={submitted}
                                options={AttributeType.ALL.map((a) => ({ value: a.name, label: a.displayedValue }))}
                                onChange={(value: any) => changeFormResult({ ...formResult, type: value })}
                                value={formResult.type}
                                required
                                id='UpdateAttributeForm-TypeSelect'
                                isValidCallback={(value) => changeTypeValid(value)}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <MultiSelect
                                label={
                                    <FormattedMessage
                                        id='CreateAttributeForm.label.values'
                                        description='Label for values'
                                        defaultMessage='values'
                                    />
                                }
                                isMulti
                                submitted={submitted}
                                menuIsOpen={false}
                                inputValue={inputValue}
                                onChange={(newValue: any) => setValue(newValue)}
                                onInputChange={(newValue) => setInputValue(newValue)}
                                onKeyDown={handleKeyDown}
                                placeholder='Enter value and press Enter or Tab'
                                value={value}
                                id='UpdateAttributeForm-ValuesSelect'
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateAttributeForm.label.category'
                                        description='Label for category'
                                        defaultMessage='category'
                                    />
                                }
                                submitted={submitted}
                                options={allCategories.map((c) => ({ label: c.name, value: c.id }))}
                                onChange={(value: any) => changeFormResult({ ...formResult, category: value })}
                                value={formResult.category}
                                isClearable
                                required
                                id='UpdateAttributeForm-CategorySelect'
                                isValidCallback={(value) => changeCategoryValid(value)}
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    onSubmit={() => changeSubmitted(true)}
                    handleClose={() => undefined}
                    disabled={submitted || !formValid}
                />
            </form>
        </>
    );
};

export default UpdateAttribute;

const attributeUpdateSuccess = (
    <FormattedMessage
        id='UpdateAttribute.Update.Attribute.Success'
        description='Message when attribute was updated successfully'
        defaultMessage='Attribute was updated successfully'
    />
);

const attributeUpdateError = (
    <FormattedMessage
        id='UpdateAttribute.Update.Attribute.Error'
        description='Message when failing to update attribute'
        defaultMessage='There was an error updating your attribute'
    />
);

const attributeUpdateDuplicate = (
    <FormattedMessage
        id='UpdateAttribute.Update.Attribute.Duplicate'
        description='Message when attribute is duplicate'
        defaultMessage='This attribute already exists'
    />
);
