import { FormattedMessage } from 'react-intl';

const FILTER = {
    DEFAULT: (
        <FormattedMessage
            id='Filter.Default'
            description='The default message for filter'
            defaultMessage='Filter'
        />
    ),
    FILTER_HERE: (
        <FormattedMessage
            id='Filter.Filter_Here'
            description='Message for filter here'
            defaultMessage='Filter here...'
        />
    ),
    REMOVE_FILTER: (
        <FormattedMessage
            id='Filter.Remove_All_Filter'
            description='Message for a button to remove all filters'
            defaultMessage='Remove all filters'
        />
    )
};

export default FILTER;
