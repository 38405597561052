import { IoNotificationsCircle } from 'react-icons/io5';

import { NotificationDetail, TransactionEvent, isNotificationEvent } from '../../../common/TransactionEvents';
import DateBadge from '../../../components/badges/DateBadge';
import ContactBadge from '../../../components/badges/contact-badge/contact-badge.component';

export default function NotificationEvent(props: { event: TransactionEvent }) {
    if (!isNotificationEvent(props.event.event_data)) return <></>;
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <IoNotificationsCircle className='text-info display-1' />
            </span>
            <div className='timeline-content'>
                <h6 className='text-dark text-sm font-weight-bold mb-0'>Notification sent</h6>
                <p className='text-nowrap text-secondary font-weight-bold text-xs mt-1 mb-0'>
                    <ContactBadge
                        contactId={(props.event.detail as NotificationDetail).contact.id}
                        contact={(props.event.detail as NotificationDetail).contact}
                    />
                </p>
                <DateBadge
                    date={props.event.event_date}
                    displayTime={true}
                />
            </div>
        </div>
    );
}
