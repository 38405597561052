import { ModalProps } from '../../../components/modals/Modal';
import SpinnerModal from '../../../components/modals/SpinnerModal';
import SpotDetailModal from '../spot-detail-modal/spot-detail-modal.component';
import useSpotDetailModalWrapper from './spot-detail-modal-wrapper.controller';

export const ID = 'spot-detail-modal-wrapper';

export interface SpotDetailModalWrapperProps extends ModalProps {
    id: string;
}

const SpotDetailModalWrapper = (props: SpotDetailModalWrapperProps): JSX.Element => {
    const viewProps = useSpotDetailModalWrapper(props);

    if (viewProps.spot == undefined) {
        return <SpinnerModal {...props} />;
    }
    return (
        <SpotDetailModal
            spot={viewProps.spot}
            {...props}
            customWidth={30}
        />
    );
};

export default SpotDetailModalWrapper;
