const swUrl = `./public/service-worker.js`;

export async function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', async () => {
            const registration = await navigator.serviceWorker.register(swUrl);

            if (registration.waiting) {
                invokeServiceWorkerUpdateFlow(registration);
            }

            //Detect Service Worker update available and wait for it to become installed
            registration.addEventListener('updatefound', () => {
                if (registration.installing) {
                    //Wait until the new Service worker is actually installed (ready to take over)
                    registration.installing.addEventListener('statechange', () => {
                        if (registration.waiting) {
                            //If there's an existing controller (previous Service Worker), show the prompt
                            //Ohterwise no action needed since the new one will be used
                            if (navigator.serviceWorker.controller) {
                                invokeServiceWorkerUpdateFlow(registration);
                            }
                        }
                    });
                }
            });
        });
    }
}

//Ask users if they want to update right now
//Should be put into a react component eventually to have a proper UX/UI for the user
function invokeServiceWorkerUpdateFlow(registration: ServiceWorkerRegistration) {
    const val = window.confirm('There is a new version available. Would you like to update?');
    if (val) {
        if (registration.waiting) {
            registration.waiting.postMessage('SKIP_WAITING');
        }
    }
}
