import { Category } from '@frontend/category';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

const ProductListColumns: Column<Category>[] = [
    {
        Header: 'ID',
        accessor: 'id'
    },
    {
        Header: (
            <FormattedMessage
                id='ProductList.tableHeader.Image'
                description='The image table header for the product list.'
                defaultMessage='Image'
            />
        ),
        accessor: 'image'
    },
    {
        Header: (
            <FormattedMessage
                id='ProductList.tableHeader.name'
                description='The name table header for the product list.'
                defaultMessage='Name'
            />
        ),
        accessor: 'name'
    }
];

export default ProductListColumns;
