import { UserInfo, fetchUser } from '@frontend/user';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { UserDetailModalWrapperProps } from './user-detail-modal-wrapper.component';

interface ViewProps {
    user?: UserInfo;
}

const useUserDetailModalWrapper = (props: UserDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state) => state.user);
    const [user, changeUser] = useState<UserInfo | undefined>(undefined);

    useEffect(() => {
        if (!props.id) return;
        else dispatch(fetchUser({ id: props.id }));
    }, []);

    useEffect(() => {
        const found = userState.usersList?.results.find((u) => u.id.toString() == props.id);
        if (found == undefined) return;
        else changeUser(found);
    }, [userState.usersList]);

    return {
        user
    };
};

export default useUserDetailModalWrapper;
