import { Transaction, fetchTransactionById } from '@frontend/transaction';
import { useEffect, useMemo } from 'react';
import { HiQuestionMarkCircle } from 'react-icons/hi';

import {
    TransactionEvent,
    TransactionEventState,
    TransactionStatusUpdateEvent,
    isAccessKeyEvent,
    isContactUpdateEvent,
    isGroupChangeEvent,
    isMovedEvent,
    isNotificationEvent,
    isStatusUpdateEvent,
    isTransactionErrorEvent
} from '../../../common/TransactionEvents';
import { useAppDispatch } from '../../../hooks/redux';
import AccessKeyEvent from './AccessKeyEvent';
import ContactUpdateEvent from './ContactUpdateEvent';
import ErrorEvent from './ErrorEvent';
import GroupChangeEvent from './GroupChangeEvent';
import MovedEvent from './MovedEvent';
import NotificationEvent from './NotificationEvent';
import StatusUpdateEvent from './StatusUpdateEvent';
import TimeLineItemTextField from './TimeLineItemTextField';

const TransactionEventTimeline = (props: { transaction: Transaction }) => {
    const dispatch = useAppDispatch();
    const stack = useMemo(() => stackDuplicates(props.transaction.transaction_events), [props.transaction.transaction_events]);

    useEffect(() => {
        if (props.transaction.transaction_events === undefined) {
            dispatch(fetchTransactionById(props.transaction.id));
        }
    }, []);

    if (props.transaction.transaction_events === undefined) {
        return (
            <div className='timeline timeline-one-side'>
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <span
                            className='spinner-grow spinner-grow-sm'
                            role='status'
                            aria-hidden='true'></span>
                    </span>
                    <div className='timeline-content w-100'>
                        <h6 className='text-dark text-sm font-weight-bold mb-0 w-100'>
                            <span className='placeholder w-100'>transaction</span>
                        </h6>
                        <p className='text-nowrap text-secondary font-weight-bold text-xs mt-1 mb-0'>
                            <span className='placeholder col-11'></span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='timeline timeline-one-side'>
            {stack.map((e) => {
                if (isStatusUpdateEvent(e.event_data) && (e.event_data.state_from === TransactionEventState.NEW || e.times != undefined)) {
                    return (
                        <>
                            <StatusChangeTimelineItem
                                key={e.id}
                                event={e}
                            />
                            <StatusChangeTimelineItem
                                key={e.id + e.event_data.state_from}
                                event={e}
                                from
                            />
                        </>
                    );
                }
                return (
                    <StatusChangeTimelineItem
                        key={e.id}
                        event={e}
                    />
                );
            })}
        </div>
    );
};
export default TransactionEventTimeline;

export const StatusChangeTimelineItem = (props: { event: TransactionEvent; from?: boolean }) => {
    if (isNotificationEvent(props.event.event_data)) {
        return <NotificationEvent event={props.event} />;
    } else if (isStatusUpdateEvent(props.event.event_data)) {
        return <StatusUpdateEvent {...props} />;
    } else if (isTransactionErrorEvent(props.event.event_data)) {
        return <ErrorEvent event={props.event} />;
    } else if (isAccessKeyEvent(props.event.event_data)) {
        return <AccessKeyEvent event={props.event} />;
    } else if (isContactUpdateEvent(props.event.event_data)) {
        return <ContactUpdateEvent event={props.event} />;
    } else if (isGroupChangeEvent(props.event.event_data)) {
        return <GroupChangeEvent event={props.event} />;
    } else if (isMovedEvent(props.event.event_data)) {
        return <MovedEvent event={props.event} />;
    } else {
        return (
            <div className='timeline-block mb-3'>
                <span className='timeline-step'>
                    <HiQuestionMarkCircle className='text-warning display-1' />
                </span>
                <TimeLineItemTextField
                    title={props.event.event_data.message}
                    date={props.event.event_date}
                />
            </div>
        );
    }
};
const COUNTED_STATUSES = [TransactionEventState.REMOVE_PARCEL_TIMEOUT, TransactionEventState.PICKUP_TIMEOUT, TransactionEventState.DROPOFF_TIMEOUT];
const STACKABLE_STATUSES = [
    TransactionEventState.REMOVE_PARCEL_TIMEOUT,
    TransactionEventState.PICKUP_TIMEOUT,
    TransactionEventState.DROPOFF_TIMEOUT,
    TransactionEventState.DROPOFF_WAITING,
    TransactionEventState.PICKUP_WAITING,
    TransactionEventState.REMOVE_PARCEL_WAITING
];

function stackDuplicates(events?: TransactionEvent[]) {
    if (events === undefined) return [];

    const result: TransactionEvent[] = [];
    const stack: TransactionEvent[] = [];
    let count = 0;

    for (const event of events) {
        if (isStatusUpdateEvent(event.event_data) && STACKABLE_STATUSES.includes(event.event_data.state_to)) {
            stack.push(event);
            if (COUNTED_STATUSES.includes(event.event_data.state_to)) count++;
        } else {
            if (stack.length > 0) {
                const exampleEvent = findExampleEvent(stack);
                if (exampleEvent !== undefined) {
                    result.push({ ...(exampleEvent as TransactionEvent), times: count });
                }
                stack.length = 0;
                count = 0;
            }
            result.push(event);
        }
    }
    return result;
}

function findExampleEvent(stack: TransactionEvent[]): TransactionEvent | undefined {
    for (const ev of stack) {
        switch ((ev.event_data as TransactionStatusUpdateEvent).state_to) {
            case TransactionEventState.DROPOFF_TIMEOUT:
            case TransactionEventState.PICKUP_TIMEOUT:
            case TransactionEventState.REMOVE_PARCEL_TIMEOUT:
                return ev;
            default:
                break;
        }
    }
    return undefined;
}
