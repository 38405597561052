import { Logger } from '@frontend/Logger';
import { DEVICE_UNIQUE_ID, getCookie, setCookie } from '@frontend/common';

import { loginRequest, refreshRequest } from './authentication-client';
import { AccessToken } from './models';

export class AuthenticationManager {
    private static instance: AuthenticationManager | null;
    private blocked = false;
    private refreshTokenTimeout?: NodeJS.Timeout;
    public loggedIn: boolean;

    private constructor() {
        Logger.log('Authentication manager initialised.');
        if (!window.location.href.includes('login') && !window.location.href.includes('logout')) {
            window.localStorage.setItem('redirect', window.location.href);
        }
        this.loggedIn = this.hasValidToken();
        if (!this.isOnPublicPage()) this.refreshToken();
    }

    public static getInstance = (): AuthenticationManager => {
        if (!AuthenticationManager.instance) {
            AuthenticationManager.instance = new AuthenticationManager();
        }
        return AuthenticationManager.instance;
    };

    accessTokenLogin(accessToken: AccessToken): void {
        this.saveTokenToStorage(accessToken);
        this.blocked = false;
        this.loggedIn = true;

        //now redirect if needed
        const landingURL = window.localStorage.getItem('redirect');
        if (landingURL != null) {
            window.location.href = landingURL;
            window.localStorage.removeItem('redirect');
        } else {
            window.location.href = '/';
        }
    }

    async login(email: string, password: string): Promise<void> {
        if (this.blocked) return;
        this.blocked = false;
        const credentials = await loginRequest(email, password);
        this.accessTokenLogin(credentials);
    }
    logout() {
        if (!this.isOnPublicPage()) {
            window.location.href = '/logout';
        } else this.loggedIn = false;
    }
    async refreshToken() {
        if (this.blocked) return;
        this.blocked = true;
        try {
            const credentials = await refreshRequest(this.getTokenFromStorage().refreshToken);
            this.saveTokenToStorage(credentials);
            this.setRefreshTokenTimeout();
            this.loggedIn = true;
        } catch (error) {
            Logger.error('Something went wrong trying to refresh the token. Logging out.');
            this.loggedIn = false;
            this.logout();
        } finally {
            this.blocked = false;
        }
    }
    async getTokenWhenReady(): Promise<AccessToken> {
        return new Promise((resolve, reject) => {
            if (!this.hasValidToken() && this.blocked === false) reject(); //should basically logout here
            if (this.blocked) {
                const interval = setInterval(() => {
                    if (this.blocked === false) {
                        resolve(this.getTokenFromStorage());
                        clearInterval(interval);
                    }
                }, 1000);
            } else resolve(this.getTokenFromStorage());
        });
    }

    hasValidToken(): boolean {
        try {
            const credentials = this.getTokenFromStorage();
            return credentials.expires.getTime() > Date.now();
        } catch (e) {
            return false;
        }
    }

    getDeviceToken(): string {
        let deviceToken = getCookie(DEVICE_UNIQUE_ID);
        if (deviceToken == undefined) deviceToken = crypto.randomUUID();
        setCookie(DEVICE_UNIQUE_ID, deviceToken, 400);
        return deviceToken;
    }

    private isOnPublicPage(): boolean {
        return (
            window.location.href.includes('login') ||
            window.location.href.includes('logout') ||
            window.location.href.includes('signup-invitation') ||
            window.location.href.includes('request-password-change') ||
            window.location.href.includes('reset-password')
        );
    }
    private getTokenFromStorage(): AccessToken {
        const acToken = localStorage.getItem('token');
        const rfToken = localStorage.getItem('refresh_token');
        if (acToken == null || rfToken == null) {
            Logger.error('Something went wrong trying te get the access token from localstorage.');
            throw new Error('No token information available.');
        }
        return {
            accessToken: acToken,
            refreshToken: rfToken,
            expires: new Date(+localStorage.getItem('expires_in')!),
            tokenType: localStorage.getItem('token_type')!
        };
    }
    private saveTokenToStorage(tokenInfo: AccessToken): void {
        localStorage.setItem('token', tokenInfo.accessToken);
        localStorage.setItem('token_type', tokenInfo.tokenType);
        localStorage.setItem('expires_in', tokenInfo.expires.getTime().toString());
        localStorage.setItem('refresh_token', tokenInfo.refreshToken);
    }

    private async setRefreshTokenTimeout() {
        const credentials = await this.getTokenWhenReady();
        if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
        if (credentials && credentials.expires && credentials.refreshToken != null) {
            this.refreshTokenTimeout = setTimeout(() => {
                this.refreshToken();
            }, credentials.expires.getTime() - Date.now() - 60 * 60 * 1000);
        }
    }
}
