import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { TransactionsQueryParams } from '@frontend/transaction';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BulkTransaction, BulkTransactionResponse, fetchBulkTransactionApi, fetchBulkTransactionsApi } from '../api/BulkTransactions';

interface BulkTransactionState {
    bulkTransactionList: BulkTransaction[];
    bulkTransactionResponseList: BulkTransactionResponse;
    bulkTransactionState: SliceStatus;
    lastUpdate: number;
}

const initialState: BulkTransactionState = {
    bulkTransactionList: [],
    bulkTransactionResponseList: { count: 0, next: '', previous: '', results: [] },
    bulkTransactionState: SliceStatus.INIT,
    lastUpdate: Date.now()
};

export const bulkTransactionSlice = createSlice({
    name: 'bulkTransactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBulkTransactionById.fulfilled, (state, action) => {
                state.bulkTransactionList = [...state.bulkTransactionList, action.payload];
            })
            .addCase(fetchBulkTransactions.pending, (state) => {
                state.bulkTransactionState = SliceStatus.INIT;
            })
            .addCase(fetchBulkTransactions.fulfilled, (state, action) => {
                state.bulkTransactionResponseList = action.payload;
            });
    }
});

export const fetchBulkTransactionById = createAsyncThunk<BulkTransaction, string>('fetchBulkTransactionById', async (bulkId, { rejectWithValue }) => {
    try {
        return await fetchBulkTransactionApi({ id: bulkId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchBulkTransactions = createAsyncThunk<BulkTransactionResponse, ApiQueryParams<TransactionsQueryParams>>(
    'fetchBulkTransactions',
    async (queryParams, { rejectWithValue }) => {
        try {
            return await fetchBulkTransactionsApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default bulkTransactionSlice.reducer;
