import SelectInput, { SelectInputProps } from '../../Select';
import useContactRoleSelect from './contact-role-select.controller';

const ContactRoleSelect = (props: SelectInputProps) => {
    const viewProps = useContactRoleSelect(props);

    return (
        <SelectInput
            submitted={props.submitted}
            label={props.label || 'Contact role'}
            options={viewProps.options}
            onChange={props.onChange}
            value={viewProps.value}
        />
    );
};

export default ContactRoleSelect;
