import { Logger } from '@frontend/Logger';
import { fetchAllCategories } from '@frontend/category';
import { SliceStatus, shouldFetch } from '@frontend/common';
import { CreateProductModel, Product, updateProduct } from '@frontend/product';
import { fetchShops } from '@frontend/shop';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ClassType } from '../../../../common/BootstrapValues';
import { CommonMessage } from '../../../../common/CommonFormattedMessages/CommonMessage';
import CheckBox from '../../../../components/forms/check/CheckBox';
import MultiCheckbox from '../../../../components/forms/check/MultiCheckbox';
import ImageInput from '../../../../components/forms/imageInput/ImageInput';
import NumberInput from '../../../../components/forms/numberInput/NumberInput';
import SelectInput from '../../../../components/forms/select/Select';
import TextArea from '../../../../components/forms/textArea/TextArea';
import TextInput from '../../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../../components/modals/CreateModalFooter';
import useAuthorization from '../../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useAccount from '../../../../hooks/useAccount';
import { validJSON } from '../../../../utils/FormUtils';
import { getAllValuesFromStore } from '../../../../utils/KeyValueStores';
import { FindChangedValue } from '../../../../utils/Logs';

interface UpdateProductFormProps {
    handleClose: () => void;
    product: Product;
}

const UpdateProductForm = (props: UpdateProductFormProps) => {
    useAuthorization(DefaultRole.ADMIN, true);
    const dispatch = useAppDispatch();
    const productState = useAppSelector((state) => state.products);
    const shopState = useAppSelector((state) => state.shops);
    const shops = useAppSelector((state) => state.shops.shopList);
    const category = useAppSelector((state) => state.categories.allCategories);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formBody, changeFormBody] = useState<FormData>();
    const [showSpecs, toggleShowSpecs] = useState<boolean>(false);
    const { selectedUser } = useAccount();
    const shopId = useLocation().pathname.split('/')[2];

    useEffect(() => {
        if (category === null) {
            dispatch(fetchAllCategories({ account: accountId?.toString(), page: '1', page_size: '1000' }));
        }
    }, []);

    useEffect(() => {
        if (shops === null) {
            if (shouldFetch(shopState.status, shopState.lastUpdate)) dispatch(fetchShops({ account: accountId?.toString() }));
        }
    }, [props.product]);

    useEffect(() => {
        if (category) {
            const foundCategory = category.results.find((c) => c.id === props.product.category);
            if (foundCategory) changeFormResult({ ...formResult, category: { label: foundCategory.name, value: foundCategory.id } });
        }
    }, [props.product, category]);

    //Need to implement shops and low stock threshold when added to
    //PUT in backend

    const initialState: CreateProductModel = {
        account: props.product.account,
        category: null,
        name: props.product.name,
        image: props.product.image,
        product_data: JSON.stringify(props.product.product_data),
        product_codes: props.product.product_codes,
        alternative_product_codes: props.product.alternative_product_codes,
        ordering_allowed: props.product.ordering_allowed,
        low_stock_threshold_alert: props.product.low_stock_threshold_alert,
        slots: props.product.slots,
        description: props.product.description,
        product_shops: props.product.product_shops,
        diameter: props.product.diameter,
        height: props.product.height,
        length: props.product.length,
        max_stock: props.product.max_stock_volume,
        stock_count: props.product.computed_stock_count,
        weight: props.product.weight,
        weight_tolerance: props.product.weight_tolerance,
        width: props.product.width
    };
    const [formResult, changeFormResult] = useState(initialState);
    const [productDataValid, changeProductDataValid] = useState<boolean>();
    const [shopValid, changeShopValid] = useState<boolean>(false);

    useEffect(() => {
        changeShopValid(formResult.product_shops.length > 0);
        const formData = new FormData();
        formData.append('account', formResult.account.toString());
        formData.append('category', formResult.category ? formResult.category.value.toString() : '');
        formData.append('name', formResult.name);
        formResult.product_shops &&
            formResult.product_shops.forEach((s) => {
                formData.append('product_shops', s.toString());
            });
        formResult.image !== initialState.image && formResult.image && formData.append('image', formResult.image);
        formResult.product_data !== initialState.product_data && formResult.product_data && formData.append('product_data', formResult.product_data);
        formResult.product_codes !== initialState.product_codes &&
            formResult.product_codes &&
            formData.append('product_codes', formResult.product_codes.join(','));
        formResult.alternative_product_codes !== initialState.alternative_product_codes &&
            formResult.alternative_product_codes &&
            formData.append('alternative_product_codes', formResult.alternative_product_codes.join(','));
        formResult.ordering_allowed && formData.append('ordering_allowed', formResult.ordering_allowed.toString());
        formResult.low_stock_threshold_alert !== initialState.low_stock_threshold_alert &&
            formResult.low_stock_threshold_alert &&
            formData.append('low_stock_threshold_alert', formResult.low_stock_threshold_alert.toString());
        formResult.slots &&
            formResult.slots.forEach((slot) => {
                formData.append(`slots`, slot.toString());
            });
        formResult.weight && formData.append('weight', formResult.weight.toString());
        formResult.weight_tolerance && formData.append('weight_tolerance', formResult.weight_tolerance.toString());
        formResult.height && formData.append('height', formResult.height.toString());
        formResult.width && formData.append('width', formResult.width.toString());
        formResult.diameter && formData.append('diameter', formResult.diameter.toString());
        formResult.length && formData.append('length', formResult.length.toString());
        formResult.max_stock && formData.append('max_stock', formResult.max_stock.toString());
        formResult.stock_count && formData.append('stock_count', formResult.stock_count.toString());
        formResult.description && formData.append('description', formResult.description.toString());
        changeFormBody(formData);
    }, [formResult]);

    const [nameValid, changeNameValid] = useState<boolean>(false);
    const [categoryValid, changeCategoryValid] = useState<boolean>(true);

    const valid = nameValid && categoryValid && shopValid && productDataValid;

    useEffect(() => {
        if (submitted && formResult.account && formBody) {
            dispatch(updateProduct({ body: formBody, id: props.product.id }));
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted && valid && productState.all) {
            const existingProduct =
                productState.all.find((p) => p !== undefined && p.id === props.product.id) ||
                (productState.searchProductsList && getAllValuesFromStore(productState.searchProductsList).find((p) => p.name === formBody?.get('name')));
            if (productState.status === SliceStatus.IDLE && existingProduct) {
                toast.success(...ToastUtil.generateToastConfig(formResult.name, UpdateProductSuccess, ClassType.SUCCESS));
                const difference = FindChangedValue(props.product, existingProduct, undefined, ['image']);
                Logger.log(`${selectedUser?.email} updated product: ${props.product.name}`, { user: selectedUser?.id, product: props.product.id }, difference);
                changeSubmitted(false);
                props.handleClose();
            } else if (productState.status === SliceStatus.ERROR) {
                changeSubmitted(false);
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, UpdateProductError, ClassType.DANGER));
                Logger.error(
                    `${selectedUser?.email} failed to update product: ${props.product.name}`,
                    { user: selectedUser?.id, product: props.product.id },
                    productState.error?.json
                );
            }
        }
    }, [productState.status]);

    return (
        <>
            <form
                method='post'
                onSubmit={(e) => e.preventDefault()}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <label>
                                {
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.OrderAllowed'
                                        description={'Label for ordering allowed in form'}
                                        defaultMessage='Order'
                                    />
                                }
                            </label>
                            <CheckBox
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.CheckBox.OrderingAllowed'
                                        description={'Checkbox for allowing or disallowing this product to be ordered'}
                                        defaultMessage='Ordering allowed?'
                                    />
                                }
                                onChange={(value) => changeFormResult({ ...formResult, ordering_allowed: value })}
                                checked={formResult.ordering_allowed}
                                submitted={submitted}
                                id='UpdateProductForm-OrderingAllowed-Checkbox'
                            />
                        </div>
                        <div className='col-6'>
                            {shops && (
                                <MultiCheckbox
                                    title={
                                        <FormattedMessage
                                            id='UpdateProductForm.Label.Shops'
                                            description={'Label for shops in form'}
                                            defaultMessage='Shops'
                                        />
                                    }
                                    valid={shopValid}
                                    required
                                    errorMessage={
                                        <FormattedMessage
                                            id='UpdateProductForm.Label.ShopCheckboxes'
                                            description='Error message for multi checkboxes on the create product page'
                                            defaultMessage='Please select at least 1 shop'
                                        />
                                    }
                                    checkboxes={shops.map((s) => ({
                                        label: s.name,
                                        key: s.id,
                                        id: s.name,
                                        submitted: submitted,
                                        checked: formResult.product_shops.includes(s.id),
                                        onChange: (isChecked) => {
                                            changeFormResult({
                                                ...formResult,
                                                product_shops: isChecked
                                                    ? [...formResult.product_shops, s.id]
                                                    : [...formResult.product_shops.filter((p) => p !== s.id)]
                                            });
                                        }
                                    }))}
                                />
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.Cateogry'
                                        description={'Label for category in form'}
                                        defaultMessage='Category'
                                    />
                                }
                                value={formResult.category}
                                onChange={(value: any) => changeFormResult({ ...formResult, category: value })}
                                options={category ? category.results.map((c) => ({ value: c.id, label: c.name })) : []}
                                submitted={submitted}
                                isValidCallback={(value) => changeCategoryValid(value)}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.ErrorMessage.Category'
                                        description={'Error message for category in form'}
                                        defaultMessage='Please select a category'
                                    />
                                }
                                id='UpdateProductForm-CategorySelect'
                            />
                        </div>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.LowStockAlert'
                                        description={'Label for low stock threshold alert in form'}
                                        defaultMessage='Low stock threshold alert'
                                    />
                                }
                                value={formResult.low_stock_threshold_alert}
                                onChange={(value) => changeFormResult({ ...formResult, low_stock_threshold_alert: value })}
                                submitted={submitted}
                                id='UpdateProductForm-LowStockInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.Name'
                                        description={'Label for name in form'}
                                        defaultMessage='Product name'
                                    />
                                }
                                errorMessage={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.ErrorMessage.Name'
                                        description={'Error message for name in form'}
                                        defaultMessage='Please fill in a product name'
                                    />
                                }
                                value={formResult.name}
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                                submitted={submitted}
                                isValidCallback={(value) => changeNameValid(value)}
                                required
                                id='UpdateProductForm-ProductNameInput'
                            />
                        </div>
                        <div className='col-6'>
                            <ImageInput
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.Image'
                                        description={'Label for image in form'}
                                        defaultMessage='Upload image'
                                    />
                                }
                                onChange={(value) => changeFormResult({ ...formResult, image: value })}
                                submitted={submitted}
                                id='UpdateProductForm-ImageInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.EAN.Code'
                                        description={'Title for the EAN code label in form'}
                                        defaultMessage='EAN'
                                    />
                                }
                                submitted={submitted}
                                id='UpdateProductForm-EanInput'
                            />
                        </div>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.SKU.Code'
                                        description={'Title for the SKU code label in form'}
                                        defaultMessage='SKU'
                                    />
                                }
                                submitted={submitted}
                                id='UpdateProductForm-SkuInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextArea
                                label={
                                    <FormattedMessage
                                        id='UpdateProductForm.Label.ProductData'
                                        description={'Textarea for product data in form'}
                                        defaultMessage='Product data'
                                    />
                                }
                                value={formResult.product_data}
                                onChange={(value) => changeFormResult({ ...formResult, product_data: value })}
                                valid={productDataValid}
                                isValidCallback={() => {
                                    changeProductDataValid(validJSON(formResult.product_data));
                                }}
                                submitted={submitted}
                                id='UpdateProductForm-ProductDataInput'
                            />
                        </div>
                        <div className='col-6'>
                            <TextArea
                                label={
                                    <FormattedMessage
                                        id='CreatedProductForm.Label.Description'
                                        description='Textarea for product description in form'
                                        defaultMessage='Description'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.description}
                                onChange={(value) => changeFormResult({ ...formResult, description: value })}
                                id='UpdateProductForm-DescriptionInput'
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <button
                            type='button'
                            className='btn bg-gradient-primary'
                            id='UpdateProductForm-SpecificationsButton'
                            onClick={() => toggleShowSpecs(!showSpecs)}>
                            {
                                <FormattedMessage
                                    id='CreateProductForm.options.Specifications'
                                    description={'Button for showing product specifications'}
                                    defaultMessage={'Specifications'}
                                />
                            }
                        </button>
                    </div>
                    {showSpecs && (
                        <>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Weight'
                                                description='Label for weight in form'
                                                defaultMessage='Weight'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.weight}
                                        onChange={(value) => changeFormResult({ ...formResult, weight: value })}
                                        id='UpdateProductForm-WeightInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.WeightTolerance'
                                                description='Label for weight tolerance in form'
                                                defaultMessage='Weight Tolerance'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.weight_tolerance}
                                        onChange={(value) => changeFormResult({ ...formResult, weight_tolerance: value })}
                                        id='UpdateProductForm-WeightToleranceInput'
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Height'
                                                description='Label for height in form'
                                                defaultMessage='Height'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.height}
                                        onChange={(value) => changeFormResult({ ...formResult, height: value })}
                                        id='UpdateProductForm-HeightInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Width'
                                                description='Label for width in form'
                                                defaultMessage='Width'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.width}
                                        onChange={(value) => changeFormResult({ ...formResult, width: value })}
                                        id='UpdateProductForm-WidthInput'
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Diameter'
                                                description='Label for diameter in form'
                                                defaultMessage='Diameter'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.diameter}
                                        onChange={(value) => changeFormResult({ ...formResult, diameter: value })}
                                        id='UpdateProductForm-DiameterInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Length'
                                                description='Label for length in form'
                                                defaultMessage='Length'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.length}
                                        onChange={(value) => changeFormResult({ ...formResult, length: value })}
                                        id='UpdateProductForm-LengthInput'
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.MaxStock'
                                                description='Label for max stock in form'
                                                defaultMessage='Max Stock'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.max_stock}
                                        onChange={(value) => changeFormResult({ ...formResult, max_stock: value })}
                                        id='UpdateProductForm-MaxStockInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Stock'
                                                description='Label for stock in form'
                                                defaultMessage='Stock'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.stock_count}
                                        onChange={(value) => changeFormResult({ ...formResult, stock_count: value })}
                                        id='UpdateProductForm-StockInput'
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <CreateModalFooter
                    disabled={!valid || JSON.stringify(formResult) === JSON.stringify(initialState)}
                    onSubmit={() => changeSubmitted(true)}
                    handleClose={() => props.handleClose()}
                />
            </form>
        </>
    );
};

export default UpdateProductForm;

const UpdateProductSuccess = (
    <FormattedMessage
        id='UpdateProductForm.UpdateProduct.Success'
        description={'Message when successfully updating a product'}
        defaultMessage='The product was successfully updated'
    />
);

const UpdateProductError = (
    <FormattedMessage
        id='UpdateProductForm.UpdateProduct.Error'
        description={'Message when getting an error while updating a product'}
        defaultMessage='There was an error while trying to update this product'
    />
);
