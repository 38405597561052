import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

export interface CreateModalFooterProps {
    onSubmit: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleClose: () => void;
    disabled?: boolean;
}

const CreateModalFooter = (props: CreateModalFooterProps) => {
    return (
        <>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <button
                    type='button'
                    onClick={props.handleClose}
                    id='CreateModal-CancelButton'
                    className='btn bg-gradient-light'>
                    <FormattedMessage
                        id='CreateModal.Cancel.Button'
                        description={'Text shown in the cancel button in an create modal'}
                        defaultMessage='Cancel'
                    />
                </button>
                <div>
                    {props.onSubmit ? (
                        <button
                            type='submit'
                            disabled={props.disabled}
                            className='btn bg-gradient-primary me-1'
                            id='CreateModal-SubmitButton'
                            onClick={(e) => props.onSubmit(e)}>
                            <div className='d-flex flex-row align-items-center'>
                                <FaPlus />
                                <span className='ms-2'>
                                    <FormattedMessage
                                        id='CreateModal.Submit.Button'
                                        description={'Text shown in the submit button in an create modal'}
                                        defaultMessage='Submit'
                                    />
                                </span>
                            </div>
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateModalFooter;
