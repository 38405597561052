import { Logger } from '@frontend/Logger';
import { fetchTransactionById, removeTransactionById } from '@frontend/transaction';

import { PubSubEvent } from '../usePubSubEventListener';

export default class TransactionEventHandler {
    static handle = (dispatch: ({ ...args }) => void, event: PubSubEvent): void => {
        if (event.content.old == null) {
            Logger.log(`Transaction id:{${event.content.new.id}} created event received.`);
            dispatch(fetchTransactionById(event.content.new.id));
            return;
        }
        if (event.content.new == null) {
            Logger.log(`Transaction id:{${event.content.old.id}} deleted event received.`);
            dispatch(removeTransactionById(event.content.old.id));
            return;
        }
        Logger.log(`Transaction id:{${event.content.new.id}} updated event received.`);
        dispatch(fetchTransactionById(event.content.new.id));
    };
}
