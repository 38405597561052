import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

export default function CloseCurrentPage() {
    const location = useLocation();
    const message = location.state?.message;

    return (
        <div className='d-flex flex-column text-center m-5'>
            <h1 className='mb-5 text-light'>
                <FormattedMessage
                    id='CloseCurrentPage.title'
                    description='The title on the Close Current page.'
                    defaultMessage='Your operation was successful.'
                />
            </h1>
            {message && <h4 className='mt-5 pt-5 text-dark'>{message}</h4>}

            <h4 className='mt-5 pt-5 text-dark'>
                <FormattedMessage
                    id='CloseCurrentPage.description'
                    description='The description on the Close Current page.'
                    defaultMessage='You may now close this page.'
                />
            </h4>
        </div>
    );
}
