import { BiCurrentLocation } from 'react-icons/bi';
import { IoIosAddCircle } from 'react-icons/io';
import { MdRemoveCircle } from 'react-icons/md';

import { TransactionStatus } from '../../common/TransactionStatus';
import TimeLineItemTextField from '../transaction-detail/transaction-event-timeline/TimeLineItemTextField';
import CompletedView from './CompletedView';
import { ElementProps } from './TransactionStatusUpdate';

export default function RemoveParcelWaitingView(props: ElementProps) {
    return (
        <>
            <div className='timeline-block mb-3'>
                <span className='timeline-step'>
                    {props.isCurrent === false ? (
                        <MdRemoveCircle className='text-warning display-1' />
                    ) : (
                        <BiCurrentLocation className='text-success display-1' />
                    )}
                </span>
                <TimeLineItemTextField title='Remove parcel waiting' />
            </div>
            {props.isCurrent !== false && (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoIosAddCircle className='text-light display-1' />
                    </span>
                    <div className='timeline-content'>
                        <button
                            id='RemoveParcel-ChangeStatusButton'
                            className='btn btn-primary me-2'
                            onClick={() => props.onChangeStatusCallback(TransactionStatus.DONE)}>
                            Remove parcel
                        </button>
                    </div>
                </div>
            )}

            {props.currentlySelectedStatus === TransactionStatus.DONE && (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdRemoveCircle className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField title={'Remove parcel done'} />
                </div>
            )}

            {props.currentlySelectedStatus === TransactionStatus.DONE && <CompletedView />}
        </>
    );
}
