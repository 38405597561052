import { APIClient, ApiQueryParams, ApiViewSet, DetailOptions } from "@frontend/api-utils";
import { Shop } from "../shop";
import { ShopsQueryParams } from "./models";

const shopViewSet: ApiViewSet = {
    baseName: 'shops'
};

export class ShopAPIClient extends APIClient {
    public static fetchShopsApi(queryParams?: ApiQueryParams<ShopsQueryParams> | null): Promise<Shop[]> {
        return this.apiList<Shop, ShopsQueryParams>(shopViewSet, queryParams);
    }
    
    public static fetchShopApi(options: DetailOptions): Promise<Shop> {
        return this.apiDetail<Shop>(shopViewSet, options);
    }
}

