import { ContactGroup, fetchContactGroups, searchContactGroups } from '@frontend/contact-group';
import React, { useEffect, useRef, useState } from 'react';

import { CommonMessage } from '../../../../common/CommonFormattedMessages/CommonMessage';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import AsyncSelectInput from '../AsyncSelectInput';
import { SelectInputProps } from '../Select';

interface ContactSelectProps extends SelectInputProps {
    onChange?: (value: any) => void;
    isValid?: (value: boolean) => void;
    terminal?: boolean;
}

export interface GroupedContactsObj {
    label: string | React.ReactNode;
    options: any[];
}

const ContactGroupSelect = (props: ContactSelectProps) => {
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const contactGroups = useAppSelector((state) => state.contactGroups);
    const [groupedContacts, changeGroupedContacts] = useState<GroupedContactsObj[]>([]);
    const [searchValue, changeSearchValue] = useState('');
    const terminalState = useAppSelector((state) => state.terminals);

    useEffect(() => {
        if (contactGroups.contactGroupsList === null) {
            dispatch(fetchContactGroups({ account: accountId.toString(), page: '1', page_size: '100' }));
        }
    }, []);

    useEffect(() => {
        const allContacts: GroupedContactsObj[] = [];
        if (props.terminal) {
            if (terminalState.terminalsList && terminalState.terminalsList.length > 0) {
                if (contactGroups.contactGroupsList) {
                    const contactgroups: Set<ContactGroup> = new Set();
                    //Stock Fill Report only accepts delivery contact groups
                    terminalState.terminalsList.forEach((t) => {
                        if (t.delivery_contacts) {
                            const found = contactGroups.contactGroupsList?.results.find((cg) => cg.id === t.delivery_contacts);
                            if (found) {
                                contactgroups.add(found);
                            }
                        }
                    });
                    allContacts.push({
                        label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP,
                        options: [...contactgroups].map((cg) => ({ value: cg.id, label: cg.name, type: 'contactgroup' }))
                    });
                }
            }
            changeGroupedContacts(allContacts);
        } else {
            if (contactGroups.contactGroupsList && searchValue.length === 0) {
                if (contactGroups.contactGroupsList)
                    allContacts.push({
                        label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP,
                        options: contactGroups.contactGroupsList.results.map((cg) => ({ value: cg.id, label: cg.name, type: 'contactgroup' }))
                    });
                changeGroupedContacts(allContacts);
            } else if (contactGroups.searchContactGroupsList && searchValue.length > 0 && contactGroups.searchContactGroupsList[searchValue]) {
                const allContacts: GroupedContactsObj[] = [];
                if (contactGroups.searchContactGroupsList[searchValue])
                    allContacts.push({
                        label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP,
                        options: contactGroups.searchContactGroupsList[searchValue].map((cg) => ({ value: cg.id, label: cg.name, type: 'contactgroup' }))
                    });
                changeGroupedContacts(allContacts);
            }
        }
    }, [searchValue, contactGroups.contactGroupsList]);

    const timer = useRef<NodeJS.Timeout | null>(null);

    const loadOptions = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        const result: any[] = [];
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            dispatch(searchContactGroups({ search: inputValue, account: accountId.toString(), page: '1', page_size: '1000' })).then((res: any) => {
                if (res.payload) {
                    const foundContactGroups = res.payload.results.map((c: ContactGroup) => ({ value: c.id, label: c.name, type: 'contactgroup' }));
                    result.push({ label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP, options: foundContactGroups });
                }
            });
            callback(result);
        }, 500);
    };

    const url = contactGroups.contactGroupsList?.next;

    return (
        <AsyncSelectInput
            required={props.required ? props.required : false}
            label={props.label}
            submitted={props.submitted}
            isClearable={props.isClearable}
            cacheOptions
            onChange={(value) => {
                props.onChange && props.onChange(value);
            }}
            value={props.value}
            loadOptions={loadOptions}
            errorMessage={props.errorMessage}
            defaultOptions={groupedContacts}
            onMenuScrollToBottom={() => {
                if (url) {
                    const contactsURL = new URLSearchParams(url);
                    const newPage = contactsURL.get('page');
                    const newPageSize = contactsURL.get('page_size');
                    dispatch(fetchContactGroups({ account: accountId.toString(), page: newPage, page_size: newPageSize }));
                    return true;
                } else return false;
            }}
            isValidCallback={(valid) => props.isValid && props.isValid(valid)}
        />
    );
};

export default ContactGroupSelect;
