import { TransactionStatus } from '../../common/TransactionStatus';

interface TransactionStatusBadgeProps {
    status?: string;
}

export default function TransactionStatusBadge(props: TransactionStatusBadgeProps) {
    if (props.status === undefined || props.status === '') return <></>;
    return (
        <span className={`badge bg-gradient-${TransactionStatus.getByString(props.status)?.classType}`}>
            <div className='d-flex flex-row align-items-center'>{TransactionStatus.getByString(props.status)?.displayedValue}</div>
        </span>
    );
}
