import { useEffect, useState } from 'react';

export default function useDetectDeviceViewport(): DeviceViewport {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    if (width <= 300) {
        return DeviceViewport.SMALL;
    } else if (width <= 768) {
        return DeviceViewport.MOBILE;
    }
    return DeviceViewport.PC;
}

export enum DeviceViewport {
    SMALL,
    MOBILE,
    PC
}
