import { Locale, NotificationChannel } from '@frontend/common';
import { TransactionAuthenticationMethods } from '@frontend/transaction';

import { UserInfo } from '../user';

export interface UserData {
    count: number;
    next: string | null;
    previous: string | null;
    results: UserInfo[];
}

export enum AuthenticationMethod {
    LOGIN_FORM = 'login_form',
    SSO = 'sso'
}

export interface AccountMembership {
    account: Account;
    role: string;
}

export interface Account {
    contacts: AccountContactModel[];
    id: number;
    name: string;
    transation_scheduling_config: AccountSchedulingConfig;
    default_receiver_contact?: number | null;
    default_receiver_contact_url?: string | null;
    untracked_transaction_receiver?: number | null;
    untracked_transaction_receiver_url?: string | null;
    computed_authentication_methods: ComputedAuthenticationMethod[];
    delivery_contacts_url?: string | null;
    delivery_contacts?: string | null;
    delivery_contact_other_url?: string | null;
    delivery_contact_other?: string | null;
    receiver_contact_group?: string | null;
    receiver_contact_group_url?: string | null;
    settings_allow_itsme_authentication?: boolean;
    settings_account_notification_channels?: NotificationChannel[];
    contact_roles: string[];
}

interface ComputedAuthenticationMethod {
    label: string;
    value: TransactionAuthenticationMethods;
    default_dropoff_action: boolean;
    default_pickup_action: boolean;
    default_remove_parcel_action: boolean;
}

export interface AccountSchedulingConfig {
    allow_weekends?: boolean;
    time_slot_length_in_min?: number;
    initial_time_slot_offset?: string; //HH:mm format
}

export interface AccountContactModel {
    pk: number;
    id: number;
    url: string;
    first_name: string;
    last_name: string;
    title: string;
    birthday: Date;
    mobile: string;
    notification_channels: NotificationChannel[];
    language: Locale;
    account: number;
    user: number;
    contact_groups: number[];
    contact_group_urls: string[];
    additional_date: object;
    qr_code_access_keys: [{ [key: number]: string }];
    email: string;
}

export enum UserQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGE_SIZE = 'page_size',
    SEARCH = 'search',
    ORDERING = 'ordering'
}

export interface CreateUserModel {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: Locale;
    email: string;
    mobile: string;
    phone: string;
    password: string | null;
}

export interface UpdateUserModel {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: Locale;
    email: string;
    mobile: string;
    phone: string;
    password: string | null; //TODO im not sure if this should even be
}

export interface UsersOptions {
    id: number;
    user: UpdateUserModel;
}

export interface RegisterUserModel {
    key: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    mobile: string;
}
