import { Product } from '@frontend/product';
import { BsBoxes } from 'react-icons/bs';

import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useProductBadge from './product-badge.controller';

export const ID = 'product-badge';

export interface Props {
    productId: string;
    product?: Product;
    name?: string;
}

const ProductBadge = (props: Props): JSX.Element => {
    const viewProps = useProductBadge(props);

    return (
        <BadgeTemplate
            type={DetailModalType.PRODUCT}
            objectId={props.productId}>
            <BsBoxes className='me-2' />
            {viewProps.name}
        </BadgeTemplate>
    );
};

export default ProductBadge;
