import { ApiError, ApiQueryParams, ApiViewSet, PaginationResponse, apiDetail, apiList, apiPagination, postApi, putApi } from '@frontend/api-utils';
import { Product } from '@frontend/product';
import { Transaction } from '@frontend/transaction';

import { SlotTypeName } from '../common/SlotType';

export enum SlotType {
    STANDARD = 'standard',
    STOCK = 'stock',
    RESERVATION = 'reservation'
}

export interface Specification {
    id: number;
    name: string;
    width: number | null;
    height: number | null;
    depth: number | null;
}

export interface SlotSettings {
    is_active: boolean;
    notifications_active: boolean;
    same_vending_products: boolean;
    transactions_limit_for_pudo: number;
    transactions_limit_for_return: number;
    transactions_limit_for_lending: number;
    transactions_limit_for_vending: number;
    pickup_in_progress_timeout_in_sec: number | null;
    dropoff_in_progress_timeout_in_sec: number | null;
    remove_parcel_in_progress_timeout_in_sec: number | null;
}

export interface Slot {
    id: string;
    real_id: number;
    url: string;
    spot: string;
    specification: Specification | null;
    slot_nr: string;
    type: SlotType;

    // slot settings
    settings: SlotSettings;

    // logs
    last_logs: lastLogsSlot;

    //settings
    additional_data: unknown;
    assigned_products: number[];
    settings_is_active: boolean;
    settings_allow_notifications: boolean;
    settings_transactions_limit_for_pudo: number;
    settings_allow_multiple_pudo_pickup_transactions: boolean;
    settings_transactions_limit_for_vending: number;
    settings_allow_same_vending_products: boolean;
    settings_transactions_limit_for_lending: number;
    settings_allow_same_lending_products: boolean;
    settings_transactions_limit_for_return: number;
    settings_allow_same_return_products: boolean;
    settings_dropoff_in_progress_timeout_in_sec: number | null;
    settings_pickup_in_progress_timeout_in_sec: number | null;
    settings_remove_parcel_in_progress_timeout_in_sec: number | null;
}

export type SlotResponse = PaginationResponse<Slot>;

export interface lastLogsSlot {
    sensor: lastSlotLogData;
    door: lastSlotLogData;
    functional: lastSlotLogData;
}

export interface lastSlotLogData {
    info: object;
    state: string;
    warning: boolean;
    created_at: Date;
    slot_log_id: number;
    transactions: {
        data: [
            {
                id: string;
                type: string;
                state: string;
                products: Product[];
                tracking_number: string;
            }
        ];
    };
}

export interface changeSlotSettings {
    account: number;
    spot: string;
    type: SlotTypeName | undefined;
    slot_nr: string | undefined;
    settings_is_active: boolean;
    settings_allow_notifications: boolean;
    settings_transactions_limit_for_pudo: number;
    settings_allow_multiple_pudo_pickup_transactions: boolean;
    settings_transactions_limit_for_vending: number;
    settings_allow_same_vending_products: boolean;
    settings_transactions_limit_for_lending: number;
    settings_allow_same_lending_products: boolean;
    settings_transactions_limit_for_return: number;
    settings_allow_same_return_products: boolean;
    settings_dropoff_in_progress_timeout_in_sec: number | null;
    settings_pickup_in_progress_timeout_in_sec: number | null;
    settings_remove_parcel_in_progress_timeout_in_sec: number | null;
}

export interface changeSlotSettingsID extends changeSlotSettings {
    id: string;
}

export interface SlotWithTransactions extends Slot {
    transactions: Transaction[];
}

export interface SlotsOptions {
    spot?: string;
    spots?: string[];
}

export interface PostSlotsOptions {
    id: string;
    body: changeSlotSettings;
}
export enum SlotsQueryParams {
    ACCOUNT = 'account',
    SPOT = 'spot',
    STATUS = 'status',
    TRANSACTION_TYPE = 'transaction_type',
    PAGE = 'page',
    PAGE_SIZE = 'page_size'
}

const slotViewSet: ApiViewSet = {
    baseName: 'slots'
};

export function fetchSlotsApi(queryParams?: ApiQueryParams<SlotsQueryParams> | null): Promise<SlotResponse> {
    return apiPagination<SlotResponse, SlotsQueryParams>(slotViewSet, queryParams);
}
export function fetchAvailableSlotsApi(queryParams?: ApiQueryParams<SlotsQueryParams> | null): Promise<Slot[]> {
    return apiList<Slot, SlotsQueryParams>(slotViewSet, queryParams);
}

export function fetchSlotByIdApi(id: string): Promise<Slot> {
    return apiDetail(slotViewSet, { id: id });
}

export async function postSlotsApi(options: PostSlotsOptions): Promise<Slot> {
    if (!options.id) {
        throw new Error('No slotsID provided');
    }
    const slotsUrl = `/slots/${options.id}/`;
    const response = await putApi(slotsUrl, options.body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error posting slot settings');
        }
        throw new ApiError('Error posting slot settings', json);
    }

    return await response.json();
}

export async function openSlot(id: string): Promise<void> {
    const response = await postApi(`/slots/${id}/open/`, {});
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error opening slot.');
        }
        throw new ApiError('Error opening slot.', json);
    }
}

export async function requestSlotLogs(id: string): Promise<void> {
    const response = await postApi(`/slots/${id}/request_slot_state/`, {});
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error requesting slot logs.');
        }
        throw new ApiError('Error requesting slot logs.', json);
    }
}
