import { Product, fetchProductById } from '@frontend/product';
import { useEffect, useState } from 'react';

import Spinner from '../../../components/loading/Spinner';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import ProductDetailModal from './ProductDetailModal';

interface Props extends ModalProps {
    id: string;
}

const ProductDetailModalWrapper = (props: Props) => {
    const dispatch = useAppDispatch();
    const products = useAppSelector((state) => state.products.all);
    const [product, changeProduct] = useState<Product | undefined>(undefined);
    const [requested, changeRequested] = useState<boolean>(false);

    useEffect(() => {
        if (!requested || product == undefined) {
            dispatch(fetchProductById(props.id));
            changeProduct(products.find((p) => p.id == +props.id));
            changeRequested(true);
        }
    }, [props.id, products]);

    if (product == undefined) {
        return (
            <Modal
                {...props}
                type={ModalType.PANE}>
                <div className='d-flex align-items-center justify-content-center h-75'>
                    <Spinner />
                </div>
            </Modal>
        );
    }
    return (
        <ProductDetailModal
            product={product}
            {...props}
        />
    );
};

export default ProductDetailModalWrapper;
