import { ChangeEvent, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getInputClass } from '../../utils/FormUtils';
import { FileInputError } from '../forms/imageInput/ImageInput';

interface ImportImageProps {
    onChange?: (value: File[] | File) => void;
    label: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    value?: string | File | null;
    errorMessage?: React.ReactNode;
    multiple?: boolean;
    extension: 'image' | 'csv';
}

export default function Import(props: ImportImageProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState('');
    const [error, changeError] = useState<FileInputError>(FileInputError.NONE);
    const [isValid, changeIsValid] = useState<boolean>(false);
    const [touched, changeTouched] = useState<boolean>(false);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && props.extension === 'image') {
            const selectedFiles = Array.from(e.target.files);

            const validImageFiles = selectedFiles.filter((file) => file.type.startsWith('image/') && /\.(png|jpg|jpeg)$/i.test(file.name));

            if (validImageFiles.length > 0) {
                const selectedFile = validImageFiles[0];
                props.onChange && props.onChange(validImageFiles);

                setValue(selectedFile.name);
                changeError(FileInputError.NONE);
                changeIsValid(true);
            } else {
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
                changeError(FileInputError.INVALID_IMAGE);
                changeIsValid(false);
            }
        } else if (e.target.files && e.target.files[0].name.endsWith('.csv') && props.extension === 'csv') {
            const selectedFile = e.target.files[0];

            if (selectedFile.name.endsWith('.csv')) {
                props.onChange && props.onChange(selectedFile);

                setValue(selectedFile.name);
                changeError(FileInputError.NONE);
                changeIsValid(true);
            } else {
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
                changeError(FileInputError.INVALID_CSV);
                changeIsValid(false);
            }
        } else {
            if (inputRef.current) {
                inputRef.current.value = '';
            }
            changeError(FileInputError.INVALID_CSV);
            changeIsValid(false);
        }
    };

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <input
                    ref={inputRef}
                    type='file'
                    multiple
                    className={getInputClass(touched, isValid, value)}
                    accept={props.extension === 'image' ? 'image/*' : '.csv'}
                    onChange={handleFileChange}
                    onFocus={() => {
                        changeTouched(true);
                    }}></input>
                {isValid === false && touched === true && (
                    <>
                        {props.errorMessage === undefined ? (
                            <>
                                {error === FileInputError.INVALID_IMAGE && invalidFileErrorImage}
                                {error === FileInputError.INVALID_CSV && invalidFileErrorCsv}
                            </>
                        ) : (
                            <span className='badge bg-gradient-danger mt-2'>
                                <small>{props.errorMessage}</small>
                            </span>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

const invalidFileErrorImage = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='imageInput.invalidFile.errorMessage'
                description='The error message for the image input.'
                defaultMessage='Please select a valid image.'
            />
        </small>
    </span>
);

const invalidFileErrorCsv = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='CSVImport.invalidFile.errorMessage'
                description='The error message for the csv input.'
                defaultMessage='Please select a valid csv file.'
            />
        </small>
    </span>
);
