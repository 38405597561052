import { ClassType } from '@frontend/common';
import { BiDoorOpen } from 'react-icons/bi';
import { FaInfoCircle } from 'react-icons/fa';

import { Slot } from '../../../api/Slots';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import ModalHeader from '../../../components/modals/ModalHeader';
import SlotDetail from '../slot-detail-component';
import useSlotDetailModal from './slot-detail-modal.controller';

export const ID = 'slot-detail-modal';

export interface SlotDetailModalProps extends ModalProps {
    slot: Slot;
}

const SlotDetailModal = (props: SlotDetailModalProps): JSX.Element => {
    const viewProps = useSlotDetailModal(props);

    return (
        <Modal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                title={props.slot.real_id}
                handleClose={props.handleClose}
                id='SlotDetailModal-close'
            />
            <div className='modal-body'>
                <SlotDetail slot={props.slot} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            type: ClassType.WARNING,
                            hide: !props.slot || !viewProps.isAdmin || !!props.slot.specification?.name.toLowerCase().includes('smart shelves'),
                            disabled: viewProps.openSlotLoading,
                            text: CommonMessage.SLOTS.OPEN_SLOT.BUTTON,
                            icon: BiDoorOpen,
                            onClick: () => viewProps.changeStartOpenSlot(true)
                        },
                        {
                            type: ClassType.SECONDARY,
                            hide: !props.slot || !viewProps.isAdmin,
                            disabled: viewProps.openSlotLogsLoading,
                            text: 'Request logs',
                            icon: FaInfoCircle,
                            onClick: viewProps.requestSlotLogs
                        }
                    ]}
                    direction='left'
                />
            </div>
            {viewProps.startOpenSlot && (
                <ConfirmationModal
                    show={viewProps.startOpenSlot}
                    severity={ClassType.WARNING}
                    message={CommonMessage.SLOTS.OPEN_SLOT.MESSAGE}
                    onConfirm={viewProps.openSlot}
                    handleClose={() => viewProps.changeStartOpenSlot(false)}
                />
            )}
        </Modal>
    );
};

export default SlotDetailModal;
