import { AccountMembership } from '@frontend/user';
import { Row } from 'react-table';

export interface AccountsListElementProps {
    selectEnabled?: boolean;
    row: Row<AccountMembership>;
    onClick?: (element: AccountMembership) => void;
}

const AccountsListElement = (props: AccountsListElementProps) => {
    return (
        <tr
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-content-center'>
                <span>{props.row.original.account.id}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-content-center'>
                <span>{props.row.original.account.name}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-content-center'>
                <span>{props.row.original.role ? props.row.original.role : 'member'}</span>
            </td>
        </tr>
    );
};

export default AccountsListElement;
