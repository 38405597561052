import { Props } from './contact-badge.component';

interface ViewProps {
    firstName: string;
    lastName: string;
}

const useContactBadge = (props: Props): ViewProps => {
    const firstName = props.contact && props.contact.first_name ? props.contact.first_name : props.name ? props.name : '';
    const lastName = props.contact && props.contact.last_name ? props.contact.last_name : props.name ? props.name : '';

    return {
        firstName,
        lastName
    };
};

export default useContactBadge;
