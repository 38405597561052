import { PaginationResponse } from '@frontend/api-utils';
import { SpotType } from '@frontend/common';

import { TransactionStatusName, TransactionStatusValue } from '../common/transaction-status';
import { TransactionStatusChangeEventValue } from '../common/transaction-status-change-event';
import { Transaction } from '../transaction';

export interface TransactionContact {
    id: string;
    first_name?: string;
    last_name?: string;
    mobile: string;
    email: string;
    language: string;
}

export interface TransactionSpot {
    id: number;
    url: string;
    account: number;
    type: SpotType;
    name: string;
    address_line_1: string;
    zip_code: string;
    city: string;
}

export interface TransactionSlot {
    id: number;
    real_id: number;
    slot_nr: string;
    slot_number: string;
}

export interface CreateTransactionModel {
    account_id: number;
    sender_id?: number;
    sender_group_id?: number;
    receiver_id?: number;
    receiver_group_id?: number;
    tt_number: string;
    spot_id?: number;
    slot_id?: number;
    type?: TransactionType;
    shipping_notes?: string;
    status?: TransactionStatusValue;

    //Products
    product_ids?: number[];
    product_instance_ids?: Array<number | string>;

    //Scheduling
    notification_time_for_scheduled_transaction?: Date;
    expected_dropoff_time?: Date;
    expected_pickup_time?: Date;
    close_transaction_at_expected_pickup_time?: boolean;

    //Notifications
    notifications_config?: unknown;

    //Advanced
    customer_ref?: string;
    callback_url?: string;
    action_authentication_methods?: Record<string, unknown>;
}

export interface TransactionContactGroup {
    id: number | string;
    name: string;
}

export enum TransactionAuthenticationMethods {
    PIN = 'pin_code',
    QR = 'qr_code',
    MICROSOFT = 'microsoft',
    ITSME = 'itsme'
}

export enum TransactionType {
    PUDO = 'pudo',
    LENDING = 'lending',
    VENDING = 'vending',
    RETURN = 'return'
}

export interface TransactionSlotReservation {
    item_count: number;
    created_date: string;
    end_date: string;
    notification_time_for_scheduled_transaction: string;
    expected_dropoff_time: string;
    expected_pickup_time: string;
    close_transaction_at_expected_pickup_time: boolean;
    slot: number;
}

export type TransactionResponse = PaginationResponse<Transaction>;

export interface BulkTransactionStatusUpdateModel {
    account_id: number;
    initial_state: TransactionStatusName;
    event: TransactionStatusChangeEventValue;
    notifications: any;
    transaction_ids: number[];
}

export enum TransactionsQueryParams {
    ACCOUNT = 'account',
    SLOT = 'slot',
    SPOT = 'spot',
    SENDER = 'sender',
    RECEIVER = 'receiver',
    SENDER_GROUP = 'sender_group',
    RECEIVER_GROUP = 'receiver_group',
    STATUS = 'status',
    CREATED_DATE_AFTER = 'created_date_after',
    CREATED_DATE_BEFORE = 'created_date_before',
    PAGE = 'page',
    PAGE_SIZE = 'page_size',
    TRANSACTION_TYPE = 'transaction_type',
    ORDER_BY = 'order_by',
    TRACKING_NUMBER = 'tracking_number',
    TRANSACTION_STATUS = 'transaction_status',
    ID = 'id',
    BULK_TRANSACTION = 'bulk_transaction',
    SEARCH = 'search',
    ORDERING = 'ordering'
}

export interface MoveTransactionOptions {
    transactionId: string;
    body: {
        new_spot_id: string;
        new_slot_id?: string;
    };
}
export interface CancelTransactionQueryParams {
    id: string;
}

export interface UpdateBulkTransactionReceiverOptions {
    account_id: number;
    receiver_id: number;
    transaction_ids: number[];
}
