import React, { useEffect, useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';

import { getInputClass } from '../../../utils/FormUtils';

interface EmailInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    submitted: boolean;
    id?: string;
    noAutoComplete?: boolean;
}

const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

export default function EmailInput(props: EmailInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === '' || props.value === undefined || !emailRegex.test(props.value))) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid, props.required]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label ? props.label : 'Email'}
                </label>
                <div className='input-group'>
                    <span className='input-group-text'>
                        <FaEnvelope />
                    </span>
                    <input
                        className={getInputClass(touched, valid, props.value)}
                        data-testid='emailInput'
                        autoComplete={props.noAutoComplete ? 'off' : 'email'}
                        name='no-complete-email'
                        id={'field1'}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        type='text'
                        placeholder='you@example.com'
                        onFocus={() => {
                            changeTouched(true);
                            props.onFocusChange && props.onFocusChange();
                        }}
                        required={props.required}
                        onChange={(event) => {
                            if (props.onChange) props.onChange(event.target.value);
                            isValid();
                        }}
                    />
                </div>

                {valid === false && touched === true ? (
                    <div className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ? props.errorMessage : 'Please fill in a valid email address.'} </small>
                        <br />
                    </div>
                ) : (
                    <></>
                )}

                {props.helpText && (
                    <div>
                        <small>{props.helpText}</small>
                    </div>
                )}
            </div>
        </>
    );
}
