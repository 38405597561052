import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from "@frontend/api-utils";
import { Attribute } from "../attribute";
import { AttributesQueryParams, CreateAttributeModel } from "./models";
const attributeViewSet: ApiViewSet = {
    baseName: 'attributes'
};


export class AttributeAPIClient extends APIClient {
    public static async  fetchAttributesApi(queryParams?: ApiQueryParams<AttributesQueryParams> | null): Promise<Attribute[]> {
        return this.apiList<Attribute, AttributesQueryParams>(attributeViewSet, queryParams);
    }
    
    public static async fetchAttributeApi(options: DetailOptions): Promise<Attribute> {
        return this.apiDetail<Attribute>(attributeViewSet, options);
    }
    
    public static async createAttributeApi(attribute: CreateAttributeModel): Promise<Attribute> {
        const attributeAddUrl = `/attributes/`;
        const response = await this.post(attributeAddUrl, attribute);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error adding attribute');
            }
            throw new ApiError(JSON.stringify(json));
        }
        return await response.json();
    }
    
    public static async updateAttributeApi(attribute: CreateAttributeModel, id: number): Promise<Attribute> {
        let URL = `/attributes/`;
        if (id) {
            URL += id + '/';
        }
        const body = attribute;
    
        const response = await this.put(URL, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating attribute');
            }
            throw new ApiError('Error updating attribute', json);
        }
        return await response.json();
    }
    
    public static async deleteAttributeApi(attributeId: number): Promise<boolean> {
        const URL = `/attributes/${attributeId}/`;
        const response = await this.delete(URL);
        return await response.ok;
    }

    public static async searchAttributeApi(queryParams?: ApiQueryParams<AttributesQueryParams>): Promise<Attribute[]> {
        return await this.apiList<Attribute, AttributesQueryParams>(attributeViewSet, queryParams);
    }
}
