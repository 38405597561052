import { SliceStatus } from '@frontend/common';
import { UserInfo, UserState, fetchUsers, searchUsers } from '@frontend/user';
import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import UserListColumns from './UserListColumns';

interface ViewProps {
    isInit: boolean;
    users: UserState;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    changeInviteUserModal: React.Dispatch<React.SetStateAction<boolean>>;
    data: UserInfo[];
    changeTextFilter: React.Dispatch<React.SetStateAction<UserInfo[] | undefined>>;
    changeTextFilterValue: React.Dispatch<React.SetStateAction<string | undefined>>;
    columns: Column<UserInfo>[];
    next: string | null | undefined;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    onClickEvent: (user: any) => void;
    selectedUser: UserInfo | undefined;
    changeSelectedUser: React.Dispatch<React.SetStateAction<UserInfo | undefined>>;
    pageSize: number;
    inviteUserModal: boolean;
    changeCustomSorting: React.Dispatch<React.SetStateAction<{ column: string; orderBy: string | undefined } | undefined>>;
    sortKeys: string[];
}

const useUserList = (): ViewProps => {
    const isSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const authorised = useAuthorization(DefaultRole.ADMIN, true);
    const [isOpen, setIsOpen] = useState(false);
    const [inviteUserModal, changeInviteUserModal] = useState(false);
    const [selectedUser, changeSelectedUser] = useState<UserInfo>();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const users = useAppSelector((state) => state.user);
    const next = users.usersList?.next;
    const dispatch = useAppDispatch();
    const [textFilter, changeTextFilter] = useState<UserInfo[] | undefined>();
    const [textFilterValue, changeTextFilterValue] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(25);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [filteredUsers, changeFilteredUsers] = useState<UserInfo[] | undefined>();
    const isInit = users.userListStatus == SliceStatus.INIT;
    const [customSorting, changeCustomSorting] = useState<{ column: string; orderBy: string | undefined } | undefined>(undefined);
    const sortKeys = ['id', 'username', 'first_name', 'last_name', 'email', 'mobile', 'phone'];

    useEffect(() => {
        if (authorised) {
            if (!customSorting && !textFilterValue) {
                dispatch(fetchUsers({ account: accountId?.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
            } else if (textFilterValue && customSorting) {
                dispatch(
                    searchUsers({
                        account: accountId?.toString(),
                        page: pageIndex.toString(),
                        page_size: pageSize.toString(),
                        search: textFilterValue,
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            } else if (textFilterValue && !customSorting) {
                dispatch(searchUsers({ account: accountId?.toString(), page: pageIndex.toString(), page_size: pageSize.toString(), search: textFilterValue }));
            } else if (!textFilterValue && customSorting) {
                dispatch(
                    fetchUsers({
                        account: accountId?.toString(),
                        page: pageIndex.toString(),
                        page_size: pageSize.toString(),
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            }
        }
    }, [pageIndex, pageSize, customSorting]);

    const onClickEvent = (user: any) => {
        changeSelectedUser(user);
    };

    useEffect(() => {
        if (users.usersList) {
            let validUsers: UserInfo[] = [];
            if (!textFilterValue) {
                validUsers = users.usersList.results;
            } else if (users.searchUsersList && users.searchUsersList[textFilterValue]) {
                validUsers = users.searchUsersList[textFilterValue].results;
            }
            if (isSuperAdmin) {
                changeFilteredUsers(validUsers);
            } else {
                changeFilteredUsers(
                    validUsers.filter(
                        (user) =>
                            (users.user && user.email.includes(users.user.email)) || (!user.email.includes('telloport') && !user.email.includes('distrispot'))
                    )
                );
            }
        }
    }, [users]);

    useEffect(() => {
        if (textFilterValue !== '') {
            dispatch(searchUsers({ search: textFilterValue, account: accountId?.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
        } else if (users.usersList) {
            if (isSuperAdmin) {
                changeFilteredUsers(users.usersList.results);
            } else {
                changeFilteredUsers(
                    users.usersList.results.filter(
                        (user) =>
                            (users.user && user.email.includes(users.user.email)) || (!user.email.includes('telloport') && !user.email.includes('distrispot'))
                    )
                );
            }
        }
    }, [textFilterValue]);

    const data = useMemo(() => {
        if (filteredUsers) {
            return filteredUsers;
        } else return [];
    }, [filteredUsers]);

    const columns: Column<UserInfo>[] = useMemo(() => UserListColumns, []);

    return {
        isInit,
        users,
        isOpen,
        setIsOpen,
        data,
        changeInviteUserModal,
        changeTextFilter,
        changeTextFilterValue,
        changeSelectedUser,
        columns,
        next,
        onClickEvent,
        selectedUser,
        setPageIndex,
        setPageSize,
        pageSize,
        inviteUserModal,
        changeCustomSorting,
        sortKeys
    };
};

export default useUserList;
