import { Category } from '@frontend/category';
import { Product, fetchProductById } from '@frontend/product';
import { useEffect, useState } from 'react';

import BooleanBadge from '../../../components/badges/BooleanBadge';
import CategoryBadge from '../../../components/badges/category-badge/category-badge.component';
import SlotBadge from '../../../components/badges/slot-badge/slot-badge.component';
import Spinner from '../../../components/loading/Spinner';
import ModalSectionTitle from '../../../components/modals/ModalSectionTitle';
import ObjectField from '../../../components/modals/ObjectField';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

interface Props {
    product: Product;
    handleClose?: () => void;
}

export default function ProductDetail(props: Props) {
    const dispatch = useAppDispatch();
    const categoryState = useAppSelector((state) => state.categories);
    const attributeState = useAppSelector((state) => state.attributes);
    const [category, changeCategory] = useState<Category | undefined>();
    const [productAttributes, changeProductAttributes] = useState<ProductAttributeList[]>([]);
    const attributeList: ProductAttributeList[] = [];
    const [product, changeProduct] = useState<Product | undefined>(undefined);
    useEffect(() => {
        changeProduct(props.product);
        if (props.product && props.product.id !== product?.id) dispatch(fetchProductById(props.product.id.toString()));
    }, [props.product]);

    useEffect(() => {
        if (props.product?.category && category === undefined && categoryState.categoryList !== null) {
            changeCategory(categoryState.categoryList.find((c) => c.id === props.product?.category));
        }
    }, []);

    useEffect(() => {
        if (props.product && props.product.attributes && props.product.product_attributes) {
            props.product.product_attributes.forEach((p) => {
                if (attributeState.attributeList) {
                    const foundAttribute = attributeState.attributeList.find((s) => s.id === p.attribute);
                    if (foundAttribute) {
                        const addAttribute = { name: foundAttribute.name, value: p.value };
                        if (!attributeList.some((s) => s.name === addAttribute.name)) {
                            attributeList.push(addAttribute);
                        }
                    }
                }
            });
            changeProductAttributes(attributeList);
        }
    }, [props.product]);

    if (props.product === undefined) return <Spinner />;
    return (
        <>
            <div className='d-flex flex-column'>
                <ObjectField
                    fieldName={'ID'}
                    value={props.product.id}
                />
                <ObjectField
                    fieldName={'Ordering allowed'}
                    value={<BooleanBadge value={props.product.ordering_allowed} />}
                />
                <ObjectField
                    fieldName={'Category'}
                    value={
                        <CategoryBadge
                            categoryId={props.product.category_obj?.id.toString()}
                            name={props.product.category_obj?.name}
                        />
                    }
                />
                <ObjectField
                    fieldName='Shops'
                    value={props.product.shops_list.map((s) => s.name)}
                />
                <ObjectField
                    fieldName={'Product data'}
                    value={JSON.stringify(props.product.product_data)}
                />
                <ObjectField
                    fieldName='SLOTs'
                    value={props.product.slots_obj?.map((s) => (
                        <SlotBadge
                            key={s.real_id}
                            slotId={s.real_id.toString()}
                            name={s.slot_nr}
                        />
                    ))}
                />
                <ModalSectionTitle title='Stock' />
                <ObjectField
                    fieldName='Stock count'
                    value={props.product.computed_stock_count}
                />
                <ObjectField
                    fieldName='Max stock'
                    value={props.product.max_stock_volume}
                />
                <ObjectField
                    fieldName='Stock threshold'
                    value={props.product.low_stock_threshold_alert}
                />
                <ModalSectionTitle title='Specifications' />
                <ObjectField
                    fieldName='Height'
                    value={props.product.height}
                />
                <ObjectField
                    fieldName='Width'
                    value={props.product.width}
                />
                <ObjectField
                    fieldName='Length'
                    value={props.product.length}
                />
                <ObjectField
                    fieldName='Weight'
                    value={props.product.weight}
                />
                <ObjectField
                    fieldName='Weight tolerance'
                    value={props.product.weight_tolerance}
                />
                <ModalSectionTitle title={'Codes'} />
                <ObjectField
                    fieldName={'EAN'}
                    value={props.product.ean}
                />
                <ObjectField
                    fieldName={'SKU'}
                    value={props.product.sku}
                />
                <ObjectField
                    fieldName={'Product codes'}
                    value={props.product.product_codes.join(',')}
                />
                {productAttributes.length > 0 && (
                    <>
                        <ModalSectionTitle title={'Attributes'} />
                        {productAttributes.map((p) => (
                            <ObjectField
                                key={p.name}
                                fieldName={p.name}
                                value={p.value + ' cm'}
                            />
                        ))}
                    </>
                )}
                {props.product?.image && (
                    <>
                        <ModalSectionTitle title={'Image'} />
                        <img src={props.product?.image} />
                    </>
                )}
            </div>
        </>
    );
}

export interface UpdatedSlotsList {
    slots: number[] | undefined;
}

export interface DisplayProductSlots {
    spot: string;
    spotId: string;
    assignedSlots: string;
    realSlotId: number;
}

export interface ProductAttributeList {
    name: string;
    value: string;
}
