import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _, { partition } from 'lodash';

import { WidgetQueryParams, WidgetTemplate, fetchWidgetTemplatesApi } from '../api/widgets/WidgetTemplates';
import {
    CreateWidgetModel,
    UserWidgetQueryParams,
    Widget,
    addWidgetApi,
    deleteWidgetApi,
    fetchWidgetApi,
    fetchWidgetsApi,
    updateWidgetApi
} from '../api/widgets/Widgets';
import { ReactGridLayout } from '../dashboard/ResponsiveWidgetView';

interface DashboardState {
    widgetTemplates: WidgetTemplate[] | null;

    widgets: Widget[] | null;
    previeusWidgetsConfig: Widget[] | null;

    status: SliceStatus;
    lastUpdate: number;
    message: string;
    error: ReduxError | null;
}

const initialState: DashboardState = {
    widgetTemplates: null,
    widgets: null,
    previeusWidgetsConfig: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: '',
    error: null
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        startEditWidgetLayout(state) {
            state.previeusWidgetsConfig = _.clone(state.widgets);
        },
        moveWidget(state, action: PayloadAction<ReactGridLayout[]>) {
            const newLayout: Widget[] = [];
            action.payload.forEach((layout) => {
                const widget = state.widgets?.find((widget) => widget.id.toString() == layout.i);
                if (widget && (widget.layout.x != layout.x || widget.layout.y != layout.y)) {
                    newLayout.push({
                        ...widget,
                        layout: {
                            ...widget.layout,
                            x: layout.x,
                            y: layout.y
                        }
                    });
                } else if (widget) {
                    newLayout.push(widget);
                } else {
                    console.error('oops something went wrong because this code should not be reached');
                }
            });
            state.widgets = newLayout;
        },
        removeWidget(state, action: PayloadAction<Widget>) {
            if (state.widgets) state.widgets = state.widgets?.filter((w) => w.id !== action.payload.id);
        },
        createWidget(state, action: PayloadAction<Widget>) {
            state.widgets?.push(action.payload);
        },
        /**
         * @param state
         * @param action -> true to save changes, false to cancel and revert to previeus save.
         */
        endEditWidgetLayout(state, action: PayloadAction<boolean>) {
            if (!action.payload) state.widgets = state.previeusWidgetsConfig;
            state.previeusWidgetsConfig = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWidgetTemplates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWidgetTemplates.fulfilled, (state, action) => {
                state.widgetTemplates = action.payload;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchWidgets.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWidgets.fulfilled, (state, action) => {
                if (state.previeusWidgetsConfig === null) {
                    state.widgets = action.payload;
                }
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchWidgetTemplates = createAsyncThunk<WidgetTemplate[], ApiQueryParams<WidgetQueryParams>>(
    'fetchWidgetTemplates',
    async (queryParams: ApiQueryParams<WidgetQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchWidgetTemplatesApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWidgets = createAsyncThunk<Widget[], ApiQueryParams<UserWidgetQueryParams>>(
    'fetchWidgets',
    async (queryParams: ApiQueryParams<UserWidgetQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchWidgetsApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWidgetById = createAsyncThunk<Widget, number>('fetchWidgetById', async (userWidgetId, { rejectWithValue }) => {
    try {
        return await fetchWidgetApi({ id: userWidgetId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchWidgetByUrl = createAsyncThunk<Widget, string>('fetchWidgetByUrl', async (userWidgetUrl, { rejectWithValue }) => {
    try {
        return await fetchWidgetApi({ url: userWidgetUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addWidget = createAsyncThunk<Widget, CreateWidgetModel>('addWidget', async (userWidget, { rejectWithValue }) => {
    try {
        return await addWidgetApi(userWidget);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateWidget = createAsyncThunk<Widget, { id: number; userWidget: CreateWidgetModel }>('updateWidget', async (params, { rejectWithValue }) => {
    try {
        return await updateWidgetApi(params);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteWidget = createAsyncThunk<boolean, number>('deleteWidget', async (id, { rejectWithValue }) => {
    try {
        const res = await deleteWidgetApi(id);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete user widget with id: ${id}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const getWidgetChanges = (state: DashboardState) => {
    let toBeAdded: Widget[] = [];
    let toBeRemoved: Widget[] = [];
    const toBeUpdated: Widget[] = [];
    if (state.widgets && state.previeusWidgetsConfig) {
        toBeAdded = state.widgets.filter((widget) => state.previeusWidgetsConfig!.find((w) => w.id == widget.id) === undefined);
        const [removed, staying] = partition(state.previeusWidgetsConfig, (widget) => state.widgets!.find((w) => w.id == widget.id) === undefined);
        toBeRemoved = removed;
        staying.filter((widget) => {
            const toCompare = state.widgets!.find((w) => w.id == widget.id);
            if (toCompare) {
                if (toCompare.layout.x != widget.layout.x || toCompare.layout.y != widget.layout.y) {
                    toBeUpdated.push(toCompare);
                }
            }
        });
    } else {
        throw new Error('The widgets where not being edited. So no changes can be found.');
    }
    return { toBeAdded, toBeRemoved, toBeUpdated };
};
export const { startEditWidgetLayout, moveWidget, endEditWidgetLayout, createWidget, removeWidget } = dashboardSlice.actions;
export default dashboardSlice.reducer;
