import { FormattedMessage } from 'react-intl';

export enum SlotTypeName {
    STANDARD = 'standard',
    STOCK = 'stock',
    RESERVATION = 'reservation'
}

export default class SlotType {
    static readonly STANDARD = new SlotType(
        SlotTypeName.STANDARD,
        (
            <FormattedMessage
                id='SlotType.STANDARD'
                description='Label for standard slot type'
                defaultMessage='Standard'
            />
        )
    );
    static readonly RESERVATION = new SlotType(
        SlotTypeName.RESERVATION,
        (
            <FormattedMessage
                id='SlotType.RESERVATION'
                description='Label for reservation slot type'
                defaultMessage='Reservation'
            />
        )
    );
    static readonly STOCK = new SlotType(
        SlotTypeName.STOCK,
        (
            <FormattedMessage
                id='SlotType.STOCK'
                description='Label for stock slot type'
                defaultMessage='Stock'
            />
        )
    );

    static readonly ALL = [SlotType.STANDARD, SlotType.STOCK, SlotType.RESERVATION];
    private constructor(public readonly value: SlotTypeName, public readonly displayedValue: React.ReactNode) {}

    static getByName(value: SlotTypeName): SlotType | undefined {
        return this.ALL.find((s) => s.value === value);
    }

    static getByString(value: string): SlotType | undefined {
        return this.ALL.find((s) => s.value.toString() === value);
    }
}
