import { IndexedDbConnection, IndexedDbStores } from '@frontend/common';

import { Log } from './models/log';

export default class LoggingDatabase {
    static saveLog = async (log: Log): Promise<boolean> => {
        const store = await IndexedDbConnection.getStoreConnection(IndexedDbStores.LOGS, 'readwrite');

        const request = store.put(log);

        return new Promise((resolve) => {
            request.onsuccess = () => {
                if (request.result !== undefined) resolve(true);
                else resolve(false);
            };

            request.onerror = () => {
                resolve(false);
            };
        });
    };

    static getLogs = async (): Promise<Log[] | undefined> => {
        const store = await IndexedDbConnection.getStoreConnection(IndexedDbStores.LOGS, 'readonly');
        const request = store.getAll();

        return new Promise((resolve) => {
            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = () => {
                resolve(undefined);
            };
        });
    };

    static removeById = async (ids: number[]): Promise<void> => {
        const store = await IndexedDbConnection.getStoreConnection(IndexedDbStores.LOGS, 'readwrite');
        ids.map((id) => store.delete(id));
    };
}
