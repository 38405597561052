import { HeaderGroup } from 'react-table';

import THeaderItem from './THeaderItem';

interface Props {
    headerGroup: HeaderGroup<any>;
    onSort?: (columnId: string, isSortedDesc: boolean | undefined) => void;
}

export default function THeader(props: Props) {
    return (
        <tr>
            {props.headerGroup.headers.map((column) => (
                <THeaderItem
                    key={column.id}
                    column={column}
                    onSort={props.onSort}
                />
            ))}
        </tr>
    );
}
