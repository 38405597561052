import { useEffect, useState } from 'react';

import { Slot } from '../../../api/Slots';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchSlotById } from '../../../store/slotSlice';
import { getAllValuesFromStore } from '../../../utils/KeyValueStores';
import { SlotDetailModalWrapperProps } from './slot-detail-modal-wrapper.component';

interface ViewProps {
    slot?: Slot;
}

const useSlotDetailModalWrapper = (props: SlotDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const slots = useAppSelector((state) => state.slots.slotList);
    const [slot, changeSlot] = useState<Slot | undefined>(undefined);

    useEffect(() => {
        const found = getAllValuesFromStore(slots).find((s) => s.id == props.id || s.real_id == parseInt(props.id));
        if (found) changeSlot(found);
        else dispatch(fetchSlotById(props.id));
    }, [props.id, slots]);

    return {
        slot
    };
};

export default useSlotDetailModalWrapper;
