import { Product, fetchProductById } from '@frontend/product';
import { useEffect } from 'react';

import { Slot } from '../api/Slots';
import { useAppDispatch, useAppSelector } from './redux';

interface AssignedProductsProps {
    slot: Slot | undefined;
}

const useAssignedProducts = (props: AssignedProductsProps): Product[] | undefined => {
    const products = useAppSelector((state) => state.products);
    const dispatch = useAppDispatch();
    const productIds = props.slot?.assigned_products.toString();

    useEffect(() => {
        if (products.allProducts !== null && products.allProducts.next === null) {
            props.slot?.assigned_products.forEach((p) => {
                const foundProduct = products.allProducts?.results.find((pr) => pr !== null && pr !== undefined && p === pr.id);
                if (!foundProduct) {
                    dispatch(fetchProductById(p.toString()));
                }
            });
        }
    }, [products.allProducts]);

    const productList = products.allProducts?.results.filter((p) => p.id.toString() === productIds);

    return productList;
};

export default useAssignedProducts;
