import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from '@frontend/api-utils';

import { TransactionStatus } from '../common/transaction-status';
import { Transaction } from '../transaction';
import {
    BulkTransactionStatusUpdateModel,
    CancelTransactionQueryParams,
    CreateTransactionModel,
    MoveTransactionOptions,
    TransactionResponse,
    TransactionsQueryParams,
    UpdateBulkTransactionReceiverOptions
} from './models';

const transactionViewSet: ApiViewSet = {
    baseName: 'transactions-v2'
};

export class TransactionAPIClient extends APIClient {
    public static async fetchTransactionsApi(queryParams?: ApiQueryParams<TransactionsQueryParams> | null): Promise<TransactionResponse> {
        return this.apiPagination<TransactionResponse, TransactionsQueryParams>(transactionViewSet, queryParams);
    }

    public static async fetchTransactionApi(options: DetailOptions): Promise<Transaction> {
        return this.apiDetail<Transaction>(transactionViewSet, options);
    }

    public static async addTransactionApi(transaction: CreateTransactionModel): Promise<Transaction> {
        const transactionAddUrl = `/transactions/`;
        const response = await this.post(transactionAddUrl, transaction);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error adding transaction');
            }
            throw new ApiError('Error adding transaction', json);
        }
        return await response.json();
    }

    public static async cancelTransactionApi(options: CancelTransactionQueryParams): Promise<Transaction> {
        let transactionUrl = '/transactions/';
        if (options && options.id) {
            transactionUrl += options.id + '/';
        }
        const body = { status: TransactionStatus.CANCELLED };
        const response = await this.patch(transactionUrl, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error cancelling this transaction');
            }
            throw new ApiError('Error cancelling this transaction', json);
        }
        return await response.json();
    }

    public static async moveTransactionApi(options: MoveTransactionOptions): Promise<Transaction> {
        const response = await this.post(`/transactions/${options.transactionId}/change_spot_slot/`, options.body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error moving transaction');
            }
            throw new ApiError('Error moving transaction', json);
        }
        return await response.json();
    }

    public static async bulkUpdateTransactionStatusApi(body: BulkTransactionStatusUpdateModel): Promise<void> {
        const response = await this.post(`/transactions/bulk/status_update/`, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating transactions');
            }
            throw new ApiError('Error updating transactions', json);
        }
        return await response.json();
    }

    public static async bulkUpdateTransactionReceiverApi(body: UpdateBulkTransactionReceiverOptions): Promise<void> {
        const response = await this.post(`/transactions/bulk/receiver_update/`, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating bulk transaction');
            }
            throw new ApiError('Error creating bulk transaction', json);
        }
        return await response.json();
    }

    public static isBeforeDropoff(statusString: string): boolean {
        const status = TransactionStatus.getByString(statusString);
        return status === TransactionStatus.NEW || status === TransactionStatus.SCHEDULED || status === TransactionStatus.READY_FOR_DROPOFF;
    }
    public static isBeforePickup(status: TransactionStatus): boolean {
        return (
            status === TransactionStatus.NEW ||
            status === TransactionStatus.SCHEDULED ||
            status === TransactionStatus.READY_FOR_DROPOFF ||
            status === TransactionStatus.DROPOFF_DONE ||
            status === TransactionStatus.DROPOFF_IN_PROGRESS ||
            status === TransactionStatus.READY_FOR_PICKUP ||
            status === TransactionStatus.CANCELLED
        );
    }
    public static getPrevieusStatuses(status: TransactionStatus): TransactionStatus[] {
        const result: TransactionStatus[] = [];
        if (status !== TransactionStatus.NEW) {
            result.push(TransactionStatus.NEW);
            if (status !== TransactionStatus.SCHEDULED && status !== TransactionStatus.READY_FOR_DROPOFF) {
                result.push(TransactionStatus.READY_FOR_DROPOFF);
                if (status !== TransactionStatus.DROPOFF_IN_PROGRESS) {
                    result.push(TransactionStatus.DROPOFF_IN_PROGRESS);
                    if (status !== TransactionStatus.DROPOFF_DONE) {
                        result.push(TransactionStatus.DROPOFF_DONE);
                        if (status !== TransactionStatus.READY_FOR_PICKUP && status !== TransactionStatus.CANCELLED) {
                            result.push(TransactionStatus.READY_FOR_PICKUP);
                            if (status !== TransactionStatus.PICKUP_IN_PROGRESS && status !== TransactionStatus.REMOVE_PARCEL_IN_PROGRESS) {
                                result.push(TransactionStatus.PICKUP_IN_PROGRESS);
                                if (status !== TransactionStatus.PICKUP_DONE) {
                                    result.push(TransactionStatus.PICKUP_DONE);
                                }
                            }
                        }
                    }
                }
            }
        }
        return result;
    }
}
