export interface CheckSwitchProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: boolean, e?: React.ChangeEvent<HTMLInputElement>) => void;
    onFocusChange?: () => void;
    value?: string;
    color?: string;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    checked: boolean;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    submitted?: boolean;
    reverse?: boolean;
}

export default function CheckSwitch(props: CheckSwitchProps) {
    return (
        <div className={`d-flex ${props.reverse ? 'flex-row-reverse' : 'flex-row'}`}>
            <label
                className={`form-check-label ${props.color ? `text-${props.color}` : ''} me-1`}
                htmlFor='flexSwitchCheckDefault'>
                {props.label}
            </label>
            <div className='form-check form-switch'>
                <input
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.checked, event);
                    }}
                    className='form-check-input'
                    type='checkbox'
                    id='flexSwitchCheckDefault'
                    checked={props.checked}
                />
            </div>
        </div>
    );
}
