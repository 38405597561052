import { useEffect, useState } from 'react';
import Select from 'react-select';

import { SelectInputProps } from '../../../components/forms/select/Select';
import { getInputClass, getInputClassMultiSelect } from '../../../utils/FormUtils';

export interface AccountSelectProps extends SelectInputProps {
    roleOptions?: { label: string; value: string }[];
}

const AccountSelect = (props: AccountSelectProps) => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    const selectedAccount = props.value.map((v: any) => v.account);
    const multiValues = props.isMulti
        ? props.options?.filter((op: any) => props.value.includes(op.value) || (selectedAccount && selectedAccount.includes(op.value)))
        : [];

    return (
        <div className='form-group'>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
            </label>
            <Select
                {...props}
                options={props.options}
                value={props.isMulti ? multiValues : props.value ? props.options?.find((op: any) => op.value === props.value) : ''}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        minWidth: '190px',
                        padding: '1.204px 0px 1.204px 10px',
                        borderRadius: '0.5rem'
                    }),
                    menu: (baseStyle, state) => ({
                        ...baseStyle,
                        zIndex: 9999
                    }),
                    valueContainer: (baseStyle, state) => ({
                        ...baseStyle,
                        padding: '0px'
                    })
                }}
                classNames={{
                    control: (state) =>
                        props.useConditionedStyling === false
                            ? 'form-control'
                            : props.isMulti
                            ? getInputClassMultiSelect(touched, valid, props.value)
                            : getInputClass(touched, valid, props.value)
                }}
                onChange={(newValue: any, actionMeta) => {
                    props.onChange && props.onChange(props.isMulti ? newValue.map((s: any) => s.value) : newValue.value, actionMeta);
                    isValid();
                }}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                onFocus={() => {
                    changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                isClearable={props.isClearable}
            />
            {valid === false && touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};

export default AccountSelect;
