import { FormattedMessage } from 'react-intl';

export const TRANSACTIONS = {
    COLUMNS: {
        RECEIVER: (
            <FormattedMessage
                id='TransactionList.Table.Title.Receiver'
                description='Table title for receiver'
                defaultMessage='Receiver'
            />
        ),
        SENDER: (
            <FormattedMessage
                id='CommonMessage.Transactions.COLUMNS.SENDER'
                description='Label for sender'
                defaultMessage='Sender'
            />
        ),
        TRACKING_NUMBER: (
            <FormattedMessage
                id='TransactionList.Table.Title.TrackingNumber'
                description='Table title for TrackingNumber'
                defaultMessage='Tracking number'
            />
        ),
        STATUS: (
            <FormattedMessage
                id='TransactionList.Table.Title.Status'
                description='Table title for status'
                defaultMessage='Status'
            />
        ),
        TYPE: (
            <FormattedMessage
                id='TransactionList.Table.Title.Type'
                description='Table title for type'
                defaultMessage='Type'
            />
        ),
        SENDER_GROUP: (
            <FormattedMessage
                id='TransactionDetail.Detail.Title.SenderGroup'
                description={'Detail title for sender group'}
                defaultMessage='Sender group'
            />
        ),
        RECEIVER_GROUP: (
            <FormattedMessage
                id='TransactionDetail.Detail.Title.ReceiverGroup'
                description={'Detail title for receiver group'}
                defaultMessage='Receiver group'
            />
        )
    },
    DROPOFF_CODE: (
        <FormattedMessage
            id='TRANSACTIONS.DROPOFF_CODE'
            description='Label for dropoff code'
            defaultMessage='Dropoff code'
        />
    ),
    PICKUP_CODE: (
        <FormattedMessage
            id='TRANSACTIONS.PICKUP_CODE'
            description='Label for pickup code'
            defaultMessage='Pickup code'
        />
    ),
    REMOVE_PARCEL_CODE: (
        <FormattedMessage
            id='TRANSACTIONS.REMOVE_PARCEL_CODE'
            description='Label for remove code'
            defaultMessage='Remove parcel code'
        />
    )
};
