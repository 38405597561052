import { Logger } from '@frontend/Logger';
import { ClassType, SliceStatus } from '@frontend/common';
import { ToastUtil } from '@frontend/toast-utils';
import { deleteUser } from '@frontend/user';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';
import { UserDetailModalProps } from './user-detail-modal.component';

interface ViewProps {
    changeShowUpdateModal: (value: React.SetStateAction<boolean>) => void;
    changeShowDeleteModal: (value: React.SetStateAction<boolean>) => void;
    showUpdateModal: boolean;
    showDeleteModal: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    onDeleteUser: () => void;
}

const useUserDetailModal = (props: UserDetailModalProps): ViewProps => {
    const dispatch = useAppDispatch();
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const userState = useAppSelector((state) => state.user);
    const [submitted, changeSubmitted] = useState<boolean>(false);

    const canUpdate = props.user !== undefined;
    const canDelete = props.user !== undefined;
    const { selectedUser } = useAccount();

    const onDeleteUser = () => {
        dispatch(deleteUser(props.user!.id));
        setIsDeleted(true);
        changeSubmitted(true);
        changeShowDeleteModal(false);
    };

    useEffect(() => {
        if (isDeleted && submitted) {
            if (userState.status === SliceStatus.IDLE) {
                toast.success(
                    ...ToastUtil.generateToastConfig(CommonMessage.STATUS.SUCCESS, `Successfully deleted user: ${props.user?.email}`, ClassType.SUCCESS)
                );
                Logger.log(`${selectedUser?.email} deleted user: ${props.user?.email}`, { user: selectedUser?.id });
                changeSubmitted(false);
                props.handleClose();
            } else if (userState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, `Failed to delete user: ${props.user?.email}`, ClassType.DANGER));
                Logger.error(`${selectedUser?.email} failed to delete user: ${props.user?.email}`, { user: selectedUser?.id }, userState.error?.json);
                changeSubmitted(false);
                props.handleClose();
            }
        }
    }, [userState.usersList]);

    return {
        changeShowDeleteModal,
        canDelete,
        canUpdate,
        changeShowUpdateModal,
        onDeleteUser,
        showDeleteModal,
        showUpdateModal
    };
};

export default useUserDetailModal;
