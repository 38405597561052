import { BsCheck2Circle, BsQuestionCircle, BsXCircle } from 'react-icons/bs';

interface Props {
    value?: boolean;
}

const BooleanBadge = (props: Props) => {
    return (
        <span className='d-flex flex-row align-items-center'>
            <span className='text-info text-lg me-1'>
                {props.value === undefined ? (
                    <BsQuestionCircle className='text-info' />
                ) : props.value ? (
                    <BsCheck2Circle className='text-success' />
                ) : (
                    <BsXCircle className='text-danger' />
                )}
            </span>
        </span>
    );
};

export default BooleanBadge;
