import { Logger } from '@frontend/Logger';
import { Product, ProductAttribute } from '@frontend/product';
import { deleteProductAttribute, updateProductAttribute } from '@frontend/product-attribute';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import CheckBox from '../../../components/forms/check/CheckBox';
import SelectInput from '../../../components/forms/select/Select';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import UpdateModalFooter from '../../../components/modals/UpdateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

interface UpdateProductAttributeProps {
    product: Product;
    handleClose: () => void;
    onSuccess: () => void;
}
const UpdateProductAttribute = (props: UpdateProductAttributeProps) => {
    const dispatch = useAppDispatch();
    const attributeState = useAppSelector((state) => state.attributes);
    const [productAttributes, setProductAttributes] = useState<ProductAttribute[]>([]);
    const [formResult, setFormResult] = useState<any | undefined>({});
    const [deleteFormResult, setDeleteFormResult] = useState<any | undefined>({});
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [deleteSubmitted, changeDeleteSubmitted] = useState<boolean>(false);
    const [openConfirmationModal, changeOpenConfirmationModal] = useState<boolean>(false);

    useEffect(() => {
        if (props.product.product_attributes) {
            setProductAttributes(props.product.product_attributes);
        }
    }, [props.product]);

    useEffect(() => {
        if (deleteFormResult && Object.values(deleteFormResult).some((v: any) => v.checked === true) && deleteSubmitted) {
            const deleteRequests = Object.values(deleteFormResult)
                .filter((v: any) => v.checked === true)
                .map((obj: any) => {
                    return dispatch(deleteProductAttribute(obj.id));
                });

            Promise.all(deleteRequests)
                .then((e) => {
                    if (e.some((s) => s.type.includes('rejected'))) {
                        ToastUtil.error(
                            CommonMessage.STATUS.ERROR,
                            <FormattedMessage
                                id='UpdateAttribute.Delete.Error'
                                description='Error message after deleting product attribute'
                                defaultMessage='Something went wrong while trying to delete these attribute(s)'
                            />
                        );
                    } else {
                        ToastUtil.success(
                            CommonMessage.STATUS.SUCCESS,
                            <FormattedMessage
                                id='UpdateAttribute.Delete.Success'
                                description='Success message after deleting product attribute'
                                defaultMessage='Removed attribute(s) from product'
                            />
                        );
                        props.onSuccess();
                    }
                    props.handleClose();
                })
                .catch((e) => {
                    Logger.error(e);
                });
        }
    }, [deleteSubmitted]);

    useEffect(() => {
        if (formResult && submitted) {
            const updateRequests = Object.values(formResult).map((v: any) => {
                return dispatch(
                    updateProductAttribute({
                        id: v.id,
                        body: {
                            account: v.account,
                            product: v.product,
                            attribute: v.attribute,
                            value: v.value
                        }
                    })
                );
            });

            Promise.all(updateRequests)
                .then((e) => {
                    if (e.some((s) => s.type.includes('rejected'))) {
                        toast.error(
                            ...ToastUtil.generateToastConfig(
                                CommonMessage.STATUS.ERROR,
                                <FormattedMessage
                                    id='UpdateAttribute.Error'
                                    description='Error message after updating attribute'
                                    defaultMessage='Something went wrong while trying to update these product attribute(s)'
                                />,
                                ClassType.DANGER
                            )
                        );
                    } else {
                        toast.success(
                            ...ToastUtil.generateToastConfig(
                                CommonMessage.STATUS.SUCCESS,
                                <FormattedMessage
                                    id='UpdateProductAttribute.Success'
                                    description='Success message after updating product attribute'
                                    defaultMessage='Product attribute(s) have been successfully updated'
                                />,
                                ClassType.SUCCESS
                            )
                        );
                        props.onSuccess();
                    }
                    props.handleClose();
                })
                .catch((error) => {
                    Logger.error(error);
                });
        }
    }, [submitted]);

    return (
        <>
            <form method='put'>
                <div
                    className='modal-body'
                    style={{ overflow: 'unset' }}>
                    {productAttributes.map((p) => (
                        <div
                            className='d-flex align-items-center'
                            key={p.id}>
                            <div className='col-10'>
                                <SelectInput
                                    key={p.id}
                                    label={attributeState.attributeList?.find((a) => a.id === p.attribute)?.name}
                                    submitted={false}
                                    options={attributeState.attributeList?.find((a) => a.id === p.attribute)?.values.map((v) => ({ value: v, label: v }))}
                                    value={formResult[p.id] ? formResult[p.id] : p.value}
                                    onChange={(v: any) => {
                                        setFormResult({
                                            ...formResult,
                                            [p.id]: {
                                                ...p,
                                                value: v.value
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div className='col-2 ps-1 pt-3'>
                                <CheckBox
                                    label={
                                        <BiTrash
                                            color='FireBrick'
                                            size={'20px'}
                                        />
                                    }
                                    checked={deleteFormResult[p.id] ? deleteFormResult[p.id].checked : false}
                                    onChange={(v: any) => {
                                        setDeleteFormResult({
                                            ...deleteFormResult,
                                            [p.id]: {
                                                ...p,
                                                checked: v
                                            }
                                        });
                                    }}
                                    submitted={false}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <UpdateModalFooter
                    handleClose={() => props.handleClose()}
                    onSubmit={(e) => {
                        changeSubmitted(true);
                        e?.preventDefault();
                    }}
                    disabled={!formResult}
                    disableDelete={Object.values(deleteFormResult).every((p: any) => p.checked === false)}
                    onDelete={(e) => {
                        changeOpenConfirmationModal(true);
                        e?.preventDefault();
                    }}
                />
                {openConfirmationModal && (
                    <ConfirmationModal
                        handleClose={() => changeOpenConfirmationModal(false)}
                        message={
                            <FormattedMessage
                                id='UpdateProductAttribute.Confirmation.Message'
                                description='Confirmation message when opening the confirmation modal when deleting product attributes'
                                defaultMessage='Are you sure you want to remove these attribute(s) from this product?'
                            />
                        }
                        onConfirm={() => changeDeleteSubmitted(true)}
                        show={openConfirmationModal}
                        severity={ClassType.DANGER}
                    />
                )}
            </form>
        </>
    );
};

export default UpdateProductAttribute;
