import { FormattedMessage } from 'react-intl';

export const SLOTS = {
    SLOT: (
        <FormattedMessage
            id='SLOTS.SLOT'
            description='Common message for slot'
            defaultMessage='SLOT'
        />
    ),
    AVAILABLE_SLOTS: (
        <FormattedMessage
            id='SLOTS.AVAILABLE_SLOTS'
            description='Message for available slots'
            defaultMessage='Available SLOTs'
        />
    ),
    ERRORS: {
        NOT_FOUND: (
            <FormattedMessage
                id='SLOTS.ERRORS.NOT_FOUND'
                description='Common message for slot not found error'
                defaultMessage='No slot found'
            />
        )
    },
    OPEN_SLOT: {
        BUTTON: (
            <FormattedMessage
                id='SlotDetailModal.OpenSlotButton'
                description='The button to remotely open a slot.'
                defaultMessage='Open SLOT'
            />
        ),
        MESSAGE: (
            <FormattedMessage
                id='SlotDetailModal.OpenSlot.ConfirmationModal.message'
                description='The message on the confirmation modal when trying to open a door remotely.'
                defaultMessage='Be carefull, this action will open a physical door. If it is not an automatically closing door anyone could have access to its contents.'
            />
        )
    }
};
