import { useEffect, useState } from 'react';
import { AiFillApi, AiFillDashboard, AiFillQuestionCircle, AiOutlineFileSearch } from 'react-icons/ai';
import { BsFillFileEarmarkSpreadsheetFill, BsGrid1X2Fill, BsQuestionCircle } from 'react-icons/bs';
import { FaBox, FaUserShield, FaWarehouse } from 'react-icons/fa';
import { FcSupport } from 'react-icons/fc';
import { FiShoppingCart } from 'react-icons/fi';
import { MdContacts, MdGroups, MdOutlineCloudQueue, MdShelves } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import logo from '../../../assets/images/logo200 × 200.png';
import Auth from '../../authorization/Auth';
import { Workflow } from '../../common/BackendEnums';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import useGetWorkflow from '../../hooks/useGetWorkflow';
import NavbarDropdown from './NavbarDropdown';
import NavbarListItem from './NavbarListItem';

const Navbar = () => {
    const { workflow } = useGetWorkflow();
    const isAdmin = !useAuthorization(DefaultRole.SUPER_ADMIN);

    const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const isNavbarListItem = event.target.closest('.bg-secondary');
            const isNavbarDropdown = event.target.closest('.collapse.show');

            if (isNavbarListItem === null && isNavbarDropdown === null) setActiveDropdown(null);
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleDropdownToggle = (dropdownId: string) => {
        setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
    };

    return (
        <Auth role={DefaultRole.ADMIN}>
            <div
                className='sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4'
                id='sidenav-main'>
                <div className='sidenav-header d-flex flex-row justify-content-between align-items-center'>
                    <a
                        className='navbar-brand m-0'
                        href='/'>
                        <img
                            src={logo}
                            className='navbar-brand-img h-100'
                            alt='main_logo'
                        />
                        <span className='ms-1 font-weight-bold'>Telloport</span>
                    </a>
                </div>

                <hr className='horizontal dark mt-0' />
                <div
                    className='collapse navbar-collapse w-auto h-100'
                    id='sidenav-collapse-main'>
                    <ul className='navbar-nav h-100'>
                        {workflow === Workflow.WAREHOUSE && (
                            <>
                                <NavbarListItem
                                    text='Dashboard'
                                    link={NavbarItems.DASHBOARD}
                                    icon={<AiFillDashboard />}
                                />
                                <NavbarListItem
                                    text='Warehouse'
                                    link={NavbarItems.WAREHOUSE}
                                    icon={<FaWarehouse />}
                                />
                                <NavbarListItem
                                    key='Lost items'
                                    text='Lost items'
                                    icon={<BsQuestionCircle />}
                                    link={NavbarItems.LOST_ITEMS}
                                />
                                <NavbarListItem
                                    key='Stock'
                                    text='Stock'
                                    icon={<MdShelves />}
                                    link={NavbarItems.STOCK}
                                />
                            </>
                        )}
                        <NavbarListItem
                            text='Transactions'
                            link={NavbarItems.TRANSACTIONS}
                            icon={<FaBox />}
                            hide={workflow === Workflow.WAREHOUSE && isAdmin}
                        />

                        <NavbarListItem
                            text='Contacts'
                            link={NavbarItems.CONTACTS}
                            icon={<MdContacts />}
                        />

                        <NavbarListItem
                            text='Groups'
                            link={NavbarItems.GROUPS}
                            icon={<MdGroups />}
                            hide={workflow === Workflow.WAREHOUSE && isAdmin}
                        />

                        <NavbarListItem
                            text='distriSPOTS'
                            link={NavbarItems.SPOTS}
                            icon={<BsGrid1X2Fill />}
                        />
                        <NavbarListItem
                            text='Reports'
                            link={NavbarItems.REPORTS}
                            icon={<BsFillFileEarmarkSpreadsheetFill />}
                        />

                        <Auth role={DefaultRole.SUPER_ADMIN}>
                            <NavbarListItem
                                text='Catalogues'
                                link={NavbarItems.CATALOGUES}
                                icon={<FiShoppingCart />}
                            />
                        </Auth>

                        <Auth role={DefaultRole.ADMIN}>
                            <NavbarListItem
                                text='IoT'
                                link={NavbarItems.IOT}
                                icon={<MdOutlineCloudQueue />}
                                hide={workflow === Workflow.WAREHOUSE && isAdmin}
                            />
                        </Auth>

                        <NavbarListItem
                            text='Users'
                            link={NavbarItems.USERS}
                            icon={<FaUserShield />}
                        />
                        <Auth role={DefaultRole.SUPER_ADMIN}>
                            <NavbarListItem
                                text='Logs'
                                link={NavbarItems.LOGS}
                                icon={<AiOutlineFileSearch />}
                                hide={workflow === Workflow.WAREHOUSE && isAdmin}
                            />
                        </Auth>
                        <NavbarDropdown
                            title='Support'
                            icon={<AiFillQuestionCircle />}
                            id='support'
                            expanded={activeDropdown === 'support'}
                            onToggle={() => handleDropdownToggle('support')}
                            elements={[
                                <NavbarListItem
                                    key='Support'
                                    link={NavbarItems.SUPPORT}
                                    text='Support request'
                                    icon={<FcSupport />}
                                />,
                                <ApiListItem key='api' />
                            ]}
                        />
                    </ul>
                </div>
            </div>
        </Auth>
    );
};

export default Navbar;

export enum NavbarItems {
    TRANSACTIONS = 'transactions',
    CONTACTS = 'contacts',
    GROUPS = 'groups',
    SPOTS = 'spots',
    REPORTS = 'reports',
    CATALOGUES = 'catalogues',
    IOT = 'iot',
    USERS = 'users',
    DASHBOARD = 'dashboard',
    SUPPORT = 'support',
    LOGS = 'logs',
    LOST_ITEMS = 'lost-items',
    STOCK_FILL = 'stock-fills',
    WAREHOUSE = 'warehouse',
    STOCK = 'stock'
}

const ApiListItem = () => {
    return (
        <a
            href={`${process.env.NX_APIDOCS}`}
            target='_blank'
            rel='noreferrer'>
            <li className='nav-item'>
                <a className='nav-link'>
                    <div className='icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center'>
                        <i className='ni ni-tv-2 text-primary text-sm opacity-10'>{<AiFillApi />}</i>
                    </div>
                    <span className='nav-link-text ms-1'>
                        <FormattedMessage
                            id='Navbar.Api'
                            description='Link to api docs'
                            defaultMessage='API Docs'
                        />
                    </span>
                </a>
            </li>
        </a>
    );
};
