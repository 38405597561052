import { ContactApiClient } from '@frontend/contact';
import React, { useEffect, useState } from 'react';

import { IAccessCode } from '../../users/forms/create-user/create-user.controller';
import { AccessCodeProps } from './access-code.component';

interface ViewProps {
    shouldCreateAccessCode: boolean;
    accessCode: IAccessCode;
    changeAccessCode: (value: React.SetStateAction<IAccessCode>) => void;
    changeShouldCreateAccessCode: (value: React.SetStateAction<boolean>) => void;
}

const useAccessCode = (props: AccessCodeProps): ViewProps => {
    const [shouldCreateAccessCode, changeShouldCreateAccessCode] = useState<boolean>(false);
    const [accessCode, changeAccessCode] = useState<IAccessCode>({});

    useEffect(() => {
        if (props.createdContact && shouldCreateAccessCode) {
            ContactApiClient.postContactAccessKey({
                contact: props.createdContact.id,
                name: `${props.createdContact.first_name} ${props.createdContact.last_name}`,
                settings_dynamic_mode: accessCode.type === 'dynamic',
                key: accessCode.type === 'static' ? accessCode.value : undefined,
                settings_dynamic_mode_access_key_change_interval: accessCode.type === 'dynamic' ? accessCode.expiration_time : undefined
            })
                .then(() => props.callback && props.callback())
                .catch((err) => {});
        } else if (props.createdContact) {
            props.callback && props.callback();
        }
    }, [props.createdContact]);

    return {
        accessCode,
        changeAccessCode,
        changeShouldCreateAccessCode,
        shouldCreateAccessCode
    };
};

export default useAccessCode;
