import { Category, updateCategory } from '@frontend/category';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import ImageInput from '../../../components/forms/imageInput/ImageInput';
import SelectInput from '../../../components/forms/select/Select';
import TextInput from '../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getAllChildren } from '../../../utils/GetAllChildren';

export default function UpdateCategory(props: { category: Category; handleClose: () => void }) {
    const dispatch = useAppDispatch();
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const categoryState = useAppSelector((state) => state.categories);
    const [formBody, changeFormBody] = useState<FormData>();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);

    useEffect(() => {
        if (categoryState.allCategories) {
            const foundCategory = categoryState.allCategories.results.find((c) => c.id === props.category.parent_id);
            if (foundCategory) changeFormResult({ ...formResult, parent: { label: foundCategory.name, value: foundCategory.url } });
        }
    }, []);

    const initialState: UpdateCategoryModel = {
        name: props.category.name,
        account: accountId!,
        image: props.category.image,
        parent: null
    };
    const [formResult, changeFormResult] = useState(initialState);

    useEffect(() => {
        const formData = new FormData();
        formData.append('account', formResult.account.toString());
        formData.append('name', formResult.name);
        formData.append('parent', formResult.parent === null ? '' : formResult.parent.value);
        formResult.image && formData.append('image', formResult.image);

        changeFormBody(formData);
    }, [formResult]);

    const [nameValid, changeNameValid] = useState<boolean>(false);
    const formValid = nameValid;

    useEffect(() => {
        if (categoryState.categoryList) {
            if (submitted && formValid && formBody) {
                dispatch(updateCategory({ category: formBody, id: props.category.id }));
            }
        } else changeSubmitted(false);
    }, [submitted]);

    useEffect(() => {
        if (submitted && categoryState.categoryList) {
            const updatedCategory = getAllChildren(categoryState.categoryList).find((c) => c.name === formResult.name);
            if (updatedCategory) {
                ToastUtil.success(CommonMessage.STATUS.SUCCESS, categoryUpdatedSuccess);
                props.handleClose();
            } else {
                ToastUtil.error(CommonMessage.STATUS.ERROR, categoryUpdatedError);
                changeSubmitted(false);
            }
        }
    }, [categoryState.categoryList]);
    return (
        <>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <div
                    className='modal-body'
                    style={{ overflowY: 'unset' }}>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateCategory.Label.Name'
                                        description='Label for selecting name'
                                        defaultMessage='Name'
                                    />
                                }
                                submitted={submitted}
                                required
                                placeholder='Enter a name'
                                value={formResult.name}
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='UpdateCategory.Name.ErrorMessage'
                                        description='Error message for name on create category'
                                        defaultMessage='Enter a valid name'
                                    />
                                }
                                isValidCallback={(value) => changeNameValid(value)}
                                id='UpdateCategory.NameInput'
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='UpdateCategory.Label.Parent'
                                        description='Label for selecting parent'
                                        defaultMessage='Parent'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.parent}
                                onChange={(value: any) => changeFormResult({ ...formResult, parent: value })}
                                options={categoryState.allCategories ? categoryState.allCategories.results.map((c) => ({ value: c.url, label: c.name })) : []}
                                isClearable
                                id='UpdateCategory.ParentInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ImageInput
                                label={
                                    <FormattedMessage
                                        id='UpdateCategory.Label.Image'
                                        description='Label for selecting image'
                                        defaultMessage='Image'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.image}
                                id='UpdateCategory.ImageInput'
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    onSubmit={() => changeSubmitted(true)}
                    handleClose={() => props.handleClose()}
                    disabled={submitted || !formValid}
                />
            </form>
        </>
    );
}

export interface UpdateCategoryModel {
    name: string;
    account: number;
    image: File | string | null;
    parent: { value: string; label: string } | null;
}

const categoryUpdatedSuccess = (
    <FormattedMessage
        id='UpdateCategory.Created.Category.Success'
        description='Message when category was updated successfully'
        defaultMessage='Category was updated successfully'
    />
);

const categoryUpdatedError = (
    <FormattedMessage
        id='UpdateCategory.Create.Category.Error'
        description='Message when failing to update category'
        defaultMessage='There was an error updating your category'
    />
);
