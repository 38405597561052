import Tooltip from '../../tooltip/Tooltip';

export interface CheckBoxProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: boolean) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    checked: boolean | undefined;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    submitted: boolean;
    id?: string;
    onTouch?: (value: boolean) => void;
    info?: { content: React.ReactNode; element: React.ReactNode; id: string };
}

export default function CheckBox(props: CheckBoxProps) {
    return (
        <>
            <div
                className='form-check'
                onClick={() => {
                    if (props.onChange) props.onChange(!props.checked);
                }}>
                <input
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.checked);
                    }}
                    className='form-check-input'
                    type='checkbox'
                    id={props.id}
                    checked={props.checked}
                    onFocus={() => {
                        props.onTouch && props.onTouch(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                />
                <label
                    className='form-check-label'
                    htmlFor='flexSwitchCheckDefault'>
                    {props.label}
                </label>
                {props.info && (
                    <Tooltip
                        id={props.info.id}
                        element={props.info.element}>
                        {props.info.content}
                    </Tooltip>
                )}
            </div>
        </>
    );
}
