import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export enum DetailModalType {
    PRODUCT = 'product_id',
    CONTACT = 'contact_id',
    TRANSACTION = 'transaction_id',
    BULK_TRANSACTION = 'bulk_transaction_id',
    SLOT = 'slot_id',
    SPOT = 'spot_id',
    CONTACT_GROUP = 'contact_group_id',
    STOCK_FILL = 'stock_fill_id',
    CATEGORY = 'category',
    USER = 'user'
}

interface ViewProps {
    modals: string[];
    handleClose: () => void;
}

const useDetailModalContainer = (): ViewProps => {
    const navigate = useNavigate();
    const location = useLocation();
    const [foundIds, changeFoundIds] = useState<string[]>([]);

    useEffect(() => {
        const detail = new URLSearchParams(location.search).get('details');
        if (detail) changeFoundIds(JSON.parse(detail));
        else if (foundIds.length != 0) changeFoundIds([]);
    }, [location]);

    const handleClose = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('details');

        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    };

    return {
        modals: foundIds,
        handleClose
    };
};

export default useDetailModalContainer;
