export function isWeekend(date: Date): boolean {
    return [6, 0].includes(new Date(date).getDay());
}

export function isValidDate(dateString: string) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z$/;
    if (!dateFormatRegex.test(dateString)) {
        return false;
    }
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
}
