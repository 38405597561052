import { FormattedMessage } from 'react-intl';

const DEVICES = {
    HEALTH: (
        <FormattedMessage
            id='PrinterList.Table.Title.Health'
            description='Table title for Health'
            defaultMessage='Health'
        />
    ),
    SERIAL_NUMBER: (
        <FormattedMessage
            id='PrinterList.Table.Title.serial_number'
            description='Table title for serial_number'
            defaultMessage='serial_number'
        />
    ),
    LAST_ACTIVE_TIME: (
        <FormattedMessage
            id='PrinterList.Table.Title.last_active_time'
            description='Table title for last_active_time'
            defaultMessage='Last active time'
        />
    ),
    MODEL: (
        <FormattedMessage
            id='Devices.MODEL'
            description='Title for device model'
            defaultMessage='Model'
        />
    ),
    PROTOCOL: (
        <FormattedMessage
            id='Devices.PROTOCOL'
            description='Title for device protocol'
            defaultMessage='Protocol'
        />
    ),
    TYPE: (
        <FormattedMessage
            id='Devices.TYPE'
            description='Title for device type'
            defaultMessage='Type'
        />
    ),
    ACTION_TYPE: (
        <FormattedMessage
            id='Devices.ACTION_TYPE'
            description='Title for device action type'
            defaultMessage='Action type'
        />
    ),
    LAST_ACTIVE_STATE: (
        <FormattedMessage
            id='Devices.LAST_ACTIVE_STATE'
            description='Title for device last active state'
            defaultMessage='Last active state'
        />
    ),
    KEY_CODE: (
        <FormattedMessage
            id='Devices.KEY_CODE'
            description='Title for device key code'
            defaultMessage='Key code'
        />
    ),
    NAME: (
        <FormattedMessage
            id='Devices.NAME'
            description='Title for device name'
            defaultMessage='Name'
        />
    )
};

export default DEVICES;
