import { Spot } from '../../../api/Spots';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import ModalHeader from '../../../components/modals/ModalHeader';
import SpotDetail from '../SpotDetail';

export const ID = 'spot-detail-modal';

export interface SpotDetailModalProps extends ModalProps {
    spot: Spot;
}

const SpotDetailModal = (props: SpotDetailModalProps): JSX.Element => {
    return (
        <Modal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                handleClose={props.handleClose}
                id='SpotDetailModal-close'
                title={props.spot.name}
            />
            <div className='modal-body'>
                <SpotDetail spot={props.spot} />
            </div>
        </Modal>
    );
};

export default SpotDetailModal;
