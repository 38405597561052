import { toNumber } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface TimeInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: any) => void;
    onFocusChange?: () => void;
    value?: any;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    helpText?: React.ReactNode;
    submitted: boolean;
}

export default function TimeInput(props: TimeInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && props.value === undefined) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <input
                    className='form-control'
                    id={props.label?.toString()}
                    value={props.value}
                    type='time'
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value);
                        isValid();
                    }}
                />
                {valid === false && touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ? props.errorMessage : 'Please enter a valid date and time.'}</small>
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
