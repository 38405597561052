import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
    children?: React.ReactNode;
    id?: string;
    clickable?: boolean;
    element: React.ReactNode;
    place?: 'top' | 'bottom' | 'left' | 'right';
}

const Tooltip = (props: TooltipProps) => {
    return (
        <>
            {props.element}
            <ReactTooltip
                clickable={props.clickable}
                id={props.id}
                place={props.place}>
                {props.children}
            </ReactTooltip>
        </>
    );
};

export default Tooltip;
