import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { ProductAttribute } from '@frontend/product';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';
import { ProductAttributeAPIClient } from './api/client';
import { ProductAttributeBody, ProductAttributeQueryParams, ProductAttributeResponse } from './api/models';



interface ProductAttributeState {
    productAttributeList: ProductAttributeResponse | null;
    status: SliceStatus;
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: ProductAttributeState = {
    productAttributeList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    error: null
};

export const productAttributeSlice = createSlice({
    name: 'productAttributes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductAttributes.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductAttributes.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.productAttributeList === null) {
                    state.productAttributeList = { ...action.payload, results: new Array(action.payload.count) };
                } else {
                    state.productAttributeList = { ...action.payload, results: state.productAttributeList.results };
                }
                state.productAttributeList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProductAttributes.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(createProductAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(createProductAttribute.fulfilled, (state, action) => {
                const productAttribute = action.payload;
                if (state.productAttributeList === null) {
                    state.productAttributeList = { count: 1, next: null, previous: null, results: [productAttribute] };
                } else state.productAttributeList.results.push(productAttribute);
                if (state.error) {
                    state.error = null;
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(createProductAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateProductAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateProductAttribute.fulfilled, (state, action) => {
                const productAttribute = action.payload;
                const id = action.meta.arg.id;
                if (state.productAttributeList === null) {
                    state.productAttributeList = { count: 1, next: null, previous: null, results: [productAttribute] };
                }
                //const foundAttribute = state.productAttributeList.results.find((p) => p !== null && p !== undefined && p)
            })
            .addCase(updateProductAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(deleteProductAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteProductAttribute.fulfilled, (state, action) => {
                if (action.payload && state.productAttributeList) {
                    const productAttributeId = action.meta.arg;
                    state.productAttributeList.results = state.productAttributeList.results.filter((p) => p.id !== productAttributeId);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteProductAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchProductAttributes = createAsyncThunk<ProductAttributeResponse, ApiQueryParams<ProductAttributeQueryParams>>(
    'fetchProductAttributes',
    async (queryParams: ApiQueryParams<ProductAttributeQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductAttributeAPIClient.fetchProductAttributesApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const createProductAttribute = createAsyncThunk<ProductAttribute, ProductAttributeBody>(
    'createProductAttribute',
    async (body: ProductAttributeBody, { rejectWithValue }) => {
        try {
            return await ProductAttributeAPIClient.createProductAttributesApi(body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const updateProductAttribute = createAsyncThunk<ProductAttribute, { id: number; body: ProductAttributeBody }>(
    'updateProductAttribute',
    async (options: { id: number; body: ProductAttributeBody }, { rejectWithValue }) => {
        try {
            return await ProductAttributeAPIClient.updateProductAttributesApi(options.id, options.body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteProductAttribute = createAsyncThunk<boolean, number>('deleteProductAttribute', async (productId, { rejectWithValue }) => {
    try {
        const res = await ProductAttributeAPIClient.deleteProductAttributesApi(productId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete product attribute with id: ${productId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const productAttributeReducer =  productAttributeSlice.reducer;
