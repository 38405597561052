import { Placement, autoUpdate, offset, useFloating } from '@floating-ui/react-dom';
import React, { useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

import { ClassType } from '../../common/BootstrapValues';
import { onClickOutside } from '../modals/onClickOutside';

interface DropdownButtonProps {
    buttonType?: string;
    placement?: Placement;

    title: React.ReactNode;
    options: DropdownOptionProps[];
    badge?: React.ReactNode;
    hideDropdown?: boolean;
}

export default function DropdownButton(props: DropdownButtonProps) {
    const [visible, changeVisible] = useState<boolean>();
    const containerRef = useRef(null);
    onClickOutside(containerRef, () => changeVisible(false));

    const { refs, floatingStyles } = useFloating({
        placement: props.placement || 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware: [offset(10)]
    });

    const buttonClassName = 'btn btn-' + (props.buttonType ? props.buttonType : 'light');

    return (
        <div ref={containerRef}>
            <button
                type='button'
                className={buttonClassName}
                ref={refs.setReference}
                id='dropdownMenuButton'
                onClick={() => changeVisible(!visible)}>
                {props.title} {props.hideDropdown ? <></> : visible ? <FaChevronUp className='ms-1' /> : <FaChevronDown className='ms-1' />}
                {props.badge && props.badge}
            </button>
            {visible && (
                <ul
                    ref={refs.setFloating}
                    style={{ ...floatingStyles, zIndex: 1000 }}
                    className='list-group mt-1'>
                    {props.options.map((option, i) => (
                        <DropdownOption
                            key={i}
                            {...option}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}

interface DropdownOptionProps {
    disabled?: boolean;
    type?: ClassType;
    onClick?: () => void;
    icon?: IconType;
    title: React.ReactNode;
}

export function DropdownOption(props: DropdownOptionProps) {
    return (
        <a
            className={`list-group-item list-group-item-action ${props.disabled === true && 'disabled'} ${props.type}`}
            onClick={props.onClick}>
            <div className='d-flex flex-row align-items-center'>
                {props.icon && <props.icon />}
                <span className='ms-2'>{props.title}</span>
            </div>
        </a>
    );
}
