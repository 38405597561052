import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from '@frontend/api-utils';

import { ContactGroup } from '../contact-group';
import { ContactGroupOptions, ContactGroupQueryParams, ContactGroupResponse, CreateContactGroupModel } from './models';

const contactGroupsViewSet: ApiViewSet = {
    baseName: 'contact-groups'
};

export class ContactGroupAPIClient extends APIClient {
    public static async fetchContactGroupsApi(queryParams?: ApiQueryParams<ContactGroupQueryParams> | null): Promise<ContactGroupResponse> {
        return await this.apiPagination<ContactGroupResponse, ContactGroupQueryParams>(contactGroupsViewSet, queryParams);
    }

    public static async fetchContactGroupApi(options: DetailOptions): Promise<ContactGroup> {
        return this.apiDetail<ContactGroup>(contactGroupsViewSet, options);
    }

    public static async createContactGroupApi(contactGroup: CreateContactGroupModel): Promise<ContactGroup> {
        const addContactGroupUrl = '/contact-groups/';
        const response = await this.post(addContactGroupUrl, contactGroup);
        if (!response.ok) {
            let json;
            try {
                json = await response.json;
            } catch (e) {
                throw new ApiError('Error creating contact group');
            }
            throw new ApiError('Error creating contact group');
        }

        return await response.json();
    }

    public static async updateContactGroupApi(options: ContactGroupOptions): Promise<ContactGroup> {
        let updateContactGroupUrl = '/contact-groups/';
        if (options && options.id) {
            updateContactGroupUrl += options.id + '/';
        }
        const body = options.contactGroup;

        const response = await this.put(updateContactGroupUrl, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating contact group');
            }
            throw new ApiError('Error updating contact group');
        }
        return await response.json();
    }

    public static async deleteContactGroupApi(contactGroupId: number): Promise<boolean> {
        const URL = `/contact-groups/${contactGroupId}/`;
        const response = await this.delete(URL);
        return await response.ok;
    }

    public static async searchContactGroupsApi(queryParams?: ApiQueryParams<ContactGroupQueryParams>): Promise<ContactGroup[]> {
        return await this.apiList<ContactGroup, ContactGroupQueryParams>(contactGroupsViewSet, queryParams);
    }
}
