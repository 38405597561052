import { Contact, fetchContact, fetchContacts, searchContacts } from '@frontend/contact';
import { useEffect, useRef, useState } from 'react';

import { CommonMessage } from '../../../../common/CommonFormattedMessages/CommonMessage';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import AsyncSelectInput from '../AsyncSelectInput';
import { SelectInputProps } from '../Select';
import { GroupedContactsObj } from './ContactGroupSelect';

interface ContactSelectProps extends SelectInputProps {
    onChange?: (value: any) => void;
    isValid?: (value: boolean) => void;
}

const ContactSelect = (props: ContactSelectProps) => {
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const contacts = useAppSelector((state) => state.contacts);
    const [groupedContacts, changeGroupedContacts] = useState<GroupedContactsObj[]>([]);
    const [searchValue, changeSearchValue] = useState('');

    useEffect(() => {
        if (contacts.contactsList === null) {
            dispatch(fetchContacts({ account: accountId.toString(), page: '1', page_size: '100' }));
        }
    }, []);

    useEffect(() => {
        if (props.value !== null && props.value !== undefined && contacts.contactsList !== null) {
            const id = props.value && props.value.value ? props.value.value : props.value;
            const foundContact = contacts.contactsList?.results.find((c) => c !== null && c !== undefined && c.id == id);
            if (!foundContact) {
                dispatch(fetchContact({ contactId: props.value }));
            }
        }
    }, [props.value, contacts.contactsList]);

    useEffect(() => {
        if (contacts.contactsList && searchValue.length === 0) {
            const allContacts: GroupedContactsObj[] = [];
            if (contacts.contactsList)
                allContacts.push({
                    label: CommonMessage.OBJECTS.CONTACTS.CONTACT,
                    options: contacts.contactsList.results.map((c) => ({ value: c.id, label: c.first_name + ' ' + c.last_name, type: 'contact' }))
                });
            changeGroupedContacts(allContacts);
        } else if (contacts.searchContactsList && searchValue.length > 0 && contacts.searchContactsList && contacts.searchContactsList[searchValue]) {
            const allContacts: GroupedContactsObj[] = [];
            if (contacts.searchContactsList && contacts.searchContactsList[searchValue])
                allContacts.push({
                    label: CommonMessage.OBJECTS.CONTACTS.CONTACT,
                    options: contacts.searchContactsList[searchValue].results.map((c) => ({
                        value: c.id,
                        label: c.first_name + ' ' + c.last_name,
                        type: 'contact'
                    }))
                });
            changeGroupedContacts(allContacts);
        }
    }, [contacts.contactsList, searchValue, contacts.searchContactsList]);

    const timer = useRef<NodeJS.Timeout | null>(null);

    const loadOptions = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        const result: any[] = [];
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            dispatch(searchContacts({ search: inputValue, account: accountId.toString(), page: '1', page_size: '1000' })).then((res: any) => {
                if (res.payload) {
                    const foundContacts = res.payload.results.map((c: Contact) => ({ value: c.id, label: c.first_name + ' ' + c.last_name, type: 'contact' }));
                    result.push({ label: CommonMessage.OBJECTS.CONTACTS.CONTACT, options: foundContacts });
                }
            });
            callback(result);
        }, 500);
    };

    const url = contacts.contactsList?.next;

    return (
        <AsyncSelectInput
            required={props.required ? props.required : false}
            label={props.label ? props.label : CommonMessage.OBJECTS.CONTACTS.CONTACT}
            submitted={props.submitted}
            isClearable={props.isClearable}
            cacheOptions
            onChange={(value) => {
                props.onChange && props.onChange(value);
            }}
            value={props.value}
            loadOptions={loadOptions}
            errorMessage={props.errorMessage}
            defaultOptions={groupedContacts}
            onMenuScrollToBottom={() => {
                if (url) {
                    const contactsURL = new URLSearchParams(url);
                    const newPage = contactsURL.get('page');
                    const newPageSize = contactsURL.get('page_size');
                    dispatch(fetchContacts({ account: accountId.toString(), page: newPage, page_size: newPageSize }));
                    return true;
                } else return false;
            }}
            isValidCallback={(valid) => props.isValid && props.isValid(valid)}
        />
    );
};

export default ContactSelect;
