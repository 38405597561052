import { Logger } from '@frontend/Logger';
import { fetchContact, removeContactById } from '@frontend/contact';

import { PubSubEvent } from '../usePubSubEventListener';

export default class ContactEventHandler {
    static handle = (dispatch: ({ ...args }) => void, event: PubSubEvent): void => {
        if (event.content.old == null) {
            Logger.log(`Contact id:{${event.content.new.id}} created event received.`);
            dispatch(fetchContact({ contactId: event.content.new.id }));
            return;
        }
        if (event.content.new == null) {
            Logger.log(`Contact id:{${event.content.old.id}} deleted event received.`);
            dispatch(removeContactById(event.content.old.id));
            return;
        }
        Logger.log(`Contact id:{${event.content.new.id}} updated event received.`);
        dispatch(fetchContact({ contactId: event.content.new.id }));
    };
}
