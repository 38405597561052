import { Logger } from '@frontend/Logger';
import { Contact, CreateContactModel, fetchContact, updateContact } from '@frontend/contact';
import { ContactGroup } from '@frontend/contact-group';
import { ToastUtil } from '@frontend/toast-utils';
import { fetchUser } from '@frontend/user';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { Locale, NotificationChannel } from '../../../common/BackendEnums';
import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import DateInput from '../../../components/forms/dateInput/DateInput';
import EmailInput from '../../../components/forms/emailInput/EmailInput';
import PhoneInput from '../../../components/forms/phoneInput/PhoneInput';
import SelectInput from '../../../components/forms/select/Select';
import ContactRoleSelect from '../../../components/forms/select/contacts/contact-role/contact-role-select.component';
import UserSelect from '../../../components/forms/select/users/user-select.component';
import TextInput from '../../../components/forms/textInput/TextInput';
import Spinner from '../../../components/loading/Spinner';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';
import { GetError } from '../../../store/utils/GetError';
import { Languages } from '../../../utils/FormUtils';
import { FindChangedValue } from '../../../utils/Logs';
import { TitleValues } from '../create-contact-form/CreateContactForm';

const UPDATE_CONTACT_MODAL_ID = 'UpdateContactModal';

interface UpdateContactFormProps {
    groups: ContactGroup[];
    onCancel: () => void;
    contact: Contact | undefined;
}

const UpdateContactForm = (props: UpdateContactFormProps) => {
    const userState = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const contacts = useAppSelector((state) => state.contacts);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const { selectedUser } = useAccount();

    if (!props.contact) return <Spinner />;

    const handleCancel = () => {
        changeSubmitted(false);
        props.onCancel();
    };

    useEffect(() => {
        if (submitted && props.contact) {
            dispatch(updateContact({ contactId: props.contact.id, updateBody: UpdateContactModalFromUpdateContactFormResult(formResult) }))
                .then((res) => {
                    if (res.type.includes('fulfilled') && props.contact) {
                        const difference = FindChangedValue(props.contact, res.payload as Contact, undefined, ['pk', 'qr_code_access_keys']);
                        Logger.log(
                            `${selectedUser?.email} updated ${(res.payload as Contact).email}`,
                            { contact: (res.payload as Contact).id, user: selectedUser?.id },
                            difference
                        );
                        toast.success(
                            ...ToastUtil.generateToastConfig(
                                props.contact.first_name + ' ' + props.contact.last_name,
                                UpdateContactSuccessMessage,
                                ClassType.SUCCESS
                            )
                        );
                        handleCancel();
                        dispatch(fetchContact({ contactId: props.contact?.id }));
                    } else if (res.type.includes('rejected')) {
                        Logger.error(
                            `${selectedUser?.email} failed to update ${(res.payload as Contact).email}`,
                            { contact: (res.payload as Contact).id, user: selectedUser?.id },
                            contacts.error?.json
                        );
                        toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, GetError(contacts.error), ClassType.DANGER));
                        changeSubmitted(false);
                    }
                })
                .catch((err) => console.error(err));
        }
    }, [submitted]);

    const [formResult, changeFormResult] = useState<UpdateContactFormResult>({
        firstName: props.contact.first_name,
        lastName: props.contact.last_name,
        email: props.contact.email,
        mobile: props.contact.mobile,
        birthday: props.contact.birthday ? props.contact.birthday : undefined,
        title:
            props.contact.title === ''
                ? { value: '', label: '' }
                : { value: props.contact.title, label: TitleValues.find((t) => t.value === props.contact?.title)!.displayName },
        language:
            props.contact.language === ''
                ? { value: '', label: '' }
                : { value: props.contact.language, label: Languages.find((l) => l.value === props.contact?.language)!.displayName },
        notificationChannels: props.contact.notification_channels,
        groups: props.contact.contact_groups,
        user: null,
        accountId: props.contact.account,
        additional_data: props.contact.additional_data
    });

    useEffect(() => {
        if (formResult && props.contact && props.contact.user) {
            const found = userState.usersList?.results.find((u) => u.id === props.contact?.user);
            if (found == undefined) dispatch(fetchUser({ id: props.contact.user }));
            else changeFormResult({ ...formResult, user: { value: found.id, label: found.first_name } });
        }
    }, [userState.usersList, props.contact]);

    const [fnValid, changeFNValid] = useState<boolean>(false);
    const [lnValid, changeLNValid] = useState<boolean>(true);
    const [emValid, changeEMValid] = useState<boolean>(true);
    const [moValid, changeMOValid] = useState<boolean>(true);
    const [biValid, changeBIValid] = useState<boolean>(true);
    const [tiValid, changeTIValid] = useState<boolean>(true);
    const [laValid, changeLAValid] = useState<boolean>(true);
    const [ncValid, changeNCValid] = useState<boolean>(true);
    const [grValid, changeGRValid] = useState<boolean>(true);
    const [adValid, changeAdValid] = useState<boolean>(true);
    const formValid = fnValid && lnValid && emValid && moValid && biValid && tiValid && laValid && ncValid && grValid && adValid;

    return (
        <form
            id={UPDATE_CONTACT_MODAL_ID}
            onSubmit={(e) => {
                e.preventDefault();
                changeSubmitted(true);
            }}>
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextInput
                            id='UpdateContactForm-firstnameInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.firstnameInput.label'
                                    description='The label for the firstname input on the update contact form.'
                                    defaultMessage='Firstname'
                                />
                            }
                            value={formResult.firstName}
                            onChange={(value) => changeFormResult({ ...formResult, firstName: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.firstnameInput.errorMessage'
                                    description='The error message for the firstname input on the update contact form.'
                                    defaultMessage='Please enter a valid firstname'
                                />
                            }
                            required
                            isValidCallback={(valid) => {
                                changeFNValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextInput
                            id='UpdateContactForm-lastnameInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.lastnameInput.label'
                                    description='The label for the lastname input on the update contact form.'
                                    defaultMessage='Lastname'
                                />
                            }
                            value={formResult.lastName}
                            onChange={(value) => changeFormResult({ ...formResult, lastName: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.lastnameInput.errorMessage'
                                    description='The error message for the lastname input on the update contact form.'
                                    defaultMessage='Please enter a valid lastname'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeLNValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <EmailInput
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.emailInput.label'
                                    description='The label for the email input on the update contact form.'
                                    defaultMessage='Email'
                                />
                            }
                            value={formResult.email}
                            onChange={(value) => changeFormResult({ ...formResult, email: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.emailInput.errorMessage'
                                    description='The error message for the email input on the update contact form.'
                                    defaultMessage='Please enter a valid email.'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeEMValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <PhoneInput
                            id='UpdateContactForm-PhoneInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.mobileInput.label'
                                    description='The label for the mobile input on the update contact form.'
                                    defaultMessage='Mobile number'
                                />
                            }
                            value={formResult.mobile}
                            onChange={(value) => changeFormResult({ ...formResult, mobile: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.mobileInput.errorMessage'
                                    description='The error message for the mobile input on the update contact form.'
                                    defaultMessage='Please enter a valid (international) mobile number.'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeMOValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <DateInput
                            id='UpdateContactForm-birthdayInput'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.birthdayInput.label'
                                    description='The label for the birthday input on the update contact form.'
                                    defaultMessage='Birthday'
                                />
                            }
                            value={formResult.birthday}
                            onChange={(value) => changeFormResult({ ...formResult, birthday: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.birthdayInput.errorMessage'
                                    description='The error message for the birthday input on the update contact form.'
                                    defaultMessage='Please enter a valid birthday'
                                />
                            }
                            isValidCallback={(valid) => {
                                changeBIValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SelectInput
                            id='UpdateContactForm-titleSelect'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.titleInput.label'
                                    description='The label for the title input on the update contact form.'
                                    defaultMessage='Title'
                                />
                            }
                            value={formResult.title}
                            onChange={(value: any) => changeFormResult({ ...formResult, title: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.titleInput.errorMessage'
                                    description='The error message for the title input on the update contact form.'
                                    defaultMessage='Please select a title.'
                                />
                            }
                            options={TitleValues.map((t) => ({ value: t.value, label: t.displayName }))}
                            isValidCallback={(valid) => {
                                changeTIValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <SelectInput
                            id='UpdateContactForm-languageSelect'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.languageInput.label'
                                    description='The label for the language input on the update contact form.'
                                    defaultMessage='Language'
                                />
                            }
                            value={formResult.language}
                            onChange={(value: any) => changeFormResult({ ...formResult, language: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.languageInput.errorMessage'
                                    description='The error message for the language input on the update contact form.'
                                    defaultMessage='Please select a language.'
                                />
                            }
                            options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                            isValidCallback={(valid) => {
                                changeLAValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-md-6'>
                        <SelectInput
                            id='UpdateContactForm-notificationSelect'
                            isMulti
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.notificationChannelInput.label'
                                    description='The label for the notification channel input on the update contact form.'
                                    defaultMessage='Notification channel'
                                />
                            }
                            value={formResult.notificationChannels}
                            onChange={(value: any) => changeFormResult({ ...formResult, notificationChannels: value })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.notificationChannelInput.errorMessage'
                                    description='The error message for the notification channel input on the update contact form.'
                                    defaultMessage='Please select a channel.'
                                />
                            }
                            options={[
                                { value: NotificationChannel.EMAIL, label: NotificationChannel.EMAIL },
                                { value: NotificationChannel.SMS, label: NotificationChannel.SMS }
                            ]}
                            isValidCallback={(valid) => {
                                changeNCValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <SelectInput
                            id='UpdateContactForm-groupSelect'
                            isMulti
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.groupInput.label'
                                    description='The label for the group input on the update contact form.'
                                    defaultMessage='Group'
                                />
                            }
                            value={formResult.groups}
                            onChange={(value: any) => changeFormResult({ ...formResult, groups: value.map((g: any) => parseInt(g)) })}
                            errorMessage={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.groupInput.errorMessage'
                                    description='The error message for the group input on the update contact form.'
                                    defaultMessage='Please select a valid group.'
                                />
                            }
                            options={props.groups.map((g) => ({ value: g.id, label: g.name }))}
                            isValidCallback={(valid) => {
                                changeGRValid(valid);
                            }}
                            submitted={submitted}
                        />
                    </div>
                    <div className='col-6'>
                        <UserSelect
                            id='UpdateContactForm-userSelect'
                            label={
                                <FormattedMessage
                                    id='contacts.forms.updatecontact.userInput.label'
                                    description='The label for the user input on the update contact form.'
                                    defaultMessage='User'
                                />
                            }
                            submitted={submitted}
                            onChange={(value: any) => changeFormResult({ ...formResult, user: value })}
                            value={formResult.user}
                            isClearable
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <ContactRoleSelect
                            submitted
                            onChange={(v: any) => changeFormResult({ ...formResult, additional_data: { ...formResult.additional_data, role: v.value } })}
                            value={formResult.additional_data?.role}
                        />
                    </div>
                </div>
            </div>
            <CreateModalFooter
                onSubmit={() => undefined}
                handleClose={() => handleCancel()}
                disabled={!formValid || submitted}></CreateModalFooter>
        </form>
    );
};

export interface UpdateContactFormResult {
    firstName: string;
    lastName?: string;
    email: string;
    mobile?: string;
    birthday?: Date | undefined;
    title: { value: string; label: React.ReactNode };
    language: { value: string; label: string };
    notificationChannels: string[];
    groups?: number[];
    user: { value: number; label: string | undefined } | null;
    accountId: number;
    additional_data: { [key: string]: any };
}

const UpdateContactSuccessMessage = (
    <FormattedMessage
        id='UpdateContactForm.UpdateContact.Success'
        description={'Message when successfully updating a contact'}
        defaultMessage='Contact successfully updated'
    />
);

const UpdateContactErrorMessage = (
    <FormattedMessage
        id='UpdateContactForm.UpdateContact.Error'
        description={'Message when something went wrong while updating a contact'}
        defaultMessage='Something went wrong while trying to update this contact'
    />
);

export const UpdateContactModalFromUpdateContactFormResult = (result: UpdateContactFormResult): CreateContactModel => {
    return {
        first_name: result.firstName,
        last_name: result.lastName,
        title: result.title.value,
        mobile: result.mobile,
        email: result.email,
        birthday: result.birthday ? new Date(result.birthday).toISOString().split('T')[0] : null,
        notification_channels: result.notificationChannels as NotificationChannel[],
        language: result.language ? (result.language.value as Locale) : undefined,
        account: result.accountId,
        user: result.user ? result.user.value : null,
        contact_groups: result.groups?.length !== 0 ? (result.groups as []) : [],
        additional_data: result.additional_data
    };
};

export default UpdateContactForm;
