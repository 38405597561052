import { Locale } from '../common/BackendEnums';

enum InputClass {
    normal = 'form-control',
    success = 'form-control is-valid',
    error = 'form-control is-invalid'
}

export function getInputClass(touched: boolean, valid: boolean, value?: string): string {
    const emptyValue = value === '' || value === undefined || value === null;
    if (touched === false) {
        return InputClass.normal;
    }
    if (valid === false) {
        return InputClass.error;
    }
    if (emptyValue) {
        return InputClass.normal;
    }
    return InputClass.success;
}

export function getInputClassMultiSelect(touched: boolean, valid: boolean, values?: string[]): string {
    if (values == undefined || values.length === 0) return getInputClass(touched, valid);
    return getInputClass(touched, valid, values[0]);
}

export const Languages: { value: string; displayName: string }[] = [
    {
        value: Locale.ENGLISH,
        displayName: 'English'
    },
    {
        value: Locale.DUTCH,
        displayName: 'Nederlands'
    },
    {
        value: Locale.FRENCH,
        displayName: 'Français'
    }
];

export function validJSON(data: string | undefined) {
    if (!data) return false;
    try {
        JSON.parse(data);
        return true;
    } catch (e) {
        return false;
    }
}
