export enum IndexedDbStores {
    LOGS = 'logs'
}

const DB_NAME = 'main';
const DB_VERSION = 6;
const request = indexedDB?.open(DB_NAME, DB_VERSION);
let indexedDbConnection: IDBDatabase | undefined = undefined;

request.onerror = (ev) => {
    console.error(ev);
    console.error('Failed connection to indexed db');
};

request.onsuccess = () => {
    indexedDbConnection = request.result;
};

request.onupgradeneeded = (event: any) => {
    const db = event.target.result;
    if (!event.target.result.objectStoreNames.contains(IndexedDbStores.LOGS)) {
        db.createObjectStore(IndexedDbStores.LOGS, { keyPath: 'id', autoIncrement: true });
    }
};

export class IndexedDbConnection {
    static async getConnection(): Promise<IDBDatabase> {
        return new Promise((resolve) => {
            if (indexedDbConnection === undefined) {
                setTimeout(() => this.getConnection().then(resolve), 1000);
            } else {
                resolve(indexedDbConnection);
            }
        });
    }

    static async getStoreConnection(
        storeName: string,
        mode?: IDBTransactionMode | undefined,
        options?: IDBTransactionOptions | undefined
    ): Promise<IDBObjectStore> {
        return new Promise((resolve) => {
            if (indexedDbConnection === undefined) {
                setTimeout(() => this.getStoreConnection(storeName, mode, options).then(resolve), 1000);
            } else {
                resolve(indexedDbConnection.transaction(storeName, mode, options).objectStore(storeName));
            }
        });
    }
}
