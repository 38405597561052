import { ClassType } from '@frontend/common';
import { Transaction } from '@frontend/transaction';
import { GrClose } from 'react-icons/gr';
import { IoArrowRedo } from 'react-icons/io5';
import { TbExchange } from 'react-icons/tb';
import { FormattedMessage } from 'react-intl';

import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import MoveTransactionForm from '../../forms/move-transaction-form/MoveTransactionForm';
import TransactionStatusUpdate from '../../status-updates/TransactionStatusUpdate';
import TransactionDetail from '../transactionDetail';
import useTransactionDetailModal from './transaction-detail-modal.controller';

export const ID = 'transaction-detail-modal';

export interface Props extends ModalProps {
    transaction: Transaction;
}

const TransactionDetailModal = (props: Props): JSX.Element => {
    const viewProps = useTransactionDetailModal(props);

    return (
        <Modal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.transaction.id}</h3>
                <button
                    id='TransactionDetailModal-close'
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>

            <div className='modal-body'>
                <TransactionDetail transaction={props.transaction} />
            </div>

            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            id: 'TransactionDetail-ChangeStatusButton',
                            type: ClassType.WARNING,
                            hide: !viewProps.canUpdateStatus,
                            text: (
                                <FormattedMessage
                                    id='TransactionList.ChangeStatus.button'
                                    description='Change status button on transaction list page.'
                                    defaultMessage='Change status'
                                />
                            ),
                            icon: TbExchange,
                            onClick: () => viewProps.updateModal.change(true)
                        },
                        {
                            type: ClassType.SECONDARY,
                            hide: !viewProps.canMove,
                            text: (
                                <FormattedMessage
                                    id='TransactionDetailModal.Move.Button'
                                    description={'Text shown in the move button in an transaction detail modal'}
                                    defaultMessage='Move'
                                />
                            ),
                            icon: IoArrowRedo,
                            onClick: () => viewProps.moveModal.change(true)
                        }
                    ]}
                    direction='left'
                />
            </div>
            {viewProps.canUpdateStatus && viewProps.updateModal.value && (
                <TransactionStatusUpdate
                    show={viewProps.updateModal.value}
                    handleClose={() => viewProps.updateModal.change(false)}
                    transactions={[props.transaction!]}
                />
            )}
            {viewProps.canMove && viewProps.moveModal.value && (
                <MoveTransactionForm
                    show={viewProps.moveModal.value}
                    handleClose={() => viewProps.moveModal.change(false)}
                    callback={() => undefined}
                    transaction={props.transaction!}
                />
            )}
        </Modal>
    );
};

export default TransactionDetailModal;
