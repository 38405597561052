import React, { useRef } from 'react';

import { onClickOutside } from './onClickOutside';

export interface ModalProps {
    id?: string;
    handleClose: () => void;
    show: boolean;
    children?: React.ReactNode;
    type?: ModalType;
    customWidth?: number;
}

const Modal = (props: ModalProps) => {
    const modalRef = useRef(null);
    onClickOutside(modalRef, props.show ? props.handleClose : () => undefined);

    const showHideClassName = props.show ? 'modal display-block' : 'modal display-none';
    const modalType = props.type ? 'modal-' + props.type.valueOf() : 'modal-prompt';
    const customWidth = props.customWidth !== undefined ? { width: props.customWidth + '%' } : {};

    if (!props.show) {
        return <></>;
    }

    return (
        <div
            className={showHideClassName}
            id={props.id}>
            <div
                className={modalType}
                style={customWidth}
                ref={modalRef}>
                {props.children}
            </div>
        </div>
    );
};

export enum ModalType {
    PROMPT = 'prompt',
    PANE = 'pane'
}

export default Modal;
