import { Logger } from '@frontend/Logger';
import { SliceStatus } from '@frontend/common';
import { Contact, deleteContact } from '@frontend/contact';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import HorizontalButtonGroup from '../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../components/loading/Spinner';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import CreateModal from '../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import UpdateContactForm from '../forms/update-contact-form/UpdateContactForm';
import ContactDetail from './ContactDetail';

const CONTACT_DETAIL_MODAL_ID = 'ContactDetailModal';

interface ContactDetailModalProps extends ModalProps {
    contact?: Contact;
}

export default function ContactDetailModal(props: ContactDetailModalProps) {
    const dispatch = useAppDispatch();
    const contactGroups = useAppSelector((state) => state.contactGroups);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const contactState = useAppSelector((state) => state.contacts);
    const [isDeleted, changeIsDeleted] = useState<boolean>(false);
    const { selectedUser } = useAccount();
    if (!props.contact) return <Spinner />;

    const canUpdate = props.contact !== undefined;
    const canDelete = props.contact !== undefined;

    const onDeleteContact = () => {
        dispatch(deleteContact(props.contact!.id));
        changeShowDeleteModal(false);
        changeIsDeleted(true);
    };

    useEffect(() => {
        if (isDeleted) {
            if (contactState.status === SliceStatus.IDLE) {
                toast.success(...ToastUtil.generateToastConfig(props.contact?.first_name, deleteContactSuccessMessage, ClassType.SUCCESS));
                Logger.log(
                    `${selectedUser?.email} deleted contact: ${props.contact?.first_name}`,
                    { contact: props.contact?.id, user: selectedUser?.id },
                    props.contact?.id
                );
                props.handleClose();
            } else if (contactState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, deleteContactErrorMessage, ClassType.DANGER));
                Logger.error(
                    `${selectedUser?.email} failed to delete contact: ${props.contact?.first_name}`,
                    { contact: props.contact?.id, user: selectedUser?.id },
                    props.contact?.id
                );
                props.handleClose();
            }
        }
    }, [contactState]);

    return (
        <Modal
            id={CONTACT_DETAIL_MODAL_ID}
            type={ModalType.PANE}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{`${props.contact?.first_name} ${props.contact?.last_name}`}</h3>
                <button
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>

            <div className='modal-body'>
                <ContactDetail contact={props.contact} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            id: 'ContactDetailModal-UpdateButton',
                            type: ClassType.WARNING,
                            hide: false,
                            text: (
                                <FormattedMessage
                                    id='ContactDetailModal.Update.Contact'
                                    description='Label for updating a contact'
                                    defaultMessage='Update contact'
                                />
                            ),
                            icon: FiEdit2,
                            onClick: () => changeShowUpdateModal(true)
                        },
                        {
                            id: 'ContactDetailModal-Delete',
                            type: ClassType.DANGER,
                            hide: false,
                            text: (
                                <FormattedMessage
                                    id='UserDetail.options.delete'
                                    description='Label for deleting a user'
                                    defaultMessage='Delete'
                                />
                            ),
                            icon: IoMdTrash,
                            onClick: () => changeShowDeleteModal(true)
                        }
                    ]}
                    direction='left'
                />
            </div>

            {canUpdate && (
                <CreateModal
                    show={showUpdateModal}
                    handleClose={() => changeShowUpdateModal(false)}
                    header={'Update contact'}>
                    <UpdateContactForm
                        groups={contactGroups.contactGroupsList ? contactGroups.contactGroupsList.results : []}
                        contact={props.contact}
                        onCancel={() => {
                            changeShowUpdateModal(false);
                            props.handleClose();
                        }}
                    />
                </CreateModal>
            )}

            {canDelete && (
                <ConfirmationModal
                    show={showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => changeShowDeleteModal(false)}
                    message={
                        <FormattedMessage
                            id='deleteContact.ConfirmMessage'
                            description={'The confirmatiom message checking if the user wants to delete the contact'}
                            defaultMessage={'Are you sure you want to delete this contact'}
                        />
                    }
                    onConfirm={onDeleteContact}
                />
            )}
        </Modal>
    );
}

const deleteContactSuccessMessage = (
    <FormattedMessage
        id='deleteContact.Success.Message'
        description={'Message displayed when successfully deleting a contact'}
        defaultMessage='This contact was successfully deleted'
    />
);

const deleteContactErrorMessage = (
    <FormattedMessage
        id='deleteContact.Error.Message'
        description={'Message displayed something went wrong while deleting a contact'}
        defaultMessage='Something went wrong while deleting this contact'
    />
);
