import { ClassType } from '@frontend/common';
import { UserInfo } from '@frontend/user';
import { FiEdit2 } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CreateModal from '../../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import UpdateUser from '../../forms/UpdateUser';
import UserDetail from '../UserDetail';
import useUserDetailModal from './user-detail-modal.controller';

const USER_DETAIL_MODAL_ID = 'UserDetailModal';

export interface UserDetailModalProps extends ModalProps {
    user: UserInfo;
}

export default function UserDetailModal(props: UserDetailModalProps) {
    const viewProps = useUserDetailModal(props);

    return (
        <Modal
            id={USER_DETAIL_MODAL_ID}
            type={ModalType.PANE}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.user?.username}</h3>
                <button
                    id='UserDetailModal-CloseButton'
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>

            <div className='modal-body'>
                <UserDetail user={props.user} />
            </div>

            <div className='modal-footer d-flex flex-row justify-content-between align-items-center flex-end'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            type: ClassType.WARNING,
                            onClick: () => viewProps.changeShowUpdateModal(true),
                            icon: FiEdit2,
                            hide: false,
                            id: 'UserDetailModal-UpdateUserButon',
                            text: (
                                <FormattedMessage
                                    id='UserDetail.options.updateUser'
                                    description='Label for updating user'
                                    defaultMessage='Update User'
                                />
                            )
                        },
                        {
                            onClick: () => viewProps.changeShowDeleteModal(true),
                            icon: IoMdTrash,
                            type: ClassType.DANGER,
                            hide: false,
                            id: 'UserDetailModal-DeleteUserButton',
                            text: (
                                <FormattedMessage
                                    id='UserDetail.options.delete'
                                    description='Label for deleting a user'
                                    defaultMessage='Delete'
                                />
                            )
                        }
                    ]}
                    direction='left'
                />
            </div>

            {viewProps.canUpdate && (
                <CreateModal
                    show={viewProps.showUpdateModal}
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    header='Update User'>
                    <UpdateUser
                        handleClose={() => viewProps.changeShowUpdateModal(false)}
                        user={props.user!}
                    />
                </CreateModal>
            )}
            {viewProps.canDelete && (
                <ConfirmationModal
                    show={viewProps.showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={
                        <FormattedMessage
                            id='deleteUser.ConfirmMessage'
                            description='The confirmation message checking if the user is sure about removing a user'
                            defaultMessage='Are you sure you want to delete this user? This action cannot be undone.'
                        />
                    }
                    onConfirm={viewProps.onDeleteUser}
                />
            )}
        </Modal>
    );
}
