import { useAppSelector } from 'apps/platform/src/app/hooks/redux';
import useAccount from 'apps/platform/src/app/hooks/useAccount';
import { useMemo } from 'react';

import { SelectInputProps } from '../../Select';

interface ViewProps {
    options: {
        value: string;
        label: string;
    }[];
    value:
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useContactRoleSelect = (props: SelectInputProps): ViewProps => {
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const { selectedUser } = useAccount();

    const options = useMemo(() => {
        if (selectedUser) {
            const found = selectedUser.account_members.find((a) => a.account.id === accountId);
            if (!found) return [];
            else return found.account.contact_roles.map((c) => ({ value: c, label: c }));
        } else return [];
    }, [selectedUser]);

    const value = useMemo(() => {
        if (props.value) {
            return options.find((o) => o.value == props.value);
        }
    }, [props.value, options]);

    return {
        options,
        value
    };
};

export default useContactRoleSelect;
