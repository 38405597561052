import { ClassType } from '@frontend/common';
import { ContactGroup } from '@frontend/contact-group';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CreateModal from '../../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import ModalHeader from '../../../components/modals/ModalHeader';
import UpdateContactGroupForm from '../../forms/UpdateContactGroupForm';
import ContactGroupDetail from '../contact-group-detail.component';
import useContactGroupDetailModal from './contact-group-detail-modal.controller';

export const ID = 'contact-group-detail-modal';
export interface ContactGroupDetailModalProps extends ModalProps {
    contactGroup: ContactGroup;
}

const ContactGroupDetailModal = (props: ContactGroupDetailModalProps): JSX.Element => {
    const viewProps = useContactGroupDetailModal(props);
    return (
        <Modal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id='ContactGroupDetailModal-close'
                title={props.contactGroup.name}
                handleClose={props.handleClose}
            />
            <div className='modal-body'>
                <ContactGroupDetail contactGroup={props.contactGroup} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            type: ClassType.WARNING,
                            hide: false,
                            text: (
                                <FormattedMessage
                                    id='ContactGroupDetailModal.Button.UpdateContactGroup'
                                    description='Button for updating the contact group'
                                    defaultMessage={`Update {name}`}
                                    values={{ name: props.contactGroup?.group_type }}
                                />
                            ),
                            icon: FiEdit2,
                            onClick: () => viewProps.changeShowUpdateModal(true),
                            id: 'ContactGroupDetailModal.UpdateButton'
                        },
                        {
                            type: ClassType.DANGER,
                            hide: false,
                            id: 'ContactGroupDetailModal.DeleteButton',
                            text: (
                                <FormattedMessage
                                    id='UserDetail.options.delete'
                                    description='Label for deleting a user'
                                    defaultMessage='Delete'
                                />
                            ),
                            icon: IoMdTrash,
                            onClick: () => viewProps.changeShowDeleteModal(true)
                        }
                    ]}
                    direction='left'
                />
            </div>

            {viewProps.canUpdate && (
                <CreateModal
                    show={viewProps.showUpdateModal}
                    id='UpdateContactGroupModal'
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    header={'Update contact group'}>
                    <UpdateContactGroupForm
                        contactGroup={props.contactGroup}
                        onCancel={() => {
                            viewProps.changeShowUpdateModal(false);
                            props.handleClose();
                        }}
                    />
                </CreateModal>
            )}

            {viewProps.canDelete && (
                <ConfirmationModal
                    show={viewProps.showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={
                        <FormattedMessage
                            id='deleteContactGroup.ConfirmMessage'
                            description={'The confirmatiom message checking if the user wants to delete the contact group'}
                            defaultMessage={'Are you sure you want to delete this contact group'}
                        />
                    }
                    onConfirm={viewProps.onDeleteContactGroup}
                />
            )}
        </Modal>
    );
};

export default ContactGroupDetailModal;
