import { Contact } from '@frontend/contact';

import CheckBox from '../forms/check/CheckBox';
import SelectInput from '../forms/select/Select';
import TextInput from '../forms/textInput/TextInput';
import useAccessCode from './access-code.controller';

export interface AccessCodeProps {
    submitted: boolean;
    createdContact?: Contact;
    callback?: () => void;
}

const AccessCode = (props: AccessCodeProps) => {
    const viewProps = useAccessCode(props);
    return (
        <>
            <div className='col-4 mt-4'>
                <CheckBox
                    checked={viewProps.shouldCreateAccessCode}
                    submitted={props.submitted}
                    label='Create access code?'
                    onChange={(v) => {
                        viewProps.changeShouldCreateAccessCode(v);
                        if (v === false) {
                            viewProps.changeAccessCode({});
                        }
                    }}
                />
            </div>
            {viewProps.shouldCreateAccessCode && (
                <>
                    <div className='col-4'>
                        <SelectInput
                            label='Access Code'
                            submitted={props.submitted}
                            options={[
                                { value: 'static', label: 'STATIC' },
                                { value: 'dynamic', label: 'DYNAMIC' }
                            ]}
                            onChange={(v: any) => {
                                viewProps.changeAccessCode({ ...viewProps.accessCode, type: v.value });
                            }}
                            value={viewProps.accessCode.type}
                        />
                    </div>
                </>
            )}

            {viewProps.accessCode.type && (
                <div className='row'>
                    <div className='col-12'>
                        {viewProps.accessCode.type === 'static' ? (
                            <TextInput
                                submitted={props.submitted}
                                label='Access code value'
                                onChange={(v) => viewProps.changeAccessCode({ ...viewProps.accessCode, value: v })}
                                value={viewProps.accessCode.value || ''}
                            />
                        ) : (
                            <TextInput
                                submitted={props.submitted}
                                label='Expiration time'
                                onChange={(v) => viewProps.changeAccessCode({ ...viewProps.accessCode, expiration_time: v })}
                                value={viewProps.accessCode.expiration_time || ''}
                                isTime
                                placeholder='hh:mm:ss'
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default AccessCode;
