import { Category, addCategory, fetchAllCategories, fetchCategories } from '@frontend/category';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import ImageInput from '../../../components/forms/imageInput/ImageInput';
import SelectInput from '../../../components/forms/select/Select';
import TextInput from '../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getAllChildren } from '../../../utils/GetAllChildren';

const CreateCategory = (props: { handleClose: () => void }) => {
    const dispatch = useAppDispatch();
    const categoryState = useAppSelector((state) => state.categories);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formBody, changeFormBody] = useState<FormData>();
    const [allCategories, changeAllCategories] = useState<Category[]>();

    useEffect(() => {
        if (categoryState.allCategories && categoryState.categoryList) {
            changeAllCategories(categoryState.allCategories.results);
        }
    }, [categoryState.allCategories]);

    const initialState: CreateCategoryModel = {
        name: '',
        account: accountId!,
        image: null,
        parent: null
    };
    const [formResult, changeFormResult] = useState(initialState);

    useEffect(() => {
        const formData = new FormData();
        formData.append('account', formResult.account.toString());
        formData.append('name', formResult.name);
        formResult.parent && formData.append('parent', formResult.parent.value);
        formResult.image && formData.append('image', formResult.image);

        changeFormBody(formData);
    }, [formResult]);

    const [nameValid, changeNameValid] = useState<boolean>(false);
    const formValid = nameValid;

    useEffect(() => {
        if (categoryState.categoryList) {
            const foundCategory = getAllChildren(categoryState.categoryList).find((c) => c.name.toLowerCase() === formResult.name.toLowerCase());
            if (!foundCategory && submitted && formValid && formBody) {
                dispatch(addCategory(formBody));
            }
        } else {
            changeSubmitted(false);
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted && categoryState.categoryList) {
            const createdCategory = getAllChildren(categoryState.categoryList).find((c) => c.name.toLowerCase() === formResult.name.toLowerCase());
            if (createdCategory) {
                toast.success(...ToastUtil.generateToastConfig(formResult.name, categoryCreatedSuccess, ClassType.SUCCESS));
                dispatch(fetchCategories({ account: accountId?.toString() }));
                dispatch(fetchAllCategories({ account: accountId?.toString(), page: '1', page_size: '1000' }));
                props.handleClose();
                changeFormResult(initialState);
            } else {
                changeSubmitted(false);
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, categoryCreatedError, ClassType.DANGER));
            }
        }
    }, [categoryState.categoryList]);

    return (
        <>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <div
                    className='modal-body'
                    style={{ overflowY: 'unset' }}>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateCategory.Label.Name'
                                        description='Label for selecting name'
                                        defaultMessage='Name'
                                    />
                                }
                                submitted={submitted}
                                required
                                id='CreateCategory.NameInput'
                                placeholder='Enter a name'
                                value={formResult.name}
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateCategory.Name.ErrorMessage'
                                        description='Error message for name on create category'
                                        defaultMessage='Enter a valid name'
                                    />
                                }
                                isValidCallback={(value) => changeNameValid(value)}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateCategory.Label.Parent'
                                        description='Label for selecting parent'
                                        defaultMessage='Parent'
                                    />
                                }
                                id='CreateCategory.ParentInput'
                                submitted={submitted}
                                value={formResult.parent}
                                onChange={(value: any) => changeFormResult({ ...formResult, parent: value })}
                                options={allCategories ? allCategories.map((c) => ({ value: c.url, label: c.name })) : []}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ImageInput
                                label={
                                    <FormattedMessage
                                        id='CreateCategory.Label.Image'
                                        description='Label for selecting image'
                                        defaultMessage='Image'
                                    />
                                }
                                id='CreateCategory.ImageInput'
                                submitted={submitted}
                                onChange={(value) => changeFormResult({ ...formResult, image: value })}
                                value={formResult.image}
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    onSubmit={() => changeSubmitted(true)}
                    handleClose={() => props.handleClose()}
                    disabled={submitted || !formValid}
                />
            </form>
        </>
    );
};

export default CreateCategory;

export interface CreateCategoryModel {
    name: string;
    parent: { value: string; label: string } | null;
    image: File | string | null;
    account: number;
}

const categoryCreatedSuccess = (
    <FormattedMessage
        id='CreateCategory.Created.Category.Success'
        description='Message when category was created successfully'
        defaultMessage='Category was created successfully'
    />
);

const categoryCreatedError = (
    <FormattedMessage
        id='CreateCategory.Create.Category.Error'
        description='Message when failing to create category'
        defaultMessage='There was an error creating your category'
    />
);
