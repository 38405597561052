import { ModalProps } from '../../../components/modals/Modal';
import SpinnerModal from '../../../components/modals/SpinnerModal';
import UserDetailModal from '../user-detail-modal-wrapper/user-detail-modal.component';
import useUserDetailModalWrapper from './user-detail-modal-wrapper.controller';

export interface UserDetailModalWrapperProps extends ModalProps {
    id: string;
}

const UserDetailModalWrapper = (props: UserDetailModalWrapperProps): JSX.Element => {
    const viewProps = useUserDetailModalWrapper(props);

    if (!viewProps.user) {
        return <SpinnerModal {...props} />;
    }
    return (
        <UserDetailModal
            user={viewProps.user}
            {...props}
            customWidth={50}
        />
    );
};
export default UserDetailModalWrapper;
