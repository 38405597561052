import { useEffect } from 'react';

export const onClickOutside = (ref: any, callback: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (ref?.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [callback, ref]);
};
