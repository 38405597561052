import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';

import { TransactionEvent, isGroupChangeEvent } from '../../../common/TransactionEvents';
import TimeLineItemTextField from './TimeLineItemTextField';

export default function GroupChangeEvent(props: { event: TransactionEvent }) {
    if (!isGroupChangeEvent(props.event.event_data)) return <></>;
    const icon = props.event.event_data.message.includes('removed') ? (
        <IoRemoveCircle className='text-warning display-1' />
    ) : (
        <IoAddCircle className='text-success display-1' />
    );
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>{icon}</span>
            <TimeLineItemTextField
                title={props.event.event_data.message}
                date={props.event.event_date}
            />
        </div>
    );
}
