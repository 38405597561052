type NodeType<T> = T & { children?: NodeType<T>[] | null };

export function getAllChildren<T>(arr: NodeType<T>[]): NodeType<T>[] {
    const resultMap = new Map<any, NodeType<T>>();

    function addNodeToResult(node: NodeType<T>) {
        resultMap.set(node, node);
        if (node.children) {
            node.children.forEach((child) => {
                if (!resultMap.has(child)) {
                    addNodeToResult(child);
                }
            });
        }
    }

    arr.forEach((item) => {
        if (!resultMap.has(item)) {
            addNodeToResult(item);
        }
    });

    return Array.from(resultMap.values());
}
