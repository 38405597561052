import Spinner from '../../../components/loading/Spinner';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import TransactionDetailModal from '../transaction-detail-modal/transaction-detail-modal.component';
import useTransactionDetailModalWrapper from './transaction-detail-modal-wrapper.controller';

export const ID = 'transaction-detail-modal-wrapper';

export interface Props extends ModalProps {
    id: string;
}

const TransactionDetailModalWrapper = (props: Props): JSX.Element => {
    const viewProps = useTransactionDetailModalWrapper(props);

    if (viewProps.transaction == undefined) {
        return (
            <Modal
                {...props}
                customWidth={60}
                type={ModalType.PANE}>
                <div className='d-flex align-items-center justify-content-center h-75'>
                    <Spinner />
                </div>
            </Modal>
        );
    }
    return (
        <TransactionDetailModal
            transaction={viewProps.transaction}
            {...props}
            customWidth={60}
        />
    );
};

export default TransactionDetailModalWrapper;
