import { ModalProps } from '../../../components/modals/Modal';
import SpinnerModal from '../../../components/modals/SpinnerModal';
import SlotDetailModal from '../slot-detail-modal/slot-detail-modal.component';
import useSlotDetailModalWrapper from './slot-detail-modal-wrapper.controller';

export const ID = 'slot-detail-modal-wrapper';

export interface SlotDetailModalWrapperProps extends ModalProps {
    id: string;
}

const SlotDetailModalWrapper = (props: SlotDetailModalWrapperProps): JSX.Element => {
    const viewProps = useSlotDetailModalWrapper(props);

    if (viewProps.slot == undefined) {
        return <SpinnerModal {...props} />;
    }
    return (
        <SlotDetailModal
            slot={viewProps.slot}
            {...props}
            customWidth={50}
        />
    );
};

export default SlotDetailModalWrapper;
