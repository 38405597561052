import { TransactionType } from '../../common/BackendEnums';

const TransactionTypeBadge = (props: { type: TransactionType }) => {
    const color = () => {
        switch (props.type) {
            case TransactionType.PUDO:
                return 'success';
            case TransactionType.VENDING:
                return 'warning';
            case TransactionType.RETURN:
                return 'danger';
            case TransactionType.LENDING:
                return 'info';
        }
    };

    return (
        <span className={`badge bg-gradient-${color()}`}>
            <div className='d-flex flex-row align-items-center'>{props.type}</div>
        </span>
    );
};
export default TransactionTypeBadge;
