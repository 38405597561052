import { UserInfo } from '@frontend/user';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

const UserListColumns: Column<UserInfo>[] = [
    {
        Header: 'username',
        accessor: 'username'
    },
    {
        id: 'email',
        Header: (
            <FormattedMessage
                id='UserListColumn.Table.Title.Email'
                description='Table title for email'
                defaultMessage='Email'
            />
        ),
        accessor: 'email'
    },
    {
        id: 'firstName',
        Header: (
            <FormattedMessage
                id='UserListColumn.Table.Title.firstName'
                description='Table title for first name'
                defaultMessage='First Name'
            />
        ),
        accessor: 'first_name'
    },
    {
        id: 'lastName',
        Header: (
            <FormattedMessage
                id='UserListColumn.Table.Title.lastName'
                description='Table title for last name'
                defaultMessage='Last Name'
            />
        ),
        accessor: 'username'
    },
    {
        id: 'language',
        Header: (
            <FormattedMessage
                id='UserListColumn.Table.Title.Language'
                description='Table title for is language'
                defaultMessage='language'
            />
        )
    },
    {
        id: 'Phone number',
        Header: (
            <FormattedMessage
                id='UserListColumn.Table.Title.Mobile'
                description={'Table title for mobile number'}
                defaultMessage='Mobile number'
            />
        )
    }
];

export default UserListColumns;
