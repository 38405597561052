import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from '@frontend/api-utils';

export interface Notifications {
    id: number;
    url: string;
    identifier: NotificationScaleError;
    value: NotificationValue;
    created_at: string;
}

export enum NotificationScaleError {
    TLP_SCALE_COUNT_MISMATCH = 'tlp_scale_count_mismatch',
    TLP_SCALE_COUNT_MISMATCH_RESOLVED = 'tlp_scale_count_mismatch_resolved'
}

export interface NotificationValue {
    spot_id: number;
    spot_name: string;
    slot_id: number;
    slot_nr: string;
}

export enum NotificationQueryParams {
    ACCOUNT = 'account'
}

const notificationViewset: ApiViewSet = {
    baseName: 'push-notifications'
};

export default class NotificationAPIClient extends APIClient {
    public static async fetchNotificationsApi(queryParams?: ApiQueryParams<NotificationQueryParams>): Promise<Notifications[]> {
        return this.apiList(notificationViewset, queryParams);
    }

    public static async fetchNotificationApi(options: DetailOptions): Promise<Notifications> {
        return this.apiDetail(notificationViewset, options);
    }

    public static async deleteNotification(id: string | number): Promise<boolean> {
        const res = await this.delete(`/${notificationViewset.baseName}/${id}/`);
        return res.ok;
    }

    public static async deleteAllNotifications(): Promise<string> {
        const res = await this.fetch(`/${notificationViewset.baseName}/delete_all/`);
        if (!res.ok) {
            let json;
            try {
                json = await res.json();
            } catch (e) {
                throw new ApiError(`Error fetching ${res.url}`);
            }
            throw new ApiError(`Error fetching ${res.url}`, json);
        }
        return res.json();
    }
}
