import { useState } from 'react';

import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import CheckBox, { CheckBoxProps } from './CheckBox';

interface MultiCheckboxProps {
    checkboxes: CheckBoxProps[];
    title?: React.ReactNode;
    errorMessage?: React.ReactNode;
    required?: boolean;
    valid?: boolean;
}
export default function MultiCheckbox(props: MultiCheckboxProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    return (
        <>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.title}
            </label>
            {props.checkboxes.map((d) => (
                <CheckBox
                    {...d}
                    onTouch={(v) => changeTouched(v)}
                />
            ))}
            {props.valid === false && touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : CommonMessage.ERRORS.SELECT_VALID_OPTION}</small>
                </span>
            ) : (
                <></>
            )}
        </>
    );
}
