import { AuthenticationManager } from '@frontend/authentication';
import { shouldFetch } from '@frontend/common';
import { fetchUserInfo } from '@frontend/user';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../redux';

const useAuthorizationConfig = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    useEffect(() => {
        if (AuthenticationManager.getInstance().loggedIn && shouldFetch(user.status, user.lastUpdate, 1)) dispatch(fetchUserInfo());
    }, [AuthenticationManager.getInstance().loggedIn]);

    const auth = {};
    /**
     * Returns false when the user is not authorized for this recource
     * @param requiredPermissions
     * @returns
     */
    const authCheck = (requiredPermissions: DefaultRole): boolean => {
        //TODO once backend variables allow propper checks on authorzsation this check can be implemented and used
        if (user.selectedMembership !== null && requiredPermissions <= getRole(user.selectedMembership.role)) {
            return true;
        }
        return false;
    };
    return { auth, authCheck } as const;
};
export default useAuthorizationConfig;

function getRole(role: string): DefaultRole {
    switch (role) {
        case 'member':
            return DefaultRole.MEMBER;
        case 'admin':
            return DefaultRole.ADMIN;
        case 'super_admin':
            return DefaultRole.SUPER_ADMIN;
        default:
            return DefaultRole.UNKOWN;
    }
}

export enum DefaultRole {
    UNKOWN = 0,
    MEMBER = 1,
    ADMIN = 2,
    SUPER_ADMIN = 3
}
