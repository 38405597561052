import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AttributeAPIClient } from './api/client';
import { AttributesQueryParams, CreateAttributeModel } from './api/models';
import { Attribute } from './attribute';



interface AttributesState {
    attributeList: Attribute[] | null;
    searchAttributeList: { [searchQuery: string]: Attribute[] };
    status: SliceStatus;
    searchStatus: SliceStatus;
    lastUpdate: number;
    lastSearchUpdate: {[searchQuery: string]: number}
    error: ReduxError | null;
}

const initialState: AttributesState = {
    attributeList: null,
    searchAttributeList: {},
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    lastUpdate: Date.now(),
    lastSearchUpdate: {},
    error: null
};

export const attributeSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttributes.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAttributes.fulfilled, (state, action) => {
                state.attributeList = action.payload;
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAttributes.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAttributeById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAttributeById.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList !== null) {
                    state.attributeList = state.attributeList.filter((s) => attribute.id !== s.id);
                    state.attributeList.push(attribute);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAttributeById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAttributeByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAttributeByUrl.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList !== null) {
                    state.attributeList = state.attributeList.filter((s) => attribute.id == s.id);
                    state.attributeList.push(attribute);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAttributeByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(addAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addAttribute.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList !== null) {
                    state.attributeList.push(attribute);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(addAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateAttribute.pending, (state) => {
                state.status === SliceStatus.LOADING;
            })
            .addCase(updateAttribute.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList) {
                    const existingAttribute = state.attributeList.find((a) => a.id === attribute.id);
                    if (existingAttribute) {
                        const attributeIndex = state.attributeList.indexOf(existingAttribute);
                        state.attributeList.splice(attributeIndex, 1, attribute);
                    }
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(updateAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(deleteAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteAttribute.fulfilled, (state, action) => {
                if (action.payload === true && state.attributeList) {
                    const attributeId = action.meta.arg;
                    state.attributeList = state.attributeList.filter((a) => a.id != attributeId);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchAttribute.pending, (state) => {
                state.searchStatus = SliceStatus.LOADING
            })
            .addCase(searchAttribute.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                if (searchQuery === undefined || searchQuery === null || Array.isArray(searchQuery)) {
                    return undefined
                } else {
                    if (state.searchAttributeList !== null) {
                        state.searchAttributeList[searchQuery.toLowerCase()] = action.payload;
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    } else {
                        state.searchAttributeList = { [searchQuery.toLowerCase()]: action.payload };
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    }
                }
                state.searchStatus = SliceStatus.IDLE;
            })
            ;
    }
});

export const fetchAttributes = createAsyncThunk<Attribute[], ApiQueryParams<AttributesQueryParams>>(
    'fetchAttributes',
    async (queryParams: ApiQueryParams<AttributesQueryParams>, { rejectWithValue }) => {
        try {
            return await AttributeAPIClient.fetchAttributesApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAttributeById = createAsyncThunk<Attribute, string>('fetchAttributeById', async (attributeId, { rejectWithValue }) => {
    try {
        return await AttributeAPIClient.fetchAttributeApi({ id: attributeId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchAttributeByUrl = createAsyncThunk<Attribute, string>('fetchAttributeByUrl', async (attributeUrl, { rejectWithValue }) => {
    try {
        return await AttributeAPIClient.fetchAttributeApi({ url: attributeUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addAttribute = createAsyncThunk<Attribute, CreateAttributeModel>('addAttribute', async (attribute, { rejectWithValue }) => {
    try {
        return await AttributeAPIClient.createAttributeApi(attribute);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateAttribute = createAsyncThunk<Attribute, { attribute: CreateAttributeModel; id: number }>(
    'updateAttribute',
    async (options: { attribute: CreateAttributeModel; id: number }, { rejectWithValue }) => {
        try {
            return await AttributeAPIClient.updateAttributeApi(options.attribute, options.id);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteAttribute = createAsyncThunk<boolean, number>('deleteAttribute', async (attributeId, { rejectWithValue }) => {
    try {
        return await AttributeAPIClient.deleteAttributeApi(attributeId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchAttribute = createAsyncThunk<Attribute[], ApiQueryParams<AttributesQueryParams>>('searchAttribute', async (queryParams, { rejectWithValue }) => {
    try {
        return await AttributeAPIClient.searchAttributeApi(queryParams);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
})
export const attributeReducer =  attributeSlice.reducer;
