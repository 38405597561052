const Footer = () => {
    return (
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-center'>
                <small className='py-5'>
                    {'Copyright © '}
                    <a
                        href='https://telloport.com/'
                        target='_blank'
                        rel='noreferrer'>
                        Telloport
                    </a>{' '}
                    {new Date().getFullYear()}{' '}
                </small>
            </div>
        </div>
    );
};

export default Footer;
