import { fetchTransactions } from '@frontend/transaction';
import { useEffect } from 'react';

import { Slot } from '../api/Slots';
import { TransactionType } from '../common/BackendEnums';
import { TransactionStatus } from '../common/TransactionStatus';
import { getAllActiveTransactions } from '../utils/Transactions';
import { useAppDispatch, useAppSelector } from './redux';

interface SlotOccupationProps {
    slot?: Slot | undefined;
    slots?: Slot[] | undefined;
}

export default function SlotOccupation(props: SlotOccupationProps) {
    const transactions = useAppSelector((state) => state.transactions);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const products = useAppSelector((state) => state.products);
    const activeTransactions = getAllActiveTransactions(transactions.transactionList);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props.slots) {
            if (
                transactions.transactionList[
                    (TransactionStatus.READY_FOR_DROPOFF.name,
                    TransactionStatus.READY_FOR_PICKUP.name,
                    TransactionStatus.SCHEDULE_WAITING.name,
                    TransactionStatus.SCHEDULE_IN_PROGRESS.name,
                    TransactionStatus.CANCELLED.name)
                ] == undefined
            ) {
                dispatch(
                    fetchTransactions({
                        account: accountId?.toString(),
                        transaction_status: [
                            TransactionStatus.READY_FOR_DROPOFF.name,
                            TransactionStatus.READY_FOR_PICKUP.name,
                            TransactionStatus.SCHEDULE_WAITING.name,
                            TransactionStatus.SCHEDULE_IN_PROGRESS.name,
                            TransactionStatus.CANCELLED.name,
                            TransactionStatus.REMOVE_PARCEL_WAITING.name
                        ],
                        page: '1',
                        page_size: '1000'
                    })
                );
            }
        }
    }, []);

    if (props.slot) {
        const slotNumbers = props.slot!.slot_nr;
        const activeTransactionSlot = activeTransactions.filter((at) => at.spot_id === props.slot?.spot);

        let activeTransactionCountPerSlot = 0;
        let activeTransactionType;

        const activeTransactionsPerSlot = activeTransactionSlot.filter((at) => at.slot_nr === slotNumbers);
        const slotContent = activeTransactionsPerSlot.map((t) => t.product_instances.map((p) => p.product)).flat(1);
        const productContentListPerSlot: any[] = [];
        slotContent.forEach((t) => {
            products.allProducts?.results.find((p) => {
                if (p.id === t) productContentListPerSlot.push(p);
            });
        });
        activeTransactionSlot.forEach((at) => {
            if (slotNumbers === at.slot_nr) {
                activeTransactionCountPerSlot += 1;
                activeTransactionType = at.type;
            }
        });
        const activeTransactionsPerSlotArray = {
            id: slotNumbers,
            activeTransactionCountPerSlot: activeTransactionCountPerSlot,
            activeTransactionType: activeTransactionType
        };
        let slotOccupationStatus = '';

        if (activeTransactionsPerSlotArray.id === props.slot!.slot_nr) {
            if (activeTransactionsPerSlotArray.activeTransactionCountPerSlot === 0) {
                slotOccupationStatus = 'Empty';
            }
            if (activeTransactionsPerSlotArray.activeTransactionType === TransactionType.PUDO) {
                if (
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot > 0 &&
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot < props.slot!.settings_transactions_limit_for_pudo
                ) {
                    slotOccupationStatus = 'Partially filled';
                } else if (activeTransactionsPerSlotArray.activeTransactionCountPerSlot === props.slot!.settings_transactions_limit_for_pudo) {
                    slotOccupationStatus = 'Full';
                }
            } else if (activeTransactionsPerSlotArray.activeTransactionType === TransactionType.VENDING) {
                if (
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot > 0 &&
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot < props.slot!.settings_transactions_limit_for_vending
                ) {
                    slotOccupationStatus = 'Partially filled';
                } else if (activeTransactionsPerSlotArray.activeTransactionCountPerSlot === props.slot!.settings_transactions_limit_for_vending) {
                    slotOccupationStatus = 'Full';
                }
            } else if (activeTransactionsPerSlotArray.activeTransactionType === TransactionType.LENDING) {
                if (
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot > 0 &&
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot < props.slot!.settings_transactions_limit_for_lending
                ) {
                    slotOccupationStatus = 'Partially filled';
                } else if (activeTransactionsPerSlotArray.activeTransactionCountPerSlot === props.slot!.settings_transactions_limit_for_lending) {
                    slotOccupationStatus = 'Full';
                }
            } else if (activeTransactionsPerSlotArray.activeTransactionType === TransactionType.RETURN) {
                if (
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot > 0 &&
                    activeTransactionsPerSlotArray.activeTransactionCountPerSlot < props.slot!.settings_transactions_limit_for_return
                ) {
                    slotOccupationStatus = 'Partially filled';
                } else if (activeTransactionsPerSlotArray.activeTransactionCountPerSlot === props.slot!.settings_transactions_limit_for_return) {
                    slotOccupationStatus = 'Full';
                }
            }
        }

        return { slotOccupationStatus, activeTransactionsPerSlot, productContentListPerSlot };
    } else if (props.slots) {
        if (props.slots.length > 0) {
            const slotNumbers = props.slots;
            const availableTransactions = activeTransactions.filter((at) => at.spot_id === props.slots![0].spot);
            const occupiedSlots: { type: TransactionType; slot: Slot }[] = [];
            const availableSlots: Slot[] = [];
            const slotTypes: { type: TransactionType[]; slot: Slot }[] = [];
            slotNumbers.forEach((t) => {
                const typesArray: TransactionType[] = [];
                const activeTransactionsPerSlotArray = availableTransactions.filter((at) => at.slot_nr === t.slot_nr);

                if (t.settings_transactions_limit_for_pudo > 0) {
                    typesArray.push(TransactionType.PUDO);
                }
                if (t.settings_transactions_limit_for_vending > 0) {
                    typesArray.push(TransactionType.VENDING);
                }
                if (t.settings_transactions_limit_for_return > 0) {
                    typesArray.push(TransactionType.RETURN);
                }
                if (t.settings_transactions_limit_for_lending > 0) {
                    typesArray.push(TransactionType.LENDING);
                }
                slotTypes.push({ type: typesArray, slot: t });
                if (activeTransactionsPerSlotArray.length === 0) {
                    availableSlots.push(t);
                }
                if (activeTransactionsPerSlotArray.length > 0) {
                    if (
                        activeTransactionsPerSlotArray[0].type === TransactionType.PUDO &&
                        (activeTransactionsPerSlotArray.length === 0 || activeTransactionsPerSlotArray.length < t.settings_transactions_limit_for_pudo)
                    ) {
                        availableSlots.push(t);
                    } else if (
                        activeTransactionsPerSlotArray[0].type === TransactionType.PUDO &&
                        activeTransactionsPerSlotArray.length >= t.settings_transactions_limit_for_pudo
                    ) {
                        occupiedSlots.push({ type: TransactionType.PUDO, slot: t });
                    }
                    if (
                        activeTransactionsPerSlotArray[0].type === TransactionType.VENDING &&
                        (activeTransactionsPerSlotArray.length === 0 || activeTransactionsPerSlotArray.length < t.settings_transactions_limit_for_vending)
                    ) {
                        availableSlots.push(t);
                    } else if (
                        activeTransactionsPerSlotArray[0].type === TransactionType.VENDING &&
                        activeTransactionsPerSlotArray.length >= t.settings_transactions_limit_for_vending
                    ) {
                        occupiedSlots.push({ type: TransactionType.VENDING, slot: t });
                    }
                    if (
                        activeTransactionsPerSlotArray[0].type === TransactionType.LENDING &&
                        (activeTransactionsPerSlotArray.length === 0 || activeTransactionsPerSlotArray.length < t.settings_transactions_limit_for_lending)
                    ) {
                        availableSlots.push(t);
                    } else if (
                        activeTransactionsPerSlotArray[0].type === TransactionType.LENDING &&
                        activeTransactionsPerSlotArray.length >= t.settings_transactions_limit_for_lending
                    ) {
                        occupiedSlots.push({ type: TransactionType.LENDING, slot: t });
                    }
                }
            });
            return { availableSlots, occupiedSlots, slotTypes };
        }
    }
}
