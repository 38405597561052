import { Logger } from '@frontend/Logger';
import { SliceStatus } from '@frontend/common';
import { BulkTransactionStatusUpdateModel, Transaction, bulkUpdateTransactionStatus } from '@frontend/transaction';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TransactionStatus, TransactionStatusName } from '../../common/TransactionStatus';
import TransactionStatusChangeEvent from '../../common/TransactionStatusChangeEvent';
import Modal, { ModalProps, ModalType } from '../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import NotificationOverwrite, { NotificationSetting } from './NotificationOverwrite';
import ReadyForDropoffView from './ReadyForDropoffView';
import ReadyForPickupView from './ReadyForPickupView';
import RemoveParcelWaitingView from './RemoveParcelWaitingView';

const TRANSACTION_STATUS_UPDATE_MODAL_ID = 'TransactionStatusUpdateModal';

interface Props extends ModalProps {
    transactions: Transaction[];
}

export interface ElementProps {
    isCurrent?: boolean;
    onChangeStatusCallback: (result: TransactionStatus) => void;
    currentlySelectedStatus?: TransactionStatus;
}

export default function TransactionStatusUpdate(props: Props) {
    const dispatch = useAppDispatch();
    const membership = useAppSelector((state) => state.user.selectedMembership);
    const transactionState = useAppSelector((state) => state.transactions);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [notificationSettings, changeNotificationSettings] = useState<NotificationSetting[]>([]);
    const [currentlySelectedStatus, changeCurrentlySelectedStatus] = useState<TransactionStatus | undefined>();
    const [result, changeResult] = useState<BulkTransactionStatusUpdateModel | undefined>();
    const { selectedUser } = useAccount();

    useEffect(() => {
        if (!props.show) {
            changeCurrentlySelectedStatus(undefined);
            changeResult(undefined);
            changeNotificationSettings([]);
        }
    }, [props.show]);

    useEffect(() => {
        if (submitted) {
            if (transactionState.status === SliceStatus.ERROR) {
                Logger.error(`${selectedUser?.email} failed to update transaction status`, { user: selectedUser?.id }, result?.transaction_ids);
            }
        }
        changeSubmitted(false);
    }, [transactionState, submitted]);

    if (!props.show || props.transactions.length === 0 || props.transactions.find((t) => t.status !== props.transactions[0]?.status)) {
        props.handleClose();
        return <></>;
    }

    const status = TransactionStatus.getByString(props.transactions[0]?.status);
    if (!status || !membership) {
        props.handleClose();
        return <></>;
    }
    const updateResult = (value: TransactionStatus) => {
        changeCurrentlySelectedStatus(value);
        const event = TransactionStatusChangeEvent.getEventForStatusUpdate(status, value);
        if (event !== undefined) {
            changeResult({
                account_id: membership.account.id,
                initial_state: status.name === TransactionStatusName.CANCELLED ? TransactionStatusName.REMOVE_PARCEL_WAITING : status.name,
                event: event.value,
                notifications: result?.notifications
                    ? { ...result.notifications, [event.value]: { profile: 'default' } }
                    : { [event.value]: { profile: 'default' } },
                transaction_ids: props.transactions.map((t) => t.real_id)
            });
        }
    };
    const updateNotify = (settings: NotificationSetting[]) => {
        changeNotificationSettings(settings);
        const notifications: any = {};
        settings.forEach((setting) => {
            notifications[setting.event.value.toString()] = { profile: 'default' };
            if (setting.send !== undefined) {
                notifications[setting.event.value.toString()] = setting.send;
            }
        });
        changeResult({
            account_id: membership.account.id,
            initial_state: result!.initial_state,
            event: result!.event,
            notifications: notifications,
            transaction_ids: result!.transaction_ids
        });
    };

    const onSubmit = async () => {
        dispatch(bulkUpdateTransactionStatus(result!));
        changeSubmitted(true);
        props.handleClose();
    };

    let content: ReactNode;
    switch (status) {
        case TransactionStatus.READY_FOR_DROPOFF:
            content = (
                <ReadyForDropoffView
                    onChangeStatusCallback={updateResult}
                    currentlySelectedStatus={currentlySelectedStatus}
                />
            );
            break;
        case TransactionStatus.READY_FOR_PICKUP:
            content = (
                <ReadyForPickupView
                    onChangeStatusCallback={updateResult}
                    currentlySelectedStatus={currentlySelectedStatus}
                />
            );
            break;
        case TransactionStatus.CANCELLED:
            content = (
                <RemoveParcelWaitingView
                    onChangeStatusCallback={updateResult}
                    currentlySelectedStatus={currentlySelectedStatus}
                />
            );
            break;
        default:
            content = <>invalid state</>;
            break;
    }

    return (
        <Modal
            id={TRANSACTION_STATUS_UPDATE_MODAL_ID}
            {...props}
            type={ModalType.PANE}
            customWidth={50}>
            <div className='modal-header'>
                <h4 className='font-weight-bolder text-primary text-gradient'>Status update</h4>
            </div>
            <div className='modal-body'>
                <div className='d-flex flex-row'>
                    {status && (
                        <>
                            {currentlySelectedStatus ? (
                                <div className='d-flex flex-column w-100'>
                                    <NotificationOverwrite
                                        previeusSettings={notificationSettings}
                                        initialStatus={status}
                                        currentEndStatus={currentlySelectedStatus}
                                        onChange={updateNotify}
                                    />
                                </div>
                            ) : (
                                <div className='d-flex flex-column w-100'>
                                    <FormattedMessage
                                        id='TransactionStatusUpdate.NoStatusUpdateSelected.helpMessage'
                                        description='the message telling the user to select a status update before trying to configure the notifications'
                                        defaultMessage='First select a status update before managing the notifications.'
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <div className='d-flex flex-column w-100'>
                        <div className='timeline timeline-one-side'>{content}</div>
                    </div>
                </div>
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <button
                    className='btn btn-secondary'
                    onClick={props.handleClose}>
                    Close
                </button>
                <button
                    id='TransactionStatusUpdate-SubmitButton'
                    className='btn btn-primary'
                    disabled={result?.event === undefined}
                    onClick={onSubmit}>
                    submit
                </button>
            </div>
        </Modal>
    );
}
