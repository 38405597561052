import React from 'react';
import { GrClose } from 'react-icons/gr';

import Modal, { ModalProps, ModalType } from './Modal';

interface DetailModalProps extends ModalProps {
    title?: React.ReactNode;
}

const DetailModal = (props: DetailModalProps) => {
    return (
        <Modal
            type={ModalType.PANE}
            id={props.id}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.title}</h3>
                <button
                    key={'Close-Modal-Button' + props.title}
                    onClick={props.handleClose}
                    id='Close-Modal-Button'
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>
            {props.children}
        </Modal>
    );
};

export default DetailModal;
