import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TransactionStatus } from './transaction-status';

export enum TransactionStatusChangeEventValue {
    DROPOFF_IN_PROGRESS = 'dropoff_in_progress',
    PICKUP_IN_PROGRESS = 'pickup_in_progress',
    REMOVE_PARCEL_IN_PROGRESS = 'remove_parcel_in_progress',
    CANCEL = 'cancel'
}

export default class TransactionStatusChangeEvent {
    static readonly DROPOFF_IN_PROGRESS = new TransactionStatusChangeEvent(
        TransactionStatusChangeEventValue.DROPOFF_IN_PROGRESS,
        (
            <FormattedMessage
                id='TransactionStatusEvent.DROPOFF_IN_PROGRESS'
                description='the displayed value for the transaction status change event DROPOFF_IN_PROGRESS'
                defaultMessage='Do dropoff'
            />
        )
    );
    static readonly PICKUP_IN_PROGRESS = new TransactionStatusChangeEvent(
        TransactionStatusChangeEventValue.PICKUP_IN_PROGRESS,
        (
            <FormattedMessage
                id='TransactionStatusEvent.PICKUP_IN_PROGRESS'
                description='the displayed value for the transaction status change event PICKUP_IN_PROGRESS'
                defaultMessage='Do pickup'
            />
        )
    );
    static readonly REMOVE_PARCEL_IN_PROGRESS = new TransactionStatusChangeEvent(
        TransactionStatusChangeEventValue.REMOVE_PARCEL_IN_PROGRESS,
        (
            <FormattedMessage
                id='TransactionStatusEvent.REMOVE_PARCEL_IN_PROGRESS'
                description='the displayed value for the transaction status change event REMOVE_PARCEL_IN_PROGRESS'
                defaultMessage='Remove parcel'
            />
        )
    );
    static readonly CANCEL = new TransactionStatusChangeEvent(
        TransactionStatusChangeEventValue.CANCEL,
        (
            <FormattedMessage
                id='TransactionStatusEvent.CANCEL'
                description='the displayed value for the transaction status change event CANCEL'
                defaultMessage='Cancel'
            />
        )
    );
    static readonly ALL = [
        TransactionStatusChangeEvent.DROPOFF_IN_PROGRESS,
        TransactionStatusChangeEvent.PICKUP_IN_PROGRESS,
        TransactionStatusChangeEvent.REMOVE_PARCEL_IN_PROGRESS,
        TransactionStatusChangeEvent.CANCEL
    ];

    private constructor(public readonly value: TransactionStatusChangeEventValue, public readonly displayedValue: React.ReactNode) {}

    static getByValue(value: TransactionStatusChangeEventValue): TransactionStatusChangeEvent | undefined {
        return this.ALL.find((s) => s.value === value);
    }

    static getExpectedEndStatusForEvent(event: TransactionStatusChangeEvent, initialState: TransactionStatus): TransactionStatus | undefined {
        switch (event) {
            case TransactionStatusChangeEvent.DROPOFF_IN_PROGRESS:
                return TransactionStatus.READY_FOR_PICKUP;
            case TransactionStatusChangeEvent.PICKUP_IN_PROGRESS:
            case TransactionStatusChangeEvent.REMOVE_PARCEL_IN_PROGRESS:
                return TransactionStatus.DONE;
            case TransactionStatusChangeEvent.CANCEL:
                if (initialState === TransactionStatus.READY_FOR_DROPOFF) return TransactionStatus.DONE;
                if (initialState === TransactionStatus.READY_FOR_PICKUP) return TransactionStatus.REMOVE_PARCEL_IN_PROGRESS;
        }
        return undefined;
    }

    static getIntermediateEventsForStatusUpdate(from: TransactionStatus, to: TransactionStatus): TransactionStatusChangeEvent[] | undefined {
        if (from === TransactionStatus.READY_FOR_DROPOFF) {
            if (to === TransactionStatus.DONE) {
                return [TransactionStatusChangeEvent.DROPOFF_IN_PROGRESS, TransactionStatusChangeEvent.PICKUP_IN_PROGRESS];
            } else if (to === TransactionStatus.DROPOFF_DONE) {
                return [TransactionStatusChangeEvent.DROPOFF_IN_PROGRESS];
            } else if (to === TransactionStatus.CANCELLED) {
                return [TransactionStatusChangeEvent.CANCEL];
            }
        } else if (from === TransactionStatus.READY_FOR_PICKUP) {
            if (to === TransactionStatus.DONE) {
                return [TransactionStatusChangeEvent.PICKUP_IN_PROGRESS];
            } else if (to === TransactionStatus.CANCELLED) {
                return [TransactionStatusChangeEvent.CANCEL];
            }
        } else if (from === TransactionStatus.CANCELLED) {
            if (to === TransactionStatus.DONE) {
                return [TransactionStatusChangeEvent.REMOVE_PARCEL_IN_PROGRESS];
            }
        }
        return undefined;
    }

    static getEventForStatusUpdate(from: TransactionStatus, to: TransactionStatus): TransactionStatusChangeEvent | undefined {
        const result = this.getIntermediateEventsForStatusUpdate(from, to);
        return result !== undefined ? result[result.length - 1] : undefined;
    }
}

//add notifications
