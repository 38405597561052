import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Slot } from '../../api/Slots';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchSlotLogs } from '../../store/slots/slotLogsSlice';
import SlotLogTimelineElement from './SlotLogTimelineElement';

const SlotLogsTimeline = (props: { slot: Slot }) => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const slotLogsStore = useAppSelector((state) => state.slotLogs.slotLogs);

    useEffect(() => {
        dispatch(fetchSlotLogs({ account: accountId?.toString(), slot: props.slot.id, page: '1', page_size: '100' }));
    }, []);

    if (slotLogsStore[props.slot.id] === undefined) {
        return (
            <div className='timeline timeline-one-side'>
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <span
                            className='spinner-grow spinner-grow-sm'
                            role='status'
                            aria-hidden='true'></span>
                    </span>
                    <div className='timeline-content w-100'>
                        <h6 className='text-dark text-sm font-weight-bold mb-0 w-100'>
                            <span className='placeholder w-100'>slot</span>
                        </h6>
                        <p className='text-nowrap text-secondary font-weight-bold text-xs mt-1 mb-0'>
                            <span className='placeholder col-11'></span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (slotLogsStore[props.slot.id].results.length === 0) {
        return (
            <div className='timeline timeline-one-side'>
                <FormattedMessage
                    id='SlotLogTimeline.EmptyMessage'
                    description='The message telling the user there are no logs for the given slot.'
                    defaultMessage='No logs yet.'
                />
            </div>
        );
    }

    return <div className='timeline timeline-one-side'>{slotLogsStore[props.slot.id].results.map(SlotLogTimelineElement)}</div>;
};

export default SlotLogsTimeline;
