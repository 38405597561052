import { FaWindowRestore } from 'react-icons/fa';

import { Slot } from '../../../api/Slots';
import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useSlotBadge from './slot-badge.controller';

export interface Props {
    slotId?: string;
    slot?: Slot;
    name?: string;
}

const SlotBadge = (props: Props) => {
    const viewProps = useSlotBadge(props);
    return (
        <BadgeTemplate
            type={DetailModalType.SLOT}
            objectId={props.slotId}>
            <FaWindowRestore className='me-2' />
            {viewProps.slot_nr}
        </BadgeTemplate>
    );
};

export default SlotBadge;
