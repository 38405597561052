interface Props {
    title: React.ReactNode;
    hideDivider?: boolean;
}
const ModalSectionTitle = (props: Props) => {
    return (
        <>
            {props.hideDivider !== true && <hr className='horizontal dark mt-0' />}
            <h5 className='d-flex justify-content-center'>{props.title}</h5>
        </>
    );
};

export default ModalSectionTitle;
