import { TransactionSpot } from '@frontend/transaction';
import { BsGrid1X2Fill } from 'react-icons/bs';

import { Spot } from '../../../api/Spots';
import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useSpotBadge from './spot-badge.controller';

export interface SpotBadgeProps {
    spot_id?: string;
    spot?: Spot | TransactionSpot;
    name?: string;
}

const SpotBadge = (props: SpotBadgeProps) => {
    const viewProps = useSpotBadge(props);

    return (
        <BadgeTemplate
            objectId={props.spot_id}
            type={DetailModalType.SPOT}>
            <BsGrid1X2Fill className='me-2' />
            {viewProps.name}
        </BadgeTemplate>
    );
};

export default SpotBadge;
