import React, { useEffect, useState } from 'react';
import { GroupBase } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';

import { getInputClass, getInputClassMultiSelect } from '../../../utils/FormUtils';

export interface CustomAsyncSelectProps<
    OptionType extends GroupBase<OptionType> = any,
    IsMulti extends boolean = false | true,
    GroupType extends OptionType = OptionType
> extends AsyncProps<OptionType, IsMulti, GroupType> {
    label: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: any[] | any;
    useConditionedStyling?: boolean;
    errorMessage?: React.ReactNode;
}

const AsyncSelectInput = (props: CustomAsyncSelectProps) => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);
    const [value, changeValue] = useState<any | null | undefined>(null);

    const isValid = (): boolean => {
        let check = true;
        if ((props.value === undefined || props.value === null) && props.required === true) check = false;
        if (props.isMulti) {
            if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        } else {
            if (props.value && (props.value.value || props.value.value === undefined || props.value.value.length === 0)) {
                if (
                    check === true &&
                    props.required === true &&
                    (props.value.value === undefined || props.value.value === null || props.value.value.length === 0)
                )
                    check = false;
            } else {
                if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
            }
        }
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    const multiValues = props.isMulti === true && props.value !== undefined ? props.options?.filter((op: any) => props.value.includes(op.value)) : [];

    const findValue = () => {
        if (props.isMulti) {
            return multiValues;
        }
        if (props.value !== undefined && props.value !== null) {
            if (props.options) {
                return props.options.find((op) => op.value === (props.value.value || props.value));
            } else if (props.defaultOptions) {
                return (props.defaultOptions as Array<any>)
                    .map((c) => c.options)
                    .flat()
                    .find((s) => s.value == props.value);
            } else return value;
        } else {
            return null;
        }
    };

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
                </label>
                <AsyncSelect
                    id={props.id}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            boxShadow: 'none',
                            minWidth: '10vw',
                            padding: '1.204px 0px 1.204px 10px',
                            borderRadius: '0.5rem'
                        }),
                        menu: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 9999
                        }),
                        valueContainer: (baseStyle, state) => ({
                            ...baseStyle,
                            padding: '0px'
                        })
                    }}
                    classNames={{
                        control: (state) =>
                            props.useConditionedStyling === false
                                ? 'form-control'
                                : props.isMulti
                                ? getInputClassMultiSelect(touched, valid, props.value)
                                : getInputClass(touched, valid, props.value)
                    }}
                    isClearable={props.isClearable}
                    isDisabled={props.isDisabled}
                    value={findValue()}
                    cacheOptions={props.cacheOptions}
                    loadOptions={props.loadOptions}
                    defaultOptions={props.defaultOptions}
                    isMulti={props.isMulti}
                    options={props.options}
                    minMenuHeight={300}
                    placeholder={props.placeholder}
                    noOptionsMessage={props.noOptionsMessage}
                    onChange={(newValue: any, actionMeta) => {
                        if (newValue === null) {
                            changeValue(null);
                        } else {
                            changeValue(newValue);
                        }

                        props.onChange && props.onChange(props.isMulti ? newValue.map((s: any) => s.value) : newValue, actionMeta);
                        isValid();
                    }}
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    onInputChange={(value, meta) => props.onInputChange && props.onInputChange(value, meta)}
                    onMenuScrollToBottom={props.onMenuScrollToBottom}
                />
                {valid === false && touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default AsyncSelectInput;

export interface AsyncSelectReturn {
    value: string;
    label: string;
}
