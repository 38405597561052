import { SliceStatus } from '@frontend/common';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { Spot, SpotSettingsBody } from '../../../api/Spots';
import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import SpotType from '../../../common/SpotType';
import CheckBox from '../../../components/forms/check/CheckBox';
import NumberInput from '../../../components/forms/numberInput/NumberInput';
import SelectInput from '../../../components/forms/select/Select';
import ContactGroupSelect from '../../../components/forms/select/contacts/ContactGroupSelect';
import ContactOrContactGroupSelect from '../../../components/forms/select/contacts/ContactOrContactGroupSelect';
import ContactSelect from '../../../components/forms/select/contacts/ContactSelect';
import TextInput from '../../../components/forms/textInput/TextInput';
import TimeInput from '../../../components/forms/timeInput/TimeInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { changeSpotSettings } from '../../../store/spotSlice';
import { GetError } from '../../../store/utils/GetError';
import { Languages } from '../../../utils/FormUtils';

interface SpotSettingsProps {
    handleClose: () => void;
    spot: Spot;
    show: boolean;
}

const SpotSettings = (props: SpotSettingsProps) => {
    useAuthorization(DefaultRole.SUPER_ADMIN, true);
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const terminals = useAppSelector((state) => state.terminals);
    const spotState = useAppSelector((state) => state.spots);
    const isInit = terminals.status == 'init';
    const [submitted, changeSubmitted] = useState(false);
    const spot = props.spot;
    if (isInit || terminals.terminalsList == null) {
        return <></>;
    }
    const initialSpotState: SpotSettingsBody = {
        account: accountId!,
        type: spot.type,
        name: spot.name,
        address_line_1: spot.address_line_1,
        zip_code: spot.zip_code,
        city: spot.city,
        delivery_contacts: spot.delivery_contacts,
        delivery_contact_other: spot.delivery_contact_other,
        default_receiver_contact: spot.default_receiver_contact,
        receiver_contact_group: spot.receiver_contact_group,
        actions: spot.actions,
        additional_data: spot.additional_data,
        sharing_allowed_for_contact_groups: spot.sharing_allowed_for_contact_groups,
        settings_allow_multi_persons_warehouse_access: spot.settings_allow_multi_persons_warehouse_access,
        settings_enable_warning_notifications: spot.settings_enable_warning_notifications,
        settings_language: spot.settings_language,
        settings_redirect_after_in_sec: spot.settings_redirect_after_in_sec,
        settings_popup_stays_visible_in_sec: spot.settings_popup_stays_visible_in_sec,
        settings_switch_lights_on_at: spot.settings_switch_lights_on_at,
        settings_switch_lights_off_at: spot.settings_switch_lights_off_at,
        settings_dropoff_in_progress_timeout_in_sec: spot.settings_dropoff_in_progress_timeout_in_sec,
        settings_pickup_in_progress_timeout_in_sec: spot.settings_pickup_in_progress_timeout_in_sec,
        settings_remove_parcel_in_progress_timeout_in_sec: spot.settings_remove_parcel_in_progress_timeout_in_sec
    };

    const [spotSettings, setSpotSettings] = useState<SpotSettingsBody>(initialSpotState);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        dispatch(changeSpotSettings({ id: +spot.id.replace('SPT', ''), body: spotSettings }));
    };

    useEffect(() => {
        if (submitted && spotState.status === SliceStatus.IDLE) {
            toast.success(
                ...ToastUtil.generateToastConfig(
                    CommonMessage.STATUS.SUCCESS,
                    <FormattedMessage
                        id='SpotSettings.changeSettings.Success'
                        description='Success message after changing spot settings'
                        defaultMessage='Successfully changed spot settings'
                    />,
                    ClassType.SUCCESS
                )
            );
            props.handleClose();
        } else if (submitted && spotState.status === SliceStatus.ERROR) {
            toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, GetError(spotState.error), ClassType.DANGER));
        }
    }, [spotState]);

    if (!spotSettings) {
        return <></>;
    }

    return (
        <>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <span>
                    {
                        <FormattedMessage
                            id='SpotSettings.Title'
                            description={'Title for Spot settings'}
                            defaultMessage='General settings'
                        />
                    }
                </span>
            </div>
            <form
                id={'general-settings'}
                onSubmit={handleSubmit}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={CommonMessage.FORM.CONTACTS.NAME}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, name: v })}
                                value={spotSettings.name}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={CommonMessage.FORM.SPOT.TYPE}
                                submitted={submitted}
                                options={SpotType.ALL.map((s) => ({ value: s.value, label: s.displayedValue }))}
                                onChange={(v: any) => setSpotSettings({ ...spotSettings, type: v })}
                                value={spotSettings.type}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={CommonMessage.FORM.SPOT.ADDRESS}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, address_line_1: v })}
                                value={spotSettings.address_line_1}
                            />
                        </div>
                        <div className='col-6'>
                            <TextInput
                                label={CommonMessage.FORM.SPOT.ZIP}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, zip_code: v })}
                                value={spotSettings.zip_code}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={CommonMessage.FORM.SPOT.CITY}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, city: v })}
                                value={spotSettings.city}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ContactOrContactGroupSelect
                                label={CommonMessage.FORM.CONTACTS.DELIVERY_CONTACT}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, delivery_contacts: v })}
                                value={spotSettings.delivery_contacts}
                            />
                        </div>
                        <div className='col-6'>
                            <ContactOrContactGroupSelect
                                label={CommonMessage.FORM.CONTACTS.DELIVERY_CONTACT_OTHER}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, delivery_contact_other: v })}
                                value={spotSettings.delivery_contact_other}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ContactSelect
                                label={CommonMessage.FORM.CONTACTS.DEFAULT_RECEIVER}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, default_receiver_contact: v })}
                                value={spotSettings.default_receiver_contact}
                            />
                        </div>
                        <div className='col-6'>
                            <ContactGroupSelect
                                label={CommonMessage.FORM.CONTACTS.RECEIVER_CONTACT_GROUP}
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, receiver_contact_group: v })}
                                value={spotSettings.receiver_contact_group}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ContactGroupSelect
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.sharing_allowed_for_contact_groups'
                                        description='Label for setting sharing allowed for contact groups'
                                        defaultMessage='Sharing allowed for contact groups'
                                    />
                                }
                                submitted={submitted}
                                isMulti
                                onChange={(v) => setSpotSettings({ ...spotSettings, sharing_allowed_for_contact_groups: v })}
                                value={spotSettings.sharing_allowed_for_contact_groups}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Language'
                                        description={'Label for general language setting'}
                                        defaultMessage='Language'
                                    />
                                }
                                options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                                value={spotSettings.settings_language}
                                onChange={(value: any) => setSpotSettings({ ...spotSettings, settings_language: value })}
                                submitted={submitted}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <CheckBox
                                checked={spotSettings.settings_allow_multi_persons_warehouse_access}
                                submitted={submitted}
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.allow_multi_persons_warehouse_access'
                                        description='Label for allow multi persons warehouse access'
                                        defaultMessage='Allow multi persons warehouse access'
                                    />
                                }
                                onChange={(v) => setSpotSettings({ ...spotSettings, settings_allow_multi_persons_warehouse_access: v })}
                            />
                        </div>
                        <div className='col-6'>
                            <CheckBox
                                checked={spotSettings.settings_enable_warning_notifications}
                                submitted={submitted}
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.enable_warning_notifications'
                                        description='Label for enable warning notifications'
                                        defaultMessage='Enable warning notifications'
                                    />
                                }
                                onChange={(v) => setSpotSettings({ ...spotSettings, settings_enable_warning_notifications: v })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.popup_stays_visible_in_sec'
                                        description='Label for popup stays visible in sec'
                                        defaultMessage='Popup stays visible in sec'
                                    />
                                }
                                submitted={submitted}
                                onChange={(v) => setSpotSettings({ ...spotSettings, settings_popup_stays_visible_in_sec: v })}
                                value={spotSettings.settings_popup_stays_visible_in_sec}
                            />
                        </div>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Redirect'
                                        description={'Label for redirect'}
                                        defaultMessage='Redirect after in sec'></FormattedMessage>
                                }
                                submitted={submitted}
                                value={spotSettings.settings_redirect_after_in_sec}
                                onChange={(value) => setSpotSettings({ ...spotSettings, settings_redirect_after_in_sec: value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TimeInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Lights.On'
                                        description={'Label for switch lights on'}
                                        defaultMessage='Switch lights on at'
                                    />
                                }
                                submitted={submitted}
                                value={spotSettings.settings_switch_lights_on_at}
                                onChange={(value) => setSpotSettings({ ...spotSettings, settings_switch_lights_on_at: value + ':00' })}
                            />
                        </div>
                        <div className='col-6'>
                            <TimeInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Lights.Off'
                                        description={'Label for switch lights off'}
                                        defaultMessage='Switch lights off at'
                                    />
                                }
                                submitted={submitted}
                                value={spotSettings.settings_switch_lights_off_at}
                                onChange={(value) => setSpotSettings({ ...spotSettings, settings_switch_lights_off_at: value + ':00' })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Dropoff.inProgress'
                                        description={'Label for dropoff in progress'}
                                        defaultMessage='Dropoff in progress timeout in sec'
                                    />
                                }
                                submitted={submitted}
                                value={spotSettings.settings_dropoff_in_progress_timeout_in_sec?.toString()}
                                onChange={(value) => setSpotSettings({ ...spotSettings, settings_dropoff_in_progress_timeout_in_sec: value })}
                            />
                        </div>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Pickup.inProgress'
                                        description={'Label for pickup in progress'}
                                        defaultMessage='Pickup in progress timeout in sec'
                                    />
                                }
                                submitted={submitted}
                                value={spotSettings.settings_pickup_in_progress_timeout_in_sec?.toString()}
                                onChange={(value) => setSpotSettings({ ...spotSettings, settings_pickup_in_progress_timeout_in_sec: value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.General.Remove.Parcel.inProgress'
                                        description={'Label for remove parcel in progress'}
                                        defaultMessage='Remove parcel in progress timeout in sec'
                                    />
                                }
                                submitted={submitted}
                                value={spotSettings.settings_remove_parcel_in_progress_timeout_in_sec?.toString()}
                                onChange={(value) => setSpotSettings({ ...spotSettings, settings_remove_parcel_in_progress_timeout_in_sec: value })}
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    onSubmit={() => undefined}
                    handleClose={() => props.handleClose()}></CreateModalFooter>
            </form>
        </>
    );
};

export default SpotSettings;

export const registrationAllowed = (
    <FormattedMessage
        id='SpotSettings.Registration.Allowed'
        description={'Label for the registration allowed checkbox'}
        defaultMessage={'Registration allowed'}></FormattedMessage>
);
export const contactRegistrationAllowed = (
    <FormattedMessage
        id='SpotSettings.Contact.Registration.Allowed'
        description={'Label for the contact registration allowed checkbox'}
        defaultMessage={'Contact registration allowed'}></FormattedMessage>
);
export const unknownContactRegistrationAllowed = (
    <FormattedMessage
        id='SpotSettings.Unknown.Contact.Registration.Allowed'
        description={'Label for the unknown contact registration allowed checkbox'}
        defaultMessage={'Unkown registration allowed'}></FormattedMessage>
);

interface TerminalSettings {
    actions_switch_terminal_on_at: boolean;
    actions_switch_terminal_off_at: number;
    settings_informational_message: string;
    settings_registration_allowed: boolean;
    settings_contact_registration_allowed: boolean;
    settings_uknown_contact_registration_allowed: boolean;
    settings_door_open_time_in_seconds: number;
}
