import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum SpotTypeName {
    UNKNOWN = 'unknown',
    STANDARD_DISTRISPOT = 'standard_distrispot',
    MULTI_DISTRISPOT = 'multi_distrispot',
    SMART_SHELVES = 'smart_shelves',
    AREA = 'area',
    WAREHOUSE = 'warehouse'
}

export class SpotType {
    static readonly UNKNOWN = new SpotType(
        SpotTypeName.UNKNOWN,
        (
            <FormattedMessage
                id='SpotType.UNKNOWN'
                description='Label for unknown spot type'
                defaultMessage='Unknown'
            />
        )
    );
    static readonly STANDARD_DISTRISPOT = new SpotType(
        SpotTypeName.STANDARD_DISTRISPOT,
        (
            <FormattedMessage
                id='SpotType.STANDARD_DISTRISPOT'
                description='Label for standard distrispot type'
                defaultMessage='Standard distriSPOT'
            />
        )
    );
    static readonly MULTI_DISTRISPOT = new SpotType(
        SpotTypeName.MULTI_DISTRISPOT,
        (
            <FormattedMessage
                id='SpotType.MULTI_DISTRISPOT'
                description='Label for multi distrispot type'
                defaultMessage='Multi distriSPOT'
            />
        )
    );
    static readonly SMART_SHELVES = new SpotType(
        SpotTypeName.SMART_SHELVES,
        (
            <FormattedMessage
                id='SpotType.SMART_SHELVES'
                description='Label for smart shelves type'
                defaultMessage='Smart shelves'
            />
        )
    );
    static readonly AREA = new SpotType(
        SpotTypeName.AREA,
        (
            <FormattedMessage
                id='SpotType.AREA'
                description='Label for area type'
                defaultMessage='Area'
            />
        )
    );
    static readonly WAREHOUSE = new SpotType(
        SpotTypeName.WAREHOUSE,
        (
            <FormattedMessage
                id='SpotType.WAREHOUSER'
                description='Label for warehouse type'
                defaultMessage='Warehouse'
            />
        )
    );

    static readonly ALL = [
        SpotType.UNKNOWN,
        SpotType.STANDARD_DISTRISPOT,
        SpotType.MULTI_DISTRISPOT,
        SpotType.SMART_SHELVES,
        SpotType.AREA,
        SpotType.WAREHOUSE
    ];
    private constructor(public readonly value: SpotTypeName, public readonly displayedValue: React.ReactNode) {}

    static getByName(value: SpotTypeName): SpotType | undefined {
        return this.ALL.find((s) => s.value === value);
    }

    static getByString(value: string): SpotType | undefined {
        return this.ALL.find((s) => s.value.toString() === value);
    }
}
