import { FormattedMessage } from 'react-intl';

const SPOTS = {
    SPOT: (
        <FormattedMessage
            id='TransactionInput.Create.Transaction.Spot'
            description={'Label for transaction spot'}
            defaultMessage='Spot'
        />
    ),
    DISTRISPOT: (
        <FormattedMessage
            id='CommonMessage.SPOTS.DISTRISPOT'
            description='Label for distriSPOT'
            defaultMessage='distriSPOT'
        />
    ),
    TOTAL_SLOTS: (
        <FormattedMessage
            id='CommonMessage.SPOTS.TOTAL_SLOTS'
            description='Label for total slots'
            defaultMessage='Total SLOTs'
        />
    )
};

export default SPOTS;
