import { ClassType } from '@frontend/common';
import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaInfoCircle, FaRegCheckCircle } from 'react-icons/fa';
import { ToastOptions, toast } from 'react-toastify';

export class ToastUtil {
    static error(title: React.ReactNode, message?: React.ReactNode, options?: ToastOptions, date?: string) {
        toast.error(...this.generateToastConfig(title, message, ClassType.DANGER, options, date));
    }
    static warning(title: React.ReactNode, message?: React.ReactNode, options?: ToastOptions, date?: string) {
        toast.warning(...this.generateToastConfig(title, message, ClassType.WARNING, options, date));
    }
    static success(title: React.ReactNode, message?: React.ReactNode, options?: ToastOptions, date?: string) {
        toast.success(...this.generateToastConfig(title, message, ClassType.SUCCESS, options, date));
    }
    static info(title: React.ReactNode, message?: React.ReactNode, options?: ToastOptions, date?: string) {
        toast.info(...this.generateToastConfig(title, message, ClassType.INFO, options, date));
    }

    static show(title: React.ReactNode, message?: React.ReactNode, type?: ClassType): void {
        this.run(this.generateToastConfig(title, message, type), type);
    }

    static generateToastConfig(
        title: React.ReactNode,
        message?: React.ReactNode,
        type?: ClassType,
        options?: ToastOptions,
        date?: string
    ): [content: JSX.Element, options?: any] {
        return [
            // eslint-disable-next-line react/jsx-key
            <div>
                <div>
                    <strong className='me-auto font-weight-bolder h6'>{title ? title : 'Info'}</strong>
                </div>
                {message && <div className='toast-body'>{message}</div>}
                {date && <div className='toast-footer'>{date}</div>}
            </div>,
            { icon: ToastUtil.getIcon(type), ...options }
        ];
    }

    private static getIcon(type?: ClassType): JSX.Element {
        switch (type) {
            case ClassType.DANGER:
            case ClassType.WARNING:
                return <BsExclamationCircle className={`text-${type}`} />;
            case ClassType.SUCCESS:
                return <FaRegCheckCircle className='text-success' />;
            case ClassType.INFO:
            case ClassType.DARK:
            case ClassType.LIGHT:
            case ClassType.PRIMARY:
            case ClassType.SECONDARY:
            default:
                return <FaInfoCircle className={`text-${type ? type : 'primary'}`} />;
        }
    }

    private static run(params: [content: JSX.Element, options?: any], type?: ClassType): void {
        switch (type) {
            case ClassType.DANGER:
                toast.error(...params);
                break;
            case ClassType.WARNING:
                toast.warning(...params);
                break;
            case ClassType.SUCCESS:
                toast.success(...params);
                break;
            case ClassType.INFO:
                toast.info(...params);
                break;
            case ClassType.DARK:
                toast.dark(...params);
                break;
            case ClassType.LIGHT:
            case ClassType.PRIMARY:
            case ClassType.SECONDARY:
            default:
                toast(...params);
        }
    }
}
