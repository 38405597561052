import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { PostSlotsOptions, Slot, SlotResponse, SlotsQueryParams, fetchAvailableSlotsApi, fetchSlotByIdApi, fetchSlotsApi, postSlotsApi } from '../api/Slots';
import { RootState } from './store';

export interface SlotsState {
    slotList: { [spotId: string]: SlotResponse };
    availableSlotsList: { [spotId: string]: Slot[] };
    status: SliceStatus;
    lastUpdate: { [spotId: string]: number };
    error: ReduxError | null;
}

const initialState: SlotsState = {
    slotList: {},
    availableSlotsList: {},
    status: SliceStatus.INIT,
    lastUpdate: {},
    error: null
};

export const slotSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSlots.fulfilled, (state, action) => {
                const spotId = action.meta.arg.spot;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (spotId === undefined || spotId === null || Array.isArray(spotId)) {
                    const slots: { [spotId: string]: SlotResponse } = {};
                    const lastUpdateList: { [spotId: string]: number } = {};
                    action.payload.results.forEach((slot, index) => {
                        if (slots[slot.spot] === undefined) slots[slot.spot] = { ...action.payload, count: 0, results: [] };
                        slots[slot.spot].results.splice(index, 1, slot);
                        slots[slot.spot].count = slots[slot.spot].results.length;
                        lastUpdateList[slot.spot] = Date.now();
                    });
                    if (Array.isArray(spotId)) {
                        spotId.forEach((s) => {
                            state.slotList[s] = slots[s] !== undefined ? slots[s] : { count: 0, next: null, previous: null, results: [] };
                            state.lastUpdate[s] = Date.now();
                        });
                    } else {
                        state.slotList = slots;
                        state.lastUpdate = lastUpdateList;
                    }
                } else {
                    if (state.slotList[spotId] === undefined) state.slotList[spotId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.slotList[spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    state.lastUpdate[spotId] = Date.now();
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSlots.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAvailableSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAvailableSlots.fulfilled, (state, action) => {
                const spotId = action.meta.arg.spot;
                if (spotId === undefined || spotId === null || Array.isArray(spotId)) {
                    const slots: { [spotId: string]: Slot[] } = {};
                    const lastUpdateList: { [spotId: string]: number } = {};
                    action.payload.forEach((slot) => {
                        if (slots[slot.spot] === undefined) slots[slot.spot] = [];
                        slots[slot.spot].push(slot);
                    });
                    if (Array.isArray(spotId)) {
                        spotId.forEach((s) => {
                            state.availableSlotsList[s] = slots[s] !== undefined ? slots[s] : [];
                        });
                    } else {
                        state.availableSlotsList = slots;
                        state.lastUpdate = lastUpdateList;
                    }
                } else {
                    state.availableSlotsList[spotId] = action.payload;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAvailableSlots.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchSlotById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSlotById.fulfilled, (state, action) => {
                const slot = action.payload;
                if (state.slotList) {
                    if (state.slotList[slot.spot]) {
                        const foundSlot = state.slotList[slot.spot].results.find((s) => s.id === slot.id);
                        if (foundSlot) {
                            state.slotList[slot.spot].results.splice(state.slotList[slot.spot].results.indexOf(foundSlot), 1, slot);
                        }
                    } else {
                        state.slotList[slot.spot] = { count: 1, next: null, previous: null, results: [slot] };
                    }
                }

                state.lastUpdate[slot.spot] = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSlotById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(postSlots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(postSlots.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const spot = +action.payload.spot.replace('SPT', '');
                if (!state.slotList) {
                    state.slotList = { [spot]: { count: 1, next: null, previous: null, results: [action.payload] } };
                } else if (!state.slotList[spot]) {
                    state.slotList[spot] = { count: 1, next: null, previous: null, results: [action.payload] };
                } else {
                    const found = state.slotList[spot].results.find((s) => s.id === action.meta.arg.id);
                    if (!found) return;
                    else state.slotList[spot].results.splice(state.slotList[spot].results.indexOf(found), 1, action.payload);
                }
            });
    }
});

export const fetchSlots = createAsyncThunk<SlotResponse, ApiQueryParams<SlotsQueryParams>>(
    'fetchSlots',
    async (queryParams: ApiQueryParams<SlotsQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchSlotsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSlotById = createAsyncThunk<Slot, string>('fetchSlotById', async (id: string, { rejectWithValue }) => {
    try {
        return await fetchSlotByIdApi(id);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

/**
 * WARNING This function does not reset the lastUpdate date since it should be updated everytime the user requests it.
 */
export const fetchAvailableSlots = createAsyncThunk<Slot[], ApiQueryParams<SlotsQueryParams>>(
    'fetchAvaliableSlots',
    async (queryParams: ApiQueryParams<SlotsQueryParams>, { rejectWithValue }) => {
        let newParams: ApiQueryParams<SlotsQueryParams> = {};
        if (queryParams) {
            newParams = { ...queryParams, status: 'available' };
        }

        try {
            return await fetchAvailableSlotsApi(newParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const postSlots = createAsyncThunk<Slot, PostSlotsOptions>('postSlots', async (params: PostSlotsOptions, { rejectWithValue }) => {
    try {
        return await postSlotsApi(params);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const selectSlots = (state: RootState) => state.slots.slotList;

export default slotSlice.reducer;
