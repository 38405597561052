import { GrClose } from 'react-icons/gr';

import Modal, { ModalProps } from './Modal';

export interface DisplayModalProps extends ModalProps {
    handleClose: () => void;
    header: string | React.ReactElement;
}

const DisplayModal = (props: DisplayModalProps) => {
    return (
        <Modal
            handleClose={props.handleClose}
            show={props.show}
            customWidth={props.customWidth ? props.customWidth : 40}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-primary text-gradient'>{props.header}</h3>
                <button
                    id='DisplayModal.closeButton'
                    className='btn btn-outline-secondary'
                    onClick={props.handleClose}>
                    <GrClose />
                </button>
            </div>
            <div>{props.children}</div>
        </Modal>
    );
};

export default DisplayModal;
