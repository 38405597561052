import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from "@frontend/api-utils";
import { Product } from "../product";
import { CreateProductParams, ProductResponse, ProductSlotsModel, ProductsQueryParams, UpdateProductParams } from "./models";

const productViewSet: ApiViewSet = {
    baseName: 'products'
};

export class ProductAPIClient extends APIClient {
    public static async fetchProductsApi(queryParams?: ApiQueryParams<ProductsQueryParams> | null): Promise<ProductResponse> {
        return await this.apiPagination<ProductResponse, ProductsQueryParams>(productViewSet, queryParams);
    }
    
    public static async fetchProductApi(options: DetailOptions): Promise<Product> {
        return this.apiDetail<Product>(productViewSet, options);
    }
    
    public static async updateSlotsOfProductApi(options: ProductSlotsModel): Promise<Product> {
        let slotsUpdateUrl = '/products/';
    
        if (options && options.id) {
            slotsUpdateUrl += options.id + '/';
        }
    
        const body = options.updatedSlotsList;
        const response = await this.patch(slotsUpdateUrl, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating Slots');
            }
            throw new ApiError('Error updating Slots', json);
        }
        return await response.json();
    }
    
    public static async createProductApi(options: CreateProductParams): Promise<Product> {
        const URL = '/products/';
        const body = options.body;
        const response = await this.post(URL, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating product');
            }
            throw new ApiError('Error creating product', json);
        }
        return await response.json();
    }
    
    public static async updateProductApi(options: UpdateProductParams): Promise<Product> {
        let URL = '/products/';
        if (options && options.id) {
            URL += options.id + '/';
        }
        const body = options.body;
    
        const response = await this.put(URL, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating product');
            }
            throw new ApiError('Error updating product', json);
        }
    
        return await response.json();
    }
    
    public static async deleteProductApi(productId: number): Promise<boolean> {
        const URL = `/products/${productId}/`;
        const response = await this.delete(URL);
        return await response.ok;
    }
    
    public static async uploadProductImagesApi(body: FormData): Promise<{ data: string }> {
        const URL = `/product-upload/images/`;
        const response = await this.post(URL, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error uploading images');
            }
            throw new ApiError('Error uploading images', json);
        }
    
        return await response.json();
    }
    
    public static async uploadProductCSVApi(body: FormData): Promise<{ data: string }> {
        const URL = `/product-upload/`;
        const response = await this.post(URL, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error uploading CSV');
            }
            throw new ApiError('Error uploading CSV', json);
        }
        return await response.json();
    }
    
    public static async searchProductsApi(queryParams: ApiQueryParams<ProductsQueryParams>): Promise<ProductResponse> {
        return await this.apiPagination<ProductResponse, ProductsQueryParams>(productViewSet, queryParams);
    }
}