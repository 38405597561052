import { SliceStatus } from '@frontend/common';
import { fetchShops } from '@frontend/shop';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { Terminal, TerminalSettingsBody } from '../../../api/Terminals';
import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import Workflow from '../../../common/Workflow';
import CheckBox from '../../../components/forms/check/CheckBox';
import CheckSwitch from '../../../components/forms/check/CheckSwitch';
import NumberInput from '../../../components/forms/numberInput/NumberInput';
import SelectInput from '../../../components/forms/select/Select';
import ContactGroupSelect from '../../../components/forms/select/contacts/ContactGroupSelect';
import ContactSelect from '../../../components/forms/select/contacts/ContactSelect';
import TextArea from '../../../components/forms/textArea/TextArea';
import TextInput from '../../../components/forms/textInput/TextInput';
import TimeInput from '../../../components/forms/timeInput/TimeInput';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setTerminalSettings, switchTerminal } from '../../../store/terminalSlice';
import { GetError } from '../../../store/utils/GetError';
import { parseObject } from '../../../utils/ParseObject';
import { contactRegistrationAllowed, registrationAllowed, unknownContactRegistrationAllowed } from './SpotSettings';

export interface TerminalSettingsProps {
    terminal: Terminal;
    handleClose: () => void;
}

const TerminalSettings = (props: TerminalSettingsProps) => {
    useAuthorization(DefaultRole.SUPER_ADMIN, true);
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const spotState = useAppSelector((state) => state.spots);
    const terminalState = useAppSelector((state) => state.terminals);
    const spotLayoutState = useAppSelector((state) => state.spotLayouts);
    const shopState = useAppSelector((state) => state.shops);
    const [submitted, changeSubmitted] = useState(false);
    const [terminalSettings, changeTerminalSettings] = useState<any>();

    useEffect(() => {
        if (shopState.shopList === null) {
            dispatch(fetchShops({ account: accountId?.toString() }));
        }
    }, []);

    const initialTerminalsState: TerminalSettingsBody = {
        account: accountId!,
        name: props.terminal.name,
        workflow: Workflow.getByString(props.terminal.workflow)?.value,
        spot_layout: props.terminal.spot_layout,
        delivery_contacts: props.terminal.delivery_contacts,
        delivery_contact_other: props.terminal.delivery_contact_other,
        default_receiver_contact: props.terminal.default_receiver_contact,
        receiver_contact_group: props.terminal.receiver_contact_group,
        shops: props.terminal.shops,
        additional_data: props.terminal.additional_data,
        settings_switch_terminal_on_at: props.terminal.settings_switch_terminal_on_at,
        settings_switch_terminal_off_at: props.terminal.settings_switch_terminal_off_at,
        settings_informational_message: props.terminal.settings_informational_message,
        settings_registration_allowed: props.terminal.settings_registration_allowed,
        settings_contact_registration_allowed: props.terminal.settings_contact_registration_allowed,
        settings_unknown_contact_registration_allowed: props.terminal.settings_unknown_contact_registration_allowed,
        settings_door_open_time_in_seconds: props.terminal.settings_door_open_in_seconds,
        settings_enable_ws_logger: props.terminal.settings_enable_ws_logger,
        settings_keep_ws_logs_in_days: props.terminal.settings_keep_ws_logs_in_days
    };
    useEffect(() => {
        changeTerminalSettings(initialTerminalsState);
    }, []);

    const handleTerminalSwitch = (t: any, value: boolean) => {
        const data = value == true ? true : false;
        dispatch(switchTerminal({ id: t, actions_switch_terminal_on: data }));
    };

    useEffect(() => {
        if (submitted) {
            dispatch(setTerminalSettings({ body: parseObject(terminalSettings) as TerminalSettingsBody, id: props.terminal.id }));
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted) {
            if (terminalState.status === SliceStatus.IDLE) {
                toast.success(
                    ...ToastUtil.generateToastConfig(
                        CommonMessage.STATUS.SUCCESS,
                        <FormattedMessage
                            id='TerminalSettings.Success'
                            description='Success message after updating terminal settings'
                            defaultMessage='Terminal settings updated successfully'
                        />,
                        ClassType.SUCCESS
                    )
                );
                props.handleClose();
            } else if (terminalState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, GetError(terminalState.error), ClassType.DANGER));
                props.handleClose();
            }
        }
    }, [terminalState]);

    if (!terminalSettings) {
        return <></>;
    }

    return (
        <>
            <form
                method='POST'
                onSubmit={(e) => {
                    e.preventDefault();
                    changeSubmitted(true);
                }}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <CheckSwitch
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.Terminal.Switch'
                                        description={'Title for terminal switch'}
                                        defaultMessage={'Switch terminal on/off'}
                                    />
                                }
                                checked={terminalSettings.actions_switch_terminal_on}
                                onChange={(value) => handleTerminalSwitch(props.terminal.id, value)}
                                submitted={submitted}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={CommonMessage.FORM.CONTACTS.NAME}
                                submitted={submitted}
                                value={terminalSettings.name}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, name: v })}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={CommonMessage.FORM.TERMINAL.WORKFLOW}
                                submitted={submitted}
                                options={Workflow.ALL.map((w) => ({ value: w.value, label: w.displayedValue }))}
                                value={terminalSettings.workflow}
                                onChange={(v: any) => changeTerminalSettings({ ...terminalSettings, workflow: v.value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.SpotLayout'
                                        description='Label for the spotlayout'
                                        defaultMessage='Spotlayout'
                                    />
                                }
                                submitted={submitted}
                                options={spotLayoutState.spotLayoutList
                                    ?.filter((s) => spotState.spotList?.map((spot) => +spot.id.replace('SPT', '')).includes(s.spot as number))
                                    .map((spotlayout) => ({
                                        value: spotlayout.id,
                                        label: spotState.spotList?.find((s) => +s.id.replace('SPT', '') === (spotlayout.spot as number))?.name
                                    }))}
                                value={terminalSettings.spot_layout}
                                onChange={(v: any) => changeTerminalSettings({ ...terminalSettings, spot_layout: v.value })}
                            />
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.Shops'
                                        description='Label for the shops'
                                        defaultMessage='Shops'
                                    />
                                }
                                submitted={submitted}
                                options={shopState.shopList?.map((s) => ({ value: s.id, label: s.name }))}
                                value={terminalSettings.shops}
                                isMulti
                                onChange={(v: any) => changeTerminalSettings({ ...terminalSettings, shops: v })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ContactGroupSelect
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.delivery_contacts'
                                        description='Label for the delivery contact'
                                        defaultMessage='Delivery contact'
                                    />
                                }
                                submitted={submitted}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, delivery_contacts: v })}
                                value={terminalSettings.delivery_contacts}
                            />
                        </div>
                        <div className='col-6'>
                            <ContactGroupSelect
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.delivery_contact_other'
                                        description='Label for the delivery contact other'
                                        defaultMessage='Delivery contact other'
                                    />
                                }
                                submitted={submitted}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, delivery_contact_other: v })}
                                value={terminalSettings.delivery_contact_other}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <ContactSelect
                                label={CommonMessage.FORM.CONTACTS.DEFAULT_RECEIVER}
                                submitted={submitted}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, default_receiver_contact: v })}
                                value={terminalSettings.default_receiver_contact}
                            />
                        </div>
                        <div className='col-6'>
                            <ContactGroupSelect
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.receiver_contact_group'
                                        description='Label for receiver contact group'
                                        defaultMessage='Receiver contact group'
                                    />
                                }
                                submitted={submitted}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, receiver_contact_group: v })}
                                value={terminalSettings.receiver_contact_group}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TimeInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.Terminal.Switch.On'
                                        description={'Title for terminal switch on'}
                                        defaultMessage='Terminal switch on at'
                                    />
                                }
                                submitted={false}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, settings_switch_terminal_on_at: v })}
                                value={terminalSettings.settings_switch_terminal_on_at}
                            />
                        </div>
                        <div className='col-6'>
                            <TimeInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.Terminal.Switch.Off'
                                        description={'Title for terminal switch off'}
                                        defaultMessage='Switch off'></FormattedMessage>
                                }
                                submitted={false}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, settings_switch_terminal_off_at: v })}
                                value={terminalSettings.settings_switch_terminal_off_at}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <TextArea
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.Informational.Message'
                                        description={'Title for informational message'}
                                        defaultMessage='Informational Message'></FormattedMessage>
                                }
                                submitted={false}
                                value={terminalSettings.settings_informational_message}
                                onChange={(value) =>
                                    changeTerminalSettings({
                                        ...terminalSettings,
                                        settings_informational_message: value
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <CheckBox
                                onChange={(value) => changeTerminalSettings({ ...terminalSettings, settings_registration_allowed: value })}
                                checked={terminalSettings.settings_registration_allowed}
                                label={registrationAllowed}
                                submitted={false}
                                id='CheckBox-settings_registration_allowed'
                            />
                        </div>
                        <div className='col-6'>
                            <CheckBox
                                onChange={(value) => changeTerminalSettings({ ...terminalSettings, settings_contact_registration_allowed: value })}
                                checked={terminalSettings.settings_contact_registration_allowed}
                                label={contactRegistrationAllowed}
                                submitted={false}
                                id='CheckBox-settings_contact_registration_allowed'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <CheckBox
                                onChange={(value) => changeTerminalSettings({ ...terminalSettings, settings_unknown_contact_registration_allowed: value })}
                                checked={terminalSettings.settings_unknown_contact_registration_allowed}
                                label={unknownContactRegistrationAllowed}
                                submitted={false}
                                id='CheckBox-settings_unknown_contact_registration_allowed'
                            />
                        </div>
                        <div className='col-6'>
                            <CheckBox
                                checked={terminalSettings.settings_enable_ws_logger}
                                submitted={submitted}
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.ws_logs_active'
                                        description='Label for ws logs active checkbox'
                                        defaultMessage='WS logger active'
                                    />
                                }
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, settings_enable_ws_logger: v })}
                                id='CheckBox-settings_enable_ws_logger'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='SpotSettings.Door.Open.In.Time'
                                        description={'Title for door open in time'}
                                        defaultMessage='Door open in time'></FormattedMessage>
                                }
                                submitted={false}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, settings_door_open_time_in_seconds: v })}
                            />
                        </div>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='TerminalSettings.ws_logger_days'
                                        description='Label for keep ws logs in days setting'
                                        defaultMessage='Keep ws logs in days'
                                    />
                                }
                                submitted={submitted}
                                onChange={(v) => changeTerminalSettings({ ...terminalSettings, settings_keep_ws_logs_in_days: v })}
                                value={terminalSettings.settings_keep_ws_logs_in_days}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <CreateModalFooter
                onSubmit={() => changeSubmitted(true)}
                handleClose={() => props.handleClose()}></CreateModalFooter>
        </>
    );
};

export default TerminalSettings;
