import { GrClose } from 'react-icons/gr';

import Modal, { ModalProps, ModalType } from './Modal';

export interface CreateModalProps extends ModalProps {
    handleClose: () => void;
    header: string;
}

const CreateModal = (props: CreateModalProps) => {
    return (
        <Modal
            {...props}
            handleClose={props.handleClose}
            show={props.show}
            id={props.id}
            type={ModalType.PROMPT}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-primary text-gradient'>{props.header}</h3>
                <button
                    id='CreateModal-CloseButton'
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>
            <div>{props.children}</div>
        </Modal>
    );
};

export default CreateModal;
