import { UserInfo } from '@frontend/user';
import { Row } from 'react-table';

import UserBadge from '../../components/badges/user-badge/user-badge.component';
import convertLanguage from '../../utils/LanguageConvert';

interface UserListElementProps {
    selectEnabled?: boolean;
    row: Row<UserInfo>;
    onClick?: (element: UserInfo) => void;
}

const UserListElement = (props: UserListElementProps) => {
    const language = convertLanguage(props.row.original.language);

    return (
        <tr
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-items-center'>
                <UserBadge
                    userId={props.row.original.id.toString()}
                    user={props.row.original}
                />
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7'>
                <span>{props.row.original.email}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7'>
                <span>{props.row.original.first_name}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7'>
                <span>{props.row.original.last_name}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7'>
                <span>{language}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7'>
                <span>{props.row.original.mobile}</span>
            </td>
        </tr>
    );
};

export default UserListElement;
