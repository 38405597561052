import { Props } from './product-badge.component';

interface ViewProps {
    name: string;
}

const useProductBadge = (props: Props): ViewProps => {
    const name = props.product ? props.product.name : props.name ? props.name : 'Unknown';

    return {
        name
    };
};

export default useProductBadge;
