import { Attribute, fetchAttributes, searchAttribute } from '@frontend/attribute';
import { fetchCategories } from '@frontend/category';
import { SliceStatus, shouldFetch } from '@frontend/common';
import { fetchProducts } from '@frontend/product';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';

import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import AttributeListColumns from './AttributeListColumns';

interface ViewProps {
    filteredAttributes: Attribute[] | null;
    changeCreateAttributeOpen: React.Dispatch<React.SetStateAction<boolean>>;
    createAttributeOpen: boolean;
    setFilterValue: React.Dispatch<React.SetStateAction<string | undefined>>;
    changeTextFilter: React.Dispatch<React.SetStateAction<Attribute[] | undefined>>;
    columns: Column<Attribute>[];
    data: Attribute[];
    selectedAttribute: Attribute | undefined;
    changeSelectedAttribute: React.Dispatch<React.SetStateAction<Attribute | undefined>>;
    sortKeys: string[];
    changeCustomSorting: React.Dispatch<React.SetStateAction<{ column: string; orderBy: string | undefined } | undefined>>;
}

const useAttributeList = (): ViewProps => {
    const authorised = useAuthorization(DefaultRole.SUPER_ADMIN, true);

    const { shopId } = useParams();
    const [URL_ATTRIBUTE_ID, changeURL_ATTRIBUTE_ID] = useState<string | null>(new URLSearchParams(window.location.search).get('id'));
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const attributes = useAppSelector((state) => state.attributes);
    const products = useAppSelector((state) => state.products);
    const categories = useAppSelector((state) => state.categories);
    const [createAttributeOpen, changeCreateAttributeOpen] = useState<boolean>(false);

    const [selectedAttribute, changeSelectedAttribute] = useState<Attribute>();
    const [filteredAttributes, changeFilteredAttributes] = useState<Attribute[] | null>(attributes.attributeList);
    const [filterValue, setFilterValue] = useState<string>();
    const [textFilter, changeTextFilter] = useState<Attribute[] | undefined>(undefined);
    const [customSorting, changeCustomSorting] = useState<{ column: string; orderBy: string | undefined } | undefined>(undefined);
    const sortKeys = ['id', 'name', 'type'];

    useEffect(() => {
        if (authorised) {
            dispatch(fetchAttributes({ account: accountId.toString() }));
        }
    }, []);

    useEffect(() => {
        if (authorised && attributes.attributeList !== null && attributes.status !== SliceStatus.LOADING) {
            if (shouldFetch(products.status, products.lastUpdate))
                dispatch(fetchProducts({ show_all: 'true', page: '1', page_size: '1000', account: accountId.toString(), shop: shopId }));
            if (shouldFetch(categories.status, categories.lastUpdate)) dispatch(fetchCategories({ account: accountId.toString() }));
        }
        if (selectedAttribute && attributes.attributeList) {
            const foundAttribute = attributes.attributeList.find((a) => a.id === selectedAttribute.id);
            if (foundAttribute) changeSelectedAttribute(foundAttribute);
        }
    }, [attributes.attributeList, attributes.status]);

    useEffect(() => {
        if (authorised) {
            if (!customSorting && !filterValue) {
                dispatch(fetchAttributes({ account: accountId?.toString() }));
            } else if (filterValue && customSorting) {
                dispatch(
                    searchAttribute({
                        account: accountId?.toString(),
                        search: filterValue,
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            } else if (filterValue && !customSorting) {
                dispatch(searchAttribute({ account: accountId?.toString(), search: filterValue }));
            } else if (!filterValue && customSorting) {
                dispatch(
                    fetchAttributes({
                        account: accountId?.toString(),
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            }
        }
    }, [customSorting]);
    useEffect(() => {
        if (!attributes.attributeList) return;

        let validAttributes: Attribute[] = attributes.attributeList;

        if (!filterValue) {
            validAttributes = attributes.attributeList;
        } else if (attributes.searchAttributeList && attributes.searchAttributeList[filterValue]) {
            validAttributes = attributes.searchAttributeList[filterValue];
        }

        changeFilteredAttributes(validAttributes);
    }, [attributes]);

    useEffect(() => {
        if (filterValue !== '') {
            dispatch(searchAttribute({ search: filterValue, account: accountId?.toString() }));
        } else if (attributes.attributeList) {
            changeFilteredAttributes(attributes.attributeList);
        }
    }, [filterValue]);

    const data = useMemo(() => {
        if (filteredAttributes) return filteredAttributes;
        else return [];
    }, [filteredAttributes]);
    const columns: Column<Attribute>[] = useMemo(() => AttributeListColumns, []);

    return {
        changeCreateAttributeOpen,
        changeSelectedAttribute,
        changeTextFilter,
        columns,
        createAttributeOpen,
        data,
        filteredAttributes,
        selectedAttribute,
        setFilterValue,
        changeCustomSorting,
        sortKeys
    };
};

export default useAttributeList;
