import { useEffect } from 'react';

import { BackendStatusUpdate } from '../utils/BackendStatusUpdate';

const useSendLogsBeforeUnload = (realAccountId: number | undefined) => {
    useEffect(() => {
        const handleBeforeUnload = async () => {
            if (realAccountId) {
                try {
                    await BackendStatusUpdate(realAccountId);
                } catch (err) {
                    console.error(err);
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [realAccountId]);
};

export default useSendLogsBeforeUnload;
