import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum LogSourceName {
    PLATFORM = 'platform',
    IOT = 'iot',
    TERMINAL = 'terminal',
    WEB = 'web'
}

export class LogSource {
    static readonly PLATFORM = new LogSource(
        LogSourceName.PLATFORM,
        (
            <FormattedMessage
                id='LogSource.PLATFORM'
                description='The displayed value for the log source PLATFORM'
                defaultMessage='Platform'
            />
        )
    );
    static readonly IOT = new LogSource(
        LogSourceName.IOT,
        (
            <FormattedMessage
                id='LogSource.IOT'
                description='The displayed value for the log source IOT'
                defaultMessage='IOT'
            />
        )
    );
    static readonly TERMINAL = new LogSource(
        LogSourceName.TERMINAL,
        (
            <FormattedMessage
                id='LogSource.TERMINAL'
                description='The displayed value for the log source TERMINAL'
                defaultMessage='Terminal'
            />
        )
    );
    static readonly WEB = new LogSource(
        LogSourceName.WEB,
        (
            <FormattedMessage
                id='LogSource.WEB'
                description='The displayed value for the log source WEB'
                defaultMessage='Web'
            />
        )
    );

    static readonly ALL = [LogSource.PLATFORM, LogSource.IOT, LogSource.TERMINAL, LogSource.WEB];

    private constructor(public readonly name: LogSourceName, public readonly displayedValue: React.ReactNode) {}

    static getByName(name: LogSourceName): LogSource | undefined {
        return this.ALL.find((s) => s.name === name);
    }
    static getByString(value: string): LogSource | undefined {
        return this.ALL.find((s) => s.name.toString() === value);
    }
}
