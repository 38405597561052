import { TransactionContact, TransactionSlot, TransactionSpot } from '@frontend/transaction';

import { Locale } from './BackendEnums';

export enum TransactionEventState {
    NEW = 'new',

    SCHEDULE_INIT = 'schedule_init',
    SCHEDULE_CHANGED = 'schedule_changed',
    SCHEDULE_WAITING = 'schedule_waiting',
    SCHEDULE_IN_PROGRESS = 'schedule_in_progress',
    SCHEDULE_DONE = 'schedule_done',
    SCHEDULE_CANCELLED = 'schedule_cancelled',
    SCHEDULED_PICKUP_DONE = 'scheduled_pickup_done',

    DROPOFF_INIT = 'dropoff_init',
    DROPOFF_WAITING = 'dropoff_waiting',
    DROPOFF_TIMEOUT = 'dropoff_timeout',
    DROPOFF_IN_PROGRESS = 'dropoff_in_progress',
    DROPOFF_DONE = 'dropoff_done',
    DROPOFF_CLEANED = 'dropoff_cleaned',
    DROPOFF_CANCELLED = 'dropoff_cancelled',

    PICKUP_INIT = 'pickup_init',
    PICKUP_WAITING = 'pickup_waiting',
    PICKUP_TIMEOUT = 'pickup_timeout',
    PICKUP_IN_PROGRESS = 'pickup_in_progress',
    PICKUP_DONE = 'pickup_done',
    PICKUP_CLEANED = 'pickup_cleaned',
    PICKUP_CANCELLED = 'pickup_cancelled',
    PICKUP_CANCELLED_CLEANED = 'pickup_cancelled_cleaned',

    REMOVE_PARCEL_INIT = 'remove_parcel_init',
    REMOVE_PARCEL_WAITING = 'remove_parcel_waiting',
    REMOVE_PARCEL_TIMEOUT = 'remove_parcel_timeout',
    REMOVE_PARCEL_IN_PROGRESS = 'remove_parcel_in_progress',
    REMOVE_PARCEL_DONE = 'remove_parcel_done',
    REMOVE_PARCEL_CLEANED = 'remove_parcel_cleaned',

    CANCELLED = 'cancelled',
    DONE = 'done',

    ERROR = 'error',
    ERROR_DONE = 'error_done'
}

export interface TransactionEvent {
    id: number;
    event_data: TransactionEventType;
    created_date: Date;
    event_date: Date;
    transaction: number;
    created_by: null | number;
    detail?: unknown;

    times?: number;
}

export interface TransactionMovedDetail {
    old_spot: TransactionSpot;
    old_slot: TransactionSlot;
    new_spot: TransactionSpot;
    new_slot: TransactionSlot;
}

export interface NotificationDetail {
    contact: TransactionContact;
}

interface TransactionEventType {
    message: string;
}

export interface TransactionNotificationEvent extends TransactionEventType {
    language: Locale;
    contact_id: number;
    recipient_email: string;
    recipient_mobile: string;
    notification_type: string;
    notifications_config: any;
}
export interface TransactionStatusUpdateEvent extends TransactionEventType {
    detail: null; //TODO: what is its purpuse
    state_to: TransactionEventState;
    state_from: TransactionEventState;
    state_from_arg: string;
}

export interface TransactionMovedEvent extends TransactionEventType {
    detail: {
        old_slot_id: number;
        old_spot_id: number;
        old_transaction: number;
    };
}

export interface TransactionErrorEvent extends TransactionEventType {
    detail: string;
}

export interface ContactUpdateEvent extends TransactionEventType {
    is_sender: boolean;
    fields: any;
}

export interface AccessKeyEvent extends TransactionEventType {
    created: boolean;
    access_key_name: string;
    access_key_id: string;
}

export interface GroupChangeEvent extends TransactionEventType {
    is_sender: boolean;
    transaction_group_id: string;
    transaction_group_name: string;
    transaction_contact_id: string;
    transaction_contact_name: string;
}

export function isNotificationEvent(event_data: TransactionEventType): event_data is TransactionNotificationEvent {
    return event_data.message === 'notification sent';
}

export function isStatusUpdateEvent(event_data: TransactionEventType): event_data is TransactionStatusUpdateEvent {
    return event_data.message === 'Transaction transition';
}

export function isMovedEvent(event_data: TransactionEventType): event_data is TransactionMovedEvent {
    return event_data.message.includes('Initiated distriSPOT/SLOT change');
}

export function isTransactionErrorEvent(event_data: TransactionEventType): event_data is TransactionErrorEvent {
    return event_data.message === 'Transaction error event';
}

export function isGroupChangeEvent(event_data: TransactionEventType): event_data is GroupChangeEvent {
    return (
        event_data.message === 'sender group removed' ||
        event_data.message === 'receiver group removed' ||
        event_data.message === 'sender group added' ||
        event_data.message === 'receiver group added'
    );
}

export function isAccessKeyEvent(event_data: TransactionEventType): event_data is AccessKeyEvent {
    return event_data.message === 'access key updated' || event_data.message === 'access key added';
}

export function isContactUpdateEvent(event_data: TransactionEventType): event_data is ContactUpdateEvent {
    return event_data.message === 'receiver update' || event_data.message === 'sender update';
}
