import { toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getInputClass } from '../../../utils/FormUtils';

interface NumberInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: number) => void;
    onFocusChange?: () => void;
    value?: string | number;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    disabled?: boolean;
    submitted: boolean;
    min?: number;
    max?: number;
    id?: string;
    minLength?: number;
    maxLength?: number;
    isClearable?: boolean;
}

export default function NumberInput(props: NumberInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(false);
    const [value, setValue] = useState<string | number | undefined>();
    const [error, changeError] = useState<NumberInputError>(NumberInputError.NONE);

    const isValid = (): boolean => {
        let error = NumberInputError.NONE;
        if (props.required === true && (props.value === '' || props.value === undefined)) error = NumberInputError.REQUIRED;
        if ((props.minLength && !props.value) || (props.minLength && props.value && props.value.toString().length < props.minLength)) {
            error = NumberInputError.MIN_LENGTH;
        }
        if ((props.maxLength && !props.value) || (props.maxLength && props.value && props.value.toString().length > props.maxLength)) {
            error = NumberInputError.MAX_LENGTH;
        }
        if (
            (props.minLength && props.maxLength && !props.value) ||
            (props.minLength &&
                props.maxLength &&
                props.value &&
                (props.value.toString().length < props.minLength || props.value?.toString().length > props.maxLength))
        ) {
            error = NumberInputError.MIN_MAX_LENGTH;
        }
        if (props.isClearable && isNaN(toNumber(props.value))) {
            error = NumberInputError.NONE;
        }
        changeError(error);
        changeValid(error === NumberInputError.NONE);
        return error === NumberInputError.NONE;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);
    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <input
                    className={getInputClass(touched, valid, props.value?.toString())}
                    autoFocus={props.autoFocus}
                    id={props.id}
                    value={value ? value : props.value}
                    min={props.min}
                    max={props.max}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    type='number'
                    step='.01'
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        const numberValue = parseFloat(event.target.value);
                        if (isNaN(numberValue)) {
                            if (props.isClearable) {
                                changeTouched(false);
                                setValue(undefined);
                                props.isValidCallback && props.isValidCallback(true);
                            } else {
                                props.isValidCallback && props.isValidCallback(false);
                            }
                        }
                        if (!isNaN(numberValue) || event.target.value === '') {
                            if (props.onChange) {
                                setValue(numberValue);
                                props.onChange(numberValue);
                                if (props.min !== undefined) {
                                    if (numberValue < props.min) {
                                        props.onChange(props.min);
                                    }
                                }
                                if (props.max !== undefined) {
                                    if (numberValue > props.max) {
                                        props.onChange(props.max);
                                    }
                                }
                                if (props.maxLength !== undefined) {
                                    if (numberValue.toString().length > props.maxLength) {
                                        const stringValue = numberValue.toString();
                                        setValue(parseFloat(stringValue.slice(0, props.maxLength)));
                                        props.onChange(parseFloat(stringValue.slice(0, props.maxLength)));
                                    }
                                }
                            }
                        }
                        isValid();
                    }}
                    disabled={props.disabled}
                />

                {valid === false && touched === true ? (
                    <>
                        {props.errorMessage === undefined ? (
                            <>
                                {error === NumberInputError.MIN_LENGTH && MinLengthError(props.minLength)}
                                {error === NumberInputError.MAX_LENGTH && MaxLengthError(props.maxLength)}
                                {error === NumberInputError.MIN_MAX_LENGTH && MinMaxLengthError(props.minLength, props.maxLength)}
                            </>
                        ) : (
                            <span className='badge bg-gradient-danger mt-2'>
                                <small>{props.errorMessage}</small>
                            </span>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

enum NumberInputError {
    NONE,
    MIN_LENGTH,
    MAX_LENGTH,
    MIN_MAX_LENGTH,
    REQUIRED
}

export const MinLengthError = (minLength: number | undefined) => (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='NumberInput.MinLength'
                description='Min length error message'
                defaultMessage={`Minimum length is {minLength}`}
                values={{ minLength: minLength }}
            />
        </small>
    </span>
);

export const MaxLengthError = (maxLength: number | undefined) => (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='NumberInput.maxLength'
                description='Max length error message'
                defaultMessage={`Maximum length is {maxLength}`}
                values={{ maxLength: maxLength }}
            />
        </small>
    </span>
);

export const MinMaxLengthError = (minLength: number | undefined, maxLength: number | undefined) => (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='NumberInput.minMaxLength'
                description='Min max length error message'
                defaultMessage={`Length of the number should be between {minLength} and {maxLength}`}
                values={{ minLength: minLength, maxLength: maxLength }}
            />
        </small>
    </span>
);
