import { Transaction } from '@frontend/transaction';
import { GrClose } from 'react-icons/gr';

import { BulkTransaction } from '../../../api/BulkTransactions';
import Spinner from '../../../components/loading/Spinner';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import BulkTransactionDetail from '../BulkTransactionDetail';

export const BulkTransactionID = 'bulk-transaction-detail-modal';

export interface Props extends ModalProps {
    bulkTransaction: BulkTransaction;
    transactions?: Transaction[];
}

const BulkTransactionDetailModal = (props: Props): JSX.Element => {
    if (!props.bulkTransaction) return <Spinner />;
    return (
        <Modal
            type={ModalType.PANE}
            id={BulkTransactionID}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.bulkTransaction?.id}</h3>
                <button
                    id='TransactionDetailModal-close'
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>

            <div className='modal-body'>
                <BulkTransactionDetail
                    bulkTransaction={props.bulkTransaction}
                    transactions={props.transactions}
                />
            </div>
        </Modal>
    );
};

export default BulkTransactionDetailModal;
