import { HiExclamationCircle } from 'react-icons/hi';

import { TransactionEvent, isTransactionErrorEvent } from '../../../common/TransactionEvents';
import TimeLineItemTextField from './TimeLineItemTextField';

export default function ErrorEvent(props: { event: TransactionEvent }) {
    if (!isTransactionErrorEvent(props.event.event_data)) return <></>;
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <HiExclamationCircle className='text-danger display-1' />
            </span>
            <TimeLineItemTextField title={props.event.event_data.message} />
        </div>
    );
}
