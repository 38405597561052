import { attributeReducer } from '@frontend/attribute';
import { categoryReducer } from '@frontend/category';
import { contactReducer } from '@frontend/contact';
import { contactGroupReducer } from '@frontend/contact-group';
import { productReducer } from '@frontend/product';
import { productAttributeReducer } from '@frontend/product-attribute';
import { shopReducer } from '@frontend/shop';
import { transactionReducer } from '@frontend/transaction';
import { userReducer } from '@frontend/user';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import bulkTransactionReducer from './bulkTransactionSlice';
import dashboardReducer from './dashboardSlice';
import accessControllerReducer from './iot/accessControllerSlice';
import printerReducer from './iot/printerSlice';
import logSliceReducer from './logSlice';
import notificationReducer from './notifications/notificationSlice';
import reportCategoryReducer from './reportCategoriesSlice';
import reportReducer from './reportSlice';
import slotReducer from './slotSlice';
import slotLogsReducer from './slots/slotLogsSlice';
import spotLayoutReducer from './spotLayoutSlice';
import spotReducer from './spotSlice';
import terminalReducer from './terminalSlice';
import userInvitesReducer from './userInvitesSlice';

export const iotReducer = combineReducers({
    printer: printerReducer,
    accessController: accessControllerReducer
});

export const store = configureStore({
    reducer: {
        user: userReducer,
        userInvites: userInvitesReducer,
        spots: spotReducer,
        transactions: transactionReducer,
        bulkTransactions: bulkTransactionReducer,
        contacts: contactReducer,
        slots: slotReducer,
        contactGroups: contactGroupReducer,
        terminals: terminalReducer,
        spotLayouts: spotLayoutReducer,
        shops: shopReducer,
        products: productReducer,
        attributes: attributeReducer,
        categories: categoryReducer,
        reportCategories: reportCategoryReducer,
        slotLogs: slotLogsReducer,
        printers: printerReducer,
        logs: logSliceReducer,
        iot: iotReducer,
        dashboard: dashboardReducer,
        reports: reportReducer,
        productAttributes: productAttributeReducer,
        notification: notificationReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
