import { Placement } from '@floating-ui/react-dom';
import { CSSProperties } from 'react';

interface NotificationContainerProps {
    isOpen?: boolean;
    children: React.ReactNode;
    placement: Placement;
    setRef?: any;
    floatingStyles?: CSSProperties;
}
const NotificationContainer = (props: NotificationContainerProps) => {
    return (
        <div
            ref={props.setRef}
            style={{ ...props.floatingStyles, zIndex: 1001 }}
            className={'mt-1'}>
            {props.children}
        </div>
    );
};

export default NotificationContainer;
