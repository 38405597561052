import { RiDoorLockFill } from 'react-icons/ri';

import { TransactionEvent, isAccessKeyEvent } from '../../../common/TransactionEvents';
import TimeLineItemTextField from './TimeLineItemTextField';

export default function AccessKeyEvent(props: { event: TransactionEvent }) {
    if (!isAccessKeyEvent(props.event.event_data)) return <></>;
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <RiDoorLockFill className={'text-' + (props.event.event_data.created ? 'success' : 'warning') + ' display-1'} />
            </span>
            <TimeLineItemTextField
                title={props.event.event_data.message}
                date={props.event.event_date}
            />
        </div>
    );
}
