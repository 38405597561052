import { APIClient, ApiError, ApiQueryParams, ApiViewSet } from '@frontend/api-utils';

import { Contact } from '../contact';
import { ContactAccessKey, ContactQueryParams, ContactResponse, ContactsOptions, CreateContactModel } from './models';

const contactViewSet: ApiViewSet = {
    baseName: 'contacts'
};

export class ContactApiClient extends APIClient {
    public static async fetchContactsApi(queryParams?: ApiQueryParams<ContactQueryParams>): Promise<ContactResponse> {
        return await this.apiPagination<ContactResponse, ContactQueryParams>(contactViewSet, queryParams);
    }

    public static async fetchContactApi(options: ContactsOptions): Promise<Contact> {
        if (options && options.contactUrl) {
            return this.apiDetail<Contact>(contactViewSet, { url: options.contactUrl });
        } else if (options && options.contactId) {
            return this.apiDetail<Contact>(contactViewSet, { id: options.contactId });
        }

        if (!options.contactUrl && options.contactLoginId) {
            options.contactUrl = `/contacts/${options.contactLoginId!}/`;
        } else options.contactUrl = `/contacts/${options.contactId!}/`;
        const response = await this.fetch(options.contactUrl, undefined);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching contacts');
            }
            throw new ApiError('Error fetching contacts', json);
        }
        return await response.json();
    }

    public static async addContactApi(contact: CreateContactModel): Promise<Contact> {
        const contactAddUrl = `/contacts/`;
        const response = await this.post(contactAddUrl, contact);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error adding contact');
            }
            throw new ApiError('Error adding contact', json);
        }
        return await response.json();
    }

    public static async updateContactApi(options: ContactsOptions): Promise<Contact> {
        let updateContactUrl = '/contacts/';
        if (options && options.contactId) {
            updateContactUrl += options.contactId + '/';
        }
        const body = options.updateBody;

        const response = await this.put(updateContactUrl, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating contact');
            }
            throw new ApiError('Error updating contact', json);
        }
        return await response.json();
    }

    public static async deleteContactApi(contactId: string | number): Promise<boolean> {
        const response = await this.delete(`/contacts/${contactId}/`);
        return await response.ok;
    }

    public static async getContactQRApi(qrCode: string): Promise<{ id: number }> {
        let URL = `/qr/`;
        if (qrCode) {
            URL += qrCode + `/`;
        }
        const response = await this.fetch(URL, undefined);
        if (!response.ok) {
            try {
                await response.json();
            } catch (e) {
                throw new ApiError('No contact found with this QR');
            }
            throw new ApiError('No contact found with this QR');
        }
        return await response.json();
    }

    public static async importContactsApi(body: FormData): Promise<void> {
        const URL = '/contacts/import-csv/';
        const response = await this.post(URL, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error uploading csv');
            }
            throw new ApiError('Error uploading csv', json);
        }
        return await response.json();
    }

    public static async searchContactsApi(queryParams?: ApiQueryParams<ContactQueryParams>): Promise<ContactResponse> {
        return await this.apiPagination<ContactResponse, ContactQueryParams>(contactViewSet, queryParams);
    }

    public static async postContactAccessKey(body: ContactAccessKey): Promise<any> {
        const response = await this.post(`/contact-access-keys/`, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error posting contact access key');
            }
            throw new ApiError('Error posting contact access key', json);
        }
        return await response.json();
    }
}
