import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceOperation, SliceStatus, addOrUpdateList, removeListElement } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clone, cloneDeep, toNumber } from 'lodash';

import { ContactApiClient } from './api/client';
import { ContactQueryParams, ContactResponse, ContactsOptions, CreateContactModel } from './api/models';
import { Contact } from './contact';

export interface ContactsState {
    all: Contact[];
    contactsList: ContactResponse | null;
    searchContactsList: { [searchQuery: string]: ContactResponse } | null;
    contactsHierachicalList: ContactResponse | null;
    loggedInContact: Contact | null;
    status: SliceStatus;
    searchStatus: SliceStatus;
    loginStatus: SliceStatus;
    lastOperation: SliceOperation | null;
    contactQR: number | undefined;
    loginUpdate: number;
    lastUpdate: number;
    lastSearchUpdate: { [searchQuery: string]: number };
    message: string;
    error: ReduxError | null;
}

const initialState: ContactsState = {
    all: [],
    contactsList: null,
    searchContactsList: null,
    contactsHierachicalList: null,
    loggedInContact: null,
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    loginStatus: SliceStatus.INIT,
    lastOperation: null,
    contactQR: undefined,
    loginUpdate: Date.now(),
    lastUpdate: Date.now(),
    lastSearchUpdate: {},
    message: '',
    error: null
};

export const contactSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        removeContactById(state, action: PayloadAction<number>) {
            if (state.contactsList) {
                const clonedList = clone(state.contactsList);
                const clonedSearchList = clone(state.searchContactsList);

                clonedList.results = clonedList.results.filter((c) => c.id !== action.payload);
                if (clonedSearchList !== null) {
                    Object.keys(clonedSearchList).forEach((key) => {
                        clonedSearchList[key].results = clonedSearchList[key].results.filter((c) => c.id !== action.payload);
                    });
                }

                state.contactsList = clonedList;
                state.searchContactsList = clonedSearchList;
            }

            removeListElement<Contact>(action.payload, state.all);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContacts.pending, (state) => {
                state.lastOperation = SliceOperation.GET;
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (action.meta.arg['contact-group-hierarchical']) state.contactsHierachicalList = action.payload;
                if (state.contactsList == null) {
                    state.contactsList = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.contactsList?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.contactsList.next = action.payload.next;
                    state.contactsList.previous = action.payload.previous;
                    if (state.contactsList.results.length !== action.payload.count) {
                        state.contactsList.count = action.payload.count;
                        state.contactsList.results = new Array(action.payload.count);
                    }
                    state.contactsList?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                action.payload.results.forEach((el) => addOrUpdateList<Contact>(el, state.all));
                state.lastUpdate = Date.now();
                state.lastOperation = SliceOperation.GET;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchContacts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchContact.pending, (state) => {
                state.lastOperation = SliceOperation.GET;
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchContact.fulfilled, (state, action) => {
                const contact = action.payload;
                addOrUpdateList<Contact>(contact, state.all);
                const isLoginContact = action.meta.arg.contactLoginId;
                if (state.contactsList === null && !action.meta.arg.contactLoginId) {
                    state.contactsList = { count: 0, next: null, previous: null, results: [] };
                    const existingContact = state.contactsList.results.find((c) => c.id === contact.id);
                    if (existingContact) {
                        const contactIndex = state.contactsList.results.indexOf(existingContact);
                        state.contactsList.results.splice(contactIndex, 1, contact);
                    } else state.contactsList.results.push(contact);
                } else if (state.contactsList !== null) {
                    const existingContact = state.contactsList.results.find((c) => c !== undefined && c.id === contact.id);
                    if (existingContact) {
                        const contactIndex = state.contactsList.results.indexOf(existingContact);
                        state.contactsList.results.splice(contactIndex, 1, contact);
                    } else {
                        for (let i = 0; i <= state.contactsList.results.length; i++) {
                            if (state.contactsList.results[i] === undefined) {
                                state.contactsList.results[i] = contact;
                                break;
                            } else if (i === state.contactsList.results.length) {
                                state.contactsList.results.push(contact);
                                break;
                            }
                        }
                    }
                }
                if (isLoginContact) {
                    state.loggedInContact = contact;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchContact.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(addContact.pending, (state) => {
                state.lastOperation = SliceOperation.POST;
                state.status = SliceStatus.LOADING;
            })
            .addCase(addContact.fulfilled, (state, action) => {
                const contact = action.payload;
                addOrUpdateList<Contact>(contact, state.all);
                if (state.contactsList == null) state.contactsList = { count: 0, next: null, previous: null, results: [] };
                state.contactsList.results.push(contact);
                state.lastOperation = SliceOperation.POST;
                state.status = SliceStatus.IDLE;
            })
            .addCase(addContact.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                if (action.error.message) state.message = action.error.message;
                state.lastOperation = SliceOperation.POST;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateContact.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateContact.fulfilled, (state, action) => {
                const contact = action.payload;
                addOrUpdateList<Contact>(contact, state.all);
                if (!state.contactsList) state.contactsList = { count: 0, next: null, previous: null, results: [] };
                const cloneList = cloneDeep(state.contactsList);
                const clonedSearchList = clone(state.searchContactsList);
                const existingContact = cloneList.results.find((c) => c !== undefined && c.id === contact.id);
                if (existingContact) {
                    const contactIndex = cloneList.results.indexOf(existingContact);
                    state.contactsList.results.splice(contactIndex, 1, contact);
                    if (clonedSearchList !== null) {
                        Object.keys(clonedSearchList).forEach((key) => {
                            const foundContact = clonedSearchList[key].results.find((c) => c.id === contact.id);
                            if (foundContact) {
                                const index = clonedSearchList[key].results.indexOf(foundContact);
                                if (index !== -1 && state.searchContactsList !== null) {
                                    state.searchContactsList[key].results.splice(index, 1, contact);
                                }
                            }
                        });
                    }
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateContact.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(deleteContact.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteContact.fulfilled, (state, action) => {
                if (action.payload === true && state.contactsList) {
                    const contactId = action.meta.arg;
                    const clonedSearchList = clone(state.searchContactsList);
                    const foundContact = state.contactsList.results.find((c) => c !== null && c !== undefined && c.id === contactId);
                    if (foundContact) {
                        const index = state.contactsList.results.indexOf(foundContact);
                        state.contactsList.results.splice(index, 1);
                    }
                    if (clonedSearchList) {
                        Object.keys(clonedSearchList).forEach((key) => {
                            if (state.searchContactsList) {
                                state.searchContactsList[key].results = state.searchContactsList[key].results.filter((c) => c.id !== contactId);
                            }
                        });
                    }
                }
                removeListElement<Contact>(action.meta.arg, state.all);
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteContact.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchContacts.pending, (state) => {
                state.searchStatus = SliceStatus.LOADING;
            })
            .addCase(searchContacts.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (searchQuery === undefined || searchQuery === null || Array.isArray(searchQuery)) {
                    return undefined;
                } else {
                    if (state.searchContactsList !== null && state.searchContactsList[searchQuery.toLowerCase()]) {
                        state.searchContactsList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    } else {
                        state.searchContactsList = { [searchQuery.toLowerCase()]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.searchContactsList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    }
                }
                state.searchStatus = SliceStatus.IDLE;
            })
            .addCase(searchContacts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.searchStatus = SliceStatus.ERROR;
            })
            .addCase(deleteContacts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteContacts.fulfilled, (state, action) => {
                if (state.contactsList) {
                    const clonedList = clone(state.contactsList);
                    const clonedSearchList = clone(state.searchContactsList);
                    action.payload.forEach((response) => {
                        if (response.result === true) {
                            clonedList.results = clonedList.results.filter((c) => c.id !== response.id);
                            if (clonedSearchList !== null) {
                                Object.keys(clonedSearchList).forEach((key) => {
                                    clonedSearchList[key].results = clonedSearchList[key].results.filter((c) => c.id !== response.id);
                                });
                            }
                        }
                    });
                    state.contactsList = clonedList;
                    state.searchContactsList = clonedSearchList;
                }
                action.payload.forEach((res) => res.result && removeListElement<Contact>(res.id, state.all));
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteContacts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(getContactQR.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(getContactQR.fulfilled, (state, action) => {
                const contactId = action.payload.id;
                if (contactId) state.contactQR = contactId;
                state.status = SliceStatus.IDLE;
            })
            .addCase(getContactQR.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.contactQR = undefined;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchContacts = createAsyncThunk<ContactResponse, ApiQueryParams<ContactQueryParams>>(
    'fetchContacts',
    async (options: ApiQueryParams<ContactQueryParams>, { rejectWithValue }) => {
        try {
            return await ContactApiClient.fetchContactsApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchContact = createAsyncThunk<Contact, ContactsOptions>('fetchContact', async (options: ContactsOptions, { rejectWithValue }) => {
    try {
        return await ContactApiClient.fetchContactApi(options);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addContact = createAsyncThunk<Contact, CreateContactModel>('addContact', async (contact, { rejectWithValue }) => {
    try {
        return await ContactApiClient.addContactApi(contact);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateContact = createAsyncThunk<Contact, ContactsOptions>('updateContact', async (contact, { rejectWithValue }) => {
    try {
        return await ContactApiClient.updateContactApi(contact);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteContact = createAsyncThunk<boolean, number>('deleteContact', async (contactId, { rejectWithValue }) => {
    try {
        const res = await ContactApiClient.deleteContactApi(contactId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete contact with id: ${contactId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchContacts = createAsyncThunk<ContactResponse, ApiQueryParams<ContactQueryParams>>(
    'searchContacts',
    async (options: ApiQueryParams<ContactQueryParams>, { rejectWithValue }) => {
        try {
            return await ContactApiClient.searchContactsApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteContacts = createAsyncThunk<{ result: boolean; id: number }[], number[]>('deleteContacs', async (contactIds, { rejectWithValue }) => {
    try {
        return await Promise.all(
            contactIds.map(async (contactId) => {
                const res = await ContactApiClient.deleteContactApi(contactId);
                return { result: res, id: contactId };
            })
        );
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const getContactQR = createAsyncThunk<{ id: number }, string>('getContactQR', async (qrCode, { rejectWithValue }) => {
    try {
        return await ContactApiClient.getContactQRApi(qrCode);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const { removeContactById } = contactSlice.actions;

export const contactReducer = contactSlice.reducer;
