import { isApiError } from '@frontend/api-utils';
import { ToastUtil } from '@frontend/toast-utils';
import { toNumber } from 'lodash';
import { toast } from 'react-toastify';

import { openSlot, requestSlotLogs } from '../../api/Slots';
import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';

export class SlotUtil {
    static getRealIdFromId(id: string): number {
        return toNumber(id.replace('SLT', ''));
    }
    static getIdFromRealId(id: number | string): string {
        if (typeof id == 'string' && id.includes('SLT')) return id;
        return `SlT${'0'.repeat(6 - JSON.stringify(id).length)}${id}`;
    }
    static async openSlot(id: string): Promise<void> {
        try {
            await openSlot(id);
        } catch (err) {
            const error = (err as any).json[0];
            if (typeof error == 'string') {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, error, ClassType.DANGER));
            } else throw err;
        }
    }
    static async requestSlotLogs(id: string): Promise<void> {
        try {
            await requestSlotLogs(id);
        } catch (err) {
            if (isApiError(err)) {
                if (err.json && 'non_field_errors' in (err.json as { non_field_errors: string[] })) {
                    const error = (err.json as { non_field_errors: string[] })['non_field_errors'][0];
                    toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, error, ClassType.DANGER));
                }
            } else throw err;
        }
    }
}
