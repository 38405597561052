import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from "@frontend/api-utils";
import { Category } from "../category";
import { CategoriesQueryParams, CategoryResponse } from "./models";


const categoryTreeViewSet: ApiViewSet = {
    baseName: 'category-trees'
};
const categoryViewSet: ApiViewSet = {
    baseName: 'categories'
};

export class CategoryAPIClient extends APIClient {
    public static async fetchCategoryTreeApi(queryParams?: ApiQueryParams<CategoriesQueryParams> | null): Promise<Category[]> {
        return this.apiList<Category, CategoriesQueryParams>(categoryTreeViewSet, queryParams);
    }
    
    public static async fetchCategoryApi(options: DetailOptions): Promise<Category> {
        return this.apiDetail<Category>(categoryViewSet, options);
    }
    
    public static async fetchAllCategoriesApi(queryParams?: ApiQueryParams<CategoriesQueryParams> | null): Promise<CategoryResponse> {
        return this.apiPagination<CategoryResponse, CategoriesQueryParams>(categoryViewSet, queryParams);
    }
    
    public static async createCategoryApi(category: FormData): Promise<Category> {
        const URL = `/categories/`;
        const response = await this.post(URL, category);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating category');
            }
            throw new ApiError('Error creating category', json);
        }
        return await response.json();
    }
    
    public static async updateCategoryApi(category: FormData, id: number): Promise<Category> {
        let URL = `/categories/`;
        if (id) {
            URL += id + '/';
        }
    
        const response = await this.put(URL, category);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating category');
            }
            throw new ApiError('Error updating category', json);
        }
        return await response.json();
    }
    
    public static async deleteCategoryApi(categoryId: number): Promise<boolean> {
        const URL = `/categories/${categoryId}/`;
        const response = await this.delete(URL);
        return await response.ok;
    }

    public static async searchCategoriesApi(queryParams?: ApiQueryParams<CategoriesQueryParams>): Promise<Category[]> {
        return this.apiList(categoryTreeViewSet, queryParams);
    }
}