import { HiOutlineMail } from 'react-icons/hi';

interface EmailBadgeProps {
    email?: string;
}

export default function EmailBadge(props: EmailBadgeProps) {
    if (props.email === undefined || props.email === '') return <>-</>;
    return (
        <a href={'mailto:' + props.email}>
            <span className='d-flex flex-row align-items-center'>
                <span className='text-info me-1'>
                    <HiOutlineMail />
                </span>
                {props.email}
            </span>
        </a>
    );
}
