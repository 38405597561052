import { FaPlus } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../common/BootstrapValues';
import Filter from '../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../components/loading/Spinner';
import CreateModal from '../../components/modals/CreateModal';
import Modal, { ModalType } from '../../components/modals/Modal';
import Table from '../../components/tables/Table';
import CreateUserForm from '../forms/create-user/create-user.component';
import ManageInvitesModal from '../invitations/ManageInvitesModal';
import UserListElement from './UserListElement';
import useUserList from './user-list.controller';

const USER_LIST_ID = 'UserList';

const UserList = () => {
    const viewProps = useUserList();

    if (viewProps.isInit || viewProps.users.usersList?.results === undefined) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}></Spinner>
        );
    }
    return (
        <>
            <div
                id={USER_LIST_ID}
                className='card'>
                <div className='card-header d-flex'>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                            <div className='d-flex flex-row jutsify-content-start align-items-cent'>
                                <HorizontalButtonGroup
                                    buttons={[
                                        {
                                            hide: false,
                                            onClick: () => viewProps.setIsOpen(true),
                                            icon: FaPlus,
                                            type: ClassType.PRIMARY,
                                            id: 'UserList-CreateUserButton',
                                            text: (
                                                <FormattedMessage
                                                    id='UserList-Create-User'
                                                    description='Label for creating user'
                                                    defaultMessage='Create User'
                                                />
                                            )
                                        },
                                        {
                                            hide: false,
                                            onClick: () => viewProps.changeInviteUserModal(true),
                                            icon: FiSend,
                                            type: ClassType.SECONDARY,
                                            text: (
                                                <FormattedMessage
                                                    id='UserList.manage.invites'
                                                    description='Label for managing user invites.'
                                                    defaultMessage='Manage invites'
                                                />
                                            )
                                        }
                                    ]}
                                    direction='left'
                                />
                            </div>
                            <div className='d-flex flex-row'>
                                <div className='px-1'>
                                    <Filter
                                        id='UserList-Filter'
                                        objects={viewProps.data}
                                        filterCallback={viewProps.changeTextFilter}
                                        filterValue={viewProps.changeTextFilterValue}
                                        filterKeys={['email', 'first_name', 'last_name', 'username']}
                                        useSearch
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    columns={viewProps.columns}
                    data={viewProps.data}
                    pageCount={viewProps.users.usersList ? Math.ceil(viewProps.users.usersList.count / viewProps.pageSize) : undefined}
                    next={viewProps.next?.toString()}
                    pageSize={(value) => viewProps.setPageSize(value)}
                    page={(value) => viewProps.setPageIndex(value + 1)}
                    onSelectElement={viewProps.onClickEvent}
                    RowElement={UserListElement}
                    id='UserList-Table'
                    sortKeys={viewProps.sortKeys}
                    customSorting={(page, sortBy, sortOrder) => {
                        if (viewProps.sortKeys.includes(sortBy)) {
                            if (sortOrder === undefined) {
                                viewProps.changeCustomSorting(undefined);
                            } else {
                                viewProps.changeCustomSorting({ column: sortBy, orderBy: sortOrder });
                            }
                        }
                    }}
                />
            </div>
            <CreateModal
                show={viewProps.isOpen}
                handleClose={() => viewProps.setIsOpen(false)}
                header={'User'}>
                <CreateUserForm handleClose={() => viewProps.setIsOpen(false)}></CreateUserForm>
            </CreateModal>
            <Modal
                handleClose={() => viewProps.changeInviteUserModal(false)}
                show={viewProps.inviteUserModal}
                type={ModalType.PANE}>
                <ManageInvitesModal onClose={() => viewProps.changeInviteUserModal(false)} />
            </Modal>
        </>
    );
};

export default UserList;
