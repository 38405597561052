import { Attribute } from '@frontend/attribute';
import { Row } from 'react-table';

import { RowProps } from '../../../components/tables/rows/TRow';

interface AttributeListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<Attribute>;
    onClick?: (element: Attribute) => void;
}

const AttributeListElement = (props: AttributeListElementProps) => {
    return (
        <tr
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td>
                <span className='text-secondary text-xs font-weight-bold'>{props.row.original.id}</span>
            </td>
            <td>
                <span className='text-secondary text-xs font-weight-bold'>{props.row.original.name}</span>
            </td>
            <td>
                <span className='text-secondary text-xs font-weight-bold'>{props.row.original.values.join(', ')}</span>
            </td>
        </tr>
    );
};

export default AttributeListElement;
