import { fetchAttributes } from '@frontend/attribute';
import { Category, fetchCategories } from '@frontend/category';
import { SliceStatus, shouldFetch } from '@frontend/common';
import { Product, ProductsState, fetchProducts, searchProducts } from '@frontend/product';
import { toNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';

import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { SelectedAttributes } from '../FilterBar/FilterBar';
import ProductListColumns from './ProductListColumns';

interface ViewProps {
    showFilter: boolean | null;
    changeSelectedCategory: (value: React.SetStateAction<Category | undefined>) => void;
    selectedCategory: Category | undefined;
    isOpen: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
    changeImportModal: (value: React.SetStateAction<boolean>) => void;
    importModal: boolean;
    data: Product[];
    changeTextFilter: React.Dispatch<React.SetStateAction<Product[] | undefined>>;
    changeTextFilterValue: React.Dispatch<React.SetStateAction<string | undefined>>;
    textFilterValue: string | undefined;
    columns: Column<Product>[];
    products: ProductsState;
    calculatePageCount: () => number | undefined;
    changePageSize: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    changePageIndex: React.Dispatch<React.SetStateAction<number>>;
    pageIndex: number;
    authorised: boolean;
    shopId: string | undefined;
    changeSelectedAttributes: React.Dispatch<React.SetStateAction<SelectedAttributes[] | undefined>>;
    changeCustomSorting: React.Dispatch<React.SetStateAction<{ column: string; orderBy: string | undefined } | undefined>>;
    sortKeys: string[];
}
const useProductList = (): ViewProps => {
    const authorised = useAuthorization(DefaultRole.SUPER_ADMIN, true);
    const { shopId } = useParams();
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const [pageSize, changePageSize] = useState(25);
    const [pageIndex, changePageIndex] = useState(1);
    const products = useAppSelector((state) => state.products);
    const attributes = useAppSelector((state) => state.attributes);
    const categories = useAppSelector((state) => state.categories);
    const [isOpen, setIsOpen] = useState(false);
    const [textFilter, changeTextFilter] = useState<Product[] | undefined>();
    const [textFilterValue, changeTextFilterValue] = useState<string>();
    const [selectedCategory, changeSelectedCategory] = useState<Category | undefined>();
    const [selectedAttributes, changeSelectedAttributes] = useState<SelectedAttributes[] | undefined>();
    const [importModal, changeImportModal] = useState<boolean>(false);
    const [customSorting, changeCustomSorting] = useState<{ column: string; orderBy: string | undefined } | undefined>(undefined);
    const sortKeys = ['name'];

    const [filteredProducts, changeFilteredProducts] = useState<Product[] | undefined>(
        products.shopProductList && shopId && products.shopProductList[shopId] ? products.shopProductList[shopId].results : []
    );

    useEffect(() => {
        if (shouldFetch(products.status, products.lastUpdate)) {
            dispatch(fetchProducts({ account: accountId?.toString(), shop: shopId, page: pageIndex.toString(), page_size: pageSize.toString() }));
        }
    }, []);

    useEffect(() => {
        if (authorised && shopId) {
            if (!customSorting && !textFilterValue) {
                dispatch(fetchProducts({ account: accountId?.toString(), shop: shopId, page: pageIndex.toString(), page_size: pageSize.toString() }));
            } else if (textFilterValue && customSorting) {
                dispatch(
                    searchProducts({
                        account: accountId?.toString(),
                        shop: shopId,
                        page: pageIndex.toString(),
                        page_size: pageSize.toString(),
                        search: textFilterValue,
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            } else if (textFilterValue && !customSorting) {
                dispatch(
                    searchProducts({
                        account: accountId?.toString(),
                        shop: shopId,
                        page: pageIndex.toString(),
                        page_size: pageSize.toString(),
                        search: textFilterValue
                    })
                );
            } else if (!textFilterValue && customSorting) {
                dispatch(
                    fetchProducts({
                        account: accountId?.toString(),
                        shop: shopId,
                        page: pageIndex.toString(),
                        page_size: pageSize.toString(),
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            }
        }
    }, [pageIndex, pageSize, customSorting]);

    useEffect(() => {
        if (authorised && products.shopProductList !== null && products.status !== SliceStatus.LOADING) {
            if (shouldFetch(attributes.status, attributes.lastUpdate)) dispatch(fetchAttributes({ account: accountId.toString() }));
            if (shouldFetch(categories.status, categories.lastUpdate)) dispatch(fetchCategories({ account: accountId.toString() }));
        }
    }, [products.shopProductList, products.status]);

    useEffect(() => {
        if (textFilterValue !== undefined && textFilterValue !== '') {
            dispatch(searchProducts({ search: textFilterValue, account: accountId.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
        }
    }, [textFilterValue]);

    useEffect(() => {
        if (!products.shopProductList) return;

        let validProducts: Product[] = [];

        if (textFilterValue !== undefined && textFilterValue !== '' && products.searchProductsList && products.searchProductsList[textFilterValue]) {
            validProducts = products.searchProductsList[textFilterValue].results.filter((p) => p.shops_list.map((s) => s.id).includes(toNumber(shopId)));
        } else {
            if (shopId && products.shopProductList && products.shopProductList[shopId]) {
                validProducts = products.shopProductList[shopId].results;
            } else validProducts = [];
        }

        if (selectedCategory) {
            const categoryIds = [selectedCategory.id, ...(selectedCategory.children || []).map((c) => c.id)];
            validProducts = validProducts.filter((p) => p.category && categoryIds.includes(p.category));
        }

        if (selectedAttributes && selectedAttributes.length > 0) {
            const filteredArray = validProducts.filter((p) =>
                p.product_attributes.some((pa) => selectedAttributes.some((sa) => sa.attribute === pa.attribute && sa.values.includes(pa.value)))
            );
            validProducts = filteredArray;
        }

        if (textFilter && textFilter.length !== 0 && textFilterValue && textFilterValue.length !== 0) {
            validProducts = validProducts.filter((p) => textFilter.some((tf) => tf.id === p.id));
        }

        changeFilteredProducts(validProducts);
    }, [products.shopProductList, textFilter, selectedCategory, selectedAttributes, products.searchProductsList, textFilterValue]);

    const data = useMemo(() => {
        if (filteredProducts) {
            return filteredProducts;
        } else return [];
    }, [filteredProducts, pageIndex, pageSize]);
    const columns: Column<Product>[] = useMemo(() => ProductListColumns, []);

    const showFilter = (categories.categoryList && categories.categoryList.length > 0) || (attributes.attributeList && attributes.attributeList.length > 0);

    function calculatePageCount() {
        const searchList = textFilterValue && products.searchProductsList && products.searchProductsList[textFilterValue];
        const shopList = products.shopProductList && shopId && products.shopProductList[shopId];

        if (textFilterValue && textFilterValue.length > 0) {
            return searchList
                ? Math.ceil(searchList.count / pageSize)
                : (selectedCategory || selectedAttributes) && filteredProducts
                ? Math.ceil(filteredProducts.length / pageSize)
                : 0;
        } else {
            return (selectedCategory || selectedAttributes) && filteredProducts
                ? Math.ceil(filteredProducts.length / pageSize)
                : shopList
                ? Math.ceil(shopList.count / pageSize)
                : undefined;
        }
    }
    return {
        showFilter,
        changeSelectedCategory,
        selectedCategory,
        isOpen,
        setIsOpen,
        changeImportModal,
        importModal,
        data,
        columns,
        changeTextFilter,
        changeTextFilterValue,
        textFilterValue,
        products,
        changePageIndex,
        changePageSize,
        pageIndex,
        pageSize,
        calculatePageCount,
        authorised,
        shopId,
        changeSelectedAttributes,
        changeCustomSorting,
        sortKeys
    };
};

export default useProductList;
