import { Category } from '@frontend/category';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../../common/BootstrapValues';
import HorizontalButtonGroup from '../../../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import CreateModal from '../../../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../../../components/modals/Modal';
import UpdateCategory from '../../forms/UpdateCategory';
import CategoryDetail from '../CategoryDetail';
import useCategoryDetailModal from './category-detail-modal.controller';

export interface CategoryDetailModalprops extends ModalProps {
    category: Category;
}

export default function CategoryDetailModal(props: CategoryDetailModalprops) {
    const viewProps = useCategoryDetailModal(props);

    return (
        <Modal
            type={ModalType.PANE}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.category.name}</h3>
            </div>
            <div className='modal-body'>
                <CategoryDetail category={props.category} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content between align-items center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            text: (
                                <FormattedMessage
                                    id='CategoryDetailModal.Button.Update'
                                    description='Button for updating category'
                                    defaultMessage='Update'
                                />
                            ),
                            hide: false,
                            onClick: () => viewProps.changeShowUpdateModal(true),
                            type: ClassType.WARNING,
                            icon: FiEdit2,
                            id: 'CategoryDetailModal.updateButton'
                        },
                        {
                            text: (
                                <FormattedMessage
                                    id='CategoryDetailModal.Button.Delete'
                                    description='Button for deleting category'
                                    defaultMessage='Delete'
                                />
                            ),
                            hide: false,
                            onClick: () => viewProps.changeShowDeleteModal(true),
                            type: ClassType.DANGER,
                            icon: IoMdTrash,
                            id: 'CategoryDetailModal.deleteButton'
                        }
                    ]}
                    direction='left'
                />
            </div>
            {viewProps.showUpdateModal && (
                <CreateModal
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    show={viewProps.showUpdateModal}
                    id='CategoryUpdateModal'
                    header='Update Category'>
                    <UpdateCategory
                        category={props.category}
                        handleClose={() => viewProps.changeShowUpdateModal(false)}
                    />
                </CreateModal>
            )}
            {viewProps.showDeleteModal && (
                <ConfirmationModal
                    show={viewProps.showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    onConfirm={viewProps.onDeleteCategory}
                    message={
                        <FormattedMessage
                            id='CategoryDetailModa.ConfirmMessage'
                            description='The confirmation message checking if the user wants to delete this category'
                            defaultMessage='Are you sure you want to delete this category?'
                        />
                    }
                />
            )}
        </Modal>
    );
}
