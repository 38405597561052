import { Category, fetchAllCategories, fetchCategories, searchCategories } from '@frontend/category';
import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { TableCategory } from './CategoryList';
import CategoryListColumns from './CategoryListColumns';

interface ViewProps {
    filteredCategories: Category[] | null;
    changeCreateCategoryOpen: React.Dispatch<React.SetStateAction<boolean>>;
    createCategoryOpen: boolean;
    setFilterValue: React.Dispatch<React.SetStateAction<string | undefined>>;
    changeTextFilter: React.Dispatch<React.SetStateAction<Category[] | undefined>>;
    columns: Column<Category>[];
    data: TableCategory[];
    changeSelectedCategory: React.Dispatch<React.SetStateAction<Category | undefined>>;
    selectedCategory: Category | undefined;
    sortKeys: string[];
    changeCustomSorting: React.Dispatch<React.SetStateAction<{ column: string; orderBy: string | undefined } | undefined>>;
}

const useCategoryList = (): ViewProps => {
    const authorised = useAuthorization(DefaultRole.SUPER_ADMIN, true);
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const categories = useAppSelector((state) => state.categories);
    const [createCategoryOpen, changeCreateCategoryOpen] = useState<boolean>(false);
    const [selectedCategory, changeSelectedCategory] = useState<Category>();
    const [filteredCategories, changeFilteredCategories] = useState<Category[] | null>(categories.categoryList);
    const [filterValue, setFilterValue] = useState<string>();
    const [textFilter, changeTextFilter] = useState<Category[] | undefined>(undefined);
    const sortKeys = ['id', 'name'];
    const [customSorting, changeCustomSorting] = useState<{ column: string; orderBy: string | undefined } | undefined>(undefined);

    useEffect(() => {
        if (authorised) {
            dispatch(fetchCategories({ account: accountId.toString() }));
            dispatch(fetchAllCategories({ account: accountId.toString(), page: '1', page_size: '1000' }));
        }
    }, []);

    useEffect(() => {
        if (categories.categoryList) changeFilteredCategories(categories.categoryList);
    }, [categories]);

    useEffect(() => {
        if (authorised) {
            if (!customSorting && !filterValue) {
                dispatch(fetchCategories({ account: accountId?.toString() }));
            } else if (filterValue && customSorting) {
                dispatch(
                    searchCategories({
                        account: accountId?.toString(),
                        search: filterValue,
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            } else if (filterValue && !customSorting) {
                dispatch(searchCategories({ account: accountId?.toString(), search: filterValue }));
            } else if (!filterValue && customSorting) {
                dispatch(
                    fetchCategories({
                        account: accountId?.toString(),
                        ordering: customSorting?.orderBy === 'asc' ? customSorting.column : `-${customSorting?.column}`
                    })
                );
            }
        }
    }, [customSorting]);

    useEffect(() => {
        if (filterValue !== '') {
            dispatch(searchCategories({ account: accountId.toString(), search: filterValue }));
        } else {
            changeFilteredCategories(categories.categoryList!.map(parseToTableCategory));
        }
    }, [filterValue]);

    useEffect(() => {
        if (!categories.categoryList) return;

        let validCategories: Category[] = categories.categoryList;

        if (!filterValue) {
            validCategories = categories.categoryList.map(parseToTableCategory);
        }
        if (filterValue && categories.searchCategoryList && categories.searchCategoryList[filterValue]) {
            validCategories = categories.searchCategoryList[filterValue].map(parseToTableCategory);
        }
        changeFilteredCategories(validCategories as TableCategory[]);
    }, [categories]);

    const parseToTableCategory = (category: Category): TableCategory => {
        return {
            subRows: category.children?.map((c) => parseToTableCategory(c)),
            ...category
        } as TableCategory;
    };

    const data = useMemo(() => {
        if (filteredCategories) return filteredCategories as TableCategory[];
        else return [];
    }, [filteredCategories]);
    const columns: Column<Category>[] = useMemo(() => CategoryListColumns, []);

    return {
        changeCreateCategoryOpen,
        changeSelectedCategory,
        changeTextFilter,
        columns,
        createCategoryOpen,
        data,
        filteredCategories,
        selectedCategory,
        setFilterValue,
        sortKeys,
        changeCustomSorting
    };
};

export default useCategoryList;

export function categorizeData(data: Category[]): TableCategory[] {
    const tableCategories: TableCategory[] = [];
    const idToCategory: { [key: number]: TableCategory } = {};

    for (const item of data) {
        const tableCategory: TableCategory = {
            subRows: [],
            ...item
        };
        idToCategory[item.id] = tableCategory;
    }

    for (const item of data) {
        if (!item.parent_obj) {
            tableCategories.push(idToCategory[item.id]);
        } else {
            const parentId = item.parent_obj.id;
            if (idToCategory[parentId]) {
                idToCategory[parentId].subRows.push(idToCategory[item.id]);
            } else {
                tableCategories.push(idToCategory[item.id]);
            }
        }
    }

    return tableCategories;
}
