import { Logger } from '@frontend/Logger';
import { useEffect, useState } from 'react';

import { Spot } from '../../api/Spots';
import { Terminal } from '../../api/Terminals';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import { switchSpotLights } from '../../store/spotSlice';

interface ViewProps {
    authorized: boolean;
    selectedTerminal: Terminal | undefined;
    changeSelectedTerminal: React.Dispatch<React.SetStateAction<Terminal | undefined>>;
    lightSettings: boolean | undefined;
    handleLightsSwitch: (value: boolean) => void;
    filteredTerminalsList: Terminal[] | undefined;
    spotIsOpen: boolean;
    changeSpotIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useSpotDetail = (props: { spot?: Spot }): ViewProps => {
    const authorized = useAuthorization(DefaultRole.SUPER_ADMIN);
    const dispatch = useAppDispatch();
    const terminals = useAppSelector((state) => state.terminals);
    const [spotIsOpen, changeSpotIsOpen] = useState(false);
    const [selectedTerminal, changeSelectedTerminal] = useState<Terminal>();
    const terminalsList = terminals.terminalsList;
    const [filteredTerminalsList, changeFilteredTerminalsList] = useState<Terminal[] | undefined>(undefined);
    const [lightSettings, changeLightSettings] = useState(props.spot?.actions.lights_on);
    const { selectedUser } = useAccount();

    useEffect(() => {
        if (terminalsList && props.spot?.terminals) {
            changeFilteredTerminalsList(terminalsList.filter((t) => props.spot?.terminals.map((s) => s.id).includes(t.id)));
        } else if (!props.spot) changeFilteredTerminalsList([]);
    }, [props.spot, terminalsList]);

    const handleLightsSwitch = (value: boolean) => {
        const data = value == true ? true : false;
        changeLightSettings(data);
        dispatch(switchSpotLights({ id: props.spot?.id, lights_on: data })).then((res) => {
            if (res.type.includes('/fulfilled')) {
                Logger.log(`${selectedUser?.email} changed lights on to: ${data} for ${props.spot?.name}`, { user: selectedUser?.id });
            } else Logger.error(`${selectedUser?.email} failed to update the lights for ${props.spot?.name}`, { user: selectedUser?.id });
        });
    };

    return {
        authorized,
        selectedTerminal,
        changeSelectedTerminal,
        lightSettings,
        handleLightsSwitch,
        filteredTerminalsList,
        spotIsOpen,
        changeSpotIsOpen
    };
};

export default useSpotDetail;
