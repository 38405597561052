export function FindAccountRoleChange(obj1: Record<string, any>, obj2: Record<string, any>) {
    const accountMembers1 = obj1.account_members || [];
    const accountMembers2 = obj2.account_members || [];
    const changes: any[] = [];
    const removedAccounts: any[] = [];

    accountMembers1.forEach((member1: any) => {
        const accountId = member1.account.id;
        const member2 = accountMembers2.find((m: any) => m.account.id === accountId);
        if (!member2) {
            removedAccounts.push({ removed: `account ${member1.account.name}` });
        }
    });

    accountMembers1.forEach((member1: any) => {
        const accountId = member1.account.id;
        const member2 = accountMembers2.find((m: any) => m.account.id === accountId);

        if (member2) {
            if (member1.role !== member2.role) {
                changes.push({
                    [`${member2.account.name}`]: 'role',
                    old_value: member1.role,
                    new_value: member2.role
                });
            }
        }
    });

    changes.push(...removedAccounts);

    return changes;
}

interface Difference {
    key: string;
    old_value: any;
    new_value: any;
}

export function FindChangedValue(obj1: Record<string, any>, obj2: Record<string, any>, parentKey = '', excludedKeys: string[] = []): Difference[] {
    const differences: Difference[] = [];

    for (const key in obj1) {
        if (excludedKeys.includes(key)) {
            continue; // Skip keys in the exclusion list
        }

        const currentValue1 = obj1[key];
        const currentValue2 = obj2[key];
        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof currentValue1 === 'object' && typeof currentValue2 === 'object') {
            if (Array.isArray(currentValue1) && Array.isArray(currentValue2)) {
                // Handle arrays by sorting and comparing
                const sorted1 = [...currentValue1].sort();
                const sorted2 = [...currentValue2].sort();
                if (JSON.stringify(sorted1) !== JSON.stringify(sorted2)) {
                    differences.push({ key: currentKey, old_value: sorted1, new_value: sorted2 });
                }
            } else {
                const nestedDifferences = FindChangedValue(currentValue1, currentValue2, currentKey, excludedKeys);
                differences.push(...nestedDifferences);
            }
        } else if (currentValue1 !== currentValue2) {
            differences.push({ key: currentKey, old_value: currentValue1, new_value: currentValue2 });
        }
    }

    return differences;
}
