import { Logger } from '@frontend/Logger';
import { SliceStatus } from '@frontend/common';
import { ContactGroup, fetchContactGroup, fetchContactGroups, updateContactGroup } from '@frontend/contact-group';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { ContactGroupType } from '../../common/BackendEnums';
import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import SelectInput from '../../components/forms/select/Select';
import TextInput from '../../components/forms/textInput/TextInput';
import Spinner from '../../components/loading/Spinner';
import CreateModalFooter from '../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import { FindChangedValue } from '../../utils/Logs';
import { parseObject } from '../../utils/ParseObject';

interface UpdateContactGroupFormProps {
    onCancel: () => void;
    successCallBack?: () => void;
    contactGroup?: ContactGroup;
}

const UpdateContactGroupForm = (props: UpdateContactGroupFormProps) => {
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const dispatch = useAppDispatch();
    const contactGroupState = useAppSelector((state) => state.contactGroups);
    const [foundGroup, changeFoundGroup] = useState<[string, ContactGroupType]>();
    const [foundParent, changeFoundParent] = useState<ContactGroup | undefined>();
    const [submitted, changeSubmitted] = useState(false);
    const { selectedUser } = useAccount();
    useEffect(() => {
        if (contactGroupState.contactGroupsList == null) {
            dispatch(fetchContactGroups({ account: accountId?.toString(), page: '1', page_size: '1000' }));
        }
        if (props.contactGroup && props.contactGroup.group_type) {
            const foundGroup = Object.entries(ContactGroupType).find((c) => c[1] === props.contactGroup?.group_type);
            if (foundGroup) changeFoundGroup(foundGroup);
        }
        if (props.contactGroup && props.contactGroup.parent && contactGroupState.contactGroupsList) {
            const foundParent = contactGroupState.contactGroupsList?.results.find((c) => c.url === props.contactGroup?.parent);
            if (foundParent) changeFoundParent(foundParent);
        }
    }, []);

    useEffect(() => {
        if (foundGroup) {
            changeFormResult({ ...formResult, group_type: { label: foundGroup[0], value: props.contactGroup?.group_type } });
        }
    }, [foundGroup]);

    const handleCancel = () => {
        props.onCancel();
    };
    const initialState = {
        name: props.contactGroup!.name,
        address: props.contactGroup!.address,
        zip: props.contactGroup!.zip,
        city: props.contactGroup!.city,
        country: props.contactGroup!.country,
        account: props.contactGroup!.account,
        parent: foundParent ? { label: foundParent.name, value: props.contactGroup!.parent } : undefined,
        group_type: foundGroup ? { label: foundGroup[0], value: props.contactGroup!.group_type } : undefined
    };
    const [formResult, changeFormResult] = useState<UpdateContactGroupModel>(initialState);

    useEffect(() => {
        if (submitted && props.contactGroup) {
            const parsedForm = parseObject(formResult);
            dispatch(
                updateContactGroup({
                    id: props.contactGroup?.id,
                    contactGroup: {
                        name: parsedForm.name,
                        account: accountId,
                        address: parsedForm.address,
                        city: parsedForm.city,
                        country: parsedForm.country,
                        group_type: parsedForm.group_type,
                        parent: parsedForm.parent,
                        zip: parsedForm.zip
                    }
                })
            );
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted) {
            const foundContact = contactGroupState.contactGroupsList?.results.find((c) => c.id === props.contactGroup?.id);
            if (contactGroupState.status === SliceStatus.IDLE && props.contactGroup && foundContact) {
                handleCancel();
                toast.success(...ToastUtil.generateToastConfig(formResult.name, UpdateContactGroupSuccessMessage, ClassType.SUCCESS));
                const difference = FindChangedValue(props.contactGroup, foundContact, undefined);
                Logger.log(`${selectedUser?.email} updated contact group: ${foundContact?.name}`, { user: selectedUser?.id }, difference);
                dispatch(fetchContactGroup({ id: props.contactGroup!.id }));
            } else if (contactGroupState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, UpdateContactGroupErrorMessage, ClassType.DANGER));
                Logger.error(
                    `${selectedUser?.email} failed to update contact group: ${foundContact?.name}`,
                    { user: selectedUser?.id },
                    contactGroupState.error?.json
                );
            }
        }
    }, [contactGroupState]);

    if (!props.contactGroup) return <Spinner />;

    return (
        <>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                    changeSubmitted(true);
                }}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.Name'
                                        description={'Form label for name'}
                                        defaultMessage='Name'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.name}
                                required
                                id='UpdateContactGroupForm.NameInput'
                                errorMessage={
                                    <FormattedMessage
                                        id='CeateContactGroupForm.Name.ErrorMessage'
                                        description={'Error message for name'}
                                        defaultMessage={'Please provide a name for the contact group'}
                                    />
                                }
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.Address'
                                        description={'Form label for address'}
                                        defaultMessage='Address'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.address}
                                id='UpdateContactGroupForm.AddressInput'
                                onChange={(value) => changeFormResult({ ...formResult, address: value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.Zip'
                                        description={'Form label for zip'}
                                        defaultMessage='Zip'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.zip}
                                id='UpdateContactGroupForm.ZipInput'
                                onChange={(value) => changeFormResult({ ...formResult, zip: value })}
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.City'
                                        description={'Form label for city'}
                                        defaultMessage='City'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.city}
                                id='UpdateContactGroupForm.CityInput'
                                onChange={(value) => changeFormResult({ ...formResult, city: value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.Country'
                                        description={'Form label for country'}
                                        defaultMessage='Country'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.country}
                                id='UpdateContactGroupForm.CountryInput'
                                onChange={(value) => changeFormResult({ ...formResult, country: value })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.Parent'
                                        description={'Form label for parent'}
                                        defaultMessage='Parent'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.parent}
                                id='UpdateContactGroupForm.ParentSelect'
                                options={
                                    contactGroupState.contactGroupsList
                                        ? contactGroupState.contactGroupsList.results
                                              .filter((cg) => cg.id !== props.contactGroup?.id)
                                              .map((cg) => ({ value: cg.url, label: cg.name }))
                                        : []
                                }
                                onChange={(value: any) => changeFormResult({ ...formResult, parent: value })}
                            />
                        </div>
                        <div className='col-md-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='UpdateContactGroupForm.Label.GroupType'
                                        description={'Form label for group type'}
                                        defaultMessage='Group type'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.group_type}
                                id='UpdateContactGroupForm-TypeSelect'
                                options={Object.entries(ContactGroupType).map((value) => ({ value: value[1], label: value[0] }))}
                                onChange={(value: any) => changeFormResult({ ...formResult, group_type: value })}
                            />
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    handleClose={() => handleCancel()}
                    onSubmit={() => undefined}
                />
            </form>
        </>
    );
};

export default UpdateContactGroupForm;

export interface UpdateContactGroupModel {
    name: string;
    address?: string;
    zip?: string;
    city?: string;
    country?: string;
    account?: number | null;
    parent?: { label: string; value: string | null } | undefined;
    group_type?: { label: string; value: ContactGroupType | undefined } | undefined;
}

const UpdateContactGroupSuccessMessage = (
    <FormattedMessage
        id='UpdateContactGroupForm.UpdateContactGroup.Success'
        description={'Message when successfully updating a contact group'}
        defaultMessage='Contact group successfully updated'
    />
);

const UpdateContactGroupErrorMessage = (
    <FormattedMessage
        id='UpdateContactGroupForm.UpdateContactGroup.Error'
        description={'Message when something went wrong while updating a contact group'}
        defaultMessage='Something went wrong while trying to update this contact group'
    />
);
