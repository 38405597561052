import { Column } from 'react-table';

import { Invite } from '../../api/UserInvites';

const InvitesListColumns: Column<Invite>[] = [
    {
        id: 'email',
        Header: 'Email'
    },
    {
        id: 'accepted',
        Header: 'Status'
    }
];

export default InvitesListColumns;
