import { Logger } from '@frontend/Logger';
import { ClassType, SliceStatus } from '@frontend/common';
import { deleteContactGroup } from '@frontend/contact-group';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';
import { ContactGroupDetailModalProps } from './contact-group-detail-modal.component';

interface ViewProps {
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    canUpdate: boolean;
    canDelete: boolean;
    showUpdateModal: boolean;
    showDeleteModal: boolean;
    onDeleteContactGroup: () => void;
}

const useContactGroupDetailModal = (props: ContactGroupDetailModalProps): ViewProps => {
    const dispatch = useAppDispatch();
    const contactGroupState = useAppSelector((state) => state.contactGroups);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const { selectedUser } = useAccount();
    const [isDeleted, setIsDeleted] = useState<boolean>(false);

    const canUpdate = props.contactGroup !== undefined;
    const canDelete = props.contactGroup !== undefined;

    const onDeleteContactGroup = () => {
        dispatch(deleteContactGroup(props.contactGroup!.id));
        setIsDeleted(true);
    };

    useEffect(() => {
        if (isDeleted) {
            if (contactGroupState.status === SliceStatus.IDLE) {
                toast.success(...ToastUtil.generateToastConfig(props.contactGroup!.name, deleteContactGroupSuccessMessage, ClassType.SUCCESS));
                Logger.log(`${selectedUser?.email} deleted contact group: ${props.contactGroup?.name}`, { user: selectedUser?.id }, props.contactGroup?.id);
                changeShowDeleteModal(false);
                props.handleClose();
            } else if (contactGroupState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(props.contactGroup!.name, deleteContactGroupErrorMessage, ClassType.DANGER));
                Logger.error(
                    `${selectedUser?.email} failed to delete contact group: ${props.contactGroup?.name}`,
                    { user: selectedUser?.id },
                    contactGroupState.error?.json
                );
                changeShowDeleteModal(false);
                props.handleClose();
            }
        }
    }, [contactGroupState]);

    return {
        changeShowUpdateModal,
        changeShowDeleteModal,
        canUpdate,
        canDelete,
        showUpdateModal,
        showDeleteModal,
        onDeleteContactGroup
    };
};

export default useContactGroupDetailModal;

const deleteContactGroupSuccessMessage = (
    <FormattedMessage
        id='deleteContactGroup.Success.Message'
        description={'Message displayed when successfully deleting a contact group'}
        defaultMessage='This contact group was successfully deleted'
    />
);

const deleteContactGroupErrorMessage = (
    <FormattedMessage
        id='deleteContactGroup.Error.Message'
        description={'Message displayed something went wrong while deleting a contact group'}
        defaultMessage='Something went wrong while deleting this contact group'
    />
);
