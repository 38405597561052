import { ApiError, ApiQueryParams, buildApiEndpoint, fetchApi } from '@frontend/api-utils';

import { LogMessageTypeName } from '../../logs/classes/LogMessageType';
import { LogSourceName } from '../../logs/classes/LogSource';

export interface RawDataField {
    [key: string]: unknown;
    source: string;
    id: number;
}

export interface LogData {
    message: string;
    message_type: LogMessageTypeName;
    source: LogSourceName;
    raw_data: RawDataField | null;
    timestamp: string;
}

export interface Logs {
    data: {
        nextToken: string;
        logs: LogData[];
    };
}

export interface LogBody {
    account: string;
    source?: LogSourceName;
    message_types?: LogMessageTypeName[];
    start_time?: string;
    end_time?: string;
    event_start_time?: string;
    event_end_time?: string;
    log_limit: string;
    [key: string]: any;
}

export enum LogQueryParams {
    ACCOUNT = 'account',
    SOURCE = 'source',
    MESSAGE_TYPE = 'message_type',
    START_TIME = 'start_time',
    END_TIME = 'end_time',
    EVENT_START_TIME = 'event_start_time',
    EVENT_END_TIME = 'event_end_time',
    NEXT_TOKEN = 'next_token',
    LOG_LIMIT = 'log_limit',
    USER = 'user',
    CONTACT = 'contact',
    CONTACT_GROUP = 'contact_group',
    TRANSACTION = 'transaction',
    TERMINAL = 'terminal',
    SLOT = 'slot',
    SPOT = 'spot',
    PRODUCT = 'product',
    SHOP = 'shop',
    OPERATOR = 'operator'
}

export async function fetchLogsApi(fetchNext?: boolean, queryparams?: ApiQueryParams<LogQueryParams>): Promise<Logs> {
    const URL = '/activity-logs/';
    const endpoint = buildApiEndpoint(URL, queryparams);
    const response = await fetchApi(endpoint, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error fetching logs');
        }
        throw new ApiError('Error fetching logs', json);
    }
    return await response.json();
}
