import { Logger } from '@frontend/Logger';
import { AuthenticationManager } from '@frontend/authentication';
import { FEATURES } from '@frontend/feature-flags';
import { useEffect, useRef, useState } from 'react';

export enum WebsocketState {
    NEW,
    INIT,
    CONNECTED,
    ERROR
}

declare global {
    interface WindowEventMap {
        'PubSub:onOpen': CustomEvent<Event>;
        'PubSub:onMessage': CustomEvent<MessageEvent<string>>;
        'PubSub:onClose': CustomEvent<CloseEvent>;
        'PubSub:onError': CustomEvent<Event>;
    }
}

export default function usePubSubConnection() {
    const [websocket, changeWebsocket] = useState<WebSocket | undefined>(undefined);
    const [websocketState, changeWebsocketState] = useState<WebsocketState>(WebsocketState.NEW);
    const cleanup = useRef(() => undefined);

    useEffect(() => {
        if (AuthenticationManager.getInstance().loggedIn && websocket == undefined && FEATURES.pubSub) {
            if (websocketState === WebsocketState.NEW) {
                AuthenticationManager.getInstance()
                    .getTokenWhenReady()
                    .then((token) => {
                        cleanup.current();
                        const newWebsocket = new WebSocket(
                            `${process.env.NX_PUBSUB_WS_URL}?access_token=${
                                token.accessToken
                            }&application=${AuthenticationManager.getInstance().getDeviceToken()}`
                        );
                        changeWebsocket(newWebsocket);
                        changeWebsocketState(WebsocketState.INIT);
                        cleanup.current = () => {
                            newWebsocket.close(1000);
                            newWebsocket.onopen = null;
                            newWebsocket.onmessage = null;
                            newWebsocket.onclose = null;
                            newWebsocket.onerror = null;
                        };
                    });
            } else if (websocketState === WebsocketState.ERROR) {
                setTimeout(() => {
                    changeWebsocketState(WebsocketState.NEW);
                }, 2000);
            }
        } else if (!AuthenticationManager.getInstance().loggedIn && websocket != undefined) {
            cleanup.current();
            changeWebsocket(undefined);
            changeWebsocketState(WebsocketState.NEW);
        }
    }, [AuthenticationManager.getInstance().loggedIn, websocket, websocketState]);

    const send = (message: string): boolean => {
        if (websocket == undefined || websocketState === WebsocketState.NEW) {
            Logger.error('No websocket connection available. Skip sending message.', {}, message);
            return false;
        }
        if (websocketState === WebsocketState.INIT) {
            Logger.error('Websocket connection available but not ready yet. Skip sending message.', {}, message);
            return false;
        }
        websocket.send(message);
        return true;
    };

    useEffect(() => {
        if (websocket) {
            const onOpen = (event: Event): void => {
                if (websocketState === WebsocketState.INIT) {
                    Logger.log('PubSub websocket connection succeeded.', {}, websocketState);
                    changeWebsocketState(WebsocketState.CONNECTED);
                    dispatchEvent(new CustomEvent<Event>('PubSub:onOpen', event));
                }
            };
            const onMessage = (event: MessageEvent<string>): void => {
                Logger.log('Received PubSub websocket message.', {}, event.data);
                dispatchEvent(new CustomEvent<MessageEvent<string>>('PubSub:onMessage', { detail: event }));
            };
            const onClose = (event: CloseEvent): void => {
                Logger.warn('PubSub websocket connection closed.', {}, event.reason);
                cleanup.current();
                changeWebsocket(undefined);
                changeWebsocketState(WebsocketState.NEW);
                dispatchEvent(new CustomEvent<CloseEvent>('PubSub:onClose', event));
            };
            const onError = (event: Event): void => {
                Logger.error('PubSub websocket connection error.', {}, event);
                cleanup.current();
                changeWebsocket(undefined);
                changeWebsocketState(WebsocketState.ERROR);
                dispatchEvent(new CustomEvent<Event>('PubSub:onError', event));
            };
            websocket.onopen = onOpen;
            websocket.onmessage = onMessage;
            websocket.onclose = onClose;
            websocket.onerror = onError;
        }
    }, [websocket]);

    useEffect(() => {
        return () => {
            if (websocket) {
                cleanup.current();
                changeWebsocket(undefined);
                changeWebsocketState(WebsocketState.NEW);
            }
        };
    }, []);

    return {
        send,
        isReady: websocketState === WebsocketState.CONNECTED,
        websocket
    };
}
