import { ApiQueryParams, ApiViewSet, apiPagination } from '@frontend/api-utils';

const slotLogsViewSet: ApiViewSet = {
    baseName: 'slot-logs'
};

export interface SlotLogsResponse {
    count: number;
    next: string;
    previous: string;
    results: Array<SlotLog>;
}

export interface SlotLog {
    id: number;
    warning: boolean;
    type: string;
    state: string;
    info: SlotLogInfo;
    slot_id: string;
    created_at: Date;
}

export enum SlotLogType {
    SENSOR = 'sensor',
    DOOR = 'door'
}

export interface SlotLogInfo {
    code: number;
    message: string;
}

export enum SlotLogsQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGE_SIZE = 'page_size',
    SLOT = 'slot',
    SPOT = 'spot',
    WARNING = 'warning'
}

export async function fetchSlotLogsApi(queryParams?: ApiQueryParams<SlotLogsQueryParams> | null): Promise<SlotLogsResponse> {
    return await apiPagination<SlotLogsResponse, SlotLogsQueryParams>(slotLogsViewSet, queryParams);
}

export function isSlotDoorLog(slotLog: SlotLog): slotLog is SlotDoorLog {
    return slotLog.type === SlotLogType.DOOR;
}
export function isSlotSensorLog(slotLog: SlotLog): slotLog is SlotSensorLog {
    return slotLog.type === SlotLogType.SENSOR;
}

export interface SlotDoorLog extends SlotLog {
    state: SlotDoorLogState;
}
export enum SlotDoorLogState {
    OPENED = 'opened',
    CLOSED = 'closed',
    UNKNOWN = 'unknown'
}

export interface SlotSensorLog extends SlotLog {
    state: SlotSensorState;
}
export enum SlotSensorState {
    EMPTY = 'empty',
    NOT_EMPTY = 'not_empty',
    UNKNOWN = 'unknown'
}
