import { AccountMembership } from '@frontend/user';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

const AccountsListColumns: Column<AccountMembership>[] = [
    {
        Header: 'ID',
        accessor: (row) => row.account.id
    },
    {
        id: 'name',
        Header: (
            <FormattedMessage
                id='AccountsListColumns.Table.Title.name'
                description='Table title for name'
                defaultMessage='name'
            />
        ),
        accessor: (row) => row.account.name
    },
    {
        id: 'role',
        Header: (
            <FormattedMessage
                id='AccountsListColumns.Table.Title.role'
                description='Table title for first role'
                defaultMessage='role'
            />
        ),
        accessor: (row) => row.role
    }
];

export default AccountsListColumns;
