import { FormattedMessage } from 'react-intl';

export const REPORT = {
    REPORT_CODE: (
        <FormattedMessage
            id='CommonMessage.Report.REPORT_CODE'
            description='Label for report code'
            defaultMessage='Report Code'
        />
    ),
    REPORT_CODE_QR: (
        <FormattedMessage
            id='CommonMessage.Report.REPORT_CODE_QR'
            description='Label for report code qr'
            defaultMessage='Report Code Qr'
        />
    )
};
