import { Transaction, fetchTransactionById } from '@frontend/transaction';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { Props } from './transaction-detail-modal-wrapper.component';

interface ViewProps {
    transaction?: Transaction;
}

const useTransactionDetailModalWrapper = (props: Props): ViewProps => {
    const dispatch = useAppDispatch();
    const transactions = useAppSelector((state) => state.transactions.all);
    const [transaction, changeTransaction] = useState<Transaction | undefined>(undefined);

    useEffect(() => {
        const found = transactions?.find((t) => t.id == props.id || t.real_id == parseInt(props.id));
        if (found) changeTransaction(found);
        else dispatch(fetchTransactionById(props.id));
    }, [props.id, transactions]);

    return { transaction };
};

export default useTransactionDetailModalWrapper;
