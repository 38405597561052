import { Transaction, TransactionResponse } from '@frontend/transaction';

import { TransactionEventState } from '../common/TransactionEvents';
import { TransactionStatus } from '../common/TransactionStatus';

export const ACTIVE_TRANSACTION_STATUSES: TransactionStatus[] = [
    TransactionStatus.NEW,
    TransactionStatus.SCHEDULED,
    TransactionStatus.SCHEDULE_IN_PROGRESS,
    TransactionStatus.SCHEDULE_WAITING,
    TransactionStatus.READY_FOR_DROPOFF,
    TransactionStatus.DROPOFF_IN_PROGRESS,
    TransactionStatus.DROPOFF_DONE,
    TransactionStatus.READY_FOR_PICKUP,
    TransactionStatus.PICKUP_IN_PROGRESS,
    TransactionStatus.CANCELLED
];

export function getAllActiveTransactions(list: { [transactionStatus: string]: TransactionResponse }): Transaction[] {
    const uniqueTransactions = new Map<string, Transaction>();

    ACTIVE_TRANSACTION_STATUSES.forEach((status) => {
        const matchingKeys = Object.keys(list).filter((key) => key.includes(status.name));
        matchingKeys.forEach((key) => {
            list[key].results.forEach((transaction) => {
                uniqueTransactions.set(transaction.id, transaction);
            });
        });
    });

    const result: Transaction[] = Array.from(uniqueTransactions.values());
    return result;
}

export function transactionEventStateToTransactionStatus(state: TransactionEventState): TransactionStatus {
    switch (state) {
        case TransactionEventState.NEW:
        case TransactionEventState.DROPOFF_INIT:
            return TransactionStatus.NEW;

        case TransactionEventState.DROPOFF_WAITING:
            return TransactionStatus.READY_FOR_DROPOFF;

        case TransactionEventState.DROPOFF_IN_PROGRESS:
        case TransactionEventState.DROPOFF_CLEANED:
        case TransactionEventState.PICKUP_INIT:
            return TransactionStatus.DROPOFF_IN_PROGRESS;

        case TransactionEventState.SCHEDULE_INIT:
        case TransactionEventState.SCHEDULE_CHANGED:
            return TransactionStatus.SCHEDULED;

        case TransactionEventState.SCHEDULE_WAITING:
            return TransactionStatus.SCHEDULE_WAITING;

        case TransactionEventState.SCHEDULE_IN_PROGRESS:
            return TransactionStatus.SCHEDULE_IN_PROGRESS;

        case TransactionEventState.SCHEDULE_DONE:
            return TransactionStatus.SCHEDULE_DONE;

        case TransactionEventState.DROPOFF_DONE:
            return TransactionStatus.DROPOFF_DONE;

        case TransactionEventState.PICKUP_WAITING:
        case TransactionEventState.SCHEDULED_PICKUP_DONE:
            return TransactionStatus.READY_FOR_PICKUP;

        case TransactionEventState.PICKUP_IN_PROGRESS:
        case TransactionEventState.PICKUP_CLEANED:
            return TransactionStatus.PICKUP_IN_PROGRESS;

        case TransactionEventState.PICKUP_DONE:
            return TransactionStatus.PICKUP_DONE;

        case TransactionEventState.SCHEDULE_CANCELLED:
        case TransactionEventState.DROPOFF_CANCELLED:
        case TransactionEventState.PICKUP_CANCELLED:
        case TransactionEventState.PICKUP_CANCELLED_CLEANED:
        case TransactionEventState.REMOVE_PARCEL_CLEANED:
        case TransactionEventState.REMOVE_PARCEL_DONE:
        case TransactionEventState.REMOVE_PARCEL_INIT:
        case TransactionEventState.REMOVE_PARCEL_WAITING:
        case TransactionEventState.CANCELLED:
            return TransactionStatus.CANCELLED;

        case TransactionEventState.REMOVE_PARCEL_IN_PROGRESS:
            return TransactionStatus.REMOVE_PARCEL_IN_PROGRESS;

        case TransactionEventState.DONE:
            return TransactionStatus.DONE;

        case TransactionEventState.ERROR:
        case TransactionEventState.ERROR_DONE:
        case TransactionEventState.DROPOFF_TIMEOUT:
        case TransactionEventState.PICKUP_TIMEOUT:
        case TransactionEventState.REMOVE_PARCEL_TIMEOUT:
            return TransactionStatus.ERROR;
    }
}
