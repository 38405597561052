import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../common/BootstrapValues';
import Filter from '../../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import CreateModal from '../../../components/modals/CreateModal';
import Table from '../../../components/tables/Table';
import AttributeDetailModal from '../attribute-detail/AttributeDetailModal';
import CreateAttribute from '../forms/CreateAttributeForm';
import AttributeListElement from './AttributeListElement';
import useAttributeList from './attribute-list.controller';

const ATTRIBUTE_LIST_ID = 'AttributeList';
export default function AttributeList() {
    const viewProps = useAttributeList();

    if (viewProps.filteredAttributes === null) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }

    return (
        <>
            <div
                id={ATTRIBUTE_LIST_ID}
                className='card'>
                <div className='card-header d-flex'>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <div className='d-flex flex-row justify-content-between align-items-end w-100'>
                            <HorizontalButtonGroup
                                buttons={[
                                    {
                                        type: ClassType.PRIMARY,
                                        hide: false,
                                        id: 'AttributeList-CreateAttributeButton',
                                        text: (
                                            <FormattedMessage
                                                id='AttributeList.CreateAttribute'
                                                description='Button for creating attribute'
                                                defaultMessage='Create Attribute'
                                            />
                                        ),
                                        onClick: () => viewProps.changeCreateAttributeOpen(true),
                                        icon: FaPlus
                                    }
                                ]}
                                direction='left'
                            />
                            <div className='ms-md-auto pe-md-3 d-flex align-items-center'>
                                <Filter
                                    objects={viewProps.filteredAttributes}
                                    filterKeys={['name']}
                                    filterValue={(value) => viewProps.setFilterValue(value.toLowerCase())}
                                    filterCallback={viewProps.changeTextFilter}
                                    useSearch
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    columns={viewProps.columns}
                    data={viewProps.data}
                    onSelectElement={viewProps.changeSelectedAttribute}
                    RowElement={AttributeListElement}
                    sortKeys={viewProps.sortKeys}
                    customSorting={(page, sortBy, sortOrder) => {
                        if (viewProps.sortKeys.includes(sortBy)) {
                            if (sortOrder === undefined) {
                                viewProps.changeCustomSorting(undefined);
                            } else {
                                viewProps.changeCustomSorting({ column: sortBy, orderBy: sortOrder });
                            }
                        }
                    }}
                />
            </div>
            {viewProps.createAttributeOpen && (
                <CreateModal
                    show={viewProps.createAttributeOpen}
                    handleClose={() => viewProps.changeCreateAttributeOpen(false)}
                    header='Create Attribute'>
                    <CreateAttribute handleClose={() => viewProps.changeCreateAttributeOpen(false)} />
                </CreateModal>
            )}
            {viewProps.selectedAttribute && (
                <AttributeDetailModal
                    attribute={viewProps.selectedAttribute}
                    handleClose={() => viewProps.changeSelectedAttribute(undefined)}
                    show={!!viewProps.selectedAttribute}
                    customWidth={30}
                />
            )}
        </>
    );
}
