import { Contact } from '@frontend/contact';
import { TransactionContact } from '@frontend/transaction';
import { AccountContactModel } from '@frontend/user';

import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import NameBadge from '../NameBadge';
import BadgeTemplate from '../badge-template/badge-template.component';
import useContactBadge from './contact-badge.controller';

export interface Props {
    contactId: string;
    contact?: Contact | TransactionContact | AccountContactModel;
    name?: string;
}

const ContactBadge = (props: Props) => {
    const viewProps = useContactBadge(props);

    return (
        <BadgeTemplate
            type={DetailModalType.CONTACT}
            objectId={props.contactId}>
            <NameBadge
                firstName={viewProps.firstName}
                lastName={viewProps.lastName}
            />
        </BadgeTemplate>
    );
};

export default ContactBadge;
