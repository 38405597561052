import { Logger } from '@frontend/Logger';
import { ClassType, Locale, NotificationChannel, SliceStatus } from '@frontend/common';
import { Contact, addContact } from '@frontend/contact';
import { ToastUtil } from '@frontend/toast-utils';
import { AccountMembership, UserInfo, addUser, fetchUsers } from '@frontend/user';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';
import { GetError } from '../../../store/utils/GetError';
import { parseObject } from '../../../utils/ParseObject';
import { CreateUserFormProps, userCreatedSuccess } from './create-user.component';

interface ViewProps {
    submitted: boolean;
    changeSubmitted: (value: React.SetStateAction<boolean>) => void;
    formResult: UserForm;
    changeFormResult: (value: React.SetStateAction<UserForm>) => void;
    formValid: FormValid;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    roleSelectOpen: boolean;
    changeRoleSelectOpen: (value: React.SetStateAction<boolean>) => void;
    isSuperAdmin: boolean;
    generateAccountMembers: (numbersArray: number[], existingAccountMembers?: AccountMember[]) => AccountMember[];
    accountMemberList: AccountMembership[];
    handleCancel: () => void;
    shouldCreateContact: boolean;
    shouldCreateAccessCode: boolean;
    changeShouldCreateContact: (value: React.SetStateAction<boolean>) => void;
    changeShouldCreateAccessCode: (value: React.SetStateAction<boolean>) => void;
    accessCode: IAccessCode;
    changeAccessCode: (value: React.SetStateAction<IAccessCode>) => void;
    changeEmailValid: (value: React.SetStateAction<boolean>) => void;
    emailValid: boolean;
    createdContact: Contact | undefined;
    callback: () => void;
}

interface FormValid {
    password: boolean;
    first_name: boolean;
    last_name: boolean;
    phone: boolean;
    mobile: boolean;
    language: boolean;
    accounts: boolean;
}

const useCreateUser = (props: CreateUserFormProps): ViewProps => {
    const isSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const authorised = useAuthorization(DefaultRole.ADMIN, true);
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.user.selectedMembership?.account);
    const usersName = useAppSelector((state) => state.user.usersList?.results?.map((u) => u.username));
    const users = useAppSelector((state) => state.user);
    const [submitted, changeSubmitted] = useState(false);
    const [formResult, changeFormResult] = useState<UserForm>(initialState);
    const [userWasCreated, changeUserWasCreated] = useState(false);
    const [roleSelectOpen, changeRoleSelectOpen] = useState<boolean>(false);
    const { selectedUser } = useAccount();
    const [shouldCreateContact, changeShouldCreateContact] = useState<boolean>(false);
    const [shouldCreateAccessCode, changeShouldCreateAccessCode] = useState<boolean>(false);
    const [accessCode, changeAccessCode] = useState<IAccessCode>({});
    const [emailValid, changeEmailValid] = useState<boolean>(false);
    const [formValid, changeFormValid] = useState<FormValid>({
        password: false,
        first_name: true,
        last_name: true,
        phone: true,
        mobile: true,
        language: false,
        accounts: false
    });
    const [createdContact, changeCreatedContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        if (!isSuperAdmin) {
            changeFormResult({ ...formResult, account_members: [{ account: account!.id, role: 'admin' }] });
        }
    }, []);

    useEffect(() => {
        if (!userWasCreated && submitted && authorised) {
            const parsedForm = parseObject(formResult);
            dispatch(
                addUser({
                    account_members: parsedForm.account_members.map((ac: any) => ({ account: { id: ac.account }, role: ac.role })),
                    email: formResult.email,
                    first_name: formResult.first_name,
                    language: parsedForm.language,
                    last_name: formResult.last_name,
                    password: formResult.password,
                    mobile: formResult.mobile,
                    phone: formResult.phone
                })
            ).then((res) => {
                if (res.type.includes('fulfilled') && shouldCreateContact) {
                    const body = res.payload as UserInfo;
                    dispatch(
                        addContact({
                            first_name: body.first_name,
                            last_name: body.last_name,
                            email: body.email,
                            user: body.id,
                            notification_channels: [NotificationChannel.EMAIL],
                            account: body.account_members[0].account.id,
                            associate_contact: false
                        })
                    ).then((res) => {
                        const contact = res.payload as Contact;
                        changeCreatedContact(contact);
                    });
                }
            });
        }
    }, [submitted]);

    useEffect(() => {
        if (users.status === SliceStatus.ERROR && submitted) {
            toast.error(...ToastUtil.generateToastConfig(formResult.email, GetError(users.error), ClassType.DANGER));
            changeSubmitted(false);
            const formObject = parseObject(formResult);
            delete formObject.password;
            Logger.error(`${selectedUser?.email} failed to create a user`, { user: selectedUser?.id }, formObject, users.error?.json);
        }
    }, [users.status]);

    useEffect(() => {
        if (!userWasCreated && submitted && users.status === SliceStatus.IDLE) {
            const createdUser = usersName?.find((a) => a === formResult.email);
            if (createdUser) {
                dispatch(fetchUsers({ account: account?.id?.toString(), page: '1', page_size: '100' }));
                changeUserWasCreated(true);
                const formObject = parseObject(formResult);
                delete formObject.password;
                Logger.log(`${selectedUser?.email} created a user: ${createdUser}`, { user: selectedUser?.id }, formObject);
                toast.success(...ToastUtil.generateToastConfig(formResult.email, userCreatedSuccess, ClassType.SUCCESS));
            } else {
                toast.error(...ToastUtil.generateToastConfig(formResult.email, GetError(users.error), ClassType.DANGER));
                changeSubmitted(false);
            }
        }
    }, [users.usersList]);

    const handleCancel = () => {
        changeFormResult(initialState);
        changeSubmitted(false);
        changeUserWasCreated(false);
        props.handleClose();
    };

    return {
        changeFormResult,
        changeFormValid,
        changeSubmitted,
        formResult,
        formValid,
        submitted,
        changeRoleSelectOpen,
        roleSelectOpen,
        isSuperAdmin,
        generateAccountMembers,
        accountMemberList: users.user ? users.user.account_members : [],
        handleCancel,
        shouldCreateAccessCode,
        changeShouldCreateContact,
        changeShouldCreateAccessCode,
        shouldCreateContact,
        accessCode,
        changeAccessCode,
        changeEmailValid,
        emailValid,
        createdContact,
        callback: handleCancel
    };
};

export default useCreateUser;

export interface UserForm {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: Locale | undefined;
    email: string;
    mobile: string;
    phone: string;
    password: string;
}

const initialState = {
    account_members: [],
    first_name: '',
    last_name: '',
    language: undefined,
    email: '',
    mobile: '',
    phone: '',
    password: ''
};

export interface AccountMember {
    account: number;
    role: string;
}

export function generateAccountMembers(numbersArray: number[], existingAccountMembers?: AccountMember[]): AccountMember[] {
    if (existingAccountMembers) {
        return numbersArray.map((number) => ({
            account: number,
            role: existingAccountMembers.find((ac) => ac.account === number) ? existingAccountMembers.find((ac) => ac.account === number)!.role : 'member'
        }));
    } else {
        return numbersArray.map((number) => ({
            account: number,
            role: 'member'
        }));
    }
}

export interface IAccessCode {
    type?: 'static' | 'dynamic';
    value?: string;
    expiration_time?: string;
}
