import { Attribute, fetchAttributes } from '@frontend/attribute';
import { Category } from '@frontend/category';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';

import CategoryBadge from '../../../components/badges/category-badge/category-badge.component';
import Spinner from '../../../components/loading/Spinner';
import ModalSectionTitle from '../../../components/modals/ModalSectionTitle';
import ObjectField from '../../../components/modals/ObjectField';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

export default function CategoryDetail(props: { category: Category | undefined }) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [category, changeCategory] = useState<Category>();
    const attributeState = useAppSelector((state) => state.attributes);
    const [attributeList, changeAttributeList] = useState<Attribute[]>([]);
    const attributeArray: Attribute[] = [];

    useEffect(() => {
        if (attributeState.attributeList === null) {
            dispatch(fetchAttributes({ account: accountId?.toString() }));
        }
    }, []);

    useEffect(() => {
        changeCategory(props.category);
    }, [props.category]);

    useEffect(() => {
        if (props.category && props.category.attributes) {
            props.category.attributes.forEach((a) => {
                if (attributeState.attributeList !== null) {
                    let foundAttribute: Attribute | undefined;
                    if (isNumber(a)) {
                        foundAttribute = attributeState.attributeList.find((att) => att.id === a);
                    } else {
                        foundAttribute = attributeState.attributeList.find((att) => att.id === a.id);
                    }
                    if (foundAttribute && foundAttribute !== undefined) {
                        const existingAttribute = attributeArray.find((att) => att.id === foundAttribute!.id);
                        if (!existingAttribute) {
                            attributeArray.push(foundAttribute);
                        }
                    }
                }
            });
            changeAttributeList(attributeArray);
        } else changeAttributeList([]);
    }, [props.category, attributeState.attributeList]);
    if (category === undefined) return <Spinner />;
    return (
        <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    value={category.name}
                    fieldName='Name'
                />
                {(category.parent_obj || category.parent_id) && (
                    <ObjectField
                        value={
                            <CategoryBadge
                                categoryId={category.parent_obj ? category.parent_obj.id.toString() : category.parent_id?.toString()}
                                name={category.parent_obj?.name}
                            />
                        }
                        fieldName='Parent'
                    />
                )}
                {attributeList.length > 0 && (
                    <div className='d-flex justify-content-between'>
                        <strong>Attributes</strong>
                        <div className='d-flex justify-content-end'>
                            {attributeList.map((a) => (
                                <p
                                    key={a.id}
                                    className='pe-1'>
                                    {a.name}
                                </p>
                            ))}
                        </div>
                    </div>
                )}
                {category.image && (
                    <>
                        <ModalSectionTitle title='Image' />
                        <img src={category.image} />
                    </>
                )}
            </div>
        </div>
    );
}
