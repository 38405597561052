import { Contact, fetchContact } from '@frontend/contact';
import { useEffect, useState } from 'react';

import Spinner from '../../components/loading/Spinner';
import Modal, { ModalProps, ModalType } from '../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import ContactDetailModal from './ContactDetailModal';

interface Props extends ModalProps {
    id: string;
}

const ContactDetailModalWrapper = (props: Props) => {
    const dispatch = useAppDispatch();
    const contacts = useAppSelector((state) => state.contacts.all);
    const [contact, changeContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        const found = contacts.find((c) => c.id == +props.id);
        if (!found) dispatch(fetchContact({ contactId: props.id }));
        else changeContact(found);
    }, [props.id, contacts]);

    if (contact == undefined) {
        return (
            <Modal
                {...props}
                type={ModalType.PANE}>
                <div className='d-flex align-items-center justify-content-center h-75'>
                    <Spinner />
                </div>
            </Modal>
        );
    }
    return (
        <ContactDetailModal
            contact={contact}
            {...props}
        />
    );
};

export default ContactDetailModalWrapper;
