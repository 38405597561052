export enum Locale {
    ENGLISH = 'en_US',
    DUTCH = 'nl_BE',
    FRENCH = 'fr_BE'
}

export enum NotificationChannel {
    EMAIL = 'email',
    SMS = 'sms'
}

export enum ContactGroupType {
    GROUP = 'group',
    COMPANY = 'company'
}

export enum TransactionType {
    PUDO = 'pudo',
    VENDING = 'vending',
    LENDING = 'lending',
    RETURN = 'return'
}

export enum PlatformHardwareDriver {
    UNKNOWN = '',
    ZHILAI = 'zhilai',
    KERONG_CU = 'kerongcu',
    KERONG_SCU = 'kerongscu',
    KERONG_12CU = 'kerong12cu',
    PSPG = 'pspg'
}

export enum ReportFormats {
    JSON = 'json',
    CSV = 'csv',
    PDF = 'pdf',
    ERP = 'erp'
}

export enum Workflow {
    PUDO = 'pudo',
    PUDO_PRIVATE = 'pudo_private',
    IMES_DEXIS_NIKE = 'imes_dexis_nike',
    LENDING = 'lending',
    VENDING = 'vending',
    WAREHOUSE = 'warehouse'
}
