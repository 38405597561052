import { FaMapMarkerAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

interface AddressBadgeProps {
    address?: string;
    zip?: string;
    city?: string;
}

export default function AddressBadge(props: AddressBadgeProps) {
    const address = `${props.address} ${props.zip} ${props.city}`;
    if (
        (props.address === undefined || props.address === '') &&
        (props.zip === undefined || props.zip === '') &&
        (props.city === undefined || props.city === '')
    )
        return (
            <div>
                <FormattedMessage
                    id='AddressBadge.InvalidAddressMessage'
                    description='message shown when the address provided is invalid'
                    defaultMessage='Invalid address'
                />
            </div>
        );
    return (
        <a
            target='_blank'
            rel='noreferrer'
            className='d-flex flex-row justify-content-center align-items-center'
            href={`https://www.google.com/maps/place/${props.address}, ${props.zip} ${props.city}`}>
            <span className='pe-2'>
                <FaMapMarkerAlt className=' text-success text-gradient' />
            </span>
            <span className='d-flex flex-column'>
                <span className='text-sm p-0 m-0'>{props.address ? address : '-'}</span>
            </span>
        </a>
    );
}
