import { FormattedMessage } from 'react-intl';

export enum AttributeTypeName {
    MULTI = 'multiple_choice',
    DROP_DOWN = 'drop_down',
    SINGLE_CHOICE = 'single_choice'
}

export class AttributeType {
    static readonly MULTI = new AttributeType(
        AttributeTypeName.MULTI,
        (
            <FormattedMessage
                id='AttributeType.MULTI'
                description='The displayed value for the attribute type MULTI'
                defaultMessage='MULTI'
            />
        )
    );
    static readonly DROPDOWN = new AttributeType(
        AttributeTypeName.DROP_DOWN,
        (
            <FormattedMessage
                id='AttributeType.DROPDOWN'
                description='The displayed value for the attribute type DROPDOWN'
                defaultMessage='DROPDOWN'
            />
        )
    );
    static readonly SINGLE_CHOICE = new AttributeType(
        AttributeTypeName.SINGLE_CHOICE,
        (
            <FormattedMessage
                id='AttributeType.SINGLE_CHOICE'
                description='The displayed value for the attribute type SINGLE_CHOICE'
                defaultMessage='SINGLE CHOICE'
            />
        )
    );

    static readonly ALL = [AttributeType.DROPDOWN, AttributeType.MULTI, AttributeType.SINGLE_CHOICE];
    private constructor(public readonly name: AttributeTypeName, public readonly displayedValue: React.ReactNode) {}

    static getByName(name: AttributeTypeName): AttributeType | undefined {
        return this.ALL.find((t) => t.name === name);
    }

    static getByString(value: string): AttributeType | undefined {
        return this.ALL.find((t) => t.name.toString() === value);
    }
}
