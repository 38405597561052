import { Product } from '@frontend/product';
import { useEffect, useState } from 'react';

import { Slot } from '../../api/Slots';
import { SpotLayoutItem } from '../../api/SpotLayoutItems';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppSelector } from '../../hooks/redux';
import useAssignedProducts from '../../hooks/useAssignedProducts';
import SlotOccupation from '../../hooks/useSlotOccupation';

interface ViewProps {
    assignedProducts: Product[] | undefined;
    slotOccupation: any | undefined;
    authorisedSuperAdmin: boolean;
    hardwareDriverOptions: any;
    spotLayoutItem: SpotLayoutItem | undefined;
    slotSettingsOpen: boolean;
    changeSlotSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useSlotDetail = (props: { slot?: Slot }): ViewProps => {
    const authorisedSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const [slotSettingsOpen, changeSlotSettingsOpen] = useState(false);
    const slotOccupation = SlotOccupation({ slot: props.slot });
    const assignedProducts = useAssignedProducts({ slot: props.slot });
    const spotLayoutItems = useAppSelector((state) => state.spotLayouts);
    const [spotLayoutItem, changeSpotLayoutItem] = useState<SpotLayoutItem>();

    useEffect(() => {
        if (props.slot) {
            if (spotLayoutItems.spotLayoutItemsMap[+props.slot.spot.replace('SPT', '')])
                changeSpotLayoutItem(spotLayoutItems.spotLayoutItemsMap[+props.slot.spot.replace('SPT', '')].find((s) => s.slot === props.slot?.id));
        }
    }, [props.slot, spotLayoutItems]);

    const hardwareDriverOptions = spotLayoutItem?.hardware_driver_options;

    return {
        assignedProducts,
        slotOccupation,
        authorisedSuperAdmin,
        hardwareDriverOptions,
        spotLayoutItem,
        slotSettingsOpen,
        changeSlotSettingsOpen
    };
};

export default useSlotDetail;
