import { Contact, fetchContacts, searchContacts } from '@frontend/contact';
import { ContactGroup, fetchContactGroups, searchContactGroups } from '@frontend/contact-group';
import { useEffect, useRef, useState } from 'react';

import { CommonMessage } from '../../../../common/CommonFormattedMessages/CommonMessage';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import AsyncSelectInput from '../AsyncSelectInput';
import { SelectInputProps } from '../Select';
import { GroupedContactsObj } from './ContactGroupSelect';

interface ContactSelectProps extends SelectInputProps {
    onChange?: (value: any) => void;
    isValid?: (value: boolean) => void;
}

const ContactOrContactGroupSelect = (props: ContactSelectProps) => {
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const contacts = useAppSelector((state) => state.contacts);
    const contactGroups = useAppSelector((state) => state.contactGroups);
    const [groupedContacts, changeGroupedContacts] = useState<GroupedContactsObj[]>([]);
    const [searchValue, changeSearchValue] = useState('');

    useEffect(() => {
        if (contacts.contactsList === null) {
            dispatch(fetchContacts({ account: accountId.toString(), page: '1', page_size: '100' }));
        }
        if (contactGroups.contactGroupsList === null) {
            dispatch(fetchContactGroups({ account: accountId.toString(), page: '1', page_size: '100' }));
        }
    }, []);

    useEffect(() => {
        if ((contacts.contactsList || contactGroups.contactGroupsList) && searchValue.length === 0) {
            const allContacts: GroupedContactsObj[] = [];
            if (contacts.contactsList)
                allContacts.push({
                    label: CommonMessage.OBJECTS.CONTACTS.CONTACT,
                    options: contacts.contactsList.results.map((c) => ({ value: c.id, label: c.first_name + ' ' + c.last_name, type: 'contact' }))
                });
            if (contactGroups.contactGroupsList)
                allContacts.push({
                    label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP,
                    options: contactGroups.contactGroupsList.results.map((cg) => ({ value: cg.id, label: cg.name, type: 'contactgroup' }))
                });
            changeGroupedContacts(allContacts);
        } else if (
            (contacts.searchContactsList || contactGroups.searchContactGroupsList) &&
            searchValue.length > 0 &&
            ((contacts.searchContactsList && contacts.searchContactsList[searchValue]) || contactGroups.searchContactGroupsList[searchValue])
        ) {
            const allContacts: GroupedContactsObj[] = [];
            if (contacts.searchContactsList && contacts.searchContactsList[searchValue])
                allContacts.push({
                    label: CommonMessage.OBJECTS.CONTACTS.CONTACT,
                    options: contacts.searchContactsList[searchValue].results.map((c) => ({
                        value: c.id,
                        label: c.first_name + ' ' + c.last_name,
                        type: 'contact'
                    }))
                });
            if (contactGroups.searchContactGroupsList[searchValue])
                allContacts.push({
                    label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP,
                    options: contactGroups.searchContactGroupsList[searchValue].map((cg) => ({ value: cg.id, label: cg.name, type: 'contactgroup' }))
                });
            changeGroupedContacts(allContacts);
        }
    }, [contacts.contactsList, searchValue, contacts.searchContactsList, contactGroups.contactGroupsList]);

    const timer = useRef<NodeJS.Timeout | null>(null);

    const loadOptions = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        const result: any[] = [];
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            dispatch(searchContactGroups({ search: inputValue, account: accountId.toString(), page: '1', page_size: '1000' })).then((res: any) => {
                if (res.payload) {
                    const foundContactGroups = res.payload.results.map((c: ContactGroup) => ({ value: c.id, label: c.name, type: 'contactgroup' }));
                    result.push({ label: CommonMessage.OBJECTS.CONTACTS.CONTACT_GROUP, options: foundContactGroups });
                }
            });
            dispatch(searchContacts({ search: inputValue, account: accountId.toString(), page: '1', page_size: '1000' })).then((res: any) => {
                if (res.payload) {
                    const foundContacts = res.payload.results.map((c: Contact) => ({ value: c.id, label: c.first_name + ' ' + c.last_name, type: 'contact' }));
                    result.push({ label: CommonMessage.OBJECTS.CONTACTS.CONTACT, options: foundContacts });
                }
            });
            callback(result);
        }, 500);
    };

    const url = contacts.contactsList?.next;
    const cgUrl = contactGroups.contactGroupsList?.next;

    return (
        <AsyncSelectInput
            required={props.required ? props.required : false}
            label={props.label}
            submitted={props.submitted}
            isClearable
            cacheOptions
            onChange={(value) => {
                props.onChange && props.onChange(value);
            }}
            value={props.value}
            loadOptions={loadOptions}
            errorMessage={props.errorMessage}
            defaultOptions={groupedContacts}
            onMenuScrollToBottom={() => {
                if (!url && !cgUrl) return false;
                if (url) {
                    const contactsURL = new URLSearchParams(url);
                    const newPage = contactsURL.get('page');
                    const newPageSize = contactsURL.get('page_size');
                    dispatch(fetchContacts({ account: accountId.toString(), page: newPage, page_size: newPageSize }));
                }
                if (cgUrl) {
                    const contactGroupsURL = new URLSearchParams(cgUrl);
                    const cgNewPage = contactGroupsURL.get('page');
                    const cgNewPageSize = contactGroupsURL.get('page_size');
                    dispatch(fetchContactGroups({ account: accountId.toString(), page: cgNewPage, page_size: cgNewPageSize }));
                }
                return true;
            }}
            isValidCallback={(valid) => props.isValid && props.isValid(valid)}
        />
    );
};

export default ContactOrContactGroupSelect;
