import { useLocation, useNavigate } from 'react-router-dom';

import { Props } from './badge-template.component';

interface ViewProps {
    onClick: () => void;
}

const useBadgeTemplate = (props: Props): ViewProps => {
    const navigate = useNavigate();
    const location = useLocation();

    const onClick = () => {
        const urlValue = props.type + '-' + props.objectId;
        const queryParams = new URLSearchParams(location.search);
        const detail = queryParams.get('details');
        if (detail) {
            queryParams.set('details', JSON.stringify([...(JSON.parse(detail) as string[]), urlValue]));
        } else {
            queryParams.set('details', JSON.stringify([urlValue]));
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    };

    return { onClick };
};

export default useBadgeTemplate;
