import { Category } from '@frontend/category';
import { BiCategory } from 'react-icons/bi';

import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useCategoryBadge from './category-badge.controller';

export interface CategoryBadgeProps {
    categoryId?: string;
    category?: Category;
    name?: string;
}

const CategoryBadge = (props: CategoryBadgeProps) => {
    const viewProps = useCategoryBadge(props);
    return (
        <BadgeTemplate
            type={DetailModalType.CATEGORY}
            objectId={props.categoryId}>
            <BiCategory className='me-2' />
            {viewProps.name}
        </BadgeTemplate>
    );
};

export default CategoryBadge;
