import { Logger } from '@frontend/Logger';
import { SliceStatus } from '@frontend/common';
import { Product, deleteProduct, fetchProductById, updateSlotsOfProduct } from '@frontend/product';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FaExchangeAlt, FaPlus } from 'react-icons/fa';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { Link, Location, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CreateModal from '../../../components/modals/CreateModal';
import DetailModal from '../../../components/modals/DetailModal';
import DisplayModal from '../../../components/modals/DisplayModal';
import { ModalProps } from '../../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';
import { fetchSlots } from '../../../store/slotSlice';
import CreateProductAttribute from '../../attributes/forms/CreateProductAttribute';
import UpdateProductAttribute from '../../attributes/forms/UpdateProductAttribute';
import SlotAssignment from '../TransferLists/SlotAssignment';
import UpdateProductForm from '../forms/update-product-form/UpdateProductForm';
import ProductDetail, { UpdatedSlotsList } from './ProductDetail';

const PRODUCT_DETAIL_MODAL_ID = 'ProductDetailModal';

interface ProductDetailModalProps extends ModalProps {
    product: Product;
    filter?: string;
}

const ProductDetailModal = (props: ProductDetailModalProps) => {
    const dispatch = useAppDispatch();
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [slotAssignmentOpen, changeSlotAssignmentOpen] = useState<boolean>(false);
    const [productSlots, changeProductSlots] = useState<UpdatedSlotsList>();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const productState = useAppSelector((state) => state.products);
    const [product, changeProduct] = useState<Product | undefined>(undefined);
    const { selectedUser } = useAccount();
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [addProductAttributeModal, setAddProductAttributeModal] = useState<boolean>(false);
    const [editProductAttributeModal, setEditProductAttributeModal] = useState<boolean>(false);

    const { state } = useLocation();
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState<Location>();
    const { shopId } = useParams();

    const canUpdate = props.product !== undefined;
    const canDelete = props.product !== undefined;

    useEffect(() => {
        if (props.product) changeProduct(props.product);
    }, [props.product]);

    useEffect(() => {
        if (productState.shopProductList) {
            let foundProduct;
            if (props.filter && productState.searchProductsList && productState.searchProductsList[props.filter]) {
                foundProduct = productState.searchProductsList[props.filter].results.find((p) => p !== null && p.id === props.product.id);
            } else if (productState.shopProductList && shopId && productState.shopProductList[shopId]) {
                productState.shopProductList[shopId!].results.find((p) => p !== null && p !== undefined && p.id === props.product.id);
            }
            if (foundProduct) {
                changeProduct(foundProduct);
            }
        }
    }, [productState.shopProductList]);

    useEffect(() => {
        if (state !== null) {
            setPrevLocation(state.prevLocation);
        }
    }, [location, prevLocation]);

    useEffect(() => {
        if (isDeleted) {
            if (productState.status === SliceStatus.IDLE) {
                toast.success(...ToastUtil.generateToastConfig(CommonMessage.STATUS.SUCCESS, `Successfully deleted ${props.product.name}`, ClassType.SUCCESS));
                Logger.log(
                    `${selectedUser?.email} deleted product: ${props.product.name}`,
                    { user: selectedUser?.id, product: props.product.id },
                    props.product.id
                );
                changeShowDeleteModal(false);
                props.handleClose();
            } else if (productState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, `${productState.error?.json}`, ClassType.DANGER));
                Logger.error(`${selectedUser?.email} failed to delete product ${props.product.name}`, { user: selectedUser?.id, product: props.product.id });
                changeShowDeleteModal(false);
                props.handleClose();
            }
        }
    }, [productState]);

    if (!product) return <Spinner />;

    const onDeleteProduct = () => {
        dispatch(deleteProduct(product.id));
        setIsDeleted(true);
    };
    const handleSubmit = () => {
        if (productSlots) {
            dispatch(updateSlotsOfProduct({ updatedSlotsList: productSlots, id: product.id.toString() }))
                .then(() => {
                    toast.success(...ToastUtil.generateToastConfig(props.product.name, changeProductSlotsSuccess, ClassType.SUCCESS));
                    Logger.log(
                        `${selectedUser?.email} updated ${props.product.name} slots`,
                        { user: selectedUser?.id, product: props.product.id },
                        productSlots.slots
                    );
                    dispatch(fetchSlots({ account: accountId?.toString(), page: '1', page_size: '1000' }));
                    dispatch(fetchProductById(product.id.toString()));
                    props.handleClose && props.handleClose();
                })
                .catch(() => {
                    toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, changeProductSlotsError, ClassType.DANGER));
                    Logger.error(`${selectedUser?.email} failed to update ${props.product.name} slots`, { user: selectedUser?.id, product: props.product.id });
                });
        }
    };

    return (
        <DetailModal
            handleClose={() => props.handleClose()}
            id={PRODUCT_DETAIL_MODAL_ID}
            title={product.name}
            show={props.show}>
            <div className='modal-body'>
                <ProductDetail
                    product={product}
                    handleClose={() => props.handleClose()}
                />
            </div>

            <div
                className='modal-footer d-flex flex-row justify-content-between align-items-center'
                style={{ overflowX: 'auto' }}>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            type: ClassType.PRIMARY,
                            hide: false,
                            text: (
                                <FormattedMessage
                                    id='ProductDetail.options.updateProduct'
                                    description='Label for updating a product'
                                    defaultMessage='Update Product'
                                />
                            ),
                            onClick: () => changeShowUpdateModal(true),
                            icon: FiEdit2,
                            id: 'ProductDetailModal.updateButton'
                        },
                        {
                            type: ClassType.WARNING,
                            hide: false,
                            text: (
                                <FormattedMessage
                                    id='ProductDetailModal.options.slotAssignment'
                                    description={'Button for changing the slot assignment'}
                                    defaultMessage={'Slot Assignment'}
                                />
                            ),
                            onClick: () => changeSlotAssignmentOpen(true),
                            icon: FaExchangeAlt,
                            id: 'UpdateProductForm-SlotAssignmentButton'
                        },
                        {
                            type: ClassType.PRIMARY,
                            hide: false,
                            id: 'ProductDetailModal-AddProductAttributeButton',
                            text: (
                                <FormattedMessage
                                    id='ProductDetailModal.options.addProductAttribute'
                                    description='Label for adding product attribute'
                                    defaultMessage='Add product attribute'
                                />
                            ),
                            onClick: () => setAddProductAttributeModal(true),
                            icon: FaPlus
                        },
                        {
                            type: ClassType.PRIMARY,
                            hide: product.product_attributes.length === 0,
                            text: (
                                <FormattedMessage
                                    id='ProductDetailModal.options.editProductAttribute'
                                    description='Label for editing product attribute'
                                    defaultMessage='Edit product attribute'
                                />
                            ),
                            onClick: () => setEditProductAttributeModal(true),
                            icon: FiEdit2
                        },
                        {
                            type: ClassType.DANGER,
                            hide: false,
                            id: 'ProductDetailModal-DeleteButton',
                            text: (
                                <FormattedMessage
                                    id='ProductDetail.options.delete'
                                    description='Label for deleting a product'
                                    defaultMessage='Delete'
                                />
                            ),
                            onClick: () => changeShowDeleteModal(true),
                            icon: IoMdTrash
                        }
                    ]}
                    direction='left'
                />

                {prevLocation && (
                    <div className='d-flex flex-row justify-content-between'>
                        <Link
                            key={prevLocation.key}
                            to={prevLocation}>
                            <button className='btn bg-gradient-info'>Go back</button>
                        </Link>
                    </div>
                )}
            </div>

            {canUpdate && (
                <CreateModal
                    show={showUpdateModal}
                    handleClose={() => changeShowUpdateModal(false)}
                    header='Update Product'>
                    <UpdateProductForm
                        handleClose={() => changeShowUpdateModal(false)}
                        product={product}
                    />
                </CreateModal>
            )}
            {canDelete && (
                <ConfirmationModal
                    show={showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => changeShowDeleteModal(false)}
                    message={
                        <FormattedMessage
                            id='deleteProduct.ConfirmMessage'
                            description='The confirmation message checking if the user is sure about removing a product'
                            defaultMessage='Are you sure you want to delete this product? This action cannot be undone.'
                        />
                    }
                    onConfirm={onDeleteProduct}
                />
            )}
            {slotAssignmentOpen && (
                <DisplayModal
                    handleClose={() => changeSlotAssignmentOpen(false)}
                    show={slotAssignmentOpen}
                    header={product.name}
                    customWidth={70}>
                    <SlotAssignment
                        product={product}
                        callback={(value) => changeProductSlots(value)}
                        onSubmit={handleSubmit}
                    />
                </DisplayModal>
            )}
            {addProductAttributeModal && (
                <CreateModal
                    show={addProductAttributeModal}
                    handleClose={() => setAddProductAttributeModal(false)}
                    header={product.name}>
                    <CreateProductAttribute
                        product={product}
                        handleClose={() => setAddProductAttributeModal(false)}
                        onSuccess={() => dispatch(fetchProductById(props.product.id.toString()))}
                    />
                </CreateModal>
            )}
            {editProductAttributeModal && (
                <CreateModal
                    show={editProductAttributeModal}
                    handleClose={() => setEditProductAttributeModal(false)}
                    header={product.name}>
                    <UpdateProductAttribute
                        product={product}
                        handleClose={() => setEditProductAttributeModal(false)}
                        onSuccess={() => dispatch(fetchProductById(props.product.id.toString()))}
                    />
                </CreateModal>
            )}
        </DetailModal>
    );
};

export default ProductDetailModal;

const changeProductSlotsSuccess = (
    <FormattedMessage
        id='TransferList.changeProductSlots.Success'
        description={'Message when successfully changing the slots on a product'}
        defaultMessage='The assigned slots were successfully updated'
    />
);

const changeProductSlotsError = (
    <FormattedMessage
        id='TransferList.changeProductSlots.Error'
        description={'Message when getting an error while changing the slots on a product'}
        defaultMessage='There was an error while trying to update the assigned slots'
    />
);
