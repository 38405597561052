import { FormattedMessage } from 'react-intl';

import { ClassType } from './BootstrapValues';

/**
 * TODO: Backend should change the statuses to be return properly
 * the mapping is very unclear now and the frontend cannot distinguish statuses properly
 */

export enum TransactionStatusName {
    NEW = 'new',
    SCHEDULED = 'scheduled',
    SCHEDULE_WAITING = 'schedule_waiting',
    SCHEDULE_IN_PROGRESS = 'schedule_in_progress',
    SCHEDULE_DONE = 'schedule_done',
    READY_FOR_DROPOFF = 'dropoff_waiting',
    DROPOFF_IN_PROGRESS = 'dropoff_in_progress',
    DROPOFF_DONE = 'dropoff_done',
    READY_FOR_PICKUP = 'pickup_waiting',
    PICKUP_IN_PROGRESS = 'pickup_in_progress',
    PICKUP_DONE = 'pickup_done',
    REMOVE_PARCEL_IN_PROGRESS = 'remove_parcel_in_progress',
    REMOVE_PARCEL_WAITING = 'remove_parcel_waiting',
    CANCELLED = 'cancelled',
    ERROR = 'error',
    ERROR_DONE = 'error_done',
    DONE = 'done'
}
export enum TransactionStatusValue {
    TRS000001 = 'TRS000001',
    TRS000021 = 'TRS000021',
    TRS000022 = 'TRS000022',
    TRS000023 = 'TRS000023',
    TRS000025 = 'TRS000025',
    TRS000002 = 'TRS000002',
    TRS000003 = 'TRS000003',
    TRS000013 = 'TRS000013',
    TRS000004 = 'TRS000004',
    TRS000005 = 'TRS000005',
    TRS000015 = 'TRS000015',
    TRS000006 = 'TRS000006',
    TRS000199 = 'TRS000199',
    TRS000099 = 'TRS000099',
    TRS000899 = 'TRS000899'
}

export class TransactionStatus {
    static readonly NEW = new TransactionStatus(
        TransactionStatusValue.TRS000001,
        TransactionStatusName.NEW,
        ClassType.SUCCESS,
        (
            <FormattedMessage
                id='TransactionStatus.NEW'
                description='the displayed value for the transaction status NEW'
                defaultMessage='New'
            />
        )
    );
    static readonly SCHEDULED = new TransactionStatus(
        TransactionStatusValue.TRS000021,
        TransactionStatusName.SCHEDULED,
        ClassType.PRIMARY,
        (
            <FormattedMessage
                id='TransactionStatus.SCHEDULED'
                description='the displayed value for the transaction status SCHEDULED'
                defaultMessage='Scheduled'
            />
        )
    );
    static readonly SCHEDULE_WAITING = new TransactionStatus(
        TransactionStatusValue.TRS000022,
        TransactionStatusName.SCHEDULE_WAITING,
        ClassType.LIGHT,
        (
            <FormattedMessage
                id='TransactionStatus.SCHEDULE_WAITING'
                description='the displayed value for the transaction status SCHEDULE_WAITING'
                defaultMessage='Schedule waiting'
            />
        )
    );
    static readonly SCHEDULE_IN_PROGRESS = new TransactionStatus(
        TransactionStatusValue.TRS000023,
        TransactionStatusName.SCHEDULE_IN_PROGRESS,
        ClassType.LIGHT,
        (
            <FormattedMessage
                id='TransactionStatus.SCHEDULE_IN_PROGRESS'
                description='the displayed value for the transaction status SCHEDULE_IN_PROGRESS'
                defaultMessage='Schedule in progress'
            />
        )
    );
    static readonly SCHEDULE_DONE = new TransactionStatus(
        TransactionStatusValue.TRS000025,
        TransactionStatusName.SCHEDULE_DONE,
        ClassType.PRIMARY,
        (
            <FormattedMessage
                id='TransactionStatus.SCHEDULE_DONE'
                description='the displayed value for the transaction status SCHEDULE_DONE'
                defaultMessage='Schedule done'
            />
        )
    );
    static readonly READY_FOR_DROPOFF = new TransactionStatus(
        TransactionStatusValue.TRS000002,
        TransactionStatusName.READY_FOR_DROPOFF,
        ClassType.SECONDARY,
        (
            <FormattedMessage
                id='TransactionStatus.READY_FOR_DROPOFF'
                description='the displayed value for the transaction status READY_FOR_DROPOFF'
                defaultMessage='Ready for dropoff'
            />
        )
    );
    static readonly DROPOFF_IN_PROGRESS = new TransactionStatus(
        TransactionStatusValue.TRS000003,
        TransactionStatusName.DROPOFF_IN_PROGRESS,
        ClassType.LIGHT,
        (
            <FormattedMessage
                id='TransactionStatus.DROPOFF_IN_PROGRESS'
                description='the displayed value for the transaction status DROPOFF_IN_PROGRESS'
                defaultMessage='Dropoff in progress'
            />
        )
    );
    static readonly DROPOFF_DONE = new TransactionStatus(
        TransactionStatusValue.TRS000013,
        TransactionStatusName.DROPOFF_DONE,
        ClassType.PRIMARY,
        (
            <FormattedMessage
                id='TransactionStatus.DROPOFF_DONE'
                description='the displayed value for the transaction status DROPOFF_DONE'
                defaultMessage='Dropoff done'
            />
        )
    );
    static readonly READY_FOR_PICKUP = new TransactionStatus(
        TransactionStatusValue.TRS000004,
        TransactionStatusName.READY_FOR_PICKUP,
        ClassType.INFO,
        (
            <FormattedMessage
                id='TransactionStatus.READY_FOR_PICKUP'
                description='the displayed value for the transaction status READY_FOR_PICKUP'
                defaultMessage='Ready for pickup'
            />
        )
    );
    static readonly PICKUP_IN_PROGRESS = new TransactionStatus(
        TransactionStatusValue.TRS000005,
        TransactionStatusName.PICKUP_IN_PROGRESS,
        ClassType.LIGHT,
        (
            <FormattedMessage
                id='TransactionStatus.PICKUP_IN_PROGRESS'
                description='the displayed value for the transaction status PICKUP_IN_PROGRESS'
                defaultMessage='Pickup in progress'
            />
        )
    );
    static readonly PICKUP_DONE = new TransactionStatus(
        TransactionStatusValue.TRS000015,
        TransactionStatusName.PICKUP_DONE,
        ClassType.PRIMARY,
        (
            <FormattedMessage
                id='TransactionStatus.PICKUP_DONE'
                description='the displayed value for the transaction status PICKUP_DONE'
                defaultMessage='Pickup done'
            />
        )
    );
    static readonly REMOVE_PARCEL_IN_PROGRESS = new TransactionStatus(
        TransactionStatusValue.TRS000199,
        TransactionStatusName.REMOVE_PARCEL_IN_PROGRESS,
        ClassType.LIGHT,
        (
            <FormattedMessage
                id='TransactionStatus.REMOVE_PARCEL_IN_PROGRESS'
                description='the displayed value for the transaction status REMOVE_PARCEL_IN_PROGRESS'
                defaultMessage='Remove parcel in progress'
            />
        )
    );
    static readonly CANCELLED = new TransactionStatus(
        TransactionStatusValue.TRS000099,
        TransactionStatusName.CANCELLED,
        ClassType.WARNING,
        (
            <FormattedMessage
                id='TransactionStatus.CANCELLED'
                description='the displayed value for the transaction status CANCELLED'
                defaultMessage='Cancelled'
            />
        )
    );
    static readonly ERROR = new TransactionStatus(
        TransactionStatusValue.TRS000899,
        TransactionStatusName.ERROR,
        ClassType.DANGER,
        (
            <FormattedMessage
                id='TransactionStatus.ERROR'
                description='the displayed value for the transaction status ERROR'
                defaultMessage='Error'
            />
        )
    );
    static readonly REMOVE_PARCEL_WAITING = new TransactionStatus(
        TransactionStatusValue.TRS000199,
        TransactionStatusName.REMOVE_PARCEL_WAITING,
        ClassType.LIGHT,
        (
            <FormattedMessage
                id='TransactionStatus.REMOVE_PARCEL_WAITING'
                description='the displayed value for the transaction status REMOVE_PARCEL_WAITING'
                defaultMessage='Remove parcel waiting'
            />
        )
    );
    static readonly ERROR_DONE = new TransactionStatus(
        TransactionStatusValue.TRS000899,
        TransactionStatusName.ERROR_DONE,
        ClassType.DANGER,
        (
            <FormattedMessage
                id='TransactionStatus.ERROR_DONE'
                description='The displayed value for the transactionstatus ERROR_DONE'
                defaultMessage='Error done'
            />
        )
    );
    static readonly DONE = new TransactionStatus(
        TransactionStatusValue.TRS000006,
        TransactionStatusName.DONE,
        ClassType.SUCCESS,
        (
            <FormattedMessage
                id='TransactionStatus.COMPLETED'
                description='the displayed value for the transaction status COMPLETED'
                defaultMessage='Completed'
            />
        )
    );
    static readonly ALL = [
        TransactionStatus.NEW,
        TransactionStatus.SCHEDULED,
        TransactionStatus.SCHEDULE_WAITING,
        TransactionStatus.SCHEDULE_IN_PROGRESS,
        TransactionStatus.SCHEDULE_DONE,
        TransactionStatus.READY_FOR_DROPOFF,
        TransactionStatus.DROPOFF_IN_PROGRESS,
        TransactionStatus.DROPOFF_DONE,
        TransactionStatus.READY_FOR_PICKUP,
        TransactionStatus.PICKUP_IN_PROGRESS,
        TransactionStatus.PICKUP_DONE,
        TransactionStatus.REMOVE_PARCEL_IN_PROGRESS,
        TransactionStatus.REMOVE_PARCEL_WAITING,
        TransactionStatus.CANCELLED,
        TransactionStatus.ERROR,
        TransactionStatus.ERROR_DONE,
        TransactionStatus.DONE
    ];

    static readonly RELEVANT = [
        TransactionStatus.SCHEDULE_WAITING,
        TransactionStatus.READY_FOR_DROPOFF,
        TransactionStatus.READY_FOR_PICKUP,
        TransactionStatus.REMOVE_PARCEL_WAITING,
        TransactionStatus.CANCELLED,
        TransactionStatus.DONE,
        TransactionStatus.ERROR
    ];

    static readonly ACTIVE = [TransactionStatus.READY_FOR_DROPOFF, TransactionStatus.READY_FOR_PICKUP, TransactionStatus.REMOVE_PARCEL_WAITING];

    // private to disallow creating other instances of this type
    private constructor(
        public readonly value: TransactionStatusValue,
        public readonly name: TransactionStatusName,
        public readonly classType: ClassType,
        public readonly displayedValue: React.ReactNode
    ) {}

    static getByName(name: TransactionStatusName): TransactionStatus | undefined {
        return this.ALL.find((s) => s.name === name);
    }
    static getByValue(value: TransactionStatusValue): TransactionStatus | undefined {
        return this.ALL.find((s) => s.value === value);
    }
    static getByString(value: string): TransactionStatus | undefined {
        return this.ALL.find((s) => s.name.toString() === value || s.value.toString() === value);
    }
}
