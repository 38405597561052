import { Transaction, moveTransaction } from '@frontend/transaction';
import { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../common/BootstrapValues';
import SelectInput from '../../../components/forms/select/Select';
import Spinner from '../../../components/loading/Spinner';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAvailableSlots } from '../../../store/slotSlice';
import { fetchSpots } from '../../../store/spotSlice';
import { parseObject } from '../../../utils/ParseObject';

interface Props extends ModalProps {
    transaction: Transaction;
    callback: (transactionId: number | undefined) => void;
}

export default function MoveTransactionForm(props: Props) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const spotsState = useAppSelector((state) => state.spots);
    const slotsState = useAppSelector((state) => state.slots);

    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formResult, changeFormResult] = useState<FormResult>({});
    const [parsedFormResult, changeParsedFormResult] = useState<Record<string, any>>(formResult);
    const [formValid, changeFormValid] = useState<FormValid>({});
    const [slotSelect, changeSlotSelect] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchSpots({ account: accountId?.toString() }));
    }, []);

    useEffect(() => {
        changeSlotSelect(parsedFormResult.spot !== undefined && slotsState.availableSlotsList[parsedFormResult.spot] !== undefined);
    }, [parsedFormResult.spot, slotsState.availableSlotsList[parsedFormResult.spot]]);

    useEffect(() => {
        if (formResult) changeParsedFormResult(parseObject(formResult));
    }, [formResult]);

    useEffect(() => {
        if (parsedFormResult.spot !== undefined) {
            dispatch(fetchAvailableSlots({ account: accountId?.toString(), spot: parsedFormResult.spot, transaction_type: props.transaction.type }));
        } else if (spotsState.spotList !== null) {
            changeFormResult({ slot: undefined, spot: props.transaction.spot_id });
        }
    }, [formResult.spot, parsedFormResult.spot, spotsState]);

    const onsubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
    };

    const onConfirm = () => {
        dispatch(
            moveTransaction({
                transactionId: props.transaction.id,
                body: {
                    new_spot_id: parsedFormResult.spot!,
                    new_slot_id: parsedFormResult.slot
                }
            })
        ).then(() => {
            props.callback(props.transaction.real_id);
            props.handleClose();
        });
    };

    return (
        <Modal
            type={ModalType.PROMPT}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-primary text-gradient'>Move transaction</h3>
                <button
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>
            <form>
                <div
                    className='modal-body'
                    style={{ overflow: 'visible' }}>
                    {spotsState.spotList === null ? (
                        <Spinner />
                    ) : (
                        <div className='row'>
                            <div className='col-md-6'>
                                <SelectInput
                                    label={
                                        <FormattedMessage
                                            id='MoveTransaction.form.spot.title'
                                            description='The select spot title on the MoveTransaction form.'
                                            defaultMessage='Spot'
                                        />
                                    }
                                    value={formResult.spot}
                                    onChange={(value: any) => changeFormResult({ slot: undefined, spot: value })}
                                    errorMessage={
                                        <FormattedMessage
                                            id='MoveTransaction.form.spot.errorMessage'
                                            description='The select spot errorMessage on the MoveTransaction form.'
                                            defaultMessage='Please select a spot.'
                                        />
                                    }
                                    options={spotsState.spotList.map((s) => ({ value: s.id, label: `${s.name} ${s.address_line_1} ${s.city}` }))}
                                    required
                                    isValidCallback={(valid) => {
                                        changeFormValid({ ...formValid, spot: valid });
                                    }}
                                    submitted={submitted}
                                />
                            </div>

                            <div className='col-md-6'>
                                <SelectInput
                                    isDisabled={!slotSelect}
                                    label={
                                        <FormattedMessage
                                            id='MoveTransaction.form.slot.title'
                                            description='The select slot title on the MoveTransaction form.'
                                            defaultMessage='Slot'
                                        />
                                    }
                                    value={formResult.slot}
                                    onChange={(value: any) => changeFormResult({ ...formResult, slot: value })}
                                    errorMessage={
                                        <FormattedMessage
                                            id='MoveTransaction.form.slot.errorMessage'
                                            description='The select slot errorMessage on the MoveTransaction form.'
                                            defaultMessage='Please select a slot.'
                                        />
                                    }
                                    options={
                                        slotSelect && slotsState.availableSlotsList[parsedFormResult.spot]
                                            ? slotsState.availableSlotsList[parsedFormResult.spot!].map((s) => ({ value: s.id, label: s.slot_nr }))
                                            : []
                                    }
                                    required
                                    isValidCallback={(valid) => {
                                        changeFormValid({ ...formValid, slot: valid });
                                    }}
                                    submitted={submitted}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='modal-footer'>
                    <button
                        disabled={formValid.spot !== true && formValid.slot !== true}
                        className='btn btn-primary'
                        onClick={onsubmit}>
                        submit
                    </button>
                </div>
            </form>

            <ConfirmationModal
                show={submitted}
                severity={ClassType.WARNING}
                message={
                    <FormattedMessage
                        id='MoveTransaction.ConfirmationMessage'
                        description='The message warnging the user before moving the transaction'
                        defaultMessage='Are you sure you want to move this transaction? This action cannot be undone.'
                    />
                }
                onConfirm={onConfirm}
                handleClose={() => changeSubmitted(false)}
            />
        </Modal>
    );
}

interface FormValid {
    spot?: boolean;
    slot?: boolean;
}

interface FormResult {
    spot?: string;
    slot?: string;
}
