import { Logger } from '@frontend/Logger';

import { fetchNotification } from '../../store/notifications/notificationSlice';
import { PubSubEvent } from '../usePubSubEventListener';

export default class PushNotificationsEventHandler {
    static handle = (dispatch: ({ ...args }) => void, event: PubSubEvent): void => {
        if (event.content.new != null) {
            Logger.log(`Push notification id:{${event.content.new.id}} event received.`);
            dispatch(fetchNotification({ id: event.content.new.id }));
        } else if (event.content.old != null) {
            Logger.log(`Push notification id:{${event.content.old.id}} event received.`);
            dispatch(fetchNotification({ id: event.content.old.id }));
        }
    };
}
