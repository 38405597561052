import { ApiQueryParams, ApiViewSet, DetailOptions, PaginationResponse, apiDetail, apiPagination } from '@frontend/api-utils';
import { TransactionContact, TransactionContactGroup, TransactionSlot, TransactionSpot, TransactionsQueryParams } from '@frontend/transaction';

import { TransactionStatusValue } from '../common/TransactionStatus';

export interface BulkTransaction {
    account: number;
    action: BulkTransactionAction;
    callback_url: string;
    close_transaction_at_expected_pickup_time: boolean;
    completed_at: string | null;
    counter: number;
    created_at: string | null;
    customer_ref: string;
    expected_dropoff_time: string | null;
    expected_pickup_time: string | null;
    fast_transition: boolean;
    id: string;
    notification_time_for_scheduled_transactions: string | null;
    notifications_config: unknown;
    quantity: number;
    receiver: TransactionContact | null;
    receiver_group: TransactionContactGroup | null;
    sender: TransactionContact | null;
    sender_group: TransactionContactGroup | null;
    shipping_notes: string | null;
    slot: TransactionSlot | null;
    spot: TransactionSpot | null;
    state: BulkTransactionState;
    status: TransactionStatusValue;
    tt_number: string | null;
    type: string | null;
    url: string;
}

export type BulkTransactionResponse = PaginationResponse<BulkTransaction>;

enum BulkTransactionState {
    INIT = 'init',
    PENDING = 'pending',
    COMPLETED = 'completed',
    ERROR = 'error'
}
enum BulkTransactionAction {
    CREATE = 'create',
    STATUS_UPDATE = 'status_update',
    MOVE = 'slot_spot_change',
    RECEIVER_UPDATE = 'receiver_update'
}

const bulkTransactionViewSet: ApiViewSet = {
    baseName: 'transactions/bulk'
};

export async function fetchBulkTransactionApi(options: DetailOptions): Promise<BulkTransaction> {
    return apiDetail<BulkTransaction>(bulkTransactionViewSet, options);
}

export async function fetchBulkTransactionsApi(queryParams?: ApiQueryParams<TransactionsQueryParams> | null): Promise<BulkTransactionResponse> {
    return apiPagination<BulkTransactionResponse, TransactionsQueryParams>(bulkTransactionViewSet, queryParams);
}
