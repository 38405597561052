import { ApiError } from '@frontend/api-utils';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Id } from 'react-toastify';
import { NotificationCenterItem, useNotificationCenter } from 'react-toastify/addons/use-notification-center';

import { NotificationScaleError, Notifications } from '../../api/notifications/Notifications';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { PubSubTopic } from '../../pub-sub/PubSubSubscription';
import usePubSubSubscription from '../../pub-sub/usePubSubSubscription';
import { deleteAllNotifications, deleteNotification, fetchNotifications } from '../../store/notifications/notificationSlice';

interface ViewProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    notificationsList: NotificationCenterItem[];
    showUnreadOnly: boolean;
    setShowUnreadOnly: (value: boolean) => void;
    unreadCount: number;
    clear: () => void;
    remove: (id: Id | Id[]) => void;
    markAsRead: {
        (id: Id | Id[]): void;
        (id: Id | Id[], read?: boolean | undefined): void;
    };
    markAllAsRead: {
        (): void;
        (read?: boolean | undefined): void;
    };
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
    removeNotification: (id: number) => void;
    totalCount: number;
    showAll: boolean;
    changeShowAll: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    removeAllNotifications: () => void;
}

const useNotification = (): ViewProps => {
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const dispatch = useAppDispatch();
    const notificationState = useAppSelector((state) => state.notification);
    const { notifications, clear, markAllAsRead, markAsRead, remove, unreadCount } = useNotificationCenter();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(false);
    const [notificationsList, changeNotificationsList] = useState<NotificationCenterItem[]>([]);
    const [initState, changeInitState] = useState<boolean>(false);
    const [notificationCounter, changeNotificationCounter] = useState<number>(0);
    const [opened, setOpened] = useState<boolean>(false);
    const [totalCount, changeTotalCount] = useState<number>(0);
    const [showAll, changeShowAll] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);

    usePubSubSubscription({
        name: 'NotificationsList',
        topic: PubSubTopic.PUSH_NOTIFICATIONS,
        enabled: accountId != undefined
    });

    function genNotificationToast(object: Notifications, identifier?: string, hide?: boolean) {
        switch (identifier) {
            case NotificationScaleError.TLP_SCALE_COUNT_MISMATCH:
                ToastUtil.error(
                    ScaleMismatch,
                    `${object.value.spot_name} ${object.value.slot_nr}`,
                    {
                        ...(hide && { style: { display: 'none' } }),
                        data: { include: true, id: object.id, createdAt: new Date(object.created_at).toLocaleString() }
                    },
                    new Date(object.created_at).toLocaleString()
                );
                break;
            case NotificationScaleError.TLP_SCALE_COUNT_MISMATCH_RESOLVED:
                ToastUtil.success(
                    ScaleResolved,
                    `${object.value.spot_name} ${object.value.slot_nr}`,
                    {
                        ...(hide && { style: { display: 'none' } }),
                        data: { include: true, id: object.id, createdAt: new Date(object.created_at).toLocaleString() }
                    },
                    new Date(object.created_at).toLocaleString()
                );
                break;
            default:
                ToastUtil.warning('Unknown');
        }
    }

    function removeNotification(id: number) {
        dispatch(deleteNotification(id));
    }

    const removeAllNotifications = () => {
        dispatch(deleteAllNotifications())
            .then((res) => {
                clear();
                changeNotificationCounter(0);
                const message = res.payload as { message: string };
                ToastUtil.success(message.message);
            })
            .catch((err) => {
                ToastUtil.error((err as ApiError).message);
            })
            .finally(() => changeShowDeleteModal(false));
    };

    useEffect(() => {
        if (notificationState.notificationList === null) {
            dispatch(fetchNotifications({ account: accountId?.toString() }));
        }
    }, []);
    useEffect(() => {
        if (notificationState.notificationList) {
            changeTotalCount(notificationState.notificationList.length);
            if (!initState) {
                const list = [...notificationState.notificationList].sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateA - dateB;
                });
                list.slice(list.length - 10, list.length).map((item: Notifications) => {
                    genNotificationToast(item, item.identifier, true);
                });
                changeInitState(true);
                changeNotificationCounter(notificationState.notificationList.length);
            } else if (initState && notificationCounter < notificationState.notificationList.length) {
                const newNotification = notificationState.notificationList?.findLast((t) => t);
                if (newNotification) {
                    genNotificationToast(newNotification, newNotification?.identifier);
                    changeNotificationCounter(notificationState.notificationList.length);
                }
            }
        }
    }, [notificationState.notificationList]);

    useEffect(() => {
        if (showAll) {
            const list = notificationState.notificationList
                ?.filter((s) => !notifications.map((n) => (n.data as any).id).includes(s.id))
                .sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });
            list?.map((item: Notifications) => {
                genNotificationToast(item, item.identifier, true);
            });
        }
    }, [showAll]);

    useEffect(() => {
        if (showUnreadOnly) {
            changeNotificationsList(notifications.filter((n) => !n.read));
        } else {
            changeNotificationsList(
                notifications
                    .filter((n) => n.data && (n.data as any).include)
                    .sort((a, b) => {
                        const dateA = new Date((a.data as any).createdAt).getTime();
                        const dateB = new Date((b.data as any).createdAt).getTime();
                        return dateB - dateA;
                    })
            );
        }
        if (notifications.length > 10 && !showAll) {
            const list = notifications
                .sort((a, b) => {
                    const dateA = new Date((a.data as any).createdAt).getTime();
                    const dateB = new Date((b.data as any).createdAt).getTime();
                    return dateB - dateA;
                })
                .slice(0, 10);
            changeNotificationsList(list);
        }
    }, [notifications, showUnreadOnly, showAll]);

    return {
        isOpen,
        setIsOpen,
        notificationsList,
        showUnreadOnly,
        setShowUnreadOnly,
        unreadCount,
        clear,
        remove,
        markAsRead,
        markAllAsRead,
        opened,
        setOpened,
        removeNotification,
        totalCount,
        changeShowAll,
        showAll,
        changeShowDeleteModal,
        showDeleteModal,
        removeAllNotifications
    };
};

export default useNotification;

export const ScaleMismatch = (
    <FormattedMessage
        id='notification-button-controller.ScaleMismatch'
        description='Error notification for scale mismatch'
        defaultMessage='Scale mismatch'
    />
);

export const ScaleResolved = (
    <FormattedMessage
        id='notification-button-controller.ScaleResolved'
        description='Notification for scale resolved'
        defaultMessage='Scale mismatch resolved'
    />
);
