import { useState } from 'react';

import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { SlotUtil } from '../../../utils/slots/Slots';
import { SlotDetailModalProps } from './slot-detail-modal.component';

interface ViewProps {
    isAdmin: boolean;
    openSlotLoading: boolean;
    changeStartOpenSlot: React.Dispatch<React.SetStateAction<boolean>>;
    openSlotLogsLoading: boolean;
    requestSlotLogs: () => void;
    startOpenSlot: boolean;
    openSlot: () => void;
}

const useSlotDetailModal = (props: SlotDetailModalProps): ViewProps => {
    const isAdmin = useAuthorization(DefaultRole.ADMIN);
    const [openSlotLoading, changeOpenSlotLoading] = useState<boolean>(false);
    const [openSlotLogsLoading, changeOpenSlotLogsLoading] = useState<boolean>(false);
    const [startOpenSlot, changeStartOpenSlot] = useState<boolean>(false);
    const openSlot = () => {
        changeStartOpenSlot(false);
        changeOpenSlotLoading(true);
        SlotUtil.openSlot(props.slot!.id).finally(() => changeOpenSlotLoading(false));
    };
    const requestSlotLogs = () => {
        changeOpenSlotLogsLoading(true);
        SlotUtil.requestSlotLogs(props.slot!.id).finally(() => changeOpenSlotLogsLoading(false));
    };
    return {
        isAdmin,
        openSlotLoading,
        changeStartOpenSlot,
        openSlotLogsLoading,
        requestSlotLogs,
        startOpenSlot,
        openSlot
    };
};

export default useSlotDetailModal;
