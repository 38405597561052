import { ApiError, ApiQueryParams, ApiViewSet, DetailOptions, apiDetail, apiList, deleteApi, postApi, putApi } from '@frontend/api-utils';

import { WidgetTemplate } from './WidgetTemplates';

export interface Widget {
    id: number;
    url: string;
    name?: string;
    description?: string;
    widget_template_json: WidgetTemplate;
    params: {
        data: { [id: string]: unknown };
    };
    layout: {
        x: number;
        y: number;
    };
}

export enum UserWidgetQueryParams {
    ACCOUNT = 'account'
}

const widgetViewSet: ApiViewSet = {
    baseName: 'widgets'
};

export interface CreateWidgetModel {
    account: number;
    widget_template: number;
    name?: string;
    description?: string;
    params?: {
        data: { [id: string]: unknown };
    };
    layout: {
        x: number;
        y: number;
    };
}

export async function fetchWidgetApi(options: DetailOptions): Promise<Widget> {
    return apiDetail<Widget>(widgetViewSet, options);
}

export async function fetchWidgetsApi(queryParams: ApiQueryParams<UserWidgetQueryParams>): Promise<Widget[]> {
    return apiList<Widget, UserWidgetQueryParams>(widgetViewSet, queryParams);
}

export async function addWidgetApi(userWidget: CreateWidgetModel): Promise<Widget> {
    const response = await postApi(`/${widgetViewSet.baseName}/`, userWidget);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error adding user widget');
        }
        throw new ApiError('Error adding user widget', json);
    }
    return await response.json();
}

export async function updateWidgetApi(params: { id: number; userWidget: CreateWidgetModel }): Promise<Widget> {
    const response = await putApi(`/${widgetViewSet.baseName}/${params.id}/`, params.userWidget);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating user widget');
        }
        throw new ApiError('Error updating user widget', json);
    }
    return await response.json();
}

export async function deleteWidgetApi(id: number): Promise<boolean> {
    const response = await deleteApi(`/${widgetViewSet.baseName}/${id}/`);
    return await response.ok;
}
