import _ from 'lodash';

import DateBadge from '../../../components/badges/DateBadge';

export default function TimeLineItemTextField(props: { title: string; date?: Date }) {
    let title = _.clone(props.title).replaceAll('_', ' ');
    title = title.charAt(0).toUpperCase() + title.slice(1);

    return (
        <div className='timeline-content'>
            <h6 className='text-dark text-sm font-weight-bold mb-0'>{title}</h6>

            {props.date && (
                <>
                    <DateBadge
                        date={props.date}
                        displayTime={true}
                    />
                </>
            )}
        </div>
    );
}
