import { Row } from 'react-table';

interface Props {
    expandEnabled?: boolean;
    selectEnabled?: boolean;
    row: Row<any>;
    onClick?: (obj: any) => void;
    RowElement: (props: RowProps) => JSX.Element;
}

export default function TRow(props: Props) {
    return (
        <props.RowElement
            row={props.row}
            selectEnabled={props.selectEnabled}
            expandEnable={props.expandEnabled}
            onClick={props.onClick}
        />
    );
}

export interface RowProps {
    expandEnable?: boolean;
    selectEnabled?: boolean;
    row: Row<any>;
    onClick?: (element: any) => void;
}
