import { ApiError, ApiQueryParams, ApiViewSet, DetailOptions, apiDetail, apiList, putApi } from '@frontend/api-utils';

import { Locale } from '../common/BackendEnums';
import { SpotTypeName } from '../common/SpotType';

/**
 * Type used to decide what the spotlayout should show.
 */
export interface Spot {
    id: string;
    url: string;
    type: SpotTypeName;
    account: number;
    name: string;
    address_line_1?: string;
    zip_code?: string;
    city?: string;
    delivery_contacts: number | null;
    delivery_contacts_url: string | null;
    delivery_contact_other_url: string | null;
    delivery_contact_other: number | null;
    default_receiver_contact: number | null;
    default_receiver_contact_url: string | null;
    receiver_contact_group: number | null;
    receiver_contact_group_url: string | null;
    slots: string;
    slots_num: number;
    available_slots_num: number;
    actions: SpotActionSettings;
    additional_data: object | null;
    sharing_allowed_for_contact_groups: number[];

    terminals: SpotTerminalsInfo[];

    //Settings
    settings_language: Locale;
    settings_switch_lights_on_at: string;
    settings_allow_scheduling: boolean;
    settings_switch_lights_off_at: string;
    settings_redirect_after_in_sec: number;
    settings_popup_stays_visible_in_sec: number;
    settings_allow_multi_persons_warehouse_access: boolean;
    settings_enable_warning_notifications: boolean;

    settings_dropoff_in_progress_timeout_in_sec: number;
    settings_pickup_in_progress_timeout_in_sec: number;
    settings_remove_parcel_in_progress_timeout_in_sec: number;
}

interface SpotTerminalsInfo {
    id: number;
    name: string;
    delivery_contacts: number;
    delivery_contact_other: any;
    receiver_contact_group: any;
    default_receiver_contact: any;
}

export interface SpotSettingsBody {
    account: number;
    type?: SpotTypeName | null;
    name: string;
    address_line_1?: string;
    zip_code?: string;
    city?: string;
    delivery_contacts?: number | null;
    delivery_contact_other?: number | null;
    default_receiver_contact?: number | null;
    receiver_contact_group?: number | null;
    actions?: object | null;
    additional_data?: object | null;
    sharing_allowed_for_contact_groups?: number[];
    settings_allow_multi_persons_warehouse_access?: boolean;
    settings_enable_warning_notifications?: boolean;
    settings_language?: Locale;
    settings_redirect_after_in_sec?: number;
    settings_popup_stays_visible_in_sec?: number;
    settings_switch_lights_on_at?: string | null;
    settings_switch_lights_off_at?: string | null;
    settings_dropoff_in_progress_timeout_in_sec?: number | null;
    settings_pickup_in_progress_timeout_in_sec?: number | null;
    settings_remove_parcel_in_progress_timeout_in_sec?: number | null;
    settings_allow_scheduling?: boolean;
}

export interface SpotActionSettings {
    lights_on: boolean;
    id?: string;
}

export enum SpotsQueryParams {
    ACCOUNT = 'account'
}

const spotViewSet: ApiViewSet = {
    baseName: 'spots'
};

export function fetchSpotsApi(queryParams?: ApiQueryParams<SpotsQueryParams> | null): Promise<Spot[]> {
    return apiList<Spot, SpotsQueryParams>(spotViewSet, queryParams);
}

export function fetchSpotApi(options: DetailOptions): Promise<Spot> {
    return apiDetail<Spot>(spotViewSet, options);
}

export async function switchSpotLightsApi(options: SpotActionSettings): Promise<any> {
    let spotsUrl = '/spots/';
    if (options && options.id) {
        spotsUrl += options.id + '/switch_lights/';
    }
    const body = { actions_turn_lights_on: options.lights_on };
    const response = await putApi(spotsUrl, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating terminal');
        }
        throw new ApiError('Error updating terminal', json);
    }
    return await response.json();
}

export async function changeSpotSettingsApi(id: number, body: SpotSettingsBody): Promise<Spot> {
    const URL = `/spots/${id}/`;
    const response = await putApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating spot settings');
        }
        throw new ApiError('Error updating spot settings', json);
    }
    return await response.json();
}
