import { SliceStatus, shouldFetch } from '@frontend/common';
import { ContactResponse, fetchContacts } from '@frontend/contact';
import { ContactGroup } from '@frontend/contact-group';
import { Transaction, fetchTransactions } from '@frontend/transaction';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ACTIVE_TRANSACTION_STATUSES } from '../../utils/Transactions';

interface ViewProps {
    contactGroupsContacts: ContactResponse | null;
    contactGroupsChildrenList: ContactGroup[] | null;
    contactGroupsParent: ContactGroup | undefined;
}

const useContactGroupDetail = (props: { contactGroup?: ContactGroup }): ViewProps => {
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const transactionsState = useAppSelector((state) => state.transactions);
    const contacts = useAppSelector((state) => state.contacts);
    const contactGroups = useAppSelector((state) => state.contactGroups.contactGroupsList);
    const contactGroupsChildren = useAppSelector((state) => state.contactGroups.ContactGroupsChildrenList);
    const contactGroupsContacts = useAppSelector((state) => state.contacts.contactsHierachicalList);
    const dispatch = useAppDispatch();
    const [linkedTransactions, changeLinkedTransactions] = useState<Transaction[] | undefined>(undefined);
    const [contactGroupsParent, changeContactGroupsParent] = useState<ContactGroup | undefined>(undefined);
    const [contactGroupsChildrenList, changeContactGroupsChildrenList] = useState<ContactGroup[] | null>([]);
    const location = useLocation();
    useEffect(() => {
        dispatch(fetchContacts({ 'contact-group-hierarchical': props.contactGroup?.id.toString(), page: '1', page_size: '100' }));
        if (shouldFetch(contacts.status, contacts.lastUpdate)) dispatch(fetchContacts({ account: accountId.toString(), page: '1', page_size: '100' }));
    }, [props.contactGroup]);

    useEffect(() => {
        ACTIVE_TRANSACTION_STATUSES.forEach((status) => {
            if (shouldFetch(transactionsState.status, transactionsState.lastUpdate)) {
                dispatch(fetchTransactions({ account: accountId.toString(), status: [status.value] }));
            }
        });
    }, []);
    useEffect(() => {
        if (transactionsState.status === SliceStatus.IDLE && props.contactGroup !== undefined) {
            changeLinkedTransactions(
                transactionsState.all.filter((tr) => {
                    if (tr.receiver_group && +tr.receiver_group.id === props.contactGroup!.id) return true;
                    if (tr.sender_group && +tr.sender_group.id === props.contactGroup!.id) return true;
                    return false;
                })
            );
        }
    }, [transactionsState, props.contactGroup]);

    useEffect(() => {
        if (props.contactGroup?.parent !== null) {
            const parent = contactGroups?.results.find((c) => c.url === props.contactGroup?.parent);
            changeContactGroupsParent(parent);
        } else changeContactGroupsParent(undefined);
        if (props.contactGroup) {
            changeContactGroupsChildrenList(
                contactGroupsChildren[props.contactGroup.id] !== undefined ? contactGroupsChildren[props.contactGroup.id].children : []
            );
        }
    }, [props.contactGroup, location]);

    return {
        contactGroupsContacts,
        contactGroupsChildrenList,
        contactGroupsParent
    };
};

export default useContactGroupDetail;
