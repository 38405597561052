import { HiQuestionMarkCircle } from 'react-icons/hi';
import { IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline, IoIosCloseCircle, IoIosCloseCircleOutline } from 'react-icons/io';

import { SlotSensorLog, SlotSensorState } from '../../api/slots/SlotLogs';
import DateBadge from '../../components/badges/DateBadge';

const SlotSensorLogElement = (props: { slotSensorLog: SlotSensorLog }) => {
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                {props.slotSensorLog.warning === true ? (
                    <>
                        {props.slotSensorLog.state === SlotSensorState.EMPTY ? (
                            <IoIosCloseCircleOutline className='text-warning display-1' />
                        ) : props.slotSensorLog.state === SlotSensorState.NOT_EMPTY ? (
                            <IoIosCloseCircle className='text-warning display-1' />
                        ) : (
                            <HiQuestionMarkCircle className='text-warning display-1' />
                        )}
                    </>
                ) : (
                    <>
                        {props.slotSensorLog.state === SlotSensorState.EMPTY ? (
                            <IoIosCheckmarkCircleOutline className='text-info display-1' />
                        ) : props.slotSensorLog.state === SlotSensorState.NOT_EMPTY ? (
                            <IoIosCheckmarkCircle className='text-info display-1' />
                        ) : (
                            <HiQuestionMarkCircle className='text-warning display-1' />
                        )}
                    </>
                )}
                {}
            </span>
            <div className='timeline-content'>
                <h6 className='text-dark text-sm font-weight-bold mb-0'>{props.slotSensorLog.state}</h6>
                <DateBadge
                    date={props.slotSensorLog.created_at}
                    displayTime={true}
                />
            </div>
        </div>
    );
};

export default SlotSensorLogElement;
