import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import { FaQrcode } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi';

import Tooltip from '../tooltip/Tooltip';

interface QRCodeBadgeProps {
    value?: string;
    displayedValue?: string | JSX.Element;
    data?: string;
    callback?: () => void;
}

export default function QRCodeBadge(props: QRCodeBadgeProps) {
    const downloadUrl = `${props.displayedValue}.png`;
    const [qr, changeQR] = useState<string | undefined>(undefined);
    const hasExpirationTime = props.value && props.value.includes('?expires');
    const timer = useRef<NodeJS.Timeout | null>(null);
    const expirationTime = props.value?.split('?expires:')[1];

    useEffect(() => {
        if (!hasExpirationTime || !expirationTime) return;
        const timeUntilExpiration = parseInt(expirationTime) - Date.now() / 1000;

        if (timeUntilExpiration <= 0) {
            props.callback && props.callback();
        } else {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
                props.callback && props.callback();
            }, timeUntilExpiration * 1000);
        }
    }, []);

    useEffect(() => {
        if (!props.value) return;
        if (props.value && hasExpirationTime && expirationTime) {
            QRCode.toDataURL(props.value.split('?expires:')[0], { width: 500 }).then((url) => {
                changeQR(url);
            });
        } else {
            QRCode.toDataURL(props.value, { width: 500 }).then((url) => {
                changeQR(url);
            });
        }
    }, [props.value]);

    useEffect(() => {
        if (props.data) changeQR(props.data);
    }, [props.data]);
    if (qr === undefined) {
        return (
            <p className='d-flex placeholder-glow w-25'>
                <span className='placeholder col-12'></span>
            </p>
        );
    }

    return (
        <>
            <p>
                <a
                    className='d-flex flex-row justify-content-center align-items-center'
                    href={qr}
                    rel='noreferrer'
                    download={downloadUrl}>
                    <span className='pe-2 pb-1'>
                        <FaQrcode />
                    </span>
                    {props.displayedValue}
                    {expirationTime && (
                        <div>
                            <Tooltip
                                id={props.value}
                                element={
                                    <HiInformationCircle
                                        className='position-absolute start-98 top-12 translate-middle'
                                        data-tooltip-id={props.value}
                                    />
                                }>
                                {expirationTime && (
                                    <span className='text-sm'>{`Expires at: ${new Date(parseFloat(expirationTime) * 1000).toLocaleDateString()} ${new Date(
                                        parseFloat(expirationTime) * 1000
                                    ).toLocaleTimeString()}`}</span>
                                )}
                            </Tooltip>
                        </div>
                    )}
                </a>
            </p>
        </>
    );
}
