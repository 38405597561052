import { Logger } from '@frontend/Logger';
import { SliceStatus } from '@frontend/common';
import { uploadProductCSV, uploadProductImages } from '@frontend/product';
import { ToastUtil } from '@frontend/toast-utils';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { TbUpload } from 'react-icons/tb';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ClassType } from '../../../common/BootstrapValues';
import Import from '../../../components/import/Import';
import Spinner from '../../../components/loading/Spinner';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAccount from '../../../hooks/useAccount';

interface ImportModalProps {
    extension: 'image' | 'csv';
    handleClose: () => void;
    helpText?: React.ReactNode;
}
export default function ImportModal(props: ImportModalProps) {
    const dispatch = useAppDispatch();
    const { shopId } = useParams();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [selectedFiles, setSelectedFiles] = useState<File[] | File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [currentRequest, setCurrentRequest] = useState(0);
    const [totalRequests, setTotalRequests] = useState(0);
    const [requestLength, setRequestLength] = useState(0);
    const uploadState = useAppSelector((state) => state.products);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [fulfilledRequests, setFulfilledRequests] = useState<number[]>([]);
    const [uploadingExtension, setUploadingExtension] = useState<'image' | 'csv'>();
    const { selectedUser } = useAccount();

    const handleUpload = async () => {
        if (!selectedFiles) return;

        //const maxFileSize = 2.5 * 1024 * 1024;  2.5 MB in bytes
        if (props.extension === 'image' && isArray(selectedFiles)) {
            const maxFileSize = 2500000;

            const fileArrays: File[][] = [];
            let currentArray: File[] = [];
            let currentSize = 0;

            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                const fileSize = file.size;

                if (currentSize + fileSize > maxFileSize) {
                    fileArrays.push(currentArray);
                    currentArray = [file];
                    currentSize = fileSize;
                } else {
                    currentArray.push(file);
                    currentSize += fileSize;
                }
            }

            if (currentArray.length > 0) {
                fileArrays.push(currentArray);
            }

            try {
                const requestCount = fileArrays.length;
                setRequestLength(requestCount);
                setTotalRequests(
                    fileArrays.reduce((accumulator, currentArray) => {
                        return accumulator + currentArray.length;
                    }, 0)
                );

                for (let i = 0; i < requestCount; i++) {
                    const array = fileArrays[i];
                    const formData = new FormData();
                    formData.append('account_id', accountId!.toString());

                    for (const file of array) {
                        formData.append('images', file);
                    }

                    setIsUploading(true);
                    setUploadingExtension(props.extension);

                    await new Promise((resolve) => setTimeout(resolve, 2000));

                    dispatch(uploadProductImages(formData)).then((res) => {
                        if (res.meta.requestStatus === 'fulfilled') {
                            setFulfilledRequests((prevFulfilledRequests) => [...prevFulfilledRequests, fileArrays[i].length]);
                        }
                    });
                    setCurrentRequest((prevRequest) => prevRequest + fileArrays[i].length);
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        } else if (props.extension === 'csv' && !isArray(selectedFiles) && selectedFiles) {
            setTotalRequests(1);
            setRequestLength(1);
            const formData = new FormData();
            formData.append('account_id', accountId!.toString());
            formData.append('shop_id', shopId!);
            formData.append('csv_file', selectedFiles);
            setIsUploading(true);
            setUploadingExtension(props.extension);
            dispatch(uploadProductCSV(formData)).then((res) => {
                if (res.meta.requestStatus === 'fulfilled') {
                    setFulfilledRequests([1]);
                } else if (res.meta.requestStatus === 'rejected') {
                    setFulfilledRequests([1]);
                }
            });
        }
    };

    useEffect(() => {
        if (
            isUploading === true &&
            (uploadState.uploadStatus === SliceStatus.IDLE || uploadState.uploadStatus === SliceStatus.ERROR) &&
            fulfilledRequests.length === requestLength
        ) {
            setIsUploading(false);
            setCurrentRequest(0);
            setSelectedFiles(null);
        }
    }, [uploadState.uploadStatus, fulfilledRequests]);

    if ((isUploading || uploadState.uploadStatus === SliceStatus.LOADING) && props.extension === uploadingExtension) {
        return (
            <div className='modal-body'>
                <div className='d-flex flex-row justify-content-center'>
                    <div className='d-flex flex-column'>
                        <Spinner />
                        {props.extension === 'image' ? <p>{`Uploading images: ${currentRequest}/${totalRequests}`}</p> : 'Uploading csv'}
                    </div>
                </div>
            </div>
        );
    }

    if (isUploading === false && uploadState.uploadStatus === SliceStatus.IDLE && submitted && fulfilledRequests.length === requestLength) {
        toast.success(...ToastUtil.generateToastConfig('Upload Success', uploadState.uploadMessage, ClassType.SUCCESS));
        Logger.log(`${selectedUser?.email} imported ${requestLength} ${uploadingExtension}`, { user: selectedUser?.id, shop: shopId });
        setFulfilledRequests([]);
        setUploadingExtension(undefined);
    } else if (isUploading === false && uploadState.uploadStatus === SliceStatus.ERROR && submitted && fulfilledRequests.length === requestLength) {
        toast.error(...ToastUtil.generateToastConfig('Upload Error', uploadState.uploadMessage, ClassType.DANGER));
        Logger.error(`${selectedUser?.email} failed to upload ${uploadingExtension}`, { user: selectedUser?.id, shop: shopId });
        setFulfilledRequests([]);
        setUploadingExtension(undefined);
    }
    return (
        <>
            <div className='modal-body'>
                <Import
                    label={
                        props.extension === 'image' ? (
                            <FormattedMessage
                                id='ProductList.Label.Import.Image'
                                description='Label for import image on ProductList component'
                                defaultMessage='Image'
                            />
                        ) : (
                            <FormattedMessage
                                id='ImportModal.Label.Import.Csv'
                                description='Label for import csv on ImportModal'
                                defaultMessage='Csv'
                            />
                        )
                    }
                    submitted={false}
                    onChange={(value) => setSelectedFiles(value)}
                    extension={props.extension}
                />
                <span className='mt-2'>
                    <small>{props.helpText}</small>
                </span>
            </div>
            <div className='ps-3'>
                <button
                    className='btn bg-gradient-primary'
                    disabled={selectedFiles === null}
                    onClick={() => {
                        handleUpload();
                        changeSubmitted(true);
                    }}>
                    <span className='pe-2'>
                        <TbUpload />
                    </span>
                    Upload
                </button>
            </div>
        </>
    );
}
