import { FormattedMessage } from 'react-intl';

export const PRODUCT = {
    PRODUCT: (
        <FormattedMessage
            id='Objects.Product.singular'
            description='Translation for product.'
            defaultMessage='Product'
        />
    ),
    PRODUCTS: (
        <FormattedMessage
            id='Objects.Product.plural'
            description='Translation for products.'
            defaultMessage='Products'
        />
    )
};
