import React from 'react';

import usePubSubConnection from '../pub-sub/usePubSubConnection';

interface MainContextInterface {
    pubSub: {
        send: (message: string) => boolean;
        isReady: boolean;
        websocket?: WebSocket;
    };
}

export const MainContext = React.createContext<MainContextInterface>({
    pubSub: {
        send: (message: string) => false,
        isReady: false
    }
});

export default function MainContextProvider(props: { children: React.ReactNode }) {
    const pubSub = usePubSubConnection();

    const context: MainContextInterface = {
        pubSub
    };

    return <MainContext.Provider value={context}>{props.children}</MainContext.Provider>;
}
