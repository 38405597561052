import { GrClose } from 'react-icons/gr';

const ModalHeader = (props: { id: string; title: any; handleClose: () => void }) => {
    return (
        <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
            <h3 className='font-weight-bolder text-info text-gradient'>{props.title}</h3>
            <button
                id={props.id}
                onClick={props.handleClose}
                className='btn btn-outline-secondary'>
                <GrClose />
            </button>
        </div>
    );
};

export default ModalHeader;
