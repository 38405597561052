import { IoCheckmarkCircle } from 'react-icons/io5';

import TimeLineItemTextField from '../transaction-detail/transaction-event-timeline/TimeLineItemTextField';

export default function CompletedView() {
    return (
        <div className='timeline-block mb-3'>
            <span className='timeline-step'>
                <IoCheckmarkCircle className='text-success display-1' />
            </span>
            <TimeLineItemTextField title={'Done'} />
        </div>
    );
}
