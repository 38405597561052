import { Category, fetchCategoryById } from '@frontend/category';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { CategoryDetailModalWrapperProps } from './category-detail-modal-wrapper.component';

interface ViewProps {
    category?: Category;
}

const useCategoryDetailModalWrapper = (props: CategoryDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const categories = useAppSelector((state) => state.categories);
    const [category, changeCategory] = useState<Category | undefined>(undefined);

    useEffect(() => {
        const found = categories.categoryList?.find((c) => c.id.toString() == props.id);
        if (found) changeCategory(found);
        else dispatch(fetchCategoryById(parseInt(props.id)));
    }, [props.id, categories]);

    return { category };
};

export default useCategoryDetailModalWrapper;
