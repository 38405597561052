import { useEffect, useState } from 'react';

import { fetchSpots } from '../store/spotSlice';
import { fetchTerminals } from '../store/terminalSlice';
import { useAppDispatch, useAppSelector } from './redux';

const useGetWorkflow = () => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const terminalState = useAppSelector((state) => state.terminals);
    const spotState = useAppSelector((state) => state.spots);
    const [workflow, setWorkflow] = useState<string | undefined | null>(undefined);

    useEffect(() => {
        if (spotState.spotList === null && accountId) {
            dispatch(fetchSpots({ account: accountId?.toString() }));
        }
    }, []);

    useEffect(() => {
        if (terminalState.terminalsList === null) {
            if (spotState.spotList) {
                dispatch(fetchTerminals({ account: accountId?.toString() }));
            }
        } else {
            if (terminalState.terminalsList.length === 0) {
                setWorkflow(null);
            } else {
                const selectedWorkflow = terminalState.terminalsList.map((t) => t.workflow)[0];
                setWorkflow(selectedWorkflow);
            }
        }
    }, [spotState.spotList, terminalState.terminalsList]);

    return { workflow };
};

export default useGetWorkflow;
