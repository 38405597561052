import { FormattedMessage } from 'react-intl';

export const ERROR = {
    SELECT_VALID_OPTION: (
        <FormattedMessage
            id='ERROR.SELECT_VALID_OPTION'
            description='Error message for selecting a valid option'
            defaultMessage='Please select a valid option'
        />
    )
};
