import { ContactGroup } from '@frontend/contact-group';
import { TransactionContactGroup } from '@frontend/transaction';
import { MdGroups } from 'react-icons/md';

import { DetailModalType } from '../../modals/detail-modal/detail-modal-container/detail-modal-container.controller';
import BadgeTemplate from '../badge-template/badge-template.component';
import useContactGroupBadge from './contact-group-badge.controller';

export interface ContactGroupBadgeProps {
    contactGroupId?: string;
    contactGroup?: ContactGroup | TransactionContactGroup;
    name?: string;
}

const ContactGroupBadge = (props: ContactGroupBadgeProps) => {
    const viewProps = useContactGroupBadge(props);
    return (
        <BadgeTemplate
            objectId={props.contactGroupId}
            type={DetailModalType.CONTACT_GROUP}>
            {<MdGroups />}
            {viewProps.name}
        </BadgeTemplate>
    );
};

export default ContactGroupBadge;
