import { Transaction } from '@frontend/transaction';
import { FormattedMessage } from 'react-intl';

import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import QRCodeBadge from '../../components/badges/QRCodeBadge';
import TransactionStatusBadge from '../../components/badges/TransactionStatusBadge';
import TransactionTypeBadge from '../../components/badges/TransactionTypeBadge';
import ContactBadge from '../../components/badges/contact-badge/contact-badge.component';
import ContactGroupBadge from '../../components/badges/contact-group-badge/contact-group-badge.component';
import SlotBadge from '../../components/badges/slot-badge/slot-badge.component';
import SpotBadge from '../../components/badges/spot-badge/spot-badge.component';
import ObjectField from '../../components/modals/ObjectField';
import TransactionEventTimeline from './transaction-event-timeline/TransactionEventTimeline';

interface Props {
    transaction: Transaction;
}

export default function TransactionDetail(props: Props) {
    return (
        <div className='d-flex flex-row w-100 h-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    value={props.transaction.id}
                    fieldName='ID'
                />
                <ObjectField
                    value={<TransactionStatusBadge status={props.transaction.status} />}
                    fieldName='Status'
                />
                <ObjectField
                    value={<TransactionTypeBadge type={props.transaction.type} />}
                    fieldName={
                        <FormattedMessage
                            id='TransactionDetail.Detail.Title.TransactionType'
                            description={'Detail title for transaction type'}
                            defaultMessage='Transaction type'
                        />
                    }
                />
                <ObjectField
                    value={
                        <SpotBadge
                            spot_id={props.transaction.spot.id.toString()}
                            name={props.transaction.spot.name}
                        />
                    }
                    fieldName={CommonMessage.SPOTS.DISTRISPOT}
                />

                {!!props.transaction.slot_id && (
                    <div className='d-flex justify-content-between'>
                        <strong>
                            <FormattedMessage
                                id='TransactionDetail.Detail.Title.Slot'
                                description={'Detail title for slot'}
                                defaultMessage='Slot'
                            />
                        </strong>
                        <SlotBadge
                            slotId={props.transaction.slot_id}
                            name={props.transaction.slot_nr}
                        />
                    </div>
                )}

                {!!props.transaction.sender && (
                    <div className='d-flex justify-content-between'>
                        <strong>
                            <FormattedMessage
                                id='TransactionDetail.Detail.Title.Sender'
                                description={'Detail title for sender'}
                                defaultMessage='Sender'
                            />
                        </strong>
                        <ContactBadge
                            contactId={props.transaction.sender.id}
                            contact={props.transaction.sender}
                        />
                    </div>
                )}
                {!!props.transaction.sender_group && (
                    <div className='d-flex justify-content-between'>
                        <strong>
                            <FormattedMessage
                                id='TransactionDetail.Detail.Title.SenderGroup'
                                description={'Detail title for sender group'}
                                defaultMessage='Sender group'
                            />
                        </strong>
                        <ContactGroupBadge
                            contactGroupId={props.transaction.sender_group.id.toString()}
                            contactGroup={props.transaction.sender_group}
                        />
                    </div>
                )}

                {!!props.transaction.receiver && (
                    <div className='d-flex justify-content-between'>
                        <strong>
                            <FormattedMessage
                                id='TransactionDetail.Detail.Title.Receiver'
                                description={'Detail title for receiver'}
                                defaultMessage='Receiver'
                            />
                        </strong>
                        <ContactBadge
                            contactId={props.transaction.receiver.id}
                            contact={props.transaction.receiver}
                        />
                    </div>
                )}
                {!!props.transaction.receiver_group && (
                    <div className='d-flex justify-content-between'>
                        <strong>
                            <FormattedMessage
                                id='TransactionDetail.Detail.Title.ReceiverGroup'
                                description={'Detail title for receiver group'}
                                defaultMessage='Receiver group'
                            />
                        </strong>
                        <ContactGroupBadge
                            contactGroupId={props.transaction.receiver_group.id.toString()}
                            contactGroup={props.transaction.receiver_group}
                        />
                    </div>
                )}

                <div className='d-flex justify-content-between'>
                    <strong>
                        <FormattedMessage
                            id='TransactionDetail.Detail.Title.Dropoff.Pin'
                            description={'Detail title for dropoff pin'}
                            defaultMessage='Dropoff Pin'
                        />
                    </strong>
                    <QRCodeBadge
                        value={props.transaction.tt_number}
                        displayedValue={props.transaction.dropoff_code}
                    />
                </div>

                <div className='d-flex justify-content-between'>
                    <strong>
                        <FormattedMessage
                            id='TransactionDetail.Detail.Title.Pickup.Pin'
                            description={'Detail title for pickup pin'}
                            defaultMessage='Pickup Pin'
                        />
                    </strong>
                    <QRCodeBadge
                        value={props.transaction.pickup_qr}
                        displayedValue={props.transaction.pickup_code}
                    />
                </div>
                {props.transaction.remove_parcel_code && (
                    <div className='d-flex justify-content-between'>
                        <strong>
                            <FormattedMessage
                                id='TransactionDetail.Detail.Title.RemovalCode'
                                description={'Detail title for removal code'}
                                defaultMessage='Removal code'
                            />
                        </strong>
                        <QRCodeBadge
                            value={props.transaction.remove_parcel_qr}
                            displayedValue={props.transaction.remove_parcel_code}
                        />
                    </div>
                )}

                <div className='d-flex justify-content-between'>
                    <strong>
                        <FormattedMessage
                            id='TransactionDetail.Detail.Title.Tracking.Number'
                            description={'Detail title for tracking number'}
                            defaultMessage='Tracking Number'
                        />
                    </strong>
                    <p>{props.transaction.tt_number}</p>
                </div>

                <hr className='horizontal dark mt-0' />
                <h5 className='d-flex justify-content-center'>
                    <FormattedMessage
                        id='TransactionDetail.Detail.Title.Notes'
                        description={'Detail title for notes'}
                        defaultMessage='Notes'
                    />
                </h5>
                <p>{props.transaction.shipping_notes}</p>
            </div>

            <div className='d-flex w-50 h-100 ms-5'>
                <TransactionEventTimeline transaction={props.transaction} />
            </div>
        </div>
    );
}
