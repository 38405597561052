import { Logger } from '@frontend/Logger';
import { AuthenticationManager } from '@frontend/authentication';
import { merge } from 'lodash';

import { api, buildApiEndpoint } from './api-utils';
import { ApiError, ApiQueryParams, ApiViewSet, DefaultViewSetActions, DetailOptions } from './models';

let lastRequests: { url: string; init?: RequestInit; timestamp: number }[] = [];
const requestTimeout = 2 * 1000;
const maxRequests = 3;

export abstract class APIClient {
    protected static async apiList<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null): Promise<T[]> {
        const baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
        const endpoint = buildApiEndpoint(baseEndpoint, queryParams);
        const response = await this.fetch(endpoint, undefined);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError(`Error fetching list of ${viewSet.baseName}`);
            }
            throw new ApiError(`Error fetching list of ${viewSet.baseName}`, json);
        }
        return await response.json();
    }

    protected static async apiDetail<T>(viewSet: ApiViewSet, options: DetailOptions): Promise<T> {
        let endpointUrl = options.url;
        if (!endpointUrl) {
            if (!options.id) {
                Logger.log('Unexpected empty url and id', {}, viewSet, options);
            }
            endpointUrl = getEndpoint(viewSet, DefaultViewSetActions.DETAIL, options.id);
        }
        const response = await this.fetch(endpointUrl, undefined);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError(`Error fetching detail of ${viewSet.baseName}`);
            }
            throw new ApiError(`Error fetching detail of ${viewSet.baseName}`, json);
        }
        return await response.json();
    }

    protected static async apiPagination<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null, url?: string): Promise<T> {
        let endpoint;
        if (url) {
            endpoint = buildApiEndpoint(url, queryParams);
        } else {
            const baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
            endpoint = buildApiEndpoint(baseEndpoint, queryParams);
        }

        const response = await this.fetch(endpoint, undefined);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError(`Error fetching  ${viewSet.baseName}`);
            }
            throw new ApiError(`Error fetching  ${viewSet.baseName}`, json);
        }
        return await response.json();
    }

    protected static async fetch(endpoint: string, init?: RequestInit, includeAccessToken = true): Promise<Response> {
        if (includeAccessToken) {
            const credentials = await AuthenticationManager.getInstance().getTokenWhenReady();
            init = merge(init, {
                headers: {
                    authorization: 'bearer ' + credentials.accessToken
                }
            });
        }
        if (!endpoint.startsWith('http')) {
            endpoint = api(endpoint);
        }
        lastRequests = lastRequests.filter((request) => Date.now() - request.timestamp < requestTimeout);

        const currentRequest = { url: endpoint, init, timestamp: Date.now() };
        const matchingRequest = lastRequests.find(
            (request) => request.url === currentRequest.url && JSON.stringify(request.init) === JSON.stringify(currentRequest.init)
        );

        if (matchingRequest) {
            return Promise.reject(new Error('Duplicate request'));
        }

        lastRequests.push(currentRequest);
        if (lastRequests.length > maxRequests) {
            lastRequests.splice(0, lastRequests.length - maxRequests);
        }
        let response = await window.fetch(endpoint, init);
        if (response.status === 401) {
            Logger.error('Unauthorized refresching token en refreshing page.');
            AuthenticationManager.getInstance().refreshToken();
            const credentials = await AuthenticationManager.getInstance().getTokenWhenReady();
            init = merge(init, {
                headers: {
                    authorization: 'bearer ' + credentials.accessToken
                }
            });
            response = await window.fetch(endpoint, init);
        }
        return response;
    }

    protected static async post(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
        const isFormData = data instanceof FormData;
        return this.fetch(
            endpoint,
            {
                method: 'POST',
                ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
                body: isFormData ? data : JSON.stringify(data)
            },
            includeAccessToken
        );
    }

    protected static async put(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
        const isFormData = data instanceof FormData;
        return this.fetch(
            endpoint,
            {
                method: 'PUT',
                ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
                body: isFormData ? data : JSON.stringify(data)
            },
            includeAccessToken
        );
    }

    protected static async patch(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
        return this.fetch(
            endpoint,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data ? JSON.stringify(data) : ''
            },
            includeAccessToken
        );
    }

    protected static async delete(endpoint: string, includeAccessToken = true): Promise<Response> {
        return this.fetch(
            endpoint,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            includeAccessToken
        );
    }
}

function getEndpoint(viewSet: ApiViewSet, action: DefaultViewSetActions, id?: string | number | null): string {
    const endpoint = viewSet.endpoints ? viewSet.endpoints[action] : undefined;
    if (endpoint) {
        return endpoint;
    }
    if (action === DefaultViewSetActions.LIST) {
        return `/${viewSet.baseName}/`;
    } else if (action === DefaultViewSetActions.DETAIL) {
        return `/${viewSet.baseName}/${id}/`;
    } else {
        Logger.log('Unexpected viewset action', undefined, action);
        return `/${viewSet.baseName}/`;
    }
}
