import { Spot } from 'apps/platform/src/app/api/Spots';
import { useEffect, useMemo, useState } from 'react';

import { CommonMessage } from '../../../../common/CommonFormattedMessages/CommonMessage';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { fetchSpots } from '../../../../store/spotSlice';
import SelectInput, { SelectInputProps } from '../Select';

interface SpotSelectProps extends Omit<SelectInputProps, 'label'> {
    idAsString?: boolean;
    filter?: number[]; // this filter is just for ids , but could be expanded in the future
}
const SpotSelect = (props: SpotSelectProps) => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const spotState = useAppSelector((state) => state.spots);
    const [filteredSpots, changeFilteredSpots] = useState<Spot[]>();
    useEffect(() => {
        if (spotState.spotList === null) {
            dispatch(fetchSpots({ account: accountId?.toString() }));
        }
    }, []);

    useEffect(() => {
        let spotList: any = [];
        if (!spotState.spotList) spotList = [];
        if (!props.filter && spotState.spotList) {
            spotList = spotState.spotList;
        } else if (props.filter && spotState.spotList) {
            spotList = spotState.spotList.filter((s) => props.filter?.includes(+s.id.replace('SPT', '')));
        }
        changeFilteredSpots(spotList);
    }, [spotState.spotList]);

    const data = useMemo(() => {
        if (filteredSpots) {
            return filteredSpots;
        } else return [];
    }, [filteredSpots]);

    return (
        <SelectInput
            label={CommonMessage.SPOTS.DISTRISPOT}
            submitted={props.submitted}
            required={props.required}
            options={data?.map((s) => ({ value: props.idAsString ? s.id : +s.id.replace('SPT', ''), label: s.name }))}
            onChange={props.onChange}
            value={props.value}
            isClearable={props.isClearable}
            isDisabled={props.isDisabled}
        />
    );
};

export default SpotSelect;
