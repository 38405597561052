import React, { createContext, useState } from 'react';

import { FEATURES } from './features';

export const FeatureFlags = createContext<{ features: any }>({ features: {} });

interface Props {
    children?: React.ReactNode;
}

/**
 * @todo once backend provides features fetch them and use the changeFeatures to set them
 * Also make sure there is a loading fallback in this case
 *
 * This contect will also not be needed for now but can best already be used since it will have to be used once we use the backend
 * @param props
 * @returns
 */
export const FeatureFlagsProvider = (props: Props) => {
    const [features] = useState(FEATURES);

    return <FeatureFlags.Provider value={{ features }}>{props.children}</FeatureFlags.Provider>;
};
