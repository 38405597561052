import { ApiError, ApiQueryParams } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ShopAPIClient } from './api/client';
import { ShopsQueryParams } from './api/models';
import { Shop } from './shop';



interface ShopsState {
    shopList: Shop[] | null;
    status: SliceStatus;
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: ShopsState = {
    shopList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    error: null
};

export const shopSlice = createSlice({
    name: 'shops',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShops.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchShops.fulfilled, (state, action) => {
                state.shopList = action.payload;
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchShops.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchShopById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchShopById.fulfilled, (state, action) => {
                const shop = action.payload;
                if (state.shopList !== null) {
                    state.shopList = state.shopList.filter((s) => shop.id !== s.id);
                    state.shopList.push(shop);
                } else {
                    state.shopList = [shop];
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchShopById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchShopByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchShopByUrl.fulfilled, (state, action) => {
                const shop = action.payload;
                if (state.shopList !== null) {
                    state.shopList = state.shopList.filter((s) => shop.id == s.id);
                    state.shopList.push(shop);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchShopByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchShops = createAsyncThunk<Shop[], ApiQueryParams<ShopsQueryParams>>(
    'fetchShops',
    async (queryParams: ApiQueryParams<ShopsQueryParams>, { rejectWithValue }) => {
        try {
            return await ShopAPIClient.fetchShopsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchShopById = createAsyncThunk<Shop, string>('fetchShopById', async (shopId, { rejectWithValue }) => {
    try {
        return await ShopAPIClient.fetchShopApi({ id: shopId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchShopByUrl = createAsyncThunk<Shop, string>('fetchShopByUrl', async (shopUrl, { rejectWithValue }) => {
    try {
        return await ShopAPIClient.fetchShopApi({ url: shopUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const shopReducer =  shopSlice.reducer;
