import { ApiError, DetailOptions } from '@frontend/api-utils';
import { ReduxError, SliceStatus } from '@frontend/common';
import { LocaleValue } from '@frontend/language';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Report, fetchReportApi } from '../api/reports/Reports';

interface ReportState {
    reportList: { [reportId: string]: Report } | null;
    status: SliceStatus;
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: ReportState = {
    reportList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    error: null
};

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReport.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchReport.fulfilled, (state, action) => {
                const reportId = action.meta.arg.options.url ? action.meta.arg.options.url.split('/')[2] : '';
                if (state.reportList === null) {
                    state.reportList = { [reportId]: action.payload };
                }
                state.reportList[reportId] = action.payload;
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchReport.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchReport = createAsyncThunk<Report, { options: DetailOptions; language: LocaleValue }>(
    'fetchReport',
    async (args: { options: DetailOptions; language: LocaleValue }, { rejectWithValue }) => {
        try {
            return await fetchReportApi(args.options, args.language);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default reportSlice.reducer;
