import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../common/BootstrapValues';
import Modal, { ModalProps } from './Modal';

interface Props extends ModalProps {
    severity?: ClassType;
    title?: React.ReactNode;
    message: React.ReactNode;
    onConfirm: () => void;
}

export default function ConfirmationModal(props: Props) {
    return (
        <Modal
            id='ConfirmationModal'
            customWidth={40}
            {...props}>
            <div className='modal-body'>
                <h3 className={`text-${props.severity ? props.severity : 'info'} text-gradient`}>
                    {props.title ? (
                        props.title
                    ) : (
                        <FormattedMessage
                            id='ConfirmationModal.title'
                            description='The title of the modal asking for confirmation'
                            defaultMessage='Are you sure?'
                        />
                    )}
                </h3>
                <div className='mb-3'>{props.message}</div>
                <div className='d-flex flex-row justify-content-between align-items-center mb-0 pb-0'>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            props.onConfirm();
                        }}
                        id='confirmation-confirm-button'
                        className={`btn btn-${props.severity ? props.severity : 'primary'} m-0`}>
                        <FormattedMessage
                            id='ConfirmationModal.answer.yes'
                            description='The no answer yes the confirmation'
                            defaultMessage='Yes'
                        />
                    </button>
                    <button
                        id='confirmation-cancel-button'
                        onClick={props.handleClose}
                        className='btn btn-outline-secondary m-0'>
                        <FormattedMessage
                            id='ConfirmationModal.answer.no'
                            description='The no answer on the confirmation'
                            defaultMessage='No'
                        />
                    </button>
                </div>
            </div>
        </Modal>
    );
}
