import { ApiError, ApiQueryParams, DetailOptions } from '@frontend/api-utils';
import { ReduxError, SliceStatus, removeListElement } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import NotificationAPIClient, { NotificationQueryParams, Notifications } from '../../api/notifications/Notifications';

interface NotificationState {
    notificationList: Notifications[] | null;
    lastUpdate: number;
    status: SliceStatus;
    error: ReduxError | null;
}

const initialState: NotificationState = {
    notificationList: null,
    lastUpdate: Date.now(),
    status: SliceStatus.INIT,
    error: null
};

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.notificationList = action.payload;
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchNotification.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchNotification.fulfilled, (state, action) => {
                if (state.notificationList !== null) {
                    state.notificationList.push(action.payload);
                } else {
                    state.notificationList = [action.payload];
                }

                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteNotification.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                if (state.notificationList) removeListElement<Notifications>(action.meta.arg, state.notificationList);
            })
            .addCase(deleteAllNotifications.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteAllNotifications.fulfilled, (state) => {
                if (state.notificationList) state.notificationList = [];
            });
    }
});

export const fetchNotifications = createAsyncThunk<Notifications[], ApiQueryParams<NotificationQueryParams>>(
    'fetchNotifications',
    async (queryParams, { rejectWithValue }) => {
        try {
            return await NotificationAPIClient.fetchNotificationsApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchNotification = createAsyncThunk<Notifications, DetailOptions>('fetchNotification', async (options: DetailOptions, { rejectWithValue }) => {
    try {
        return await NotificationAPIClient.fetchNotificationApi(options);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteNotification = createAsyncThunk<boolean, number>('deleteNotification', async (id: number, { rejectWithValue }) => {
    try {
        const res = await NotificationAPIClient.deleteNotification(id);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete notification with id: ${id}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteAllNotifications = createAsyncThunk<string>('deleteAllNotifications', async (_, { rejectWithValue }) => {
    try {
        const res = await NotificationAPIClient.deleteAllNotifications();
        return res;
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export default notificationSlice.reducer;
