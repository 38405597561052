import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import { isWeekend } from '../../../utils/Dates';

interface DateInputProps {
    id?: string;
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: Date | undefined) => void;
    onFocusChange?: () => void;
    value?: Date;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    helpText?: React.ReactNode;
    submitted: boolean;

    disallowWeekends?: boolean;
    addedValidCheck?: (date: Date | undefined) => boolean;
    minDate?: Date;
    maxDate?: Date;
}

export default function DateInput(props: DateInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check && props.addedValidCheck) check = props.addedValidCheck(props.value);
        if (check === true && props.required === true && props.value === undefined) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        if (check && props.disallowWeekends === true && props.value) check = !isWeekend(props.value);
        if (props.minDate && props.value && props.minDate.getTime() >= props.value.getTime()) check = false;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <input
                    className='form-control'
                    id={props.id ? props.id : props.label?.toString()}
                    value={props.value ? moment(props.value).format('YYYY-MM-DD') : ''}
                    type='date'
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        if (event.target.value.length === 0) {
                            props.onChange && props.onChange(undefined);
                        } else {
                            if (props.onChange) props.onChange(new Date(event.target.value));
                        }
                        isValid();
                    }}
                    min={props.minDate ? moment(props.minDate).format('YYYY-MM-DD') : undefined}
                    max={props.maxDate ? moment(props.maxDate).format('YYYY-MM-DD') : undefined}
                />
                {valid === false && touched === true && (
                    <>
                        {props.disallowWeekends === true && props.value && isWeekend(props.value) ? (
                            <span className='badge bg-gradient-danger mt-2'>
                                <small>{CommonMessage.FORM.ERROR.WEEKENDS_NOT_ALLOWED}</small>
                            </span>
                        ) : (
                            <span className='badge bg-gradient-danger mt-2'>
                                <small>
                                    {props.errorMessage ? (
                                        props.errorMessage
                                    ) : (
                                        <FormattedMessage
                                            id='DateTimeInput.DefaultErrorMessage'
                                            description='The default error message on the DatePicker.'
                                            defaultMessage='Please enter a valid date.'
                                        />
                                    )}
                                </small>
                            </span>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
