import { SpotBadgeProps } from './spot-badge.component';

interface ViewProps {
    name: string;
}

const useSpotBadge = (props: SpotBadgeProps): ViewProps => {
    const name = props.spot ? props.spot.name : props.name ? props.name : 'Unknown';

    return {
        name
    };
};

export default useSpotBadge;
