import { Attribute, deleteAttribute } from '@frontend/attribute';
import { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../common/BootstrapValues';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CreateModal from '../../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import { useAppDispatch } from '../../../hooks/redux';
import UpdateAttribute from '../forms/UpdateAttribute';
import AttributeDetail from './AttributeDetail';

interface Props extends ModalProps {
    attribute: Attribute;
}

export default function AttributeDetailModal(props: Props) {
    const dispatch = useAppDispatch();
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [attribute, changeAttribute] = useState<Attribute | undefined>();

    useEffect(() => {
        if (props.attribute) changeAttribute(props.attribute);
    }, [props.attribute]);

    const onDeleteAttribute = () => {
        dispatch(deleteAttribute(props.attribute.id));
        changeShowDeleteModal(false);
        props.handleClose();
    };
    return (
        <Modal
            type={ModalType.PANE}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                {attribute ? (
                    <h3 className='font-weight-bolder text-info text-gradient'>{attribute?.name}</h3>
                ) : (
                    <h3 className='placeholder-glow'>
                        <span className='palceholder col-6'></span>
                    </h3>
                )}
                <button
                    id='AttributeDetailModal.closeButton'
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>
            <div className='modal-body'>
                <AttributeDetail attribute={attribute} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content between align-items center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            text: (
                                <FormattedMessage
                                    id='AttributeDetailModal.Button.Update'
                                    description='Button for updating attribute'
                                    defaultMessage='Update'
                                />
                            ),
                            hide: false,
                            onClick: () => changeShowUpdateModal(true),
                            type: ClassType.WARNING,
                            icon: FiEdit2,
                            id: 'AttributeDetailModal.UpdateButton'
                        },
                        {
                            text: (
                                <FormattedMessage
                                    id='AttributeDetailModal.Button.Delete'
                                    description='Button for deleting attribute'
                                    defaultMessage='Delete'
                                />
                            ),
                            id: 'AttributeDetailModal.DeleteButton',
                            hide: false,
                            onClick: () => changeShowDeleteModal(true),
                            type: ClassType.DANGER,
                            icon: IoMdTrash
                        }
                    ]}
                    direction='left'
                />
            </div>
            {showUpdateModal && (
                <CreateModal
                    header='Update Attribute'
                    show={showUpdateModal}
                    handleClose={() => changeShowUpdateModal(false)}>
                    <UpdateAttribute
                        handleClose={() => changeShowUpdateModal(false)}
                        attribute={props.attribute}
                    />
                </CreateModal>
            )}
            {showDeleteModal && (
                <ConfirmationModal
                    show={showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => changeShowDeleteModal(false)}
                    onConfirm={onDeleteAttribute}
                    message={
                        <FormattedMessage
                            id='deleteAttribute.ConfirmMessage'
                            description='The confirmation message checking if the user wants to delete this attribute'
                            defaultMessage='Are you sure you want to delete this attribute?'
                        />
                    }
                />
            )}
        </Modal>
    );
}
