import { BiCurrentLocation } from 'react-icons/bi';
import { IoIosAddCircle } from 'react-icons/io';
import { IoArrowUpCircle } from 'react-icons/io5';
import { MdCancel } from 'react-icons/md';

import { TransactionStatus } from '../../common/TransactionStatus';
import TimeLineItemTextField from '../transaction-detail/transaction-event-timeline/TimeLineItemTextField';
import CompletedView from './CompletedView';
import RemoveParcelWaitingView from './RemoveParcelWaitingView';
import { ElementProps } from './TransactionStatusUpdate';

export default function ReadyForPickupView(props: ElementProps) {
    return (
        <>
            <div className='timeline-block mb-3'>
                <span className='timeline-step'>
                    {props.isCurrent === false ? (
                        <IoArrowUpCircle className='text-light display-1' />
                    ) : (
                        <BiCurrentLocation className='text-success display-1' />
                    )}
                </span>
                <TimeLineItemTextField title='Pickup waiting' />
            </div>
            <div className='timeline-block mb-3'>
                <span className='timeline-step'>
                    <IoIosAddCircle className='text-light display-1' />
                </span>
                <div className='timeline-content'>
                    <button
                        id='ReadyForPickup-ChangeStatusButton'
                        className='btn btn-primary me-2'
                        onClick={() => props.onChangeStatusCallback(TransactionStatus.DONE)}>
                        pickup done
                    </button>
                    {props.isCurrent !== false && (
                        <button
                            id='ReadyForPickup-cancelTransactionButton'
                            className='btn btn-secondary'
                            onClick={() => props.onChangeStatusCallback(TransactionStatus.CANCELLED)}>
                            cancelled
                        </button>
                    )}
                </div>
            </div>

            {props.currentlySelectedStatus === TransactionStatus.DONE && (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoArrowUpCircle className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField title={'Pickup done'} />
                </div>
            )}
            {props.currentlySelectedStatus === TransactionStatus.CANCELLED && (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdCancel className='text-warning display-1' />
                    </span>
                    <TimeLineItemTextField title={'Cancelled'} />
                </div>
            )}

            {props.currentlySelectedStatus === TransactionStatus.DONE && <CompletedView />}
            {props.currentlySelectedStatus === TransactionStatus.CANCELLED && (
                <RemoveParcelWaitingView
                    isCurrent={false}
                    onChangeStatusCallback={props.onChangeStatusCallback}
                />
            )}
        </>
    );
}
