import { ContactGroup, fetchContactGroup } from '@frontend/contact-group';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ContactGroupDetailModalWrapperProps } from './contact-group-detail-modal-wrapper.component';

interface ViewProps {
    contactGroup?: ContactGroup;
}

const useContactGroupDetailModalWrapper = (props: ContactGroupDetailModalWrapperProps): ViewProps => {
    const dispatch = useAppDispatch();
    const contactGroups = useAppSelector((state) => state.contactGroups.contactGroupsList);
    const [contactGroup, changeContactGroup] = useState<ContactGroup | undefined>(undefined);

    useEffect(() => {
        const found = contactGroups?.results.find((cg) => cg.id.toString() == props.id);
        if (found) changeContactGroup(found);
        else dispatch(fetchContactGroup({ id: props.id }));
    }, [props.id, contactGroups]);

    return {
        contactGroup
    };
};

export default useContactGroupDetailModalWrapper;
