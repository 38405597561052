import { BiCurrentLocation } from 'react-icons/bi';
import { IoIosAddCircle } from 'react-icons/io';
import { IoArrowDownCircle } from 'react-icons/io5';
import { MdCancel } from 'react-icons/md';

import { TransactionStatus } from '../../common/TransactionStatus';
import TimeLineItemTextField from '../transaction-detail/transaction-event-timeline/TimeLineItemTextField';
import CompletedView from './CompletedView';
import ReadyForPickupView from './ReadyForPickupView';
import { ElementProps } from './TransactionStatusUpdate';

export default function ReadyForDropoffView(props: ElementProps) {
    return (
        <>
            <div className='timeline-block mb-3'>
                <span className='timeline-step'>
                    <BiCurrentLocation className='text-success display-1' />
                </span>
                <TimeLineItemTextField title='Dropoff waiting' />
            </div>
            <div className='timeline-block mb-3'>
                <span className='timeline-step'>
                    <IoIosAddCircle className='text-light display-1' />
                </span>
                <div className='timeline-content'>
                    <button
                        id='ReadyForDropoff-ChangeStatusButton'
                        className='btn btn-primary me-2'
                        onClick={() => props.onChangeStatusCallback(TransactionStatus.DROPOFF_DONE)}>
                        dropoff done
                    </button>
                    <button
                        id='ReadyForDropoff-cancelTransactionButton'
                        className='btn btn-secondary'
                        onClick={() => props.onChangeStatusCallback(TransactionStatus.CANCELLED)}>
                        cancelled
                    </button>
                </div>
            </div>

            {props.currentlySelectedStatus !== undefined && props.currentlySelectedStatus !== TransactionStatus.CANCELLED && (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <IoArrowDownCircle className='text-primary display-1' />
                    </span>
                    <TimeLineItemTextField title={'Dropoff done'} />
                </div>
            )}
            {props.currentlySelectedStatus === TransactionStatus.CANCELLED && (
                <div className='timeline-block mb-3'>
                    <span className='timeline-step'>
                        <MdCancel className='text-warning display-1' />
                    </span>
                    <TimeLineItemTextField title={'Cancelled'} />
                </div>
            )}

            {props.currentlySelectedStatus !== undefined && props.currentlySelectedStatus !== TransactionStatus.CANCELLED && (
                <ReadyForPickupView
                    isCurrent={false}
                    onChangeStatusCallback={props.onChangeStatusCallback}
                    currentlySelectedStatus={props.currentlySelectedStatus}
                />
            )}
            {props.currentlySelectedStatus === TransactionStatus.CANCELLED && <CompletedView />}
        </>
    );
}
