import { FormattedMessage } from 'react-intl';

import { Locale } from '../common/BackendEnums';

const convertLanguage = (lang: Locale | '') => {
    if (lang === '') {
        return '';
    }
    if (lang === 'nl_BE') {
        return Dutch;
    } else if (lang === 'en_US') {
        return English;
    } else if (lang === 'fr_BE') {
        return French;
    }
};

export default convertLanguage;

const Dutch = (
    <FormattedMessage
        id='LanguageConverter.Dutch'
        description={'Converts nl_BE to Dutch in its corresponding language'}
        defaultMessage='Dutch'
    />
);

const English = (
    <FormattedMessage
        id='LanguageConverter.English'
        description={'Converts en_GB to English in its corresponding language'}
        defaultMessage='English'
    />
);

const French = (
    <FormattedMessage
        id='LanguageConverter.French'
        description={'Converts fr_BE to French in its corresponding language'}
        defaultMessage='French'
    />
);
