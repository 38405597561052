import SelectInput, { SelectInputProps } from '../Select';
import useUserSelect from './user-select.controller';

const UserSelect = (props: SelectInputProps) => {
    const viewProps = useUserSelect(props);

    return (
        <SelectInput
            submitted={props.submitted}
            label={props.label || 'User'}
            id={props.id + '-userSelect'}
            onChange={props.onChange}
            value={props.value}
            options={viewProps.options}
            isClearable={props.isClearable}
            isLoading={viewProps.isLoading}
        />
    );
};

export default UserSelect;
