import { APIClient, ApiError, ApiQueryParams, ApiViewSet, DetailOptions } from '@frontend/api-utils';

import { UserInfo } from '../user';
import { RegisterUserModel, UpdateUserModel, UserData, UserQueryParams, UsersOptions } from './models';

const USER_INFO_ENDPOINT = '/me/';
const USER_ENDPOINT = '/users/';

const userViewSet: ApiViewSet = {
    baseName: 'users'
};

export class UserApiClient extends APIClient {
    public static async fetchUserInfoApi(): Promise<UserInfo> {
        return (await this.fetch(USER_INFO_ENDPOINT, undefined)).json();
    }

    public static async fetchUsersApi(queryParams: ApiQueryParams<UserQueryParams> | null): Promise<UserData> {
        return await this.apiPagination<UserData, UserQueryParams>(userViewSet, queryParams);
    }

    public static async fetchUserApi(options: DetailOptions): Promise<UserInfo> {
        return await this.apiDetail<UserInfo>(userViewSet, options);
    }

    public static async addUserApi(user: UpdateUserModel): Promise<UserInfo> {
        const response = await this.post(USER_ENDPOINT, user);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating user');
            }
            throw new ApiError('Error creating user', json);
        }
        return await response.json();
    }

    public static async updateUserApi(options: UsersOptions): Promise<UserInfo> {
        const url = `${USER_ENDPOINT}${options.id}/`;
        const body = options.user;
        const response = await this.put(url, body);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating user');
            }
            throw new ApiError('Error updating user', json);
        }
        return response.json();
    }

    public static async deleteUserApi(userId: number): Promise<boolean> {
        const url = `${USER_ENDPOINT}${userId}/`;
        const response = await this.delete(url);
        return await response.ok;
    }

    public static async registerUser(body: RegisterUserModel, inviteId: string) {
        let url = `${process.env['NX_API_URL']}/rest-auth/invite-registration/${inviteId}/`;
        url = url.replace('/api', '');

        const response = await this.post(url, body, false);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating user');
            }
            throw new ApiError('Error creating user', json);
        }
        return await response.json();
    }

    public static async searchUsersApi(queryParams?: ApiQueryParams<UserQueryParams>): Promise<UserData> {
        return await this.apiPagination<UserData, UserQueryParams>(userViewSet, queryParams);
    }
}
