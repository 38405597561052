export interface PaginationResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export class ApiError extends Error {
    json?: unknown;

    constructor(message?: string, json?: string) {
        super(message);

        Object.setPrototypeOf(this, ApiError.prototype);
        this.json = json;
    }
}

export interface ApiNonFieldErrors {
    non_field_errors: string[];
}

export enum DefaultViewSetActions {
    LIST = 'list',
    DETAIL = 'detail'
}

export interface ApiViewSet {
    baseName: string;
    endpoints?: {
        [key in DefaultViewSetActions]?: string | null;
    };
}

export type ApiQueryParams<T extends string | number> = { [key in T]?: string | string[] | undefined | null };

export type DetailOptions =
    | {
          id: string | number;
          url?: never;
      }
    | {
          id?: never;
          url: string;
      };
