import { deleteCategory } from '@frontend/category';
import { useState } from 'react';

import { useAppDispatch } from '../../../../hooks/redux';
import { CategoryDetailModalprops } from './category-detail-modal.component';

interface ViewProps {
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    showUpdateModal: boolean;
    showDeleteModal: boolean;
    onDeleteCategory: () => void;
}

const useCategoryDetailModal = (props: CategoryDetailModalprops): ViewProps => {
    const dispatch = useAppDispatch();
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);

    const onDeleteCategory = () => {
        dispatch(deleteCategory(props.category.id));
        changeShowDeleteModal(false);
        props.handleClose();
    };
    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        onDeleteCategory,
        showDeleteModal,
        showUpdateModal
    };
};

export default useCategoryDetailModal;
