import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../common/BootstrapValues';
import SelectInput from '../../../components/forms/select/Select';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import { useAppSelector } from '../../../hooks/redux';
import { AccountMember } from '../create-user/create-user.controller';

interface RoleSelectModalProps {
    accounts: AccountMember[];
    onChange: (value: AccountMember[]) => void;
    handleClose: () => void;
}

const RoleSelectModal = (props: RoleSelectModalProps) => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [accountArray, changeAccountArray] = useState<AccountMember[]>(props.accounts);
    const accountState = useAppSelector((state) => state.user.user?.account_members);
    const user = useAppSelector((state) => state.user.user);

    const changeRoles = () => {
        props.onChange && props.onChange(accountArray);
    };

    function getRoles(accountId: number) {
        const userRole = user!.account_members?.find((ac) => ac.account.id === accountId)?.role;
        return userRole ? rolesByUserRole[userRole] : [];
    }
    const modalClass = props.accounts.length === 1 ? 'modal-body overflow-unset' : 'modal-body';
    return (
        <>
            <div className={modalClass}>
                {props.accounts && (
                    <>
                        <div className='row'>
                            {props.accounts.map((a, index) => (
                                <>
                                    <div className='col-4'>
                                        <p className='pt-4'>{accountState?.find((ac) => ac.account.id === a.account)?.account.name}</p>
                                    </div>
                                    <div className='col-8'>
                                        <SelectInput
                                            key={a.account}
                                            value={accountArray[index].role}
                                            options={getRoles(a.account)}
                                            label={
                                                <FormattedMessage
                                                    id='CreateUserForm.Label.Role'
                                                    description='The label for role select'
                                                    defaultMessage='Role'
                                                />
                                            }
                                            onChange={(value: any) => {
                                                changeAccountArray((prevAccountArray) => {
                                                    const updatedArray = [...prevAccountArray];
                                                    updatedArray[index] = { ...updatedArray[index], role: value.value };
                                                    return updatedArray;
                                                });
                                            }}
                                            submitted={submitted}
                                        />
                                    </div>
                                </>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <div className='modal-footer'>
                <HorizontalButtonGroup
                    direction='left'
                    buttons={[
                        {
                            text: (
                                <FormattedMessage
                                    id='RoleSelectModal.Button.Apply'
                                    description='Button for applying roles on the RoleSelectModal component'
                                    defaultMessage='Apply'
                                />
                            ),
                            hide: false,
                            type: ClassType.PRIMARY,
                            onClick: () => {
                                changeRoles();
                                props.handleClose && props.handleClose();
                            }
                        }
                    ]}
                />
            </div>
        </>
    );
};

export default RoleSelectModal;

export const roles = [
    { value: 'member', label: 'Member' },
    { value: 'admin', label: 'Admin' },
    { value: 'super_admin', label: 'Super Admin' }
];

export const rolesByUserRole: Record<string, Array<{ value: string; label: string }>> = {
    super_admin: roles,
    admin: roles.filter((role) => role.value !== 'super_admin'),
    member: []
};
