import Spinner from '../../../components/loading/Spinner';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import BulkTransactionDetailModal from '../bulk-transaction-detail-modal/bulk-transaction-detail-modal.component';
import useBulkTransactionDetailModalWrapper from './bulk-transaction-detail-modal-wrapper.controller';

export const bulkTransactionDetailModalWrapperID = 'bulk-transaction-detail-modal-wrapper';

export interface BulkTransactionDetailModalWrapperProps extends ModalProps {
    id: string;
}

const BulkTransactionDetailModalWrapper = (props: BulkTransactionDetailModalWrapperProps): JSX.Element => {
    const viewProps = useBulkTransactionDetailModalWrapper(props);
    if (viewProps.transactions === undefined) {
        return (
            <Modal
                {...props}
                customWidth={60}
                type={ModalType.PANE}>
                <div className='d-flex align-items-center justify-content-center h-75'>
                    <Spinner />
                </div>
            </Modal>
        );
    }
    return (
        <BulkTransactionDetailModal
            bulkTransaction={viewProps.bulkTransaction!}
            transactions={viewProps.transactions}
            {...props}
            customWidth={60}
        />
    );
};

export default BulkTransactionDetailModalWrapper;
