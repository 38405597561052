import { SliceStatus } from '@frontend/common';
import { Transaction } from '@frontend/transaction';
import { useEffect, useState } from 'react';

import { BulkTransaction } from '../../api/BulkTransactions';
import { TransactionType } from '../../common/BackendEnums';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import TransactionStatusBadge from '../../components/badges/TransactionStatusBadge';
import TransactionTypeBadge from '../../components/badges/TransactionTypeBadge';
import ContactBadge from '../../components/badges/contact-badge/contact-badge.component';
import ContactGroupBadge from '../../components/badges/contact-group-badge/contact-group-badge.component';
import ProductBadge from '../../components/badges/product-badge/product-badge.component';
import SlotBadge from '../../components/badges/slot-badge/slot-badge.component';
import SpotBadge from '../../components/badges/spot-badge/spot-badge.component';
import TransactionBadge from '../../components/badges/transaction-badge/transaction-badge.component';
import Spinner from '../../components/loading/Spinner';
import ModalSectionTitle from '../../components/modals/ModalSectionTitle';
import ObjectField from '../../components/modals/ObjectField';
import { useAppSelector } from '../../hooks/redux';

interface BulkTransactionDetailProps {
    bulkTransaction: BulkTransaction;
    transactions?: Transaction[];
}

const BulkTransactionDetail = (props: BulkTransactionDetailProps) => {
    const transactionState = useAppSelector((state) => state.transactions);
    const TrxLoading = transactionState.status === SliceStatus.LOADING;
    const [transactionList, setTransactionList] = useState<any[]>([]);

    useEffect(() => {
        if (props.transactions && props.transactions.length > 0) {
            const mappedTrx = props.transactions.map((t) => ({ product: t.product_instances, transaction: t.id }));
            setTransactionList(mappedTrx);
        }
    }, [props.transactions]);

    return (
        <>
            <div className='d-flex flex-row w-100 h-100'>
                <div className='d-flex flex-column w-100 h-100'>
                    <ObjectField
                        value={props.bulkTransaction.id}
                        fieldName='ID'
                    />
                    <ObjectField
                        value={<TransactionStatusBadge status={props.bulkTransaction.status} />}
                        fieldName='Status'
                    />
                    <ObjectField
                        value={<TransactionTypeBadge type={props.bulkTransaction.type as TransactionType} />}
                        fieldName={CommonMessage.TRANSACTIONS.COLUMNS.TYPE}
                    />
                    {!!props.bulkTransaction.sender && (
                        <ObjectField
                            value={
                                <ContactBadge
                                    contactId={props.bulkTransaction.sender.id}
                                    contact={props.bulkTransaction.sender}
                                />
                            }
                            fieldName={CommonMessage.TRANSACTIONS.COLUMNS.SENDER}
                        />
                    )}
                    {!!props.bulkTransaction.sender_group && (
                        <ObjectField
                            value={
                                <ContactGroupBadge
                                    contactGroupId={props.bulkTransaction.sender_group.id.toString()}
                                    name={props.bulkTransaction.sender_group.name}
                                />
                            }
                            fieldName={CommonMessage.TRANSACTIONS.COLUMNS.SENDER_GROUP}
                        />
                    )}
                    {!!props.bulkTransaction.receiver && (
                        <ObjectField
                            value={
                                <ContactBadge
                                    contactId={props.bulkTransaction.receiver.id}
                                    contact={props.bulkTransaction.receiver}
                                />
                            }
                            fieldName={CommonMessage.TRANSACTIONS.COLUMNS.RECEIVER}
                        />
                    )}
                    {!!props.bulkTransaction.receiver_group && (
                        <ObjectField
                            value={
                                <ContactGroupBadge
                                    contactGroupId={props.bulkTransaction.receiver_group.id.toString()}
                                    name={props.bulkTransaction.receiver_group.name}
                                />
                            }
                            fieldName={CommonMessage.TRANSACTIONS.COLUMNS.RECEIVER_GROUP}
                        />
                    )}

                    <ObjectField
                        value={
                            <SpotBadge
                                spot_id={props.bulkTransaction.spot?.id.toString()}
                                name={props.bulkTransaction.spot?.name}
                            />
                        }
                        fieldName={CommonMessage.SPOTS.SPOT}
                    />
                    <ObjectField
                        value={
                            <SlotBadge
                                slotId={props.bulkTransaction.slot?.id.toString()}
                                name={props.bulkTransaction.slot?.slot_nr}
                            />
                        }
                        fieldName={CommonMessage.SLOTS.SLOT}
                    />
                    <ObjectField
                        value={props.bulkTransaction.tt_number}
                        fieldName={CommonMessage.TRANSACTIONS.COLUMNS.TRACKING_NUMBER}
                    />
                    <ModalSectionTitle title={'Transactions'} />
                    {TrxLoading ? (
                        <Spinner />
                    ) : (
                        <div className='d-flex flex-column'>
                            {transactionList.map((t: any) => (
                                <>
                                    <div className='row mb-2'>
                                        <div className='col-6'>
                                            <TransactionBadge transactionId={t.transaction} />
                                        </div>
                                        <div className='col-6'>
                                            {t.product.map((p: any) => (
                                                <ProductBadge
                                                    key={p.id}
                                                    productId={p.product}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BulkTransactionDetail;
