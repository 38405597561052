import { memo } from 'react';
import { TbAlertTriangle } from 'react-icons/tb';

import useAuthorizationConfig, { DefaultRole } from '../hooks/authorization/useAuthorizationConfig';

interface Props {
    role: DefaultRole;
    children: React.ReactNode;
    show?: boolean;
}

const Auth = (props: Props) => {
    const { auth, authCheck } = useAuthorizationConfig();

    if (authCheck(props.role)) {
        return <>{props.children}</>;
    }

    if (props.show === true) {
        return (
            <div
                className='d-flex justify-content-start bg-danger p-3 rounded'
                role='alert'>
                <span className='text-light me-3'>
                    <TbAlertTriangle />
                </span>
                <span className='text-light'>
                    <small>Unauthorized!</small>
                </span>
            </div>
        );
    }

    return <></>;
};

export default memo(Auth);
