import { FaPlus } from 'react-icons/fa';
import { FaFileImport } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';

import { ClassType } from '../../../common/BootstrapValues';
import Filter from '../../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import CreateModal from '../../../components/modals/CreateModal';
import DisplayModal from '../../../components/modals/DisplayModal';
import Table from '../../../components/tables/Table';
import FilterBar from '../FilterBar/FilterBar';
import ProductBreadcrumb from '../ProductBreadcrumb/ProductBreadcrumb';
import CreateProductForm from '../forms/create-product-form/CreateProductForm';
import ImportModal from '../import/ImportModal';
import ProductListElement from './ProductListElement';
import useProductList from './product-list.controller';

const PRODUCT_LIST_ID = 'ProductList';

export default function ProductList() {
    const viewProps = useProductList();

    if (viewProps.products.shopProductList === null) {
        return <Spinner />;
    }
    return (
        <>
            <div
                id={PRODUCT_LIST_ID}
                className='d-flex flex-row'>
                <div className={viewProps.showFilter ? `col-2 pe-2` : ``}>
                    {viewProps.showFilter && (
                        <FilterBar
                            onChange={(value) => viewProps.changeSelectedCategory(value)}
                            selectedCategory={viewProps.selectedCategory}
                            selectedAttributes={(value) => viewProps.changeSelectedAttributes(value)}
                        />
                    )}
                </div>
                <div className={viewProps.showFilter ? `col-10` : `col-12`}>
                    <div className='card'>
                        <div className='card-header d-flex'>
                            <div className='d-flex flex-column align-items-start w-100'>
                                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                    <div className='d-flex flex-row justify-content-start align-items-center'>
                                        <HorizontalButtonGroup
                                            buttons={[
                                                {
                                                    type: ClassType.PRIMARY,
                                                    hide: false,
                                                    text: (
                                                        <FormattedMessage
                                                            id='UserList-Create-Product'
                                                            description='Label for creating a product'
                                                            defaultMessage='Create Product'
                                                        />
                                                    ),
                                                    icon: FaPlus,
                                                    id: 'ProductList-CreateProductButton',
                                                    onClick: () => viewProps.setIsOpen(true)
                                                },
                                                {
                                                    type: ClassType.SECONDARY,
                                                    hide: false,
                                                    text: (
                                                        <FormattedMessage
                                                            id='ProductList.Button.Import'
                                                            description='The button for import on the product list component'
                                                            defaultMessage='Import'
                                                        />
                                                    ),
                                                    icon: FaFileImport,
                                                    onClick: () => viewProps.changeImportModal(true)
                                                }
                                            ]}
                                            direction='left'
                                        />
                                    </div>
                                    <div className='d-flex flex-row px-1'>
                                        <ProductBreadcrumb
                                            selectedCategory={viewProps.selectedCategory}
                                            onChange={(value) => viewProps.changeSelectedCategory(value)}
                                        />
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <div className='px-1'>
                                            <Filter
                                                objects={viewProps.data}
                                                filterCallback={viewProps.changeTextFilter}
                                                filterValue={(value) => viewProps.changeTextFilterValue(value.toLowerCase())}
                                                filterKeys={['name']}
                                                placeholder='Search'
                                                useSearch
                                                id='ProductList-Filter'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table
                            columns={viewProps.columns}
                            data={viewProps.data}
                            next={viewProps.products.shopProductList?.[viewProps.shopId!]?.next}
                            pageCount={viewProps.calculatePageCount()}
                            pageSize={(value) => viewProps.changePageSize(value)}
                            page={(value) => viewProps.changePageIndex(value + 1)}
                            RowElement={ProductListElement}
                            sortKeys={viewProps.sortKeys}
                            customSorting={(page, sortBy, sortOrder) => {
                                if (viewProps.sortKeys.includes(sortBy)) {
                                    if (sortOrder === undefined) {
                                        viewProps.changeCustomSorting(undefined);
                                    } else {
                                        viewProps.changeCustomSorting({ column: sortBy, orderBy: sortOrder });
                                    }
                                }
                            }}
                        />
                    </div>
                    {viewProps.isOpen && (
                        <CreateModal
                            show={viewProps.isOpen}
                            handleClose={() => viewProps.setIsOpen(false)}
                            header='Create Product'>
                            <CreateProductForm
                                pageSize={viewProps.pageSize}
                                handleClose={() => viewProps.setIsOpen(false)}
                            />
                        </CreateModal>
                    )}
                    {viewProps.importModal && (
                        <>
                            <DisplayModal
                                show={viewProps.importModal}
                                header='Import products'
                                handleClose={() => viewProps.changeImportModal(false)}>
                                <div className='modal-body'>
                                    <p>
                                        {Helptext1}
                                        <ul>
                                            <li>
                                                {Helptext2}
                                                <br />
                                                Name, Description, Category, EAN, SKU, Image Name, Weight, Weight Tolerance, Length, Width, Height, Diameter,
                                                Low Stock Threhsold Alert, Max Stock
                                            </li>
                                            <li>{Helptext3}</li>
                                            <li>{Helptext4}</li>
                                            <li>{Helptext5}</li>
                                            <li>{Helptext6}</li>
                                        </ul>

                                        {Helptext7}
                                    </p>
                                </div>
                                <ImportModal
                                    extension='image'
                                    handleClose={() => viewProps.changeImportModal(false)}
                                    helpText={
                                        <FormattedMessage
                                            id='ImportModal.helpText.Image'
                                            description='Help text for the image import'
                                            defaultMessage='Make sure the images you select have the exact name and extension as in your csv file'
                                        />
                                    }
                                />
                                <ImportModal
                                    extension='csv'
                                    handleClose={() => viewProps.changeImportModal(false)}
                                    helpText={
                                        <FormattedMessage
                                            id='ImportModal.helpText.CSV'
                                            description='Help text for the image import'
                                            defaultMessage='Make sure to upload your images first & that your csv contains the exact name and extension of the images'
                                        />
                                    }
                                />
                            </DisplayModal>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

const Helptext1 = (
    <FormattedMessage
        id='ImportModal.helpText.1'
        description='Help text for the product import'
        defaultMessage='When you are trying to upload products using a CSV import you should follow the following steps.'
    />
);
const Helptext2 = (
    <FormattedMessage
        id='ImportModal.helpText.2'
        description='Help text for the product import'
        defaultMessage='The csv file should contain the following columns (in the same order):'
    />
);
const Helptext3 = (
    <FormattedMessage
        id='ImportModal.helpText.3'
        description='Help text for the product import'
        defaultMessage='None of the fields are required except for the Name field. Note that the columns should always be there, the values can simply be empty.'
    />
);
const Helptext4 = (
    <FormattedMessage
        id='ImportModal.helpText.4'
        description='Help text for the product import'
        defaultMessage='When filling in fields like weight or lenght, do not add units. They will be added by the server.'
    />
);
const Helptext5 = (
    <FormattedMessage
        id='ImportModal.helpText.5'
        description='Help text for the product import'
        defaultMessage='Upload all images mentioned inside the csv file.'
    />
);
const Helptext6 = (
    <FormattedMessage
        id='ImportModal.helpText.6'
        description='Help text for the product import'
        defaultMessage='Upload the csv file itself.'
    />
);
const Helptext7 = (
    <FormattedMessage
        id='ImportModal.helpText.7'
        description='Help text for the product import'
        defaultMessage='Note that when filling in fields like weight or lenght, do not add units. They will be added by the server. For lengths use millimeters and for weights use grams.'
    />
);
