import { Props } from './slot-badge.component';

interface ViewProps {
    slot_nr: string | undefined;
}
const useSlotBadge = (props: Props): ViewProps => {
    const slot_nr = props.slot ? props.slot.slot_nr : props.name ? props.name : 'Unknown';

    return {
        slot_nr
    };
};

export default useSlotBadge;
