import { ApiError, ApiQueryParams, ApiViewSet, apiPagination, deleteApi, postApi } from '@frontend/api-utils';
import { Locale } from '@frontend/common';

const USER_INVITES_ENDPOINT = '/invitations/';

const invitationViewSet: ApiViewSet = {
    baseName: 'invitations'
};

export interface Invite {
    uuid: string;
    url: string;
    account_invitations: { account: number }[];
    language: Locale;
    email: string;
    accepted: boolean;
    sent: Date;
    created: Date;
    accounts: number[][];
}

export interface UserInviteData {
    count: number;
    next: string | null;
    previous: string | null;
    results: Invite[];
}

interface CreateInviteModel {
    accounts: number[];
    email: string;
    language: Locale;
}

export enum InviteQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGE_SIZE = 'page_size'
}

export async function fetchUserInvitesApi(queryParams?: ApiQueryParams<InviteQueryParams> | null): Promise<UserInviteData> {
    return await apiPagination<UserInviteData, InviteQueryParams>(invitationViewSet, queryParams);
}

export async function deleteUserInviteApi(inviteId: string): Promise<boolean> {
    const response = await deleteApi(`/invitations/${inviteId}/`);
    return await response.ok;
}

export async function sendInviteApi(body: CreateInviteModel) {
    const response = await postApi(USER_INVITES_ENDPOINT, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating invite');
        }
        throw new ApiError('Error creating invite', json);
    }
    return await response.json();
}
