import { Contact, fetchContact } from '@frontend/contact';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import EmailBadge from '../../components/badges/EmailBadge';
import PhoneNumberBadge from '../../components/badges/PhoneNumberBadge';
import QRCodeBadge from '../../components/badges/QRCodeBadge';
import ContactGroupBadge from '../../components/badges/contact-group-badge/contact-group-badge.component';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import convertLanguage from '../../utils/LanguageConvert';

interface ContactDetailProps {
    contact?: Contact;
}
//TODO fetching transactions like this should not happen -> this method creates a lot of overhead
export default function ContactDetail(props: ContactDetailProps) {
    //const membership = useAppSelector((state) => state.user.selectedMembership);
    //const transactionsState = useAppSelector((state) => state.transactions);
    const dispatch = useAppDispatch();
    const contactState = useAppSelector((state) => state.contacts);
    //const [linkedTransactions, changeLinkedTransactions] = useState<Transaction[] | undefined>(undefined);
    const [contact, changeContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        changeContact(props.contact);
        if (props.contact && props.contact.id !== contact?.id) dispatch(fetchContact({ contactId: props.contact.id }));
    }, [props.contact]);

    useEffect(() => {
        if (contactState.contactsList && props.contact) {
            const searchList = contactState.contactsList.results.filter((c) => c !== null && c !== undefined);
            const foundContact = searchList.find((c) => c.id !== null && c.id !== undefined && c.id === props.contact!.id);
            if (foundContact) {
                changeContact(foundContact);
            } else dispatch(fetchContact({ contactId: props.contact.id }));
        }
    }, [contactState.contactsList]);

    // useEffect(() => {
    //     ACTIVE_TRANSACTION_STATUSES.forEach((status) => {
    //         dispatch(fetchTransactions({ account: membership?.account.id.toString(), status: status.name, page: '1', page_size: '1000' }));
    //     });
    // }, []);

    // useEffect(() => {
    //     if (transactionsState.status === SliceStatus.IDLE && contact !== undefined) {
    //         changeLinkedTransactions(
    //             getAllActiveTransactions(transactionsState.transactionList).filter((tr) => {
    //                 if (tr.sender && +tr.sender.id === contact!.id) return true;
    //                 if (tr.receiver && +tr.receiver.id === contact!.id) return true;
    //                 return false;
    //             })
    //         );
    //     }
    // }, [transactionsState, contact]);
    if (contact === undefined) return <></>;

    const language = convertLanguage(contact.language);

    const birthday = contact.birthday ? new Date(contact.birthday) : undefined;
    return (
        <div className='d-flex flex-column'>
            <h5 className='d-flex justify-content-center'>
                <FormattedMessage
                    id='ContactDetail.infofield.title'
                    description='The infofield  title on the contact detail component.'
                    defaultMessage='Info'
                />
            </h5>
            {!!contact.title && (
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='ContactDetail.contact.title'
                            description='The contact title on the contact detail component.'
                            defaultMessage='Title'
                        />
                    </h6>
                    <p>{contact?.title}</p>
                </div>
            )}
            {!!birthday && (
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='ContactDetail.contact.birthday'
                            description='The contact birthday on the contact detail component.'
                            defaultMessage='Birthday'
                        />
                    </h6>
                    <p>{birthday.toLocaleDateString()}</p>
                </div>
            )}
            {!!contact.language && (
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='ContactDetail.contact.language'
                            description='The contact language on the contact detail component.'
                            defaultMessage='Language'
                        />
                    </h6>
                    <p>{language}</p>
                </div>
            )}
            {!!contact.mobile && (
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='ContactDetail.contact.mobile'
                            description='The contact mobile on the contact detail component.'
                            defaultMessage='Mobile'
                        />
                    </h6>
                    <p>
                        <PhoneNumberBadge phoneNumber={contact?.mobile} />
                    </p>
                </div>
            )}
            {!!contact.email && (
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='ContactDetail.contact.email'
                            description='The contact email on the contact detail component.'
                            defaultMessage='Email'
                        />
                    </h6>
                    <p>
                        <EmailBadge email={contact?.email} />
                    </p>
                </div>
            )}
            {!!contact.qr_code_access_keys && (
                <div className='d-flex justify-content-between'>
                    <h6>
                        <FormattedMessage
                            id='ContactDetail.contact.qr'
                            description='The contact qr on the contact detail component'
                            defaultMessage='QR'
                        />
                    </h6>
                    {contact.access_keys.map((key) => (
                        <QRCodeBadge
                            displayedValue={`Badge ${contact.first_name}`}
                            value={key}
                            key={key}
                            callback={() => dispatch(fetchContact({ contactId: props.contact?.id }))}
                        />
                    ))}
                </div>
            )}

            {contact.contact_groups.length > 0 ? (
                <>
                    <hr className='horizontal dark mt-0' />
                    <h5 className='d-flex justify-content-center'>
                        <FormattedMessage
                            id='ContactDetail.contact.groups'
                            description='The contact groups on the contact detail component.'
                            defaultMessage='Groups'
                        />
                    </h5>
                    <div className='row'>
                        {contact.contact_groups_obj.map((cg) => (
                            <div
                                className='col-6 d-flex flex-row'
                                key={cg.id}>
                                <ContactGroupBadge
                                    contactGroupId={cg.id.toString()}
                                    name={cg.name}
                                />
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <></>
            )}
            <hr className='horizontal dark mt-0' />
            <h5 className='d-flex justify-content-center'>
                <FormattedMessage
                    id='ContactDetail.extrainfofield.title'
                    description='The extrainfofield  title on the contact detail component.'
                    defaultMessage='Extra info'
                />
            </h5>
            <p>{JSON.stringify(contact.additional_data, null, 2)}</p>

            {/* <hr className='horizontal dark mt-0' />
            <h5 className='d-flex justify-content-center'>
                <FormattedMessage
                    id='ContactDetail.transactionsField.title'
                    description='The transactions field title on the contact detail component.'
                    defaultMessage='Transactions'
                />
            </h5>
            <div className='d-flex flex-row justify-content-around flex-wrap'>
                {linkedTransactions ? (
                    linkedTransactions.map((tr) => (
                        <span
                            key={tr.id}
                            className='pe-3'>
                            <TransactionBadge transactionId={tr.id} />
                        </span>
                    ))
                ) : (
                    <p className='d-flex placeholder-glow w-25'>
                        <span className='placeholder col-12'></span>
                    </p>
                )}
            </div> */}
        </div>
    );
}
