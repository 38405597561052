import { FEATURES } from '@frontend/feature-flags';
import { useContext, useEffect, useRef, useState } from 'react';

import { MainContext } from '../contexts/MainContext';
import PubSubSubscription, { FilterPolicy, PubSubTopic } from './PubSubSubscription';

interface Props {
    name?: string;
    topic: PubSubTopic;
    filter_policy?: FilterPolicy;
    enabled?: boolean;
}

export default function usePubSubSubscription(props: Props) {
    const { pubSub } = useContext(MainContext);
    const [subscription, changeSubscription] = useState<PubSubSubscription | undefined>(undefined);
    const cleanup = useRef(() => undefined);

    useEffect(() => {
        if (props.enabled && pubSub.isReady && FEATURES.pubSub) {
            const timeout = setTimeout(() => {
                const sub = new PubSubSubscription(props.topic, props.name, props.filter_policy);
                sub.subscribe(pubSub.send);
                if (subscription == undefined) changeSubscription(sub);
                cleanup.current = () => {
                    sub.unsubscribe(pubSub.send);
                };
            }, 500);

            return () => {
                clearTimeout(timeout);
                cleanup.current();
            };
        }
    }, [props.enabled, pubSub.isReady]);

    useEffect(() => {
        if (
            subscription &&
            JSON.stringify(subscription.filter_policy) != JSON.stringify(props.filter_policy) &&
            props.enabled &&
            pubSub.isReady &&
            FEATURES.pubSub
        ) {
            const timeout = setTimeout(() => {
                subscription.unsubscribe(pubSub.send).then(() => {
                    const sub = new PubSubSubscription(props.topic, props.name, props.filter_policy);
                    sub.subscribe(pubSub.send);
                    changeSubscription(sub);
                    cleanup.current = () => {
                        sub.unsubscribe(pubSub.send);
                    };
                });
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [props.filter_policy]);
}
