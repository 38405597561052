import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { msalConfig } from './app/config/authConfig';
import { store } from './app/store/store';
import './assets/js/argon-dashboard.js';
import './assets/js/core/bootstrap.min.js';
import './assets/js/core/popper.min.js';
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './serviceWorkerRegistration';
import './styles.scss';
// eslint-disable-next-line @nx/enforce-module-boundaries
import '/node_modules/react-grid-layout/css/styles.css';
// eslint-disable-next-line @nx/enforce-module-boundaries
import '/node_modules/react-resizable/css/styles.css';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
function render() {
    //TODO dirty fix (if else should be removed) -> https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5091
    if (window.location.hash !== '') {
        console.log('hash found' + window.location.hash);
    } else {
        root.render(
            <React.StrictMode>
                <MsalProvider instance={msalInstance}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </MsalProvider>
            </React.StrictMode>
        );
    }
}

render();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Service worker
registerServiceWorker();

//unregisterServiceWorkers();
/*

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then(function (registration) {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(function (error) {
      console.error('Service Worker registration failed:', error);
    });
}
*/

/**
 * TODO
 * this function is needed to remove any existing service workers
 * after some period we can remove this function since no service workers are being registered anymore so it wont have any use
 * its only for transitioning from an app with one to an app without one
 */
/*
async function unregisterServiceWorkers() {
    try {
        const registrations = await navigator.serviceWorker.getRegistrations();

        for (const registration of registrations) {
            await registration.unregister();
            console.log(`Service worker for ${registration.scope} unregistered`);
        }

        console.log('All service workers unregistered');
    } catch (error) {
        console.error('Error while unregistering service workers:', error);
    }
}
*/
