import { Logger } from '@frontend/Logger';

import { fetchBulkTransactionById } from '../../store/bulkTransactionSlice';
import { PubSubEvent } from '../usePubSubEventListener';

export default class BulkTransactionEventHandler {
    static handle = (dispatch: ({ ...args }) => void, event: PubSubEvent): void => {
        if (event.content.new != null) {
            Logger.log(`Bulk transaction id:{${event.content.new.id}} event received.`);
            dispatch(fetchBulkTransactionById(event.content.new.id));
        } else if (event.content.old != null) {
            Logger.log(`Bulk transaction id:{${event.content.old.id}} event received.`);
            dispatch(fetchBulkTransactionById(event.content.old.id));
        }
    };
}
