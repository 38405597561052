import { useEffect, useState } from 'react';

import { SpotLayoutItem } from '../../api/SpotLayoutItems';

interface Props {
    spotLayoutItem: SpotLayoutItem;
    spotWidth: number;
    spotHeight: number;
    label?: string;
    occupied?: boolean;
    selected?: boolean;
    highlightedSlots?: boolean;
    highlighted?: boolean;
    onClick?: (item: SpotLayoutItem) => void;
    id?: string;
}

const Item = (props: Props) => {
    const [itemClass, changeItemClass] = useState<string>('normal');
    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${(100 * props.spotLayoutItem.origin_x) / props.spotWidth}%`,
        right: `${100 - (100 * (props.spotLayoutItem.origin_x + props.spotLayoutItem.width)) / props.spotWidth}%`,
        bottom: `${(100 * props.spotLayoutItem.origin_y) / props.spotHeight}%`,
        top: `${100 - (100 * (props.spotLayoutItem.origin_y + props.spotLayoutItem.height)) / props.spotHeight}%`
    };

    useEffect(() => {
        if (props.spotLayoutItem.terminal) {
            changeItemClass('terminal');
        } else if (props.highlighted === true) {
            changeItemClass('highlighted');
        } else if (props.occupied === true) {
            changeItemClass('occupied');
        } else if (props.selected) {
            changeItemClass('selected');
        } else if (props.highlightedSlots) {
            changeItemClass('highlighted-slot');
        } else {
            changeItemClass('normal');
        }
    }, [props.spotLayoutItem, props.occupied, props.selected, props.highlightedSlots]);

    const label = props.label ? props.label : props.spotLayoutItem.name;

    const handleClick = () => {
        if (!props.occupied) {
            props.onClick && props.onClick(props.spotLayoutItem);
        }
    };

    return (
        <div
            style={style}
            id={props.id}
            className={'spotlayout-item spotlayout-item-' + itemClass + ' align-items-center justify-content-center text-white'}
            onClick={handleClick}>
            <div>{label}</div>
        </div>
    );
};

export default Item;
